import React from 'react';
import useFetch from '../../utils/useFetch';

//Images
const Upgrade = () =>{
	const {data, loading, error} = useFetch("http://localhost:8080/admin/users");

	if (loading) return <span>Loading...</span>;
	if (error) console.error(error);

	return(
		<>
			<h1>Upgrade</h1>
		</>
	)
}
export default Upgrade;