import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import Orders from "./components/Dashboard/Orders";
import OrderDetail from "./components/Dashboard/OrderDetail/OrderDetail";
import Products from "./components/Dashboard/Products";
import ProductDetail from "./components/Dashboard/ProductDetail/ProductDetail";
import Payments from "./components/Dashboard/Payments/Payments";
import Suppliers from "./components/Dashboard/Suppliers";
import SupplierProfile from "./components/Dashboard/SupplierProfile";
import ClientProfile from "./components/Dashboard/ClientProfile";
import Clients from "./components/Dashboard/Clients";
import Inventory from "./components/Dashboard/Inventory/Inventory";
import MyWarehouse from "./components/Dashboard/MyWarehouse";
import AccountApp from "./components/Dashboard/Settings/AccountApp";
import UserPermissions from "./components/Dashboard/Settings/UserPermissions";
import BillingPayments from "./components/Dashboard/Settings/BillingPayments/BillingPayments";
import Integrations from "./components/Dashboard/Settings/Integrations/Integrations";
import Notifications from "./components/Dashboard/Settings/Notifications";
import Upgrade from "./components/Dashboard/Settings/Upgrade";
import Messages from "./components/Dashboard/Messages";

/// Pages
import Registration from "./pages/Registration";
import SignUpSupplier from "./pages/SignUpSupplier";
import SignUpBusiness from "./pages/SignUpBusiness";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import AmazonCallback from "./pages/AmazonCallback";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import ViewNotificaion from "./components/Dashboard/notifications/notifications";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "orders", component: Orders },
    { url: "order-detail/:id", component: OrderDetail },
    { url: "payments", component: Payments },
    { url: "products", component: Products },
    { url: "product-detail/:id", component: ProductDetail },
    { url: "suppliers", component: Suppliers },
    { url: "supplier-profile/:id", component: SupplierProfile },
    { url: "clients", component: Clients },
    { url: "client-profile/:id", component: ClientProfile },
    { url: "inventory", component: Inventory },
    { url: "my-warehouse", component: MyWarehouse },
    { url: "messages", component: Messages },

    
    /// Settings
    { url: "account-app", component: AccountApp },
    { url: "user-permissions", component: UserPermissions },
    { url: "billing-payments", component: BillingPayments },
    { url: "integrations", component: Integrations },
    { url: "notifications", component: Notifications },
    { url: "upgrade", component: Upgrade },

    /// pages
    { url: "page-register", component: Registration },
    { url: "register-supplier", component: SignUpSupplier },
    { url: "register-business", component: SignUpBusiness },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-amazon_callback", component: AmazonCallback },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    { url: "view-notifications", component: ViewNotificaion}
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
      <ScrollToTop />
    </>
  );
};

export default Markup;
