import React from "react";
import amazonLogo from "./../../../../../images/freight/amazon.png";
import { indexOf } from 'lodash';

const AmazonShippingCard = ({
  shipmentDetails,
  onClickUpdate,
  orderUnits,
  receivedUnits,
}) => {
  return (
    <>
      {shipmentDetails && <div className="amazon_tracking mb-4">
        <div className="mb-3">
          <div className="d-flex align-items-center project-image mb-3">
            <img src={amazonLogo} alt="" />
            <div>
              <small className="d-block fs-16 font-w700">Shipping ID</small>
              <span className="d-block fs-12 font-w500">{shipmentDetails?.refId}</span>
            </div>
          </div>
          <span className="d-block fs-16 text-black font-w600">{shipmentDetails?.shipmentStatus}</span>
          <div className="progress mb-2">
            <div
              className="progress-bar progress-animated"
              style={{ width: `${(100 * (1 + indexOf(['WORKING', 'SHIPPED', 'CHECKED_IN', 'RECEIVING', 'CLOSED'], shipmentDetails?.shipmentStatus)) / 5)}%` }}
            ></div>
          </div>
          {shipmentDetails && <span className="d-block fs-12 font-w500">
            {shipmentDetails?.fulfillmentCenterId}
            {shipmentDetails?.destinationAddressLineOne ? ', ' + shipmentDetails?.destinationAddressLineOne : ''}
            {shipmentDetails?.destinationCity ? ', ' + shipmentDetails?.destinationCity : ''}
            {shipmentDetails?.destinationStateOrProvinceCode ? ', ' + shipmentDetails?.destinationStateOrProvinceCode : ''}
            {shipmentDetails?.destinationPostalCode ? ', ' + shipmentDetails?.destinationPostalCode : ''}
            {shipmentDetails?.destinationCountryCode ? ', ' + shipmentDetails?.destinationCountryCode : ''}
          </span>}
        </div>
        <div className="row">
          <div className="col-xl-6">
            <h6 className="mb-0">Units expected</h6>
            <div className="">
              <span className="fs-18 font-w600 d-block text-success">
                {shipmentDetails?.shippingUnits}
              </span>
            </div>
          </div>
          <div className="col-xl-6">
            <h6 className="mb-0">Units received</h6>
            <div className="">
              <span className="fs-18 font-w400 d-block text-danger">
                {shipmentDetails?.recievedUnits}
              </span>
            </div>
          </div>
        </div>
      </div>}
      {!shipmentDetails && <div className="card-footer text-center border-0 mt-0">
        <button type="button" className="me-2 btn btn-primary btn-rounded" onClick={onClickUpdate}>
          <span className="btn-icon-start text-primary">
            <i className="fab fa-amazon"></i>
          </span>
          + Shipping ID
        </button>
      </div>}
    </>
  );
};

export default AmazonShippingCard;
