import React, { useState, useCallback, useEffect } from 'react'
import InventorySumary from './InventorySummary'
import InventoryRow from './InventoryRow'
import { dataMultiSortByRequest } from '../../../../connector/httpConnector/httpRequest'
import { useStateValue } from '../../../../store/selectors/useStateValue'
import { config } from '../../../../connector/config'
import LoadingSpinner from '../../elements/LoadingSpinner/LoadingSpinner'
import Paginator from '../../elements/Paginator/Paginator'
import { useFetchProfile } from '../../../../data/account-data'
import { Spinner } from 'react-bootstrap'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const SortIcon = ({ onClick, order }) => {
  if (order === 'asc') {
    return (
      <i
        role="button"
        className="fa fa-sort-down"
        onClick={onClick}
        style={{ marginLeft: 7 }}
      ></i>
    )
  } else if (order === 'desc') {
    return (
      <i
        role="button"
        className="fa fa-sort-up"
        onClick={onClick}
        style={{ marginLeft: 7 }}
      ></i>
    )
  } else {
    return (
      <i
        role="button"
        className="fa fa-sort"
        onClick={onClick}
        style={{ marginLeft: 7 }}
      ></i>
    )
  }
}
const Inventory = () => {
  const location = useLocation()
  const { auth } = useStateValue()
  const [productsData, setProducts] = useState([])
  const fetchProfile = useFetchProfile()
  const { baseURL, businessGetProducts } = config
  const [integratedProductsData, setIntegratedProductsData] = useState()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get('page')) || 1
  )
  const [sort, setSort] = useState([])
  // useEffect(() =>{
  //     setSort([
  //       ['fbaAvailable', undefined],
  //       ['dailySales', undefined],
  //       ['withInboundOrderWithin', undefined],
  //       ['stockInboundLeftDays', undefined],
  //       ['actionStatus', undefined]
  //     ])
  // },[])

  const updateSortOrder = (colName, order = 'asc') => {
    let copySort = [...sort]
    let index = copySort.findIndex((ele) => ele[0] === colName)
    if (index !== -1) {
      copySort.splice(index, 1)
    }
    copySort.unshift([colName, order])
    setSort(copySort)
  }

  const getSortOrder = (colName) => {
    let copySort = [...sort]
    let index = copySort.findIndex((ele) => ele[0] === colName)
    if (index === -1) {
      return null
    }
    let sortCol = copySort[index]
    return sortCol[1]
  }

  const swicthSortOrder = (colName) => {
    const order = getSortOrder(colName)
    if (order === 'asc') {
      updateSortOrder(colName, 'desc')
    }
    if (order === 'desc') {
      updateSortOrder(colName, 'asc')
    }
    if (order === null) {
      updateSortOrder(colName, 'asc')
    }
  }

  const clearSortOrder = (colName) => {
    let copySort = [...sort]
    let index = copySort.findIndex((ele) => ele[0] === colName)
    if (index !== -1) {
      copySort.splice(index, 1)
      setSort(copySort)
    }
  }

  const token = auth.auth.accessToken

  const sortProducts = useCallback(() => {
    dataMultiSortByRequest(
      baseURL,
      token,
      businessGetProducts,
      'get',
      page,
      12,
      sort
    )
      .then((data) => {
        setIntegratedProductsData(data)
        setProducts(data.data.products)
        setLoading(false)
        const queryParams = new URLSearchParams({
          page: page,
        })
        window.history.pushState(null, null, `?${queryParams.toString()}`)
      })
      .catch((error) => {
        setLoading(false)
      })
  }, [baseURL, token, businessGetProducts, sort, page])

  useEffect(() => {
    setLoading(true)
    sortProducts()
  }, [sortProducts, sort, page])

  const handleChangePage = useCallback(
    (page) => {
      setPage(page)
      const params = new URLSearchParams(window.location.search)
      params.set('page', page)
      window.history.pushState(
        {},
        '',
        `${location.pathname}?${params.toString()}`
      )
    },
    [location.pathname]
  )

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const pageParam = params.get('page')
    setPage(pageParam ? parseInt(pageParam) : 1)
  }, [])

  useEffect(() => {
    const handlePopState = () => {
      const params = new URLSearchParams(window.location.search)
      const pageParam = params.get('page')
      setPage(pageParam ? parseInt(pageParam) : 1)
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  const inventorySummary = integratedProductsData?.data

  return (
    <>
      {loading ? (
        <LoadingSpinner size={150} />
      ) : (
        <>
          {productsData.length === 0 && (
            <div>
              <Spinner
                className="me-2"
                size={'sm'}
                style={{ float: 'left' }}
                animation="border"
              ></Spinner>
              <h6>Products syncing! Expect to see them shortly</h6>
            </div>
          )}
          <div className="row">
            <InventorySumary
              availableUnits={integratedProductsData?.totalAvailableUnits}
              totalInbound={integratedProductsData?.totalInboundUnits}
              riskUnits={integratedProductsData?.totalOrderNowUnits}
              attentionUnits={integratedProductsData?.totalAttentionNowUnits}
            />
          </div>
          {productsData.length > 0 && (
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div id="inventory_data" className="dataTables_wrapper">
                        {/* <Button
                        variant="success" size="xs" className="reset_sort"
                        disabled={(sort.length===0)} onClick={()=>setSort([])}
                      >
                        <i className="fa fa-redo"></i>
                      </Button> */}
                        <table
                          className="display w-100 dataTable "
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                className="sorting"
                                style={{ width: '150px' }}
                              >
                                SKU
                                <SortIcon
                                  order={getSortOrder('productSKU')}
                                  onClick={() => swicthSortOrder('productSKU')}
                                />
                                <div className="mt-1 fs-12 font-w500">
                                  {/* <span className="font-w600 text-primary">
                                  SKU
                                </span> */}
                                  <span className="d-block fs-14 font-w500 text-primary">
                                    ASIN
                                    <SortIcon
                                      order={getSortOrder('productASIN')}
                                      onClick={() =>
                                        swicthSortOrder('productASIN')
                                      }
                                    />
                                  </span>
                                </div>
                              </th>
                              <th
                                className="sorting"
                                style={{ width: '100px' }}
                              >
                                Available
                                <SortIcon
                                  order={getSortOrder('fbaAvailable')}
                                  onClick={() =>
                                    swicthSortOrder('fbaAvailable')
                                  }
                                />
                                <span className="d-block fs-14 font-w500 text-primary">
                                  Inbound
                                  <SortIcon
                                    order={getSortOrder('inboundUnits')}
                                    onClick={() =>
                                      swicthSortOrder('inboundUnits')
                                    }
                                  />
                                </span>
                              </th>
                              <th
                                className="sorting"
                                style={{ width: '100px' }}
                              >
                                Daily Sales
                                <SortIcon
                                  order={getSortOrder('dailySales')}
                                  onClick={() => swicthSortOrder('dailySales')}
                                />
                              </th>
                              <th
                                className="sorting"
                                style={{ width: '180px' }}
                              >
                                Stock Left
                                <SortIcon
                                  order={getSortOrder('stockLeftDays')}
                                  onClick={() =>
                                    swicthSortOrder('stockLeftDays')
                                  }
                                />
                                <span className="d-block fs-14 font-w500 text-primary">
                                  With Inbound
                                  <SortIcon
                                    order={getSortOrder('stockInboundLeftDays')}
                                    onClick={() =>
                                      swicthSortOrder('stockInboundLeftDays')
                                    }
                                  />
                                </span>
                              </th>
                              <th
                                className="sorting"
                                style={{ width: '180px' }}
                              >
                                Order In
                                <SortIcon
                                  order={getSortOrder('orderWithin')}
                                  onClick={() => swicthSortOrder('orderWithin')}
                                />
                                <span className="d-block fs-14 font-w500 text-primary">
                                  With Inbound
                                  <SortIcon
                                    order={getSortOrder(
                                      'withInboundOrderWithin'
                                    )}
                                    onClick={() =>
                                      swicthSortOrder('withInboundOrderWithin')
                                    }
                                  />
                                </span>
                              </th>
                              <th
                                className="sorting"
                                style={{ width: '100px' }}
                              >
                                Action
                                <SortIcon
                                  order={getSortOrder('actionStatus')}
                                  onClick={() =>
                                    swicthSortOrder('actionStatus')
                                  }
                                />
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <InventoryRow inventoryData={productsData} />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {inventorySummary?.totalPages > 1 && (
                <Paginator
                  total={inventorySummary.totalPages}
                  current={page}
                  onChangePage={handleChangePage}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Inventory
