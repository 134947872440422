import { useMutation, useQuery } from "react-query";
import { useStateValue } from "../store/selectors/useStateValue";
import { config } from "../connector/config";
import http from "./http";

export const  useFetchSupplierLocations = () => {
    const { auth } = useStateValue()
    return useMutation(({supplierId, query}) =>{
        let params = {
            currPage: 1, perPage: 20
        }
        if(query){
            params['search'] = query;
        }

        return http().request({
            url:    `${config.businessSupplierLocations}`.replace(`{supplierId}`,supplierId),
            method: 'GET',
            params:  params,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
          })
    })
}

export const  useFetchSupplierLocationById = () => {
    const { auth } = useStateValue()
    return useMutation(({supplierId, locId}) =>{

        return http().request({
            url:    `${config.businessSupplierLocations}/${locId}`.replace(`{supplierId}`,supplierId),
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
          })
    })
}