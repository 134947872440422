import React, { useState, useEffect } from "react";
import LoadingSpinner from "../components/elements/LoadingSpinner/LoadingSpinner";
import swal from "sweetalert";

import { useStateValue } from "../../store/selectors/useStateValue";
import { connectAmazonRequest } from "../../connector/httpConnector/httpRequest";
import { config } from "../../connector/config";
import { useHistory } from "react-router-dom";
import { useConnectSellerAccount } from "../../data/account-data";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AmazonCallback = () => {
  // const { auth } = useStateValue();
  const history = useHistory();
  // const [amazonConnectStatus, setAmazonConnectStatus] = useState();
  const connectSellerAcountData = useConnectSellerAccount()
  const location = useLocation()

  // const urlParams = new URLSearchParams(window.location.search);
  // const oAuthCode = urlParams.get("spapi_oauth_code");
  // const partnerId = urlParams.get("selling_partner_id");
  // const regionId  = urlParams.get("state");

  // const { baseURL } = config;
  // const token = auth.auth.accessToken;

  // console.log("oAuthCode", oAuthCode);
  // console.log("partnerId", partnerId);
  // console.log()

  // useEffect(() => {
  //   connectAmazonRequest(baseURL, token, oAuthCode, partnerId, regionId,'post')
  //     .then((data) => {
  //       setAmazonConnectStatus(data);
  //     })
  //     .catch((error) => {
  //       setAmazonConnectStatus(error.response);
  //     });
  // }, [baseURL, oAuthCode, partnerId, regionId,token]);

  useEffect(async () => {
    const urlParams = new URLSearchParams(location.search);
    const oAuthCode = urlParams.get("spapi_oauth_code");
    const partnerId = urlParams.get("selling_partner_id");
    const regionId = urlParams.get("state");
    try {
      const response = await connectSellerAcountData.mutateAsync({
        params: {
          spapi_oauth_code: oAuthCode,
          selling_partner_id: partnerId,
          regionCode: regionId
        }
      })
      swal("Good job!", "Successfully Integrated", "success");
    }
    catch (error) {
      swal("Oops", JSON.parse(error?.request?.response)?.message || "Something went wrong", "error");
    }
  },[location.search])

  // const failStatus = amazonConnectStatus?.data?.status === "fail";
  // const error400   = amazonConnectStatus?.status === 400;
  // const error500   = amazonConnectStatus?.status === 500;

  // if (failStatus || error400 || error500) {
  //    history.push("/integrations");
  // } else {
  //   history.push("/integrations");
  // }

  useEffect(()=>{
        if(!connectSellerAcountData.isLoading){
            history.push("/integrations");
        }
  },[connectSellerAcountData.isLoading])
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-12">
            <LoadingSpinner size={150} />
            <div className="form-input-content text-center error-page">
              <h1 className="error-text font-weight-bold">Connecting...</h1>
              <h4>
                <i className="fa fa-exclamation-triangle text-danger" /> Please
                don't refresh
              </h4>
              <p>We are connecting with Amazon</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmazonCallback;
