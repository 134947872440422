export const initialInvoiceFormState = {
  totalCost: "",
  shippingCost: "",
  invoiceNumber: "",
  invoiceInfo: {},
  invoiceSRC: "",
  createdDate: "",
};

export const initialBatchInfoState = {
  lotNumber: "",
  expiryDate: "",
  batchNumber: "",
  color: "",
  modelNumber: "",
  type: "",
  size: "",
};

export const initialShipmentInfoState = {
  id: "",
  shipmentName: "",
};


// export const initialShipmentDetailsState = {
//      boxes: [
//        { length: ``, width: ``, height: ``, weight: ``, quantity: ``},
//        { length: ``, width: ``, height: ``, weight: ``, quantity: ``}
//      ],
// }