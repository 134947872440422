import React, { useState, useEffect } from 'react';
import { Dropdown, ButtonGroup, SplitButton } from "react-bootstrap";
import { useUserCreate, GetUserTeamData, useDeleteUserTeam, useUserModify } from "../../../../data/user-data"
import swal from "sweetalert";
//Images
const UserPermissions = () => {
  const [currPage, setCarrPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const fetchUserTeamData = GetUserTeamData(currPage, perPage);
  const userCreateMutation = useUserCreate();
  const DeleteUserTeam = useDeleteUserTeam();
  const UpdateUserRole = useUserModify();
  const [email, setEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState('member');
  const [selectedRoleUpdate, setSelectedRoleUpdate] = useState('');
  useEffect(() => {
  }, [fetchUserTeamData.data])
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleRoleSelect = (role) => {
    setSelectedRole(role.toLowerCase()); // Convert role to lowercase and an array
  }

  const handleInviteClick = () => {
    try {
      if (email && selectedRole.length > 0) {
        userCreateMutation.mutate({ email, role: [selectedRole] });
      } else {
        swal("Please enter an email and select a role before inviting.");
      }
    }
    catch (error) {
      console.error(error, 'check')
    }
  }
  useEffect(() => {
    if (!userCreateMutation.isLoading) {
      if (userCreateMutation.isSuccess) {
        swal("Good job!", "Successfully Invited", "success");
      }
      if (userCreateMutation.isError) {
        swal("Oops", JSON.parse(userCreateMutation.error.request.response).message, "error");
      }
      userCreateMutation.reset()
    }

  }, [
    userCreateMutation.data,
    userCreateMutation.isError,
    userCreateMutation.isSuccess,
    userCreateMutation.isLoading
  ])

  const handleDeleteClick = (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      const userIdToDelete = parseInt(userId);
      DeleteUserTeam.mutate({ userIdToDelete });

    }
  };

  const handleRoleUpdate = (role, userId) => {
    setSelectedRoleUpdate(role.toLowerCase());
    UpdateUserRole.mutate({ userIdToModify: userId, newRole: [role.toLowerCase()] });
  }
  return (
    <>
      <div className="project-page d-flex justify-content-between align-items-center flex-wrap">
        <div className="input-group mb-4 d-flex justify-content-end">
          <div className="col-sm-6 contact-name">
            <div className="input-group input-primary">
              <input
                type="text"
                className="form-control user_invite"
                autoComplete="off"
                name="productWidth"
                placeholder="Enter Email Address"
                value={email}
                onChange={handleEmailChange}
              />
              <SplitButton
                as={ButtonGroup}
                id="dropdown-button-drop-down"
                drop="down"
                variant="primary btn-square"
                size="sm"
                title={selectedRole}
                className=""
              >
                <Dropdown.Item href="#" onClick={() => handleRoleSelect("member")}>Member</Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => handleRoleSelect("guest")}>Guest</Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => handleRoleSelect("admin")}>Admin</Dropdown.Item>
              </SplitButton>
              <button className="btn btn-primary" type="button" onClick={handleInviteClick}>
                Invite
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12" >
        <div className="card">
          <div className="card-body">
            <div className="table-responsive pb-5 mb-5">
              <div
                id="permission_data"
                className="dataTables_wrapper pb-5 mb-5"
              >
                <table
                  className="display w-100 dataTable pb-5 mb-5"
                  id="example5"
                  role="grid"
                  aria-describedby="example5_info"
                >
                  <thead>
                    <tr role="row">
                      <th className="sorting" style={{ width: "220px" }}>
                        Name
                      </th>
                      <th className="sorting" style={{ width: "230px" }}>
                        Email
                      </th>
                      <th className="sorting" style={{ width: "120px" }}>
                        Role
                      </th>
                      <th className="sorting" style={{ width: "100px" }}>
                        Settings
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {fetchUserTeamData.data?.data.teams.map((user) => (
                      <tr key={user.id} role="row">
                        <td>
                          <div className="d-flex align-items-center flex-wrap">
                            <div>
                              <div className="mt-1 fs-12 font-w500">
                                <span className="d-block font-w600 fs-16 text-primary">
                                  {user.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <span className="fs-16 font-w500 text-black me-2">
                              {user.email}
                            </span>
                          </div>
                        </td>
                        <td>
                          <SplitButton
                            as={ButtonGroup}
                            id="dropdown-button-drop-down"
                            drop="down"
                            variant="primary"
                            size="sm"
                            title={user.role || selectedRoleUpdate}
                            className="mt-1 me-1"
                          >
                            <Dropdown.Item href="#" onClick={() => handleRoleUpdate('Member', user.id)}>Member</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => handleRoleUpdate('Guest', user.id)}>Guest</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => handleRoleUpdate('Admin', user.id)}>Admin</Dropdown.Item>
                            <div className="dropdown-divider"></div>
                            <Dropdown.Item href="#">Remove Access</Dropdown.Item>
                          </SplitButton>
                        </td>
                        <td>
                          <div>
                            <a
                              className="btn btn-danger shadow btn-xs sharp"
                              href="#"
                              onClick={() => handleDeleteClick(user.id)}
                            >
                              <i className="fa fa-trash"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
export default UserPermissions;
