import {
  SET_WarehouseS_ACTION,
  SET_SELECTED_Warehouse_ID,
} from '../actions/warehouseTypes'

const initialState = {
  warehousesState: [],
  selectedWarehouseId: 0,
}

export default function WarehousesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WarehouseS_ACTION:
      const updatedState = {
        ...state,
        warehousesState: action.warehouses,
      }
      return updatedState

    case SET_SELECTED_Warehouse_ID:
      return {
        ...state,
        ...state.warehousesState,
        selectedWarehouseId: action.id,
      }

    default:
      return state
  }
}
