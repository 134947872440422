import React from "react";

const UploadbuttonProductDetails = ({ onClickUpload}) => {
  return (
  
              <div className="mt-3">
                <button
                  type="button"
                  className="me-2 btn btn-primary btn-rounded"
                  onClick={onClickUpload}
                >
                  <span className="btn-icon-start text-primary">
                    <i className="fas fa-plus"></i>
                  </span>
                  Upload Images
                </button>
              </div>
           
  );
};

export default UploadbuttonProductDetails;
