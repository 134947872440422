import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import logo from '../../images/app-logo.png'
import { useUploadResetPassword } from '../../data/forgot-password-data'
import swal from 'sweetalert'
const ForgotPassword = () => {
  const UploadResetPassword = useUploadResetPassword()
  const [email, setEmail] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await UploadResetPassword.mutateAsync({ data: { email } })
      swal(
        'Success!',
        'Password reset link sent, please check your email.',
        'success'
      )
    } catch (error) {
      swal('Oops', 'Email not recognized. Please check and try again.', 'error')
    }
  }

  return (
    <>
      <div className="authincation h-100 p-meddle">
        <div className="authincation_header">
          <div className="authincation_header_logo text-center mb-4 pt-1">
            <a href="https://app.bluudo.com/login">
              <img src={logo} alt="Logo" />
            </a>
          </div>
        </div>
        <div className="container h-100">
          {' '}
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content style-2">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                    <h3 className="text-center mb-4 text-black">Forgot Password</h3>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label className="">
                            <strong>Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div
                          className="text-center"
                          style={{ marginTop: '20px' }}
                        >
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={UploadResetPassword.isLoading}
                          >
                            {UploadResetPassword.isLoading ? (
                              <Spinner size="md" as="span" animation="border" />
                            ) : (
                              <span>SUBMIT</span>
                            )}
                          </button>
                        </div>
                      </form>
                      <div className="new-account mt-3">
                        <p className="">
                          Already have an account?{' '}
                          <Link className="text-primary" to="/login">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
