/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

/// Image
import { useStateValue } from "../../../store/selectors/useStateValue";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const { auth } = useStateValue();
  const isBusinessUser = auth.userType === "business";

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);
  // For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = ["", "dashboard", "calendar", "kanban", "task"],
    orders = ["orders"],
    products = ["products"],
    payments = ["payments"],
    suppliers = ["suppliers"],
    clients = ["clients"],
    inventory = ["inventory"],
    myWarehouse = ["my-warehouse"],
    settings = [
      "account-app",
      "user-permissions",
      "billing-payments",
      "integrations",
      "notifications",
      "upgrade",
    ];

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link to="/dashboard" className="ai-icon">
              <i className="fas fa-home"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li className={`${orders.includes(path) ? "mm-active" : ""}`}>
            <Link to="/orders" className="ai-icon">
              <i className="fas fa-ship"></i>
              <span className="nav-text">Orders</span>
            </Link>
          </li>
          {isBusinessUser && (
            <>
              <li className={`${products.includes(path) ? "mm-active" : ""}`}>
                <Link to="/products" className="ai-icon">
                  <i className="fas fa-list"></i>
                  <span className="nav-text">Products</span>
                </Link>
              </li>
              <li className={`${inventory.includes(path) ? "mm-active" : ""}`}>
                <Link to="/inventory" className="ai-icon">
                  <i className="fas fa-box"></i>
                  <span className="nav-text">Inventory</span>
                </Link>
              </li>
              <li
                className={`${myWarehouse.includes(path) ? "mm-active" : ""}`}
              >
                <Link to="/my-warehouse" className="ai-icon">
                  <i className="fas fa-warehouse"></i>
                  <span className="nav-text">My Warehouse</span>
                </Link>
              </li>
            </>
          )}
          <li className={`${payments.includes(path) ? "mm-active" : ""}`}>
            <Link to="/payments" className="ai-icon">
              <i className="fas fa-money-bill"></i>
              <span className="nav-text">Payments</span>
            </Link>
          </li>
          <li
            className={
              isBusinessUser
                ? `${suppliers.includes(path) ? "mm-active" : ""}`
                : `${clients.includes(path) ? "mm-active" : ""}`
            }
          >
            <Link
              to={isBusinessUser ? "/suppliers" : "/clients"}
              className="ai-icon"
            >
              <i className="fas fa-id-card"></i>
              <span className="nav-text">
                {isBusinessUser ? "Suppliers" : "Clients"}
              </span>
            </Link>
          </li>
          <li className={`${settings.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="fa fa-cog" aria-hidden="true"></i>
              <span className="nav-text">Settings</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "account-app" ? "mm-active" : ""}`}
                  to="/account-app"
                >
                  Account & App
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "user-permissions" ? "mm-active" : ""
                  }`}
                  to="/user-permissions"
                >
                  User Permissions
                </Link>
              </li>
              {isBusinessUser && (
                <>
                  <li>
                    <Link
                      className={`${
                        path === "billing-payments" ? "mm-active" : ""
                      }`}
                      to="/billing-payments"
                    >
                      Billing & Payments
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "integrations" ? "mm-active" : ""
                      }`}
                      to="/integrations"
                    >
                      Integrations
                    </Link>
                  </li>
                </>
              )}
              <li>
                <Link
                  className={`${path === "notifications" ? "mm-active" : ""}`}
                  to="/notifications"
                >
                  Notifications
                </Link>
              </li>
            </ul>
          </li>
        </MM>
        <div className="copyright">
          {/* <p className="fs-12"><span className="heart"></span></p> */}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
