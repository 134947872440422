import React, { useEffect, useState } from 'react'
import pic2 from '../../../../images/avatar/pic2.jpg'
import {
  useHandleUploadProfileImage,
  useFetchProfile,
  useUpdateUserData,
  useGetUser,
  useUpdateAppData,
  useGetAppSettings,
  useUploadBillingAddress,
  useGetBillingAddress,
} from '../../../../data/account-data'
import swal from 'sweetalert'
import { Country, State } from 'country-state-city'
import { Spinner } from 'react-bootstrap'
//Images
const AccountApp = () => {
  const UploadProfileImage = useHandleUploadProfileImage()
  const FetchProfile = useFetchProfile()
  const updateUserDataMutation = useUpdateUserData()
  const fetchUserProfile = useGetUser()
  const UpdateAppDataMutation = useUpdateAppData()
  const fetchAppSettings = useGetAppSettings()
  const uploadBillingAddress = useUploadBillingAddress()
  const fetchBillingAddress = useGetBillingAddress()
  const [selectedImage, setSelectedImage] = useState(null)
  const [currentCountry, setCurrentCountry] = useState()
  const handleChooseProfile = (e) => {
    const file = e.target.files[0]
    setSelectedImage(file)
  }

  const handleUploadProfile = async () => {
    if (selectedImage) {
      await UploadProfileImage.mutate(selectedImage)
    }
  }

  useEffect(() => {
    if (!UploadProfileImage.isLoading) {
      if (UploadProfileImage.isSuccess) {
        swal('Good job!', 'Successfully Updated', 'success')
      }
      if (UploadProfileImage.isError) {
        swal('Oops', 'Something went wrong', 'error')
      }
      UploadProfileImage.reset()
    }
  }, [
    UploadProfileImage.data,
    UploadProfileImage.isError,
    UploadProfileImage.isSuccess,
    UploadProfileImage.isLoading,
  ])

  //account
  const [formData, setFormData] = useState({})
  useEffect(() => {
    setFormData({
      firstName: fetchUserProfile.data?.data.data.firstName || '',
      lastName: fetchUserProfile.data?.data.data.lastName || '',
    })
  }, [fetchUserProfile.data])
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await updateUserDataMutation.mutateAsync({
      data: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        // companyName: formData.companyName,
        // phoneNumber: formData.phoneNumber,
      },
    })
  }
  useEffect(() => {
    if (!updateUserDataMutation.isLoading) {
      if (updateUserDataMutation.isSuccess) {
        swal('Good job!', 'Successfully Updated', 'success')
      }
      if (updateUserDataMutation.isError) {
        swal('Oops', 'Something went wrong', 'error')
      }
      updateUserDataMutation.reset()
    }
  }, [
    updateUserDataMutation.data,
    updateUserDataMutation.isError,
    updateUserDataMutation.isSuccess,
    updateUserDataMutation.isLoading,
  ])

  //app

  const [appFormData, setAppFormData] = useState({})
  useEffect(() => {
    setAppFormData({
      currency: fetchAppSettings.data?.data?.data?.[0]?.currency || '',
      timezone: fetchAppSettings.data?.data?.data?.[0]?.timezone || '+00:00',
      dateformat:
        fetchAppSettings.data?.data?.data?.[0]?.dateformat || 'option4',
    })
  }, [fetchAppSettings.data])
  const handleAppSubmit = async (e) => {
    e.preventDefault()
    const response = await UpdateAppDataMutation.mutateAsync({
      data: appFormData,
    })
  }
  useEffect(() => {
    if (!UpdateAppDataMutation.isLoading) {
      if (UpdateAppDataMutation.isSuccess) {
        swal('Good job!', 'App Settings Successfully Updated', 'success')
      }
      if (UpdateAppDataMutation.isError) {
        swal('Oops', 'Something went wrong', 'error')
      }
      updateUserDataMutation.reset()
    }
  }, [
    UpdateAppDataMutation.data,
    UpdateAppDataMutation.isError,
    UpdateAppDataMutation.isSuccess,
    UpdateAppDataMutation.isLoading,
  ])

  const handleAppChange = (e) => {
    setAppFormData({ ...appFormData, [e.target.name]: e.target.value })
  }

  //billing
  const [billingFormData, setBillingFormData] = useState({})
  useEffect(() => {
    if (!fetchBillingAddress.isLoading && fetchBillingAddress.isSuccess) {
      setCurrentCountry(fetchBillingAddress.data.data.data.countryName)
      setBillingFormData({
        addressLine1: fetchBillingAddress.data.data.data.addressLine1,
        addressLine2:
          fetchBillingAddress.data.data.data.addressLine2 ?? undefined,
        cityName: fetchBillingAddress.data.data.data.cityName,
        zipCode: fetchBillingAddress.data.data.data.zipCode,
        countryName: fetchBillingAddress.data.data.data.countryName,
        stateOrProvinceName:
          fetchBillingAddress.data.data.data.stateOrProvinceName ?? undefined,
      })
    }
  }, [
    fetchBillingAddress.data,
    fetchBillingAddress.isSuccess,
    fetchBillingAddress.isError,
    fetchBillingAddress.isLoading,
  ])

  const handleBillingSubmit = async (e) => {
    e.preventDefault()
    if (billingFormData.addressLine2 === '') {
      delete billingFormData.addressLine2
    }
    uploadBillingAddress.mutate({ data: billingFormData })
  }

  useEffect(() => {
    if (!uploadBillingAddress.isLoading) {
      if (uploadBillingAddress.isSuccess) {
        swal('Good job!', 'Billing Address added successfully', 'success')
      }
      if (uploadBillingAddress.isError) {
        swal('Oops', uploadBillingAddress.error.response.data.message, 'error')
      }
      uploadBillingAddress.reset()
    }
  }, [
    uploadBillingAddress.data,
    uploadBillingAddress.isError,
    uploadBillingAddress.isSuccess,
    uploadBillingAddress.isLoading,
  ])

  const handleBillingChange = (e) => {
    setBillingFormData({ ...billingFormData, [e.target.name]: e.target.value })
  }

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Account Settings</h4>
            </div>
            <div className="card-body">
              <div
                className=" position-absolute"
                style={{ marginTop: '120px' }}
              >
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleChooseProfile}
                  id="fileInput"
                />
                <button
                  className="btn btn-primary justify-content-end "
                  onClick={() => document.getElementById('fileInput').click()}
                >
                  Choose Profile
                </button>
              </div>
              <div className="position-absolute" style={{ marginTop: '60px' }}>
                <button
                  className="btn btn-primary justify-content-end "
                  onClick={handleUploadProfile}
                >
                  {UploadProfileImage.isLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      className="me-2"
                    ></Spinner>
                  )}
                  Upload Profile
                </button>
              </div>
              <div className="image-placeholder mb-4">
                <div className="avatar-preview">
                  <div id="imagePreview">
                    <img
                      id="saveImageFile"
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : (FetchProfile.data &&
                              FetchProfile.data.data &&
                              FetchProfile.data.data.profileImage &&
                              FetchProfile.data.data.profileImage[0] &&
                              FetchProfile.data.data.profileImage[0]
                                .fileSource) ||
                            pic2
                      }
                      alt="Profile"
                    />
                  </div>
                </div>
              </div>
              <div className="basic-form authincation-content style-2">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="first-name"
                    >
                      First Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        id="first-name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="last-name"
                    >
                      Last Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        id="last-name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label" htmlFor="email">Company Name</label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Company Name"
                            id="companyname"
                            name="companyName"
                            value={formData.companyName} // Updated variable name
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label" htmlFor="email">Phone</label>
                    <div className="col-sm-9">
                        <input
                            type="tel"
                            className="form-control"
                            placeholder="Phone Number"
                            id="phonenumber"
                            name="phoneNumber"
                            value={formData.phoneNumber} // Updated variable name
                            onChange={handleChange}
                        />
                    </div>
                </div> */}
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-primary justify-content-end"
                      >
                        {updateUserDataMutation.isLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            className="me-2"
                          ></Spinner>
                        )}
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">App Settings</h4>
            </div>
            <div className="card-body">
              <div className="basic-form authincation-content style-2">
                <form onSubmit={handleAppSubmit}>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency"
                    >
                      Currency
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        id="currency"
                        name="currency"
                        value={appFormData.currency}
                        onChange={handleAppChange}
                      >
                        <option value="USD">USD - United States Dollars</option>
                        <option value="EUR">EUR - Euros</option>
                        <option value="GBP">GBP - British Pounds</option>
                        <option value="CAD">CAD - Canadian Dollars</option>
                        <option value="MXN">MXN - Mexican Peso</option>
                        <option value="AUD">AUD - Australian Dollars</option>
                        <option value="JPY">JPY - Japanese Yen</option>
                        <option value="INR">INR - Indian Rupee</option>
                        <option value="SEK">SEK - Swedish Krona</option>
                        <option value="BRL">BRL - Brazilian Real</option>
                        <option value="EGP">EGP - Egyptian Pound</option>
                        <option value="AED">
                          AED - United Arab Emirates Dirham
                        </option>
                        <option value="SAR">SAR - Saudi Riyal</option>
                        <option value="TRY">TRY - Turkish Lira</option>
                        <option value="SGD">SGD - Singapore Dollar</option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="timezone"
                    >
                      Timezone
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        id="timezone"
                        name="timezone"
                        value={appFormData.timezone}
                        onChange={handleAppChange}
                      >
                        <option value="-12:00">
                          (GMT -12:00) Eniwetok, Kwajalein
                        </option>
                        <option value="-11:00">
                          (GMT -11:00) Midway Island, Samoa
                        </option>
                        <option value="-10:00">(GMT -10:00) Hawaii</option>
                        <option value="-09:50">(GMT -9:30) Taiohae</option>
                        <option value="-09:00">(GMT -9:00) Alaska</option>
                        <option value="-08:00">
                          (GMT -8:00) Pacific Time (US &amp; Canada)
                        </option>
                        <option value="-07:00">
                          (GMT -7:00) Mountain Time (US &amp; Canada)
                        </option>
                        <option value="-06:00">
                          (GMT -6:00) Central Time (US &amp; Canada), Mexico
                          City
                        </option>
                        <option value="-05:00">
                          (GMT -5:00) Eastern Time (US &amp; Canada), Bogota,
                          Lima
                        </option>
                        <option value="-04:50">(GMT -4:30) Caracas</option>
                        <option value="-04:00">
                          (GMT -4:00) Atlantic Time (Canada), Caracas, La Paz
                        </option>
                        <option value="-03:50">(GMT -3:30) Newfoundland</option>
                        <option value="-03:00">
                          (GMT -3:00) Brazil, Buenos Aires, Georgetown
                        </option>
                        <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                        <option value="-01:00">
                          (GMT -1:00) Azores, Cape Verde Islands
                        </option>
                        <option value="+00:00">
                          (GMT) Western Europe Time, London, Lisbon, Casablanca
                        </option>
                        <option value="+01:00">
                          (GMT +1:00) Brussels, Copenhagen, Madrid, Paris
                        </option>
                        <option value="+02:00">
                          (GMT +2:00) Kaliningrad, South Africa
                        </option>
                        <option value="+03:00">
                          (GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg
                        </option>
                        <option value="+03:50">(GMT +3:30) Tehran</option>
                        <option value="+04:00">
                          (GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi
                        </option>
                        <option value="+04:50">(GMT +4:30) Kabul</option>
                        <option value="+05:00">
                          (GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent
                        </option>
                        <option value="+05:50">
                          (GMT +5:30) Bombay, Calcutta, Madras, New Delhi
                        </option>
                        <option value="+05:75">
                          (GMT +5:45) Kathmandu, Pokhara
                        </option>
                        <option value="+06:00">
                          (GMT +6:00) Almaty, Dhaka, Colombo
                        </option>
                        <option value="+06:50">
                          (GMT +6:30) Yangon, Mandalay
                        </option>
                        <option value="+07:00">
                          (GMT +7:00) Bangkok, Hanoi, Jakarta
                        </option>
                        <option value="+08:00">
                          (GMT +8:00) Beijing, Perth, Singapore, Hong Kong
                        </option>
                        <option value="+08:75">(GMT +8:45) Eucla</option>
                        <option value="+09:00">
                          (GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk
                        </option>
                        <option value="+09:50">
                          (GMT +9:30) Adelaide, Darwin
                        </option>
                        <option value="+10:00">
                          (GMT +10:00) Eastern Australia, Guam, Vladivostok
                        </option>
                        <option value="+10:50">
                          (GMT +10:30) Lord Howe Island
                        </option>
                        <option value="+11:00">
                          (GMT +11:00) Magadan, Solomon Islands, New Caledonia
                        </option>
                        <option value="+11:50">
                          (GMT +11:30) Norfolk Island
                        </option>
                        <option value="+12:00">
                          (GMT +12:00) Auckland, Wellington, Fiji, Kamchatka
                        </option>
                        <option value="+12:75">
                          (GMT +12:45) Chatham Islands
                        </option>
                        <option value="+13:00">
                          (GMT +13:00) Apia, Nukualofa
                        </option>
                        <option value="+14:00">
                          (GMT +14:00) Line Islands, Tokelau
                        </option>
                      </select>
                    </div>
                  </div>
                  <fieldset className="form-group">
                    <div className="row mb-3">
                      <label className="col-form-label col-sm-3 pt-0">
                        Date format
                      </label>
                      <div className="col-sm-9">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            value="option1"
                            id="option1"
                            checked={appFormData.dateformat === 'mm/dd/yyyy'}
                            onChange={() =>
                              setAppFormData({
                                ...appFormData,
                                dateformat: 'mm/dd/yyyy',
                              })
                            }
                          />
                          <label className="form-check-label" htmlFor="option1">
                            mm/dd/yyyy
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            value="option2"
                            id="option2"
                            checked={appFormData.dateformat === 'dd/mm/yyyy'}
                            onChange={() =>
                              setAppFormData({
                                ...appFormData,
                                dateformat: 'dd/mm/yyyy',
                              })
                            }
                          />
                          <label className="form-check-label" htmlFor="option2">
                            dd/mm/yyyy
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            value="option3"
                            id="option3"
                            checked={appFormData.dateformat === 'yyyy/mm/dd'}
                            onChange={() =>
                              setAppFormData({
                                ...appFormData,
                                dateformat: 'yyyy/mm/dd',
                              })
                            }
                          />
                          <label className="form-check-label" htmlFor="option3">
                            yyyy/mm/dd
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            value="option4"
                            id="option4"
                            check
                            ed={appFormData.dateformat === 'MMM dd, yyyy'}
                            onChange={() =>
                              setAppFormData({
                                ...appFormData,
                                dateformat: 'MMM dd, yyyy',
                              })
                            }
                          />
                          <label className="form-check-label" htmlFor="option4">
                            MMM dd, yyyy
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-primary justify-content-end"
                      >
                        {UpdateAppDataMutation.isLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            className="me-2"
                          ></Spinner>
                        )}
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AccountApp
