import React, { useState } from 'react'

const SignInForm = ({
  email,
  password,
  onChangeEmail,
  onChangePassword,
  errors,
  rememberPreference,
  onCheckboxChange,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <div className="form-group mb-3">
        <label className="mb-1" htmlFor="val-email">
          <strong>Email</strong>
        </label>
        <div>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={onChangeEmail}
            placeholder="Type Your Email Address"
          />
        </div>
        {errors.email && (
          <div className="text-danger fs-12">{errors.email}</div>
        )}
      </div>
      <div className="form-group mb-3">
        <div className="password-input">
          <label className="mb-1">
            <strong>Password</strong>
          </label>
          <input
            type={showPassword ? 'text' : 'password'}
            className="form-control"
            value={password}
            placeholder="Type Your Password"
            onChange={onChangePassword}
          />
          <div className="password-toggle" onClick={togglePasswordVisibility}>
            {showPassword ? (
              <i className="fas fa-eye-slash"></i>
            ) : (
              <i className="fas fa-eye"></i>
            )}
          </div>
        </div>
        {errors.password && (
          <div className="text-danger fs-12">{errors.password}</div>
        )}
      </div>
      <div className="form-row d-flex justify-content-between mt-4 mb-2">
        <div className="form-group mb-3">
          <div className="custom-control custom-checkbox ml-1">
            <input
              type="checkbox"
              className="form-check-input"
              id="basic_checkbox_1"
              checked={rememberPreference}
              onChange={onCheckboxChange}
            />
            <label className="form-check-label" htmlFor="basic_checkbox_1">
              Remember my preference
            </label>
          </div>
        </div>
      </div>
      <div className="text-center form-group mb-3">
        <button type="submit" className="btn btn-primary btn-block">
          Sign In
        </button>
      </div>
    </>
  )
}

export default SignInForm
