import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import swal from 'sweetalert';
import { initialShipmentDetailsState } from '../initialState';
import { Form, Field, FieldArray, Formik, useFormikContext, useField } from 'formik';
import Stepper from 'react-stepper-horizontal';
import { Alert, Card, Table, Toast } from 'react-bootstrap';
import { Country, State } from 'country-state-city'
import Select from 'react-select';
import { useFetchSupplierLocationById, useFetchSupplierLocations } from '../../../../../data/location-data';
import { useAddShipmentTransport, useConfirmShipment, useContinueShipmentPlan, useCreatePallets, useCreateShipment, useGenerateEstimate, useGetShipmentDetails, useSaveNewLocation, useSaveShipFromLocation, useTransportCancel } from '../../../../../data/shipment-data';
import moment from 'moment';
import LoadingSpinner from '../../../elements/LoadingSpinner/LoadingSpinner';
import { useQueryClient } from 'react-query';
import { useDebounce } from 'react-use';
import { ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom/cjs/react-router-dom';



const MultiStepFormContext = createContext({
    // shipment:{
    //     amazonPartnered: true,
    //     pickupDate: moment().add(1,'days').format('YYYY-MM-DD'),
    //     shipmentExpiresOn: moment().add(1,'years').format('YYYY-MM-DD'),
    //     shipmentType:'SP',
    //     freightClassCode: null
    // }
})

function whichStep(order) {

    const shipment = order?.Shipment;
    const pallets = order?.Pallets || [];

    if (!shipment) {
        return 1;
    }
    if (
        shipment &&
        shipment.transportStatus === 'PENDING'
    ) {
        if (
            shipment.transportStatus === 'READY' ||
            shipment.transportStatus === 'LABEL_REQUESTED'
        ) {
            return 2
        }
        else if (
            order?.Shipment !== null
        ) {
            return 2;
        } else if (order?.Boxes.length !== 0) {
            return 1;
        }
    }
    if (
        shipment.amazonPartnered === false &&
        shipment.shipmentType === 'SP'
    ) {
        if (
            shipment.transportStatus === 'WORKING'
        ) {
            return 2;
        }
    }
    if (
        shipment.amazonPartnered === false &&
        shipment.shipmentType === 'LTL'
    ) {
        if (
            shipment.transportStatus === 'READY' ||
            shipment.transportStatus === 'LABEL_REQUESTED'
        ) {
            return 3
        }
        else if (pallets.length !== 0) {
            return 3
        }
        else if (
            shipment.transportStatus === 'WORKING'
        ) {
            return 3;
        }
    }
    if (
        shipment?.amazonPartnered === true &&
        shipment?.shipmentType === 'LTL'
    ) {
        if (
            shipment.transportStatus === 'CONFIRMED' ||
            shipment.transportStatus === 'ESTIMATED' ||
            shipment.transportStatus === 'READY' ||
            shipment.transportStatus === 'LABEL_REQUESTED'
        ) {
            return 4;
        }
        else if (
            shipment.transportStatus === 'WORKING' &&
            pallets.length !== 0
        ) {
            return 4
        }
        else if (
            shipment.transportStatus === 'WORKING' &&
            pallets.length === 0
        ) {
            return 3
        }
    }
    if (
        shipment.amazonPartnered === true &&
        shipment.shipmentType === 'SP'
    ) {
        if (
            shipment.transportStatus === 'CONFIRMED' ||
            shipment.transportStatus === 'ESTIMATED' ||
            shipment.transportStatus === 'WORKING' ||
            shipment.transportStatus === 'READY' ||
            shipment.transportStatus === 'LABEL_REQUESTED'

        ) {
            return 3;
        }
    }
    return 1;
}


const useObserveLoading = (entity) => {

    const queryClient = useQueryClient();
    const [orderLoading, setLoading] = useState(false)
    const query = queryClient.getQueryState('get-order-details', { exact: false })

    useEffect(() => {
        setLoading(query.isFetching)
    }, [
        query.isFetching
    ])
    return { orderLoading }
}

const useGetOrder = () => {


}


const AddShipmentMultiStepForm = ({
    handleClose
}) => {

    // const { orders: { selectedOrder }, auth } = useStateValue();
    // const [currentStep, setCurrentStep ] = useState(whichStep(selectedOrder));
    const [maxStep, setMaxStep] = useState(1)
    // const { orderLoading }         = useObserveLoading(selectedOrder)
    const addTransportDetailsData = useAddShipmentTransport();
    const createPalletsData = useCreatePallets();
    const createShipmentData = useCreateShipment();
    const supplierLocationData = useFetchSupplierLocations();
    const generateEstimateData = useGenerateEstimate();
    const confirmShipmentData = useConfirmShipment();
    const transportCancelData = useTransportCancel()
    const shipFromLocationData = useSaveShipFromLocation();
    const newLocationData = useSaveNewLocation();
    const queryClient = useQueryClient();
    const { id: orderId } = useParams();
    const getOrderDetails = useGetShipmentDetails(orderId);
    const [currentStep, setCurrentStep] = useState(whichStep(getOrderDetails.data?.data.data));
    queryClient.setQueryDefaults('get-shipment-details', { refetchOnWindowFocus: false })

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setMaxStep(whichStep(getOrderDetails.data?.data.data))
        supplierLocationData.mutate({ supplierId: getOrderDetails.data?.data.data.supplierId })
    }, [getOrderDetails.data])

    //    useEffect(() => {
    //         queryClient.invalidateQueries('get-shipment-details')
    //    },[])



    useEffect(() => {
        if (
            addTransportDetailsData.isLoading ||
            createPalletsData.isLoading ||
            createShipmentData.isLoading ||
            getOrderDetails.isFetching ||
            generateEstimateData.isLoading ||
            confirmShipmentData.isLoading ||
            transportCancelData.isLoading ||
            shipFromLocationData.isLoading ||
            newLocationData.isLoading
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [
        addTransportDetailsData.isLoading,
        createPalletsData.isLoading,
        createShipmentData.isLoading,
        generateEstimateData.isLoading,
        confirmShipmentData.isLoading,
        getOrderDetails.isFetching,
        transportCancelData.isLoading,
        shipFromLocationData.isLoading,
        newLocationData.isLoading
    ])

    const steps = useMemo(() => {
        const shipment = getOrderDetails.data?.data.data.Shipment;
        const stepArray = [
            { title: `Create Shipment` },
            { title: `Transport Details` },
        ];
        if (shipment && shipment.shipmentType === 'LTL') {
            stepArray.push({ title: `Pallets` })
        }
        if (shipment && shipment.amazonPartnered === true) {
            stepArray.push({ title: `Confirm Shipment` })
        }
        return stepArray;
    }, [getOrderDetails.data])

    const getStepComponent = useCallback(() => {
        const shipment = getOrderDetails.data?.data.data.Shipment;

        if (
            !shipment
        ) {
            return <ShipmentPlanCreateForm />
        }
        if (
            shipment &&
            shipment.transportStatus === 'PENDING'
        ) {
            switch (currentStep) {
                case 1:
                    return <ShipmentPlanCreateForm />;
                case 2:
                    return <ChooseShipmentTypeForm />;
                default:
                    return <React.Fragment />;
            }
        }
        if (
            shipment.amazonPartnered === false &&
            shipment.shipmentType === 'SP'
        ) {
            switch (currentStep) {
                case 1:
                    return <ShipmentPlanCreateForm />;
                case 2:
                    return <ChooseShipmentTypeForm />;
                default:
                    return <React.Fragment />;
            }
        }
        if (
            shipment.amazonPartnered === false &&
            shipment.shipmentType === 'LTL'
        ) {
            switch (currentStep) {
                case 1:
                    return <ShipmentPlanCreateForm />;
                case 2:
                    return <ChooseShipmentTypeForm />;
                case 3:
                    return <ChargeAcceptForm />;
                default:
                    return <React.Fragment />;
            }
        }
        if (
            shipment.amazonPartnered === true &&
            shipment.shipmentType === 'LTL'
        ) {
            switch (currentStep) {
                case 1:
                    return <ShipmentPlanCreateForm />;
                case 2:
                    return <ChooseShipmentTypeForm />;
                case 3:
                    return <ChargeAcceptForm />;
                case 4:
                    return <ShipmentFinalForm />;
                default:
                    return <React.Fragment />;
            }
        }
        if (
            shipment.amazonPartnered === true &&
            shipment.shipmentType === 'SP'
        ) {
            switch (currentStep) {
                case 1:
                    return <ShipmentPlanCreateForm />;
                case 2:
                    return <ChooseShipmentTypeForm />;
                case 3:
                    return <ShipmentFinalForm />;
                default:
                    return <React.Fragment />;
            }
        }
    }, [getOrderDetails.data, currentStep])

    useEffect(() => {
        if (!addTransportDetailsData.isLoading) {
            if (addTransportDetailsData.isSuccess) {
                const shipment = addTransportDetailsData.data?.data.data;
                if (shipment.amazonPartnered === false && shipment.shipmentType === 'SP') {
                    handleClose();
                    swal("Good job!", "Successfully shipment transport details added.", "success");
                }
                else {
                    setCurrentStep(currentStep + 1)
                }
            }
            if (addTransportDetailsData.isError) {
                handleClose()
                swal("oops!", "Something went wrong", "error")
            }
            addTransportDetailsData.reset()
        }
    }, [
        addTransportDetailsData.data,
        addTransportDetailsData.isLoading,
        addTransportDetailsData.isError,
        addTransportDetailsData.isSuccess,
        addTransportDetailsData.error
    ])

    return (<MultiStepFormContext.Provider
        value={{
            addTransportDetailsData,
            createPalletsData,
            supplierLocationData,
            createShipmentData,
            generateEstimateData,
            confirmShipmentData,
            transportCancelData,
            shipFromLocationData,
            newLocationData,
            loading,
            order: getOrderDetails.data?.data.data,
            shipment: getOrderDetails.data?.data.data.Shipment,
            disableCreateShipment: getOrderDetails.data?.data.data.Shipment ? true : false,
            disableTypeSelect: (
                getOrderDetails.data?.data.data.Shipment !== null &&
                (
                    getOrderDetails.data?.data.data.Shipment.transportStatus === 'CONFIRMED' ||
                    getOrderDetails.data?.data.data.Shipment.transportStatus === 'READY' ||
                    getOrderDetails.data?.data.data.Shipment.transportStatus === 'LABEL_REQUESTED'
                )
            ),
            disablePalletCreate: (
                getOrderDetails.data?.data.data.Pallets.length !== 0
            ),
            disableConfirmShipment: (
                getOrderDetails.data?.data.data.Shipment !== null &&
                getOrderDetails.data?.data.data.Shipment.transportStatus === 'READY'
            ),
        }}
    >
        <div className="create_shipment_modal"></div>
        <Modal.Header>
            <Modal.Title>Shipment</Modal.Title>
            <button
                type="button"
                className="btn-close"
                onClick={() => handleClose()}
                data-dismiss="modal"
            ></button>
        </Modal.Header>
        <Modal.Header>
            <Stepper steps={steps} activeStep={maxStep - 1}> </Stepper>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '60vh' }}>
            {getStepComponent()}
        </Modal.Body>
        <Modal.Footer>
            <div className="justify-content-between d-flex footer_flex_100">
                <Button
                    className="btn btn-sm btn-success fs-18 font-w600 btn-rounded"
                    disabled={currentStep == 1}
                    variant="primary"
                    onClick={() => setCurrentStep(currentStep - 1)}
                ><i class="fas fa-chevron-left me-2"></i>Prev</Button>
                <Button
                    className="btn btn-sm btn-success fs-18 font-w600 btn-rounded"
                    disabled={(currentStep == maxStep) || loading}
                    variant="primary"
                    onClick={() => setCurrentStep(currentStep + 1)}
                >Next<i class="fas fa-chevron-right ms-2"></i></Button>
            </div>
        </Modal.Footer>
    </MultiStepFormContext.Provider>
    );
}
const CountrySelect = ({
    options,
    name,
    disabled,
    onCountrySelect,
}) => {
    const [field, meta, helper] = useField(name)
    const [value, setValue] = useState();

    const handleChange = (newValue) => {
        helper.setValue(newValue);
        onCountrySelect(newValue);

    }

    return (
        <Select
            value={field.value}
            options={options}
            onChange={handleChange}
            isDisabled={disabled}
            onInputChange={setValue}
            inputValue={value}
        >
        </Select>
    )
}

const CustomSelect = ({
    inputValue,
    onInputChange,
    options,
    name,
    disabled
}) => {
    const [field, meta, helper] = useField(name)
    const [value, setValue] = useState();

    const handleChange = (newValue) => {
        helper.setValue(newValue);
    }

    return (
        <Select
            onInputChange={onInputChange}
            value={field.value}
            options={options}
            onChange={handleChange}
            inputValue={inputValue}
            isDisabled={disabled}
        >
        </Select>
    )
}
const marketplaceOptions = [
    { value: 'A2EUQ1WTGCTBG2', label: 'Canada (CA)' },
    { value: 'ATVPDKIKX0DER', label: 'United States (US)' },
    { value: 'A1AM78C64UM0Y8', label: 'Mexico (MX)' },
    { value: 'A2Q3Y263D00KWC', label: 'Brazil (BR)' },
    { value: 'A1RKKUPIHCS9HS', label: 'Spain (ES)' },
    { value: 'A1F83G8C2ARO7P', label: 'United Kingdom (UK)' },
    { value: 'A13V1IB3VIYZZH', label: 'France (FR)' },
    { value: 'AMEN7PMS3EDWL', label: 'Belgium (BE)' },
    { value: 'A1805IZSGTT6HS', label: 'Netherlands (NL)' },
    { value: 'A1PA6795UKMFR9', label: 'Germany (DE)' },
    { value: 'APJ6JRA9NG5V4', label: 'Italy (IT)' },
    { value: 'A2NODRKZP88ZB9', label: 'Sweden (SE)' },
    { value: 'AE08WJ6YKNBMC', label: 'South Africa (ZA)' },
    { value: 'A1C3SOZRARQ6R3', label: 'Poland (PL)' },
    { value: 'ARBP9OOSHTCHU', label: 'Egypt (EG)' },
    { value: 'A33AVAJ2PDY3EV', label: 'Turkey (TR)' },
    { value: 'A17E79C6D8DWNP', label: 'Saudi Arabia (SA)' },
    { value: 'A2VIGQ35RCS4UG', label: 'United Arab Emirates (AE)' },
    { value: 'A21TJRUUN4KGV', label: 'India (IN)' },
    { value: 'A19VAU5U5O7RUS', label: 'Singapore' }
];


const ShipmentPlanCreateForm = ({ }) => {
    const [selectedCountry, setSelectedCountry] = useState();
    const [locationSearch, setLocationSearch] = useState();
    const [locations, setLocations] = useState([]);
    const [initialShipmentDetails, setInitialShipmentDetails] = useState({});
    const [destination, setDestination] = useState({});
    const [feePerUnit, setFeePerUnit] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [units, setUnits] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [defaultShipFromLocation, setDefaultShipFromLocation] = useState();
    const [hasNewLocation, setHasNewLocation] = useState(false);

    const {
        supplierLocationData,
        createShipmentData,
        shipFromLocationData,
        newLocationData,
        loading,
        shipment,
        disableCreateShipment: disableInput,
        order
    } = useContext(MultiStepFormContext)

    const handleButtonClick = (e) => {
        e.preventDefault();
        setShowForm(false);

    };
    useEffect(() => {
        if (supplierLocationData.isSuccess) {
            let locs = supplierLocationData.data?.data?.data.locations
                .map((loc) => ({
                    value: loc.id,
                    label: loc.locationName
                }));
            setLocations(locs)
        }
    }, [supplierLocationData.data])

    const handleButtonClickShipment = (e) => {
        // const contactSection = document.getElementById('contactSection');
        // if (contactSection) {
        //   contactSection.style.display = (contactSection.style.display === 'none') ? 'block' : 'none';
        // }
        e.preventDefault()
        setShowForm(true);
    };

    const handleLocationSearch = (newValue) => {
        setLocationSearch(newValue)
        supplierLocationData.mutate({
            supplierId: order?.supplierId,
            query: newValue
        })
    }

    useEffect(() => {

        if (!shipFromLocationData.isLoading) {
            if (shipFromLocationData.isSuccess) {
                const newLocation = {
                    value: shipFromLocationData.data.data.data.id,
                    label: shipFromLocationData.data.data.data.locationName
                }
                setHasNewLocation(true);
                setDefaultShipFromLocation(newLocation);
                setShowForm(false);
                setLocations([newLocation])
            }
        }
    }, [
        shipFromLocationData.data,
        shipFromLocationData.isLoading,
        shipFromLocationData.isSuccess,
        shipFromLocationData.isError
    ])

    useEffect(() => {

        if (!newLocationData.isLoading) {
            if (newLocationData.isSuccess) {
                const newLocation = {
                    value: newLocationData.data.data.data.id,
                    label: newLocationData.data.data.data.locationName
                }
                setHasNewLocation(true);
                setDefaultShipFromLocation(newLocation);
                setShowForm(false);
                setLocations([newLocation])
            }
        }
    }, [
        newLocationData.data,
        newLocationData.isLoading,
        newLocationData.isSuccess,
        newLocationData.isError
    ])

    return (
        <Formik
            onSubmit={(values) => {
                if (showForm) {
                    if (order.supplierEmail === 'no_supplier@example.com') {
                        const data = {
                            locationName: values.locationName,
                            addressLine1: values.addressLine1,
                            zipCode: values.zipCode,
                            personEmail: values.personEmail,
                            personName: values.personName,
                            cityName: values.cityName,
                            personPhoneNumber: values.personPhoneNumber,
                            countryName: values.countryName.value,
                            stateOrProvinceName: values.countyName.value
                        }
                        shipFromLocationData.mutate({ data })
                    } else {
                        const data = {
                            locationName: values.locationName,
                            addressLine1: values.addressLine1,
                            zipCode: values.zipCode,
                            cityName: values.cityName,
                            phoneNumber: values.phoneNumber,
                            countryName: values.countryName.value,
                            stateOrProvinceName: values.countyName.value
                        }
                        newLocationData.mutate({ data, supplierId: order.supplierId })
                    }
                } else {
                    const data = {
                        marketplaceId: values.marketplaceId.value,
                        shipmentName: values.shipmentName,
                        packType: values.packType.value,
                        fromAddressId: values.fromAddressId.value
                    }
                    createShipmentData.mutate({ orderId: order?.id, data: data })
                }
            }}
            initialValues={(
                shipment ? ({
                    shipmentName: shipment.shipmentName,
                    marketplaceId: {
                        value: shipment.destMarketplaceId,
                        label: marketplaceOptions.find((m) => m.value === shipment.destMarketplaceId)
                            .label,
                    },
                    fromAddressId: {
                        label: shipment.Location.locationName,
                        value: shipment.Location.id
                    },

                }) : ({
                    shipmentName: '',
                    fromAddressId: defaultShipFromLocation ? defaultShipFromLocation :
                        ({ label: '', value: '' }),
                    marketplaceId: {
                        value: '',
                        label: ''
                    },
                    packType: {
                        label: 'Case Packed',
                        value: 'case'
                    }
                })
            )}
            enableReinitialize={true}

        >
            <Form>
                <Card.Title>Shipment Information</Card.Title>
                <Card.Body>
                    {loading ?
                        <LoadingSpinner size={30} /> :
                        <div className="add-contact-box">
                            <div className="add-contact-content">
                                <div className="row">
                                    {!showForm &&
                                        <>
                                            <div className="form-group col-sm-6 mb-3">
                                                <label className="text-black font-w500">Shipment Name</label>
                                                <div className="contact-name">
                                                    <Field
                                                        name="shipmentName"
                                                        className="form-control"
                                                        disabled={disableInput}
                                                    >

                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 mb-3">
                                                <label className="text-black font-w500">Shipment From</label>
                                                <div className="contact-name mb-2">
                                                    {
                                                        <CustomSelect
                                                            onInputChange={(newValue) => handleLocationSearch(newValue)}
                                                            options={locations}
                                                            inputValue={locationSearch}
                                                            name="fromAddressId"
                                                            disabled={disableInput || hasNewLocation}
                                                        >
                                                        </CustomSelect>}
                                                </div>
                                                {!disableInput &&
                                                    !showForm &&
                                                    <div className="form-group col-sm-12">
                                                        <button onClick={handleButtonClickShipment} role="button" class="btn btn-xs btn-primary light btn-rounded me-2 mb-0"><i class="fa fa-plus me-3 scale3"></i>New Location</button>
                                                    </div>
                                                }
                                            </div>

                                            <div className="form-group col-sm-6 mb-3">
                                                <label className="text-black font-w500">Send To Marketplace</label>
                                                <div className="contact-name">
                                                    {
                                                        <CustomSelect
                                                            // value={marketplaceId}
                                                            options={marketplaceOptions}
                                                            // onChange={(newValue)=>setMarketplaceId(newValue)}
                                                            name="marketplaceId"
                                                            disabled={disableInput}
                                                        >
                                                        </CustomSelect>}
                                                </div>
                                            </div>
                                            {!disableInput &&
                                                <div className="form-group col-sm-6 mb-3">
                                                    <label className="text-black font-w500">Shipment Type</label>
                                                    <div className="contact-name">
                                                        {
                                                            <CustomSelect
                                                                // value={marketplaceId}
                                                                options={[
                                                                    { label: 'Case Packed', value: 'case' },
                                                                    { label: 'Individual', value: 'individual' }
                                                                ]}

                                                                name="packType"
                                                            >
                                                            </CustomSelect>}
                                                    </div>
                                                </div>}
                                        </>}
                                    {disableInput &&
                                        shipment.SubShipments &&
                                        (shipment.SubShipments.length !== 0) &&
                                        shipment.SubShipments.map((subShipment) => (
                                            <div className="form-group col-sm-6 mb-3">
                                                <Toast style={{ boxShadow: 'none' }}>
                                                    <Toast.Header closeButton={false}>
                                                        <strong>
                                                            <h6>{subShipment.destinationName || ''} ({subShipment.refId})</h6>
                                                        </strong>
                                                    </Toast.Header>
                                                    <Toast.Body>
                                                        <span> - {subShipment.destinationAddressLineOne}</span>
                                                        {subShipment.destinationAddressLineTwo &&
                                                            <span> - {subShipment.destinationAddressLineTwo} </span>}
                                                        <p> {`${subShipment.destinationCity} : ${subShipment.destinationPostalCode}, ${subShipment.destinationStateOrProvinceCode}, ${subShipment.destinationCountryCode}`}</p>
                                                    </Toast.Body>
                                                </Toast>
                                            </div>
                                        ))
                                    }
                                    {
                                        !disableInput &&
                                        showForm &&
                                        <>
                                            <div className="form-group col-sm-12 mb-3">
                                                <button onClick={handleButtonClick} role="button" class="btn btn-xs btn-primary light btn-rounded me-2 mb-0"><i className='fa fa-arrow-left'></i> Back</button>
                                            </div>
                                            <div className="form-group col-sm-6 mb-3">
                                                <label className="text-black font-w500">Country</label>
                                                <div className="contact-name">
                                                    {
                                                        <CountrySelect
                                                            // value={marketplaceId}
                                                            // onChange={(newValue)=>setMarketplaceId(newValue)}
                                                            options={
                                                                Country.getAllCountries()
                                                                    .map(country => ({ value: country.isoCode, label: country.name }))
                                                            }
                                                            // onInputChange={(newValue)=> {
                                                            //     console.log(newValue)
                                                            //    const allCountries =  Country.getAllCountries();
                                                            //    for (let i = 0; i < allCountries.length; i++) {
                                                            //     const singleCountry = allCountries[i];
                                                            //     // console.log(singleCountry.name,newValue,singleCountry.name == newValue)
                                                            //     if (singleCountry.name.toLowerCase()===newValue?.trim()){
                                                            //         setSelectedCountry(singleCountry.isoCode)

                                                            //         break;

                                                            //     }

                                                            //    }
                                                            // }}
                                                            onCountrySelect={(newValue) => setSelectedCountry(newValue.value)}
                                                            name="countryName"
                                                            disabled={disableInput}
                                                        >
                                                        </CountrySelect>}
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 mb-3">
                                                <label className="text-black font-w500">County</label>
                                                <div className="contact-name">
                                                    {
                                                        <CustomSelect
                                                            // value={marketplaceId}
                                                            // onChange={(newValue)=>setMarketplaceId(newValue)}
                                                            options={
                                                                State.getStatesOfCountry(selectedCountry)
                                                                    .map((state) => ({ value: state.isoCode, label: state.name }))
                                                            }
                                                            name="countyName"
                                                            disabled={disableInput}
                                                        >
                                                        </CustomSelect>}
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 mb-3">
                                                <label className="text-black font-w500">Location name</label>
                                                <div className="contact-name">
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        name="locationName"
                                                        required="required"
                                                        placeholder="locationName"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 mb-3">
                                                <label className="text-black font-w500">
                                                    First line of address
                                                </label>
                                                <div className="contact-name">
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        name="addressLine1"
                                                        required="required"
                                                        placeholder="First line of address"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 mb-3">
                                                <label className="text-black font-w500">City</label>
                                                <div className="contact-name">
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        name="cityName"
                                                        required="required"
                                                        placeholder="City"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 mb-3">
                                                <label className="text-black font-w500">Postal/Zip code</label>
                                                <div className="contact-name">
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        name="zipCode"
                                                        required="required"
                                                        placeholder="Zip Code"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 mb-3">
                                                <label className="text-black font-w500">Country</label>
                                                <div className="contact-name">
                                                    {
                                                        <CountrySelect
                                                            // value={marketplaceId}
                                                            // onChange={(newValue)=>setMarketplaceId(newValue)}
                                                            options={
                                                                Country.getAllCountries()
                                                                    .map(country => ({ value: country.isoCode, label: country.name }))
                                                            }
                                                            // onInputChange={(newValue)=> {
                                                            //     console.log(newValue)
                                                            //    const allCountries =  Country.getAllCountries();
                                                            //    for (let i = 0; i < allCountries.length; i++) {
                                                            //     const singleCountry = allCountries[i];
                                                            //     // console.log(singleCountry.name,newValue,singleCountry.name == newValue)
                                                            //     if (singleCountry.name.toLowerCase()===newValue?.trim()){
                                                            //         setSelectedCountry(singleCountry.isoCode)

                                                            //         break;

                                                            //     }

                                                            //    }
                                                            // }}
                                                            onCountrySelect={(newValue) => setSelectedCountry(newValue.value)}
                                                            name="countryName"
                                                            disabled={disableInput}
                                                        >
                                                        </CountrySelect>}
                                                </div>
                                            </div>
                                            {order.supplierEmail !== 'no_supplier@example.com' &&
                                                <div className="form-group col-sm-6 mb-3">
                                                    <label className="text-black font-w500">Phone Number</label>
                                                    <div className="contact-name">
                                                        <Field
                                                            type="tel"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            name="phoneNumber"
                                                            required="required"
                                                            placeholder="Phone Number"
                                                        />
                                                    </div>
                                                </div>}
                                            {order.supplierEmail === 'no_supplier@example.com' &&
                                                <div className="form-group col-sm-6 mb-3">
                                                    <label className="text-black font-w500">Person Email</label>
                                                    <div className="contact-name">
                                                        <Field
                                                            type="email"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            name="personEmail"
                                                            required="required"
                                                            placeholder="Person Email"
                                                        />
                                                    </div>
                                                </div>}
                                            {order.supplierEmail === 'no_supplier@example.com' &&
                                                <div className="form-group col-sm-6 mb-3">
                                                    <label className="text-black font-w500">Person Name</label>
                                                    <div className="contact-name">
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            name="personName"
                                                            required="required"
                                                            placeholder="Person Name"
                                                        />
                                                    </div>
                                                </div>}
                                            {order.supplierEmail === 'no_supplier@example.com' &&
                                                <div className="form-group col-sm-6 mb-3">
                                                    <label className="text-black font-w500">Person Phone Number</label>
                                                    <div className="contact-name">
                                                        <Field
                                                            type="tel"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            name="personPhoneNumber"
                                                            required="required"
                                                            placeholder="Phone Number"
                                                        />
                                                    </div>
                                                </div>}
                                        </>}

                                </div>
                            </div>
                        </div>
                    }
                    {createShipmentData?.isError &&
                        <Alert variant='danger'>
                            {(
                                createShipmentData.error.response?.data?.message?.[0]?.message ||
                                createShipmentData.error.response?.data?.message
                            )}
                        </Alert>
                    }
                    {newLocationData?.isError &&
                        <Alert variant='danger'>
                            {(
                                newLocationData.error.response?.data?.message?.[0]?.message ||
                                newLocationData.error.response?.data?.message
                            )}
                        </Alert>
                    }
                    {shipFromLocationData?.isError &&
                        <Alert variant='danger'>
                            {(
                                shipFromLocationData.error.response?.data?.message?.[0]?.message ||
                                shipFromLocationData.error.response?.data?.message
                            )}
                        </Alert>
                    }
                </Card.Body>
                {!disableInput &&
                    <Card.Footer>
                        <Button type="submit" disabled={loading} variant="success" className="btn-md btn-success fs-22 font-w700 btn-rounded"><i class="fas fa-save me-2"></i>Save</Button>
                    </Card.Footer>}
            </Form>
        </Formik>
    );

}
/*
const ConfirmShipmentForm = () => {

    const { orders: { selectedOrder } } = useStateValue();
    const [ destination, setDestination] = useState({});
    const [ feePerUnit, setFeePerUnit ] = useState(0);
    const [ totalFee, setTotalFee ] = useState(0);
    const [ units, setUnits  ] = useState(0);
    const continueShipmentPlanData = useContinueShipmentPlan();
    const [ step, setStep] = useState(1)
    const order = useObserveLoading(selectedOrder);

    useEffect(()=>{
           let shipment = selectedOrder.Shipment;
           setDestination({
               name:  shipment.destinationName,
               addr1: shipment.destinationAddressLineOne,
               addr2: shipment.destinationAddressLineTwo,
               sCode: shipment.destinationStateOrProvinceCode,
               cCode: shipment.destinationCountryCode,
               city:  shipment.destinationCity,
               pCode: shipment.destinationPostalCode,
           });
           setTotalFee(shipment.totalFee);
           setFeePerUnit(shipment.feePerUnit);
           setUnits( shipment.shippingUnits);
           setStep(whichStep(selectedOrder))
    },[selectedOrder])

    return(
        <Formik
           initialValues={{shipDate: moment().format('YYYY-MM-DD')}}
           onSubmit={(values,formikHelpers) => {
                  continueShipmentPlanData.mutate({orderId:selectedOrder.id,data: values})
           }}
        >
            <Form>
                    <Card.Header>
                        <Card.Title>Plan Preview</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {(continueShipmentPlanData.isLoading || order.isLoading) ?
                        <LoadingSpinner size={30}/> :
                        <div className='row'>
                            <div  className="form-group col-sm-4 mb-4">
                                <label className="text-black font-w500">Ship Date</label>
                                <Field 
                                     className="form-control" 
                                     name="shipDate"
                                     type="date"
                                     disabled={(step !== 2)}
                                ></Field>
                            </div>
                            <div  className="form-group col-sm-4 mb-4">
                                <h6>{destination.name || ''}</h6>
                                <span> - { destination.addr1 }</span>
                                { destination.addr2  && 
                                <span> - { destination.addr2} </span>}
                                <p>{`${destination.city} : ${destination.pCode}, ${destination.sCode}, ${destination.cCode}`}</p>
                            </div>
                            <div  className="form-group col-sm-4 mb-4">
                                <p style={{textAlign:'right'}}> {`${units} x $${feePerUnit}`}</p>
                                <p style={{textAlign:'right'}}> {` $${totalFee}`}</p>
                            </div>
                        </div>} 
                    </Card.Body>
                    <Card.Footer>
                        {(step === 2) &&
                        <Button type='submit' disabled={continueShipmentPlanData.isLoading}>Continue</Button>}
                    </Card.Footer>
            </Form>
        </Formik>
    )
}
*/
const weightOptions = [
    { value: 50, label: 'Suitable for durable freight that fits on a standard 4\' × 4\' pallet.' },
    { value: 55, label: 'Suitable for bricks, cement, hardwood flooring, construction materials.' },
    { value: 60, label: 'Suitable for car accessories and car parts.' },
    { value: 65, label: 'Suitable for car accessories and parts, boxed books, bottled drinks.' },
    { value: 70, label: 'Suitable for car accessories and parts, auto engines, food items.' },
    { value: 70.5, label: 'Suitable for tires, bathroom fixtures.' },
    { value: 92.5, label: 'Suitable for computers, monitors, refrigerators.' },
    { value: 100, label: 'Suitable for furniture, small appliances, tile bundles.' },
    { value: 110, label: 'Suitable for car parts, engines, medium-density fiberboard.' },
    { value: 125, label: 'Suitable for small furniture, auto parts, boxed books.' },
    { value: 150, label: 'Suitable for auto engines, transmissions, medium-density fiberboard.' },
    { value: 175, label: 'Suitable for clothes, linens, sheet metal.' },
    { value: 200, label: 'Suitable for sheet metal, auto parts, wooden crates.' },
    { value: 250, label: 'Suitable for small machinery, sheet metal, marble slabs.' },
    { value: 300, label: 'Suitable for cast iron stoves, boat motors, sheet metal.' },
    { value: 400, label: 'Suitable for automobile engines, palletized freight, sheet metal.' },
    { value: 500, label: 'Suitable for car body parts, aircraft parts, bulk bags of rice.' },
];

const LtlFieldsForm = (props) => {

    const { values, setFieldValue } = useFormikContext()
    const { disableInput: disableInput } = useContext(MultiStepFormContext);

    useEffect(() => {
        if (
            values.shipmentType !== 'LTL' ||
            values.amazonPartnered !== '1'
        ) {
            setFieldValue('freightClassCode', undefined)
            setFieldValue('pickupDate', undefined)
        }
    }, [values.shipmentType, values.amazonPartnered])

    if (
        values.shipmentType !== 'LTL' ||
        values.amazonPartnered !== '1'
    ) {
        return <></>
    }
    return (
        <div className='row'>
            <div className="form-group col-sm-4 mb-4">
                <label className="text-black font-w500">Pickup Date</label>
                <Field name="pickupDate" className="form-control" type="date"
                    disabled={disableInput} />
            </div>
            <div className="form-group col-sm-4 mb-4">
                <label className="text-black font-w500">Freight Class</label>
                <CustomSelect
                    options={weightOptions}
                    name="freightClassCode"
                    disabled={disableInput}
                />
            </div>
        </div>
    )
}


const ChooseShipmentTypeForm = () => {

    const {
        addTransportDetailsData,
        loading,
        disableTypeSelect: disableInput,
        shipment,
        order,
    } = useContext(MultiStepFormContext);


    return <Formik
        onSubmit={(values) => {
            const newTransportDetails = {
                amazonPartnered: values.amazonPartnered === '1' ? true : false,
                shipmentType: values.shipmentType,
            }
            if (values.shipmentExpiresOn) {
                newTransportDetails.shipmentExpiresOn = values.shipmentExpiresOn
            }
            if (values.boxLabelType) {
                newTransportDetails.boxLabelType = values.boxLabelType
            }
            if (values.pickupDate) {
                newTransportDetails.pickupDate = values.pickupDate
            }
            if (values.freightClassCode?.value) {
                newTransportDetails.freightClassCode = values.freightClassCode.value
            }
            addTransportDetailsData.mutate({ orderId: order.id, data: newTransportDetails })
        }}
        initialValues={{
            amazonPartnered: shipment?.amazonPartnered !== null ? (shipment.amazonPartnered === true ? '1' : '0') : '1',
            shipmentType: shipment?.shipmentType ?? 'SP',
            pickupDate: shipment?.pickupDate
                ? moment(shipment.pickupDate)
                    .format('YYYY-MM-DD')
                : moment().add(1, 'day').format('YYYY-MM-DD'),
            shipmentExpiresOn: shipment?.shipmentExpiresOn
                ? moment(shipment.shipmentExpiresOn)
                    .format('YYYY-MM-DD')
                : moment().add(1, 'years').format('YYYY-MM-DD'),
            freightClassCode: {
                value: shipment?.freightClassCode ?? null,
                label: (weightOptions.find(({ value }) => shipment?.freightClassCode == value)
                    ?.label) ?? 'choose...'
            }
        }}
        enableReinitialize={true}
    >
        <Form>
            <Card.Header>
                <Card.Title>Transport Details</Card.Title>
            </Card.Header>
            <Card.Body>
                {(loading) ?
                    <LoadingSpinner size={30} /> :
                    <div className='row'>
                        <div className="form-group col-sm-4 mb-4">
                            <label className="text-black font-w500">Shipment Type</label>
                            <div class="form-check radio_select_box">
                                <Field
                                    className="form-check-input"
                                    type="radio"
                                    checked="checked"
                                    name="shipmentType"
                                    value="SP"
                                    id="smallParcel"
                                    disabled={disableInput}
                                ></Field>
                                <label class="form-check-label" for="smallParcel">
                                    Small Parcel
                                </label>
                            </div>
                            <div class="form-check radio_select_box">
                                <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="shipmentType"
                                    value="LTL"
                                    id="ltl"
                                    disabled={disableInput}
                                ></Field>
                                <label class="form-check-label" for="ltl">
                                    LTL / FTL
                                </label>
                            </div>
                        </div>
                        <div className="form-group col-sm-4 mb-4">
                            <label className="text-black font-w500">Shipment With</label>
                            <div class="form-check radio_select_box">
                                <Field
                                    className="form-check-input"
                                    type="radio"
                                    checked="checked"
                                    name="amazonPartnered"
                                    value="1"
                                    id="amazonPartnered"
                                    disabled={disableInput}
                                ></Field>
                                <label class="form-check-label" for="amazonPartnered">
                                    Amazon Partnered
                                </label>
                            </div>
                            <div class="form-check radio_select_box">
                                <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="amazonPartnered"
                                    value="0"
                                    id="nonAmazonPartnered"
                                    disabled={disableInput}
                                ></Field>
                                <label class="form-check-label" for="nonAmazonPartnered">
                                    Non-Amazon Partnered
                                </label>
                            </div>
                        </div>
                        {order?.Product?.expiryRequired && <div className="form-group col-sm-4 mb-4">
                            <label className="text-black font-w500">Shipment Expires On</label>
                            <Field
                                name="shipmentExpiresOn"
                                className="form-control"
                                disabled={disableInput}
                                type="date"
                            />
                        </div>}

                        <label className="text-black font-w500">Box Label Type</label>
                        <Field
                            as="select"
                            name="boxLabelType"
                            className="form-control form-control-md"
                            required={true}
                        >
                            <option value="" disabled selected >Choose</option>
                            <option value="PackageLabel_Letter_2">PackageLabel_Letter_2</option>
                            <option value="PackageLabel_Letter_4">PackageLabel_Letter_4</option>
                            <option value="PackageLabel_Letter_6">PackageLabel_Letter_6</option>
                            <option value="PackageLabel_Letter_6_CarrierLeft">PackageLabel_Letter_6_CarrierLeft</option>
                            <option value="PackageLabel_A4_2">PackageLabel_A4_2</option>
                            <option value="PackageLabel_Plain_Paper">PackageLabel_Plain_Paper</option>
                            <option value="PackageLabel_Plain_Paper_CarrierBottom">PackageLabel_Plain_Paper_CarrierBottom</option>
                            <option value="PackageLabel_Thermal">PackageLabel_Thermal</option>
                            <option value="PackageLabel_Thermal_Unified">PackageLabel_Thermal_Unified</option>
                            <option value="PackageLabel_Thermal_NonPCP">PackageLabel_Thermal_NonPCP</option>
                        </Field>
                        {/* <div  className="form-group col-sm-4 mb-4"> 
                        <label className="text-black font-w500">Freight Class</label>
                        <FreightClassField name="freightClassCode" className="form-control" disabled={step !== 3}/>
                     </div>
                     <div  className="form-group col-sm-4 mb-4"> 
                        <label className="text-black font-w500">Shipment Expires On</label>
                        <ExpiryDateField name="shipmentExpiresOn" className="form-control" disabled={step !== 3}/>
                     </div> */}
                        <LtlFieldsForm />
                    </div>}
                {addTransportDetailsData?.isError &&
                    <Alert variant='danger'>
                        {
                            addTransportDetailsData?.error?.response?.data?.message?.[0]?.message ||
                            addTransportDetailsData?.error?.response?.data?.message
                        }
                    </Alert>}
            </Card.Body>
            {!disableInput &&
                <Card.Footer>
                    <div className="d-flex justify-content-end ">
                        <Button type="submit" disabled={loading} variant="success" className="btn-md btn-success fs-22 font-w700 btn-rounded"><i class="fas fa-save me-2"></i>Save</Button>
                    </div>
                </Card.Footer>}
        </Form>
    </Formik>
}


const ChargeAcceptForm = () => {

    const {
        createPalletsData,
        loading,
        shipment,
        order,
        disablePalletCreate: disableInput
    } = useContext(MultiStepFormContext);

    return (
        <>
            <Card.Header>
                <Card.Title>Pallets</Card.Title>
            </Card.Header>
            <Card.Body>
                {loading ?
                    <LoadingSpinner size={30} /> :
                    shipment.SubShipments.map((subShipment) => (
                        <>
                            <div className="row justify-content-center">
                                <div className='col-12'>
                                    <h4>{subShipment.shipmentName}</h4></div>
                                <div className="from-group col-sm-12  d-flex justify-content-center">
                                    <Table size='sm' bordered striped responsive='sm'>
                                        <thead>
                                            <thead>
                                                <td> id </td>
                                                <td> Height /inch </td>
                                                <td> Width  /inch </td>
                                                <td> Length /inch </td>
                                                <td> Weight /lbs </td>
                                            </thead>
                                            <tbody>
                                                {
                                                    order.Pallets.filter((plt) => (plt.subShipmentId == subShipment.id)).map((plt, i) => (
                                                        <tr>
                                                            <td> #{i + 1}    </td>
                                                            <td>{plt.height}</td>
                                                            <td>{plt.width} </td>
                                                            <td>{plt.length}</td>
                                                            <td>{plt.weight}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </thead>
                                    </Table>
                                </div>
                            </div>
                        </>
                    ))

                }
                {
                    createPalletsData?.isError &&
                    <Alert variant='danger' className='my-2'>
                        {
                            createPalletsData.error.response?.data?.message?.[0]?.message ||
                            createPalletsData.error.response?.data?.message
                        }
                    </Alert>
                }
            </Card.Body>
            {!disableInput &&
                <Card.Footer>
                    {/* {(step === 4) &&
                 <button  className="btn btn-primary "
                   disabled={(isLtl && !isPalletized) || generateEstimateData.isLoading}
                   onClick={()=> generateEstimateData.mutate({orderId: selectedOrder.id})}
                 > Generate Estimate</button>} */}
                    <button onClick={() => createPalletsData.mutate({ orderId: order.id })} disabled={loading} role="button" class="btn btn-md btn-success fs-22 font-w700 btn-rounded"><i class="fas fa-save me-2"></i>Create Palltes</button>
                </Card.Footer>}
        </>
    )
}


const ShipmentFinalForm = () => {
    const {
        generateEstimateData,
        disableConfirmShipment: disableInput,
        shipment,
        order,
        loading,
        confirmShipmentData,
        transportCancelData
    } = useContext(MultiStepFormContext)

    const handleConfirm = () => {
        confirmShipmentData.mutate({ orderId: order.id });
    }

    const handleEstimate = () => {
        generateEstimateData.mutate({ orderId: order.id });
    }

    const handleCancelTransport = () => {
        transportCancelData.mutate({ orderId: order.id });
    }

    return <>
        <Card.Header>
            <Card.Title> Confirm Shipment</Card.Title>
        </Card.Header>
        <Card.Body>

            {loading ?
                <LoadingSpinner size={30} /> :
                <>{
                    (
                        shipment?.transportStatus === 'ESTIMATED' ||
                        shipment?.transportStatus === 'CONFIRMED'
                    ) &&
                    <>
                        {shipment.SubShipments.map((subShipment) => (
                            <Toast style={{ boxShadow: 'none', marginTop: '7px' }}>
                                <Toast.Header closeButton={false}>
                                    <strong>{subShipment.shipmentName} (#{subShipment.refId})</strong>
                                </Toast.Header>
                                <Toast.Body>

                                    <p>{subShipment.name} Estimated Cost:  ${subShipment.estimatedAmount}</p>

                                    {/* <p>Others Cost:     ${shipment.totalFee}</p>
                        <p>Total Cost:      ${parseFloat(shipment.totalFee) + parseFloat(shipment.estimatedAmount)}</p> */}
                                </Toast.Body>
                            </Toast>))
                        }
                        <p style={{ textAlign: 'right' }}> Total ${shipment.SubShipments.reduce((sum, subShipment) => (sum + Number(subShipment.estimatedAmount)), 0)}</p>
                    </>
                }
                </>
            }

            {
                generateEstimateData?.isError &&
                <Alert variant='danger' className='my-2'>
                    {
                        generateEstimateData.error.response?.data?.message?.[0]?.message ||
                        generateEstimateData.error.response?.data?.message
                    }
                </Alert>
            }
            {
                confirmShipmentData?.isError &&
                <Alert variant='danger' className='my-2'>
                    {
                        confirmShipmentData.error.response?.data?.message?.[0]?.message ||
                        confirmShipmentData.error.response?.data?.message
                    }
                </Alert>
            }
        </Card.Body>
        <Card.Footer>
            {(shipment?.transportStatus === 'ESTIMATED') &&
                <button className='btn-md fs-22 font-w700 btn-rounded btn btn-success' type="button" disabled={loading} onClick={handleConfirm}><i class="fas fa-save me-2"></i>Confirm</button>}
            {(shipment?.transportStatus === 'WORKING') &&
                <button className='btn-md fs-22 font-w700 btn-rounded btn btn-success' type="button" disabled={loading} onClick={handleEstimate}><i class="fas fa-save me-2"></i>Estimate</button>}
            {(shipment?.transportStatus === 'READY' || shipment?.transportStatus === 'CONFIRMED') &&
                <button className='btn-md fs-22 font-w700 btn-rounded btn btn-alert' type="button" disabled={loading} onClick={handleCancelTransport}><i class="fas fa-save me-2"></i>
                    Cancel Transport</button>}
        </Card.Footer>
    </>
}
export default AddShipmentMultiStepForm

