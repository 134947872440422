import React, { useState } from 'react'
import RegistrationForm from '../components/customForms/RegistrationForm'
import { connect, useDispatch } from 'react-redux'
import {
  loadingToggleAction,
  signupAction,
} from '../../store/actions/AuthActions'

function SignUpBusiness(props) {
  const role = ['business']
  const [name, setFullName] = useState('')
  const [email, setEmail] = useState('')
  let errorsObj = { name: '', email: '', password: '', tc: '' }
  const [errors, setErrors] = useState(errorsObj)
  const [password, setPassword] = useState('')
  const [agreedTC, setAgreedTC] = useState(false)

  const dispatch = useDispatch()

  const toggleAgreeTC = () => {
    setAgreedTC(!agreedTC)
  }

  const strongPasswordRegex =
    /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/

  function onSignUp(e) {
    e.preventDefault()
    let error = false
    const errorObj = { ...errorsObj }
    if (name === '') {
      errorObj.name = 'Name is Required'
      error = true
    }
    if (email === '') {
      errorObj.email = 'Email is Required'
      error = true
    }
    if (password === '') {
      errorObj.password = 'Password is Required'
      error = true
    }
    if (!strongPasswordRegex.test(password)) {
      errorObj.password =
        'Use stonger password (At least 1 uppercase, 1 lowercase, 1 special character, 1 number and minimum 8 characters)'
      error = true
    }
    if (!error && !agreedTC) {
      errorObj.tc = 'Please agree to the terms to proceed.'
      error = true
    }
    setErrors(errorObj)
    if (error) return

    dispatch(loadingToggleAction(true))
    dispatch(signupAction(name, email, password, role, props.history))
  }

  return (
    <RegistrationForm
      errorMessage={props.errorMessage}
      successMessage={props.successMessage}
      errors={errors}
      onSubmit={onSignUp}
      onChangeFullName={(e) => setFullName(e.target.value)}
      onChangeEmail={(e) => setEmail(e.target.value)}
      onChangePassword={(e) => setPassword(e.target.value)}
      onChangeTC={toggleAgreeTC}
      userType="Business"
    />
  )
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  }
}

export default connect(mapStateToProps)(SignUpBusiness)
