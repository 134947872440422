import { useMutation, useQuery,useQueryClient } from "react-query";
import { useStateValue } from "../store/selectors/useStateValue";
import { config } from "../connector/config";
import http from "./http";
import { data } from "jquery";


export const useGetPaymentDetails= (currPage,perPage, sort) => {
    const { auth } = useStateValue();
    return useQuery(['fetchUserPaymentDetails', auth.auth.accessToken,perPage,currPage, sort],() => { 
        return  http().request({
            url: `${config.baseURL}/orders?currPage=${currPage}&perPage=${perPage}&orderBy=latest&invoiceApproved=true&${sort}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
            
        })
    })
};
