import React, { useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useStateValue } from '../../../../../store/selectors/useStateValue'
import { config } from '../../../../../connector/config'
import { nonPaginatedDataRequest } from '../../../../../connector/httpConnector/httpRequest'
import SellerAccountCard from './sellerAccountCard'
import LoadingSpinner from '../../../elements/LoadingSpinner/LoadingSpinner'

//Images
import amzlogo from '../../../../../images/amazon-logo.png'
import { DropdownButton } from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
const Integrations = () => {
  const { auth } = useStateValue()

  const { baseURL, getSellerAccountsList } = config
  const [sellerAccountsData, setSellerAccountsData] = useState()
  const [loading, setLoading] = useState(false)
  const token = auth.auth.accessToken

  const fetchSellerAccount = useCallback(() => {
    nonPaginatedDataRequest(baseURL, token, getSellerAccountsList)
      .then((data) => {
        setSellerAccountsData(data.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }, [baseURL, getSellerAccountsList, token])

  console.log('sellerAccountsData', sellerAccountsData)

  useEffect(() => {
    setLoading(true)
    fetchSellerAccount()
  }, [
    fetchSellerAccount.isLoading,
    fetchSellerAccount.success,
    fetchSellerAccount,
  ])

  const redirectToAmazonSellerCentral = (region) => {
    //const amazonSellerCentralUrl = "https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.25ab9c5d-6391-442d-a7d1-618f6764749c&state=shipx_amazon&version=beta&mons_sel_dir_mcid=amzn1.merchant.d.ABU34DJ22QFOMTT63RI5AFYO4CHA&mons_sel_mkid=A1F83G8C2ARO7P&mons_sel_dir_paid=amzn1.pa.d.ABGUX4ZFMDGXMMUMHN76R53Z5T6Q&ignore_selection_changed=true";
    let amazonSellerCentralUrl = null
    if (region == 'eu') {
      amazonSellerCentralUrl = `https://sellercentral-europe.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.25ab9c5d-6391-442d-a7d1-618f6764749c&state=${region}&version=beta`
    } else if (region == 'na') {
      amazonSellerCentralUrl = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.25ab9c5d-6391-442d-a7d1-618f6764749c&state=${region}&version=beta`
    } else if (region == 'fe') {
      amazonSellerCentralUrl = `https://sellercentral-japan.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.25ab9c5d-6391-442d-a7d1-618f6764749c&state=${region}&version=beta`
    }
    window.location.href = amazonSellerCentralUrl
  }
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card tryal-gradient">
                    <div className="card-body tryal row">
                      <div className="col-xl-7 col-sm-6">
                        <h2 className="text-black">Connect Your Seller Accounts</h2>
                        <span>Link your Amazon Seller Central account with bluudo and streamline your operations. Effortlessly create FBA shipments and live-track your shipments. Additionally, track your orders and inventory levels in real-time with our cutting-edge automated systems.</span>
                        <button
                          onClick={() => redirectToAmazonSellerCentral('na')}
                          className="btn d-hide btn-rounded fs-18 font-w500 bg-white text-black position-relative font-weight-bold "
                          style={{ zIndex: '3' }}
                        >
                          Connect
                        </button>
                      </div>
                      <div className="col-xl-5 col-sm-6">
                        <img src={amzlogo} alt="" className="sd-shape" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <LoadingSpinner size={150} />
            ) : (
              <div className="col-xl-6">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="row">
                      <SellerAccountCard
                        sellerAccountsData={sellerAccountsData}
                        fetchSellerAccount={fetchSellerAccount}
                      />
                      <div className="col-xl-6 col-sm-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="text-center pt-4 pb-4">
                              {/* <button
                  type="button"
                  className="btn btn-primary btn-rounded"
                  onClick={redirectToAmazonSellerCentral}
                >
                                <span className="btn-icon-start text-primary">
                                  <i className="fas fa-plus"></i>
                                </span>
                                Seller account 
                              </button> */}
                              <DropdownButton title={'Seller account'}>
                                <DropdownItem
                                  onClick={() =>
                                    redirectToAmazonSellerCentral('na')
                                  }
                                >
                                  North America
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    redirectToAmazonSellerCentral('eu')
                                  }
                                >
                                  Europe
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    redirectToAmazonSellerCentral('fe')
                                  }
                                >
                                  Far East
                                </DropdownItem>
                              </DropdownButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Integrations
