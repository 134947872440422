import { useMutation, useQuery,useQueryClient } from "react-query";
import { useStateValue } from "../store/selectors/useStateValue";
import { config } from "../connector/config";
import http from "./http";


export const useFetchRecentOrdersData  = () => {
    const { auth } = useStateValue();
    return useQuery('recent-orders', ()=> {
       return http().request({
           url: `${config.baseURL}/recent-orders`,
           method: 'GET',
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
       refetchOnWindowFocus: false 
    })
}
