import { useState, useEffect } from "react";
const PaymentSumary = ({ sumary, appSettings }) => {

  const [currencyFormat, setCurrencyFormat] = useState(Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
  }));

  useEffect(() => {
    setCurrencyFormat(Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: appSettings?.currency || 'USD',
    }));
  }, [appSettings]);

  return (
    <>
      {(sumary?.totalCost12 || sumary?.totalCost30) && (<div className="col-xl-3 col-sm-6">
        <div className="card">
          <div className="card-body d-flex px-4  justify-content-between">
            <div>
              <div className="">
                <span className="fs-18 font-w500 d-block">Order Total</span>
                <h2 className="fs-32 font-w700">
                  {sumary?.totalCost12 || 0}
                </h2>
                <span className="d-block fs-16 font-w400">
                  Last 30 Days
                </span>
                <h6 className="fs-20 font-w500">
                  {sumary?.totalCost30 || 0}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>)}


      {(sumary?.balance12 || sumary?.balance30) && (<div className="col-xl-3 col-sm-6">
        <div className="card">
          <div className="card-body d-flex px-4  justify-content-between">
            <div>
              <div className="">
                <span className="fs-18 font-w500 d-block">Total Balance</span>
                <h2 className="fs-32 font-w700">
                  <span className="fs-18 font-w500 local_currency"></span>
                  {currencyFormat.format(sumary?.balance12 || 0)}
                </h2>

                <span className="d-block fs-16 font-w400">
                  Last 30 Days
                </span>
                <h2 className="fs-20 font-w500">
                  <span className="fs-16 font-w400 local_currency"></span>
                  {currencyFormat.format(sumary?.balance30 || 0)}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>)}


      {(sumary?.ordersCount12 || sumary?.ordersCount30) && (<div className="col-xl-3 col-sm-6">
        <div className="card">
          <div className="card-body d-flex px-4  justify-content-between">
            <div>
              <div className="">
                <span className="fs-18 font-w500 d-block">New Orders</span>
                <h2 className="fs-32 font-w700">
                  {sumary?.ordersCount12 || 0}
                </h2>
                <span className="d-block fs-16 font-w400">
                  Last 30 Days
                </span>
                <h2 className="fs-20 font-w500">
                  {sumary?.ordersCount30 || 0}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>)}


      {(sumary?.totalOrderUnits12 || sumary?.totalOrderUnits30) && (<div className="col-xl-3 col-sm-6">
        <div className="card">
          <div className="card-body d-flex px-4  justify-content-between">
            <div>
              <div className="">
                <span className="fs-18 font-w500 d-block">Total Units Ordered</span>
                <h2 className="fs-32 font-w700">
                  {sumary?.totalOrderUnits12}
                </h2>
                <span className="d-block fs-16 font-w400">
                  Last 30 Days
                </span>
                <h2 className="fs-20 font-w500">
                  {sumary?.totalOrderUnits30}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>)}
    </>
  );
};

export default PaymentSumary;
