import React from 'react'
import { Pagination } from 'react-bootstrap'

const Paginator = ({ total, current, onChangePage }) => {
  let items = []

  const generatePaginationItems = (start, end) => {
    for (let page = start; page <= end; page++) {
      items.push(
        <Pagination.Item
          key={page}
          data-page={page}
          active={page === current}
          onClick={() => onChangePage(page)}
        >
          {page}
        </Pagination.Item>
      )
    }
  }

  let startPage = Math.max(1, current - 2)
  let endPage = Math.min(total, startPage + 5)

  if (current > 1) {
    items.push(
      <Pagination.Prev
        className="prev"
        key="prev"
        onClick={() => onChangePage(current - 1)}
      />
    )
  }

  if (current >= 4) {
    items.push(
      <Pagination.First
        className="first"
        key="first"
        onClick={() => onChangePage(1)}
      />
    )
  }

  generatePaginationItems(startPage, endPage)

  if (current < total) {
    items.push(
      <Pagination.Next
        className="next"
        key="next"
        onClick={() => onChangePage(current + 1)}
      />
    )
  }

  return (
    <div className="paginator">
      <Pagination>{items}</Pagination>
    </div>
  )
}

export default Paginator
