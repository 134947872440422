export const formatDate = (date, userDateFormat = 'mm dd, yyyy') => {
  function formatDate(date, format) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();
  
    if (month.length < 2) 
      month = '0' + month;
    if (day.length < 2) 
      day = '0' + day;
  
    switch (format.toLowerCase()) {
      case 'mm/dd/yyyy':
        return [month, day, year].join('/');
      case 'dd/mm/yyyy':
        return [day, month, year].join('/');
      case 'yyyy/mm/dd':
        return [year, month, day].join('/');
      case 'mmm dd, yyyy':
        return date.toLocaleDateString('en', { month: 'short', day: '2-digit', year: 'numeric' });
      default:
        return date.toLocaleDateString('en');
    }
  }
  return formatDate(date, userDateFormat);
};
