import { useMutation } from "react-query"
import { config } from "../connector/config";
import http from "./http";
import { useStateValue } from "../store/selectors/useStateValue";
export const useDropDownWarehouses = () => {
     const { auth } = useStateValue();
     return useMutation((params) => {
        return http().request({
            url:    `${config.businessWarehousesList}`,
            method: 'GET',
            params: params,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
          })
     })
}