import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStateValue } from "../store/selectors/useStateValue";
import { config } from "../connector/config";
import http from "./http";


export const useGetShipmentDetails = ( orderId ) => {

    const { auth } = useStateValue();

    const { businessOrders, supplierOrders } = config;

    const endpoint = auth.auth.role[0] === `business`? businessOrders: supplierOrders;

    return useQuery(['get-shipment-details',orderId, auth.auth.accessToken], () => {
         return  http().request({
            url:    `${endpoint}/${orderId}`,
            method: 'GET',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            }
         })
    })
}

export const  useCreateShipment = () => {
    const { auth } = useStateValue()
    const queryClient = useQueryClient()
    return useMutation(({orderId, data}) =>{

        return http().request({
            url:    `${config.businessShipments}`.replace(`{orderId}`,orderId),
            method: 'POST',
            data:   data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    },{
        onSuccess: () => {
           queryClient.invalidateQueries(`get-shipment-details`);
        }
    })
}

export const  useAddShipmentTransport = () => {
    const { auth } = useStateValue()
    const queryClient = useQueryClient()
    return useMutation(({orderId, data}) =>{

        return http().request({
            url:    `${config.businessShipments}`.replace(`{orderId}`,orderId) + `/transport-details`,
            method: 'POST',
            data:   data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    },{
        onSuccess: () => {
           queryClient.invalidateQueries(`get-shipment-details`);
           queryClient.invalidateQueries(`get-order-details`);
        }
    })
}

// export const  useContinueShipmentPlan = () => {
//     const { auth } = useStateValue()
//     const queryClient = useQueryClient()
//     return useMutation(({orderId, data}) =>{

//         return http().request({
//             url:    `${config.businessShipments}`.replace(`{orderId}`,orderId) + `/confirm`,
//             method: 'POST',
//             data:   data,
//             headers: {
//                 "Content-Type": "application/json",
//                 "x-access-token": auth.auth.accessToken,
//             }
//         })
//     },{
//         onSuccess: () => {
//            queryClient.invalidateQueries(`get-shipment-details`);
//         }
//     })
// }

export const  useCreatePallets = () => {
    const { auth } = useStateValue()
    const queryClient = useQueryClient()
    return useMutation(({orderId}) =>{

        return http().request({
            url:    `${config.businessOrders}/${orderId}/pallets`,
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    },{
        onSuccess: () => {
           queryClient.invalidateQueries(`get-shipment-details`);
        }
    })
}

export const  useGenerateEstimate = () => {
    const { auth } = useStateValue()
    const queryClient = useQueryClient()
    return useMutation(({orderId}) =>{

        return http().request({
            url:    `${config.businessOrders}/${orderId}/shipments/estimate`,
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    },{
        onSuccess: () => {
           queryClient.invalidateQueries(`get-shipment-details`);
        }
    })
}

export const  useConfirmShipment = () => {
    const { auth } = useStateValue()
    const queryClient = useQueryClient()
    return useMutation(({orderId}) =>{

        return http().request({
            url:    `${config.businessOrders}/${orderId}/shipments/transport-confirm`,
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    },{
        onSuccess: () => {
           //queryClient.invalidateQueries(`get-shipment-details`);
           queryClient.invalidateQueries(`get-order-details`);
        }
    })
}


export const  usePrintLabels = () => {
    const { auth } = useStateValue()
    const queryClient = useQueryClient()
    return useMutation(({orderId}) =>{
            return http().request({
                url:    `${config.businessOrders}/${orderId}/shipments/labels`,
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
                }
            })
    },{
        onSuccess: () => {
           queryClient.invalidateQueries(`get-order-details`);
        }
    })
}

export const  useTransportCancel = () => {
    const { auth }    = useStateValue()
    const queryClient = useQueryClient()
    return useMutation(({orderId}) =>{
             return http().request({
                url:    `${config.businessOrders}/${orderId}/shipments/transport-cancel`,
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
                }
            })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`get-shipment-details`);
            queryClient.invalidateQueries(`get-order-details`);
        }
    })
}

export const useSaveBoxTrackNo = () =>{
    const { auth }    = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({orderId, data})=>{
        return http().request({
            url:    `${config.businessOrders}/${orderId}/box-track-no`,
            method: 'POST',
            data: data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    },{
        onSuccess: ()=>{
            queryClient.invalidateQueries(`get-order-details`);
        }
    })
}

export const useAddBoxTrackNo = () =>{
    const { auth }    = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({orderId, data})=>{
        return http().request({
            url:    `${config.businessOrders}/${orderId}/add-box-track-no`,
            method: 'POST',
            data: data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    },{
        onSuccess: ()=>{
            queryClient.invalidateQueries(`get-order-details`);
        }
    })
}

export const useSaveShipFromLocation = () => {
    const { auth }    = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({data})=>{
        return http().request({
            url: `${config.baseURL}/business/ship-from-locations`,
            method: 'POST',
            data: data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    })
}

export const useSaveNewLocation = () => {
    const { auth }    = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({supplierId, data})=>{
        return http().request({
            url: `${config.baseURL}/business/supplier/${supplierId}/locations/create`,
            method: 'POST',
            data: data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    })
}