import { Modal, Toast } from "react-bootstrap"



export const PrintLabelModalForm = ({
     handleClose,
     subShipments
}) =>{

    return <>
      <Modal.Header>
           <Modal.Title>
                 Print Labels
           </Modal.Title>
           <button
             type="button"
             className="btn-close"
             onClick={()=>handleClose()}
             data-dismiss="modal"
           ></button>
      </Modal.Header>
      <Modal.Body>
          {
               subShipments.map((subShipment)=>(
                    <>
                    <h4>{subShipment.shipmentName} - ({subShipment.refId})</h4>
                    { subShipment.palletLabelSource &&
                         <Toast style={{boxShadow:'none'}} className="mb-2">
                              <Toast.Header closeButton={false} > Pallet Lables</Toast.Header>
                              <Toast.Body>
                                   <a href={subShipment.palletLabelSource} download={subShipment.palletLabelSource}
                                   >
                                   <button className="btn btn-outline-primary">
                                   Download
                                   </button>
                              </a>
                              </Toast.Body>
                         </Toast>}
                         {subShipment.boxLabelSource &&
                         <Toast style={{boxShadow:'none'}} className="mb-2">
                              <Toast.Header closeButton={false}> Box Lables</Toast.Header>
                              <Toast.Body>
                              <a href={subShipment.boxLabelSource } download={subShipment.boxLabelSource }
                              >
                                   <button className="btn btn-outline-primary">
                                   Download
                                   </button>
                              </a>
                              </Toast.Body>
                         </Toast>}
               </>
               ))
          }
      </Modal.Body>
    </>

}