import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import LoadingSpinner from "../elements/LoadingSpinner/LoadingSpinner";
import { useDropDownWarehouses } from "../../../data/warehouse-data";
import  Select from 'react-select';
import { useHandleGetProduct } from "../../../data/product-data";
import { Spinner } from "react-bootstrap";


const AddOrderFormTwo = ({
onCloseModal,
onInputChange,
inputValues,
onSubmit,
setFieldValue,
selectedProduct,
loading
}) => {

  // const [destinations, setDestinations] = useState([]);
  // const [searchDestination, setSearchDestination] = useState();
  const dropDownWarehouses = useDropDownWarehouses();
  const getProductData  = useHandleGetProduct();
  const [suppliers,   setSuppliers] = useState([]);
  const [disableInputs, setDisableInputs] = useState({
      warhouse: false,
      fba: true
  })
  

  useEffect(()=> {
      dropDownWarehouses.mutate({
         currPage: 1,
         perPage: 10,
      })
      getProductData.mutate({productId: selectedProduct.id}) 
  },[])

  useEffect(()=>{
        if(inputValues.destinationType === 'My Warehouse'){
              setDisableInputs({warhouse:false,fba:true})
              setFieldValue('supplierId', '')
        }else if(inputValues.destinationType === 'Amazon FBA'){
              setDisableInputs({fba:false, warehouse:true})
              setFieldValue('warehouseId', '')
        }
  },[inputValues])

  
  return (
    <form>
      <div className="modal-body">
        <i className="flaticon-cancel-12 close"></i>
        <div className="add-contact-box">
          <div className="add-contact-content">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group mb-3">
                  <div className="product-label-holder">
                    <label className="text-black font-w500">
                     product
                    </label>
                  </div>
                  <div className="contact-name">
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    name="productName"
                    required="required"
                    disabled
                    value={selectedProduct?.productName}
                  />
                </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">
                    Number of Units
                  </label>
                  <div className="contact-name">
                    <input
                      type="number"
                      className="form-control"
                      autoComplete="off"
                      name="orderUnits"
                      required="required"
                      placeholder="units"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="product-label-holder">
                    <label className="text-black font-w500">
                      Shipping Mode
                    </label>
                  </div>
                  <select
                    defaultValue={"option"}
                    className="form-control form-control-md"
                    name="shippingMode"
                    onChange={onInputChange}
                  >
                    <option value="select">Select Shipping Mode</option>
                    <option>air</option>
                    <option>sea</option>
                    <option>land</option>
                  </select>
                </div>
                <div className="form-group mb-3">
                  <div className="product-label-holder">
                    <label className="text-black font-w500">
                      Destination option
                    </label>
                  </div>
                  <div className="col-sm-9 radio-holder">
                    <div className="form-check radio_select_box">
                      <input
                        id="amzfba"
                        className="form-check-input"
                        type="radio"
                        name="destinationType"
                        value="Amazon FBA"
                        defaultChecked
                        onChange={onInputChange}
                      />
                      <label className="form-check-label" htmlFor="amzfba">
                        Amazon FBA
                      </label>
                    </div>
                    <div className="form-check radio_select_box">
                      <input
                        id="mywarehouse"
                        className="form-check-input"
                        type="radio"
                        name="destinationType"
                        value="My Warehouse"
                        onChange={onInputChange}
                      />
                      <label className="form-check-label" htmlFor="mywarehouse">
                        My Warehouse
                      </label>
                    </div>
                  </div>
                </div>
                 { disableInputs.warhouse === false &&
                  <div className="form-group mb-3">
                    <div className="product-label-holder">
                      <label className="text-black font-w500">
                        Destination
                      </label>
                    </div>
                    <Select
                       options={(
                          dropDownWarehouses.data?
                          dropDownWarehouses.data.data.data.warehouses.map((wh)=>({
                              value: wh.id,
                              label: wh.warehouseName
                          })):[]
                        )}
                        onChange={(newValue)=> setFieldValue('warehouseId',newValue.value)}
                    >
                    </Select>
                  </div>}
                  { disableInputs.fba === false &&
                  <div className="form-group mb-3">
                    <div className="product-label-holder">
                      <label className="text-black font-w500">
                        Choose Suppliers
                      </label>
                    </div>
                    <Select
                       options={(
                          getProductData.data?
                          getProductData.data.data.data.Suppliers.map((sup)=>({
                              value: sup.id,
                              label: sup.name || sup.email
                          })):[]
                        )}
                        onChange={(newValue)=> setFieldValue('supplierId',newValue.value)}
                    >
                    </Select>
                  </div>}
              </div>
               {/* { getProductData.isLoading? (
                <div className="col-md-4">
                  <LoadingSpinner />
                </div>
               ) : (
                <>
                  {  getProductData.data && 
                    !disableInputs.fba &&
                  (
                    <div className="col-md-4">
                      <div className="form-group mb-3">
                        <div className="product-label-holder">
                          <label className="text-black font-w500">
                            Choose Supplier
                          </label>
                        </div>
                        <div className="col-sm-9">
                          {getProductData.data.data.data.Suppliers.map((supplier, index) => (
                            <div className="form-check" key={supplier.id}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="supplierId"
                                onChange={onInputChange}
                                value={supplier.id}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={supplier.id}
                              >
                                {supplier.name || supplier.email}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={onSubmit}
        >
          {loading &&
          <Spinner size="sm" as="span" animation="border"/>}
          <span className="ms-1">Add</span>
        </button>
        <button
          type="button"
          onClick={onCloseModal}
          className="btn btn-danger"
        >
          {" "}
          <i className="flaticon-delete-1"></i> Discard
        </button>
      </div>
    </form>
  );
};

export default AddOrderFormTwo;
