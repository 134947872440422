import {
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from '../../services/AuthService'
import swal from 'sweetalert'

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup'
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup'
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login'
export const LOGIN_FAILED_ACTION = '[login action] failed login'
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading'
export const LOGOUT_ACTION = '[Logout action] logout action'
export const SET_USER_TYPE_ACTION = '[User type action] set user type action'

export function signupAction(name, email, password, role, history) {
  return (dispatch) => {
    signUp(name, email, password, role)
      .then((response) => {
        // saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, 3600 * 1000, history)
        dispatch(confirmedSignupAction(response.data))
        swal({
          title: 'Success!',
          text: 'Registration successful! Please verify your email to continue.',
          icon: 'success',
        }).then((result) => {
          if (result) {
            history.push(`/login`)
          }
        })
      })
      .catch((error) => {
        const errorMessage = error.response.data.message
        dispatch(signupFailedAction(errorMessage))
      })
  }
}

export function logout(history) {
  localStorage.removeItem('userDetails')
  history.push('/login')
  return {
    type: LOGOUT_ACTION,
  }
}

export function loginAction(email, password, history, rememberPreference) {
  return (dispatch) => {
    login(email, password, rememberPreference)
      .then((response) => {
        // saveTokenInLocalStorage(response.data);
        // runLogoutTimer(dispatch, 3600 * 1000, history);
        dispatch(loginConfirmedAction(response.data))
        history.push('/dashboard')
      })
      .catch((error) => {
        const errorMessage = error.response.data.message
        dispatch(loginFailedAction(errorMessage))
      })
  }
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  }
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  }
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  }
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  }
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  }
}

export const setUserTypeAction = (userType) => {
  return (dispatch) => {
    dispatch(setUserType(userType))
  }
}

export function setUserType(userType) {
  return {
    type: SET_USER_TYPE_ACTION,
    userType: userType,
  }
}
