import React from 'react'
import { Badge } from 'react-bootstrap'

const InventoryRow = ({ inventoryData }) => {
  return (
    <>
      {inventoryData &&
        inventoryData?.map((inventory) => (
          <tr key={inventory.id} className="odd " role="row">
            <td>
              <div className="d-flex align-items-center flex-wrap">
                <div className="project-image mb-0 align-items-center">
                  <img src={inventory.productThumb} alt="" />
                </div>
                <div>
                  <div className="mt-1 fs-12 font-w500">
                    <span className="d-block font-w600 fs-14 text-primary">
                      {inventory.productSKU}
                    </span>
                    <span className="d-block">{inventory.productASIN}</span>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <h2 className="fs-22 font-w700 mb-0">{inventory.fbaAvailable}</h2>
              <div>
                <span className="fs-14 font-w500 text-primary me-2">
                  {inventory.inboundUnits}
                </span>
              </div>
            </td>
            <td>
              <h2 className="fs-22 font-w700 mb-0 text-black">
                {inventory.dailySales}
              </h2>
            </td>
            <td>
              <h2 className="fs-22 font-w700 mb-0 text-warning">
                {inventory.dailySales === '0' ? 0 : inventory.stockLeftDays}{' '}
                <span className="fs-16 font-w600">Days</span>
              </h2>
              <div>
                <span className="fs-12 font-w500 text-primary me-2">
                  {inventory.dailySales === '0'
                    ? 0
                    : inventory.stockInboundLeftDays}{' '}
                  Days with inbound
                </span>
              </div>
            </td>
            <td>
              <h2 className="fs-22 font-w700 mb-0 text-warning">
                {inventory.dailySales === '0' ? 0 : inventory.orderWithin}{' '}
                <span className="fs-16 font-w600">Days</span>
              </h2>
              <div>
                <span className="fs-12 font-w500 text-primary me-2">
                  {inventory.dailySales === '0'
                    ? 0
                    : inventory.withInboundOrderWithin}{' '}
                  Days with inbound
                </span>
              </div>
            </td>
            <td>
              {inventory.orderWithin <= 0 ? (
                <Badge variant="badge badge-danger badge-xl light">
                  Order Now
                </Badge>
              ) : inventory.orderWithin > 0 && inventory.orderWithin <= 2 ? (
                <Badge variant="warning badge-xl light">Order Now</Badge>
              ) : (
                <Badge variant="badge badge-badge badge-success badge-xl light">
                  Good Stand
                </Badge>
              )}
            </td>
          </tr>
        ))}
    </>
  )
}

export default InventoryRow
