import React from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import { useEffect } from 'react';

const StripePyamentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const { getStripeDate } = props;
    getStripeDate(stripe, elements);
  }, [stripe, elements]);

  return (
    <form>
      <div className="mb-3 row">
        <label
          className="col-sm-3 col-form-label"
          htmlFor="first-name"
        >
          Card Number
        </label>
        <div className="col-sm-9" style={{margin: "10px !important", padding: "10px !important", border: "inset", display: "block", background: "white", position: "relative", opacity: "1 !important"}}>
          <CardNumberElement />
        </div>
        <label
          className="col-sm-3 col-form-label"
          htmlFor="last-name"
        >
          Expiry
        </label>
        <div className="col-sm-9" style={{margin: "10px !important", padding: "10px !important", border: "inset", display: "block", background: "white", position: "relative !important", opacity: "1 !important"}}>
          <CardExpiryElement />
        </div>
        <label
          className="col-sm-3 col-form-label"
          htmlFor="last-name"
        >
          CVC
        </label>
        <div className="col-sm-9" style={{margin: "10px !important", padding: "10px !important", border: "inset", display: "block", background: "white", position: "relative !important", opacity: "1 !important"}}>
          <CardCvcElement />
        </div>
      </div>
    </form>
  );
}

export default StripePyamentForm
