import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
/// Scroll
import PerfectScrollbar from 'react-perfect-scrollbar'

/// Image
import {
  useFetchHeaderNotificationData,
  useHandleNotificationSeen,
} from '../../../data/notification-data'
import { Dropdown } from 'react-bootstrap'
import LogoutPage from './Logout'
import moment from 'moment'
import { useGetUser } from '../../../data/account-data'

const Header = () => {
  const fetchUserProfile = useGetUser()
  var path = window.location.pathname.split('/')
  var appIndex = path.findIndex((part) => part === 'app')
  var desiredPart = path[appIndex + 1]

  var filterName = desiredPart.replace(/-/g, ' ')
  var finalName = filterName
    .split(' ')
    .filter(
      (f) =>
        f !== 'app' &&
        f !== 'ui' &&
        f !== 'uc' &&
        f !== 'basic' &&
        f !== 'jquery' &&
        f !== 'table' &&
        f !== 'page' &&
        f !== 'email' &&
        f !== 'ecom' &&
        f !== 'chart' &&
        f !== 'editor'
    )
  const FetchHeaderNotificationData = useFetchHeaderNotificationData(1, 10)
  const handleSeenNotification = useHandleNotificationSeen()

  const [userData, setUserData] = useState({})
  useEffect(() => {
    setUserData({
      firstName: fetchUserProfile.data?.data.data.firstName || '',
      lastName: fetchUserProfile.data?.data.data.lastName || '',
    })
  }, [fetchUserProfile.data])

  const userName = `${userData.firstName} ${userData.lastName}`

  function getInitials(name) {
    name = name.trim()
    if (name.indexOf(' ') === -1) {
      return name.substring(0, 2)
    }

    const words = name.split(' ')
    let initials = ''

    words.forEach((word) => {
      initials += word.charAt(0)
    })

    if (initials.length > 2) {
      initials = initials.substring(0, 2)
    }

    return initials
  }

  return (
    <div className="header border-bottom">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: 'capitalize' }}
              >
                {finalName.join(' ').length === 0
                  ? 'Dashboard'
                  : finalName.join(' ') === 'dashboard dark'
                  ? 'Dashboard'
                  : finalName.join(' ')}
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification">
              <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown"
                onClick={() => handleSeenNotification.mutate()}
              >
                <Dropdown.Toggle
                  className="nav-link i-false c-pointer"
                  variant=""
                  as="a"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.3333 19.8333H23.1187C23.2568 19.4597 23.3295 19.065 23.3333 18.6666V12.8333C23.3294 10.7663 22.6402 8.75902 21.3735 7.12565C20.1068 5.49228 18.3343 4.32508 16.3333 3.80679V3.49996C16.3333 2.88112 16.0875 2.28763 15.6499 1.85004C15.2123 1.41246 14.6188 1.16663 14 1.16663C13.3812 1.16663 12.7877 1.41246 12.3501 1.85004C11.9125 2.28763 11.6667 2.88112 11.6667 3.49996V3.80679C9.66574 4.32508 7.89317 5.49228 6.6265 7.12565C5.35983 8.75902 4.67058 10.7663 4.66667 12.8333V18.6666C4.67053 19.065 4.74316 19.4597 4.88133 19.8333H4.66667C4.35725 19.8333 4.0605 19.9562 3.84171 20.175C3.62292 20.3938 3.5 20.6905 3.5 21C3.5 21.3094 3.62292 21.6061 3.84171 21.8249C4.0605 22.0437 4.35725 22.1666 4.66667 22.1666H23.3333C23.6428 22.1666 23.9395 22.0437 24.1583 21.8249C24.3771 21.6061 24.5 21.3094 24.5 21C24.5 20.6905 24.3771 20.3938 24.1583 20.175C23.9395 19.9562 23.6428 19.8333 23.3333 19.8333Z"
                      fill="#717579"
                    />
                    <path
                      d="M9.9819 24.5C10.3863 25.2088 10.971 25.7981 11.6766 26.2079C12.3823 26.6178 13.1838 26.8337 13.9999 26.8337C14.816 26.8337 15.6175 26.6178 16.3232 26.2079C17.0288 25.7981 17.6135 25.2088 18.0179 24.5H9.9819Z"
                      fill="#717579"
                    />
                  </svg>
                  <span className="badge light text-white bg-warning rounded-circle">
                    {FetchHeaderNotificationData.data?.data?.data.unseenCount}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="mt-2 dropdown-menu dropdown-menu-end"
                >
                  <PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
                    <ul className="timeline">
                      {FetchHeaderNotificationData.data?.data?.data.notifications.map(
                        (notification) => (
                          <li key={notification.id}>
                            <div className="timeline-panel">
                              {notification.creator === 'user' && (
                                <>
                                  {notification.Creator.profileImage !==
                                  null ? (
                                    <div className="media me-2">
                                      <img
                                        alt="images"
                                        width={50}
                                        src={notification.Creator.profileImage}
                                      />
                                    </div>
                                  ) : (
                                    <div className="media me-2 media-info">
                                      {notification.Creator.name
                                        .split(' ')
                                        .map((w) => w.charAt(0))
                                        .slice(0, 2)
                                        .join('')}
                                    </div>
                                  )}
                                </>
                              )}
                              {notification.creator === 'system' && (
                                <div className="media me-2 media-success">
                                  <i className="fa fa-home" />
                                </div>
                              )}
                              <div className="media-body">
                                <p
                                  className="mb-1 font-weight-bold"
                                  style={{ fontWeight: '700' }}
                                >
                                  {notification.title.toUpperCase()}
                                </p>
                                <span className="mb-1">
                                  {notification.content
                                    .split(' ')
                                    .slice(0, 15)
                                    .join(' ')}
                                  ...
                                </span>
                                <small
                                  className="d-block"
                                  style={{ fontWeight: '700' }}
                                >
                                  {moment(notification.createdAt).format(
                                    'DD MMMM YYYY - hh:mm A'
                                  )}
                                </small>
                              </div>
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                    <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                      <div
                        className="ps__thumb-x"
                        tabIndex={0}
                        style={{ left: 0, width: 0 }}
                      />
                    </div>
                    <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                      <div
                        className="ps__thumb-y"
                        tabIndex={0}
                        style={{ top: 0, height: 0 }}
                      />
                    </div>
                  </PerfectScrollbar>
                  <Link className="all-notification" to="/view-notifications">
                    See all notifications <i className="ti-arrow-right" />
                  </Link>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                  role="button"
                  data-toggle="dropdown"
                >
                  <div className="profile-initials">
                    {getInitials(userName)}
                  </div>
                  {/* <img src={profile} width={20} alt="" /> */}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="mt-3 dropdown-menu dropdown-menu-end"
                >
                  <Link to="/account-app" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary me-1"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ms-2">Account</span>
                  </Link>
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Header
