import { useFetchHeaderNotificationData, useHandleNotificationSeen } from "../../../../data/notification-data";
import moment from "moment";
import Paginator from "../../elements/Paginator/Paginator";
import { useEffect, useState } from "react";

const ViewNotificaion = () => {
    const [page, setPage] = useState(1);

    const FetchHeaderNotificationData = useFetchHeaderNotificationData(page,10);
    const handleSeenNotification = useHandleNotificationSeen();

    useEffect(()=>{
        handleSeenNotification.mutate();
    },[])

    return (
        <div className="row justify-content-center">
             <div className="col-md-9 card">
                <div className="row">
                     <div className="col-md-12 pt-5">
                        <div className="widget-media px-4">
                            <ul className="timeline">
                            {FetchHeaderNotificationData.data?.data?.data.notifications.map(notification => (
                            <li key={notification.id}>
                                    
                                    <div className="timeline-panel">
                                            { 
                                            (notification.creator === 'user') && 
                                            <>
                                            {
                                                notification.Creator.profileImage !== null ?
                                                <div className="media me-2">
                                                    <img alt="images" width={50} src={notification.Creator.profileImage} />
                                                </div>:
                                                <div className="media me-2 media-info">
                                                    {notification.Creator.name.split(' ').map((w) => w.charAt(0)).slice(0,2).join('') }
                                                </div>
                                            }
                                            </>
                                            }
                                            {
                                            (notification.creator === 'system') &&
                                            <div className="media me-2"> 
                                                <img alt="images" width={50} src={notification?.Creator?.profileImage} />
                                            </div>
                                            }
                                        <div className="media-body">
                                            <p     className="mb-1 font-weight-bold" style={{fontWeight:'700'}}>{notification.title.toUpperCase()}</p>
                                            <span  className="mb-1">{notification.content}</span>
                                            <small className="d-block" style={{fontWeight:'700'}}>
                                            { moment(notification.createdAt).format('DD MMMM YYYY - hh:mm A')}
                                            </small>
                                        </div>
                                    </div>
                                </li>
                            ))}
                            </ul>
                        </div>
                     </div>
                     <div className="col-md-12 d-flex p-4 mt-2">
                            <Paginator
                                current={page}
                                total={
                                    FetchHeaderNotificationData.data?.data?.data.totalPages || 0
                                }
                                onChangePage={(val)=>setPage(Number(val))}
                            />
                     </div>
                </div>
            </div>
        </div>
      
    )
}

export default ViewNotificaion;