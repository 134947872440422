import React from "react";
import LoadingSpinner from "../../../elements/LoadingSpinner/LoadingSpinner";

const AttachShipmentIdForm = ({ onClickSubmit, onClickCancel, loading, onShipmentIdChange }) => {
  return (
    (<div>
      {loading ? (
				<LoadingSpinner size={50} />
			) : (<div>
        <div className="card-header justify-content-between">
          <div className="card-title h5">Add Shipping ID</div>
          <button
            type="button"
            className="btn-close"
            onClick={onClickCancel}
          ></button>
        </div>
        <div className="modal-body">
          <div className="add-contact-box">
            <div className="add-contact-content">
              <div className="form-group mb-3">
                <label className="text-black font-w500">Shipment ID</label>
                <div className="contact-name">
                  <input
                    className="form-control"
                    autoComplete="off"
                    name={`shipment_id`}
                    placeholder="FBAXXXXXXXXX"
                    onChange={onShipmentIdChange}
                  // disabled={disableInput}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={onClickSubmit}
            >
              Submit
            </button>
            <button
              type="button"
              onClick={onClickCancel}
              className="btn btn-danger"
            >
              {" "}
              <i className="flaticon-delete-1"></i> Discard
            </button>
          </div>
        </div>
      </div>)}
    </div>)
  );
};

export default AttachShipmentIdForm;
