import { useMutation, useQuery, useQueryClient } from "react-query";
import http from "./http";
import { config } from "../connector/config";
import { useStateValue } from "../store/selectors/useStateValue";

export const useFetchOrders = (params = { }) => {

    const { auth } = useStateValue();
    const { businessOrders, supplierOrders } = config;

    const endpoint = auth.auth.role[0] === `business`? businessOrders: supplierOrders;
    return useQuery(['get-orders',JSON.stringify(params),auth.auth.accessToken],( ) => {
        return http().request({
            url:    endpoint,
            method: 'GET',
            params: params,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
          })
    },{
       retry:0
    })
}

export const useSaveNewOrder = () => {

  const { auth } = useStateValue();
  const queryClient = useQueryClient();

  return useMutation((newOrder)=> {
    return http().request({
      url:    `${config.businessOrders}`,
      method: 'POST',
      data: newOrder,
      headers: {
          "Content-Type": "application/json",
          "x-access-token": auth.auth.accessToken,
      }
    })
  },{
    onSuccess: () => {
      queryClient.invalidateQueries(`get-orders`)
    }
  });
}

export const useDeleteOrder = () => {
  
   const { auth }    = useStateValue();
   const queryClient = useQueryClient();

   return useMutation(({ orderId })=>{

       return http().request({
          url: `${config.businessOrders}/${orderId}`,
          method: 'DELETE',
          headers: {
            "Content-Type": "application/json",
            "x-access-token": auth.auth.accessToken,
          }
       })
   },{
       onSuccess: () => {
           queryClient.invalidateQueries('get-orders')
       }
   })
}