import React, { useState } from "react";
import { Country, State } from "country-state-city";
import Select from 'react-select';



export const initialLocationFormState = {
  warehouseName: "",
  firstLine: "",
  city: "",
  county: "",
  zipCode: "",
  country: "",
  PhoneNumber:"",
};

const EditLocationForm = ({
  locationName,
  addressLine1,
  cityName,
  stateOrProvinceName,
  zipCode,
  countryName,
  phoneNumber,
  onLocationNameChange,
  onFirstLineChange,
  onCityChange,
  onZipCodeChange,
  onCountyChange,
  onCountryChange,
  onPhoneNumberChange,
  onClickSubmit,
  onClickCancel,
  countryCode,
  countyCode
}) => {
    const [selectedCountry, setSelectedCountry] = useState();
    return (
        <div className="modal-body">
          <i className="flaticon-cancel-12 close"></i>
          <div className="add-contact-box">
            <div className="row add-contact-content">
              <div className="form-group col-sm-6 mb-3">
                <label className="text-black font-w500">Location name</label>
                <div className="contact-name">
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    name="locationName"
                    required="required"
                    value={locationName}
                    onChange={onLocationNameChange}
                    placeholder="locationName"
                  />
                </div>
              </div>
              <div className="form-group col-sm-6 mb-3">
                <label className="text-black font-w500">
                  First line of address
                </label>
                <div className="contact-name">
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    name="addressLine1"
                    required="required"
                    value={addressLine1}
                    onChange={onFirstLineChange}
                    placeholder="First line of address"
                  />
                </div>
              </div>
              <div className="form-group col-sm-6 mb-3">
                <label className="text-black font-w500">City</label>
                <div className="contact-name">
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    name="cityName"
                    required="required"
                    value={cityName}
                    onChange={onCityChange}
                    placeholder="City"
                  />
                </div>
              </div>
              <div className="form-group col-sm-6 mb-3">
                <label className="text-black font-w500">County</label>
                <div className="contact-name">
                 
              <Select
                options={
                   State.getStatesOfCountry(selectedCountry)
                  .map((state) => ({value: state.isoCode, label: state.name}))
                }
                defaultValue={{value: countyCode, label: stateOrProvinceName}}
                onChange={(newValue)=>{
                  const event  = {
                     target: {
                        getAttribute : () =>  'stateOrProvinceName',
                        value: newValue.value
                     },
                     preventDefault: ( )=>{}
                  }
                  onCountyChange( event )
                }}
              >
              </Select>
                </div>
              </div>
              <div className="form-group col-sm-6 mb-3">
                <label className="text-black font-w500">Zip code</label>
                <div className="contact-name">
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    name="zipCode"
                    required="required"
                    value={zipCode}
                    onChange={onZipCodeChange}
                    placeholder="Zip Code"
                  />
                </div>
              </div>
              <div className="form-group col-sm-6 mb-3">
                <label className="text-black font-w500">Country</label>
                <div className="contact-name">
                   <Select
                    options={
                       Country.getAllCountries()
                      .map(country => ({value: country.isoCode,label: country.name}))
                    }
                    defaultValue={{value: countryCode, label: countryName}}
                    onChange={(newValue)=>{
                          const event  = {
                            target: {
                                getAttribute : () =>  'country',
                                value: newValue.value
                            },
                            preventDefault: ( )=>{}
                          }
                          setSelectedCountry(newValue.value)
                          onCountryChange( event )
                    }}
                 ></Select>
                </div>
              </div>
              <div className="form-group col-sm-6 mb-3">
                <label className="text-black font-w500">Phone Number</label>
                <div className="contact-name">
                  <input
                    type="tel"
                    className="form-control"
                    autoComplete="off"
                    name="phoneNumber"
                    required="required"
                    value={phoneNumber}
                    onChange={onPhoneNumberChange}
                    placeholder="Phone Number"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={onClickSubmit}
            >
              Add
            </button>
            <button
              type="button"
              onClick={onClickCancel}
              className="btn btn-danger"
            >
              {" "}
              <i className="flaticon-delete-1"></i> Discard
            </button>
          </div>
        </div>
      );
};

export default EditLocationForm;
