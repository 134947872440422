import React from "react";
import dhl from "./../../../../images/freight/dhl.png";
import fedex from "./../../../../images/freight/fedex.png";
import ups from "./../../../../images/freight/ups.png";
import usps from "./../../../../images/freight/usps.png";
import defaultIcon from "./../../../../images/freight/default-icon.png";

export const getShippingIcon = (shippingMode) => {
  switch (shippingMode) {
    case "air": {
      return "fa-plane";
    }
    case "sea": {
      return "fa-ship";
    }
    case "land": {
      return "fa-truck";
    }
    default: {
      return;
    }
  }
};

export const getCarrierIcon = (carrier) => {
  switch (carrier) {
    case "DHL_EXPRESS_USA_INC": {
      return dhl;
    }
    case "FEDERAL_EXPRESS_CORP": {
      return fedex;
    }
    case "UNITED_STATES_POSTAL_SERVICE": {
      return usps;
    }
    case "UNITED_PARCEL_SERVICE_INC":{
      return ups;
    }
    default: {
      return defaultIcon;
    }
  }
};

function convertStatusToReadable(status) {
  const statusMap = {
    unknown: "Unknown",
    pre_transit: "Pre Transit",
    in_transit: "In Transit",
    out_for_delivery: "Out For Delivery",
    delivered: "Delivered",
    available_for_pickup: "Available for Pickup",
    return_to_sender: "Return to Sender",
    failure: "Failure",
    cancelled: "Cancelled",
    error: "Error"
  };

  return statusMap[status] || status;
}

const TrackingCard = ({
  shipmentAgentLogo,
  masterCarton,
  shippingMode,
}) => {
  const arr = masterCarton?.trackingInfo?.tracking_details
  const lastUpdatedDetails = arr?.[arr?.length - 1];
  const currentLocation = lastUpdatedDetails?.tracking_location
  const statusArray = ["pre_transit", "in_transit", "available_for_pickup", "out_for_delivery", "delivered"];
  const statusIndex = lastUpdatedDetails?.status ? statusArray.indexOf(lastUpdatedDetails.status) : -1;

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center project-image mb-3">
          <img src={getCarrierIcon(masterCarton?.carrierName)} alt="" />
          <div>
            <small className="d-block fs-16 font-w700">Tracking ID</small>
            <span className="d-block fs-12 font-w500">
              {masterCarton?.trackingId}
            </span>
          </div>
        </div>
        <div className="circle-icon">
          <span className="ms-0">
            <i className={`fas ${getShippingIcon(shippingMode)}`}></i>
          </span>
        </div>
      </div>
      <span className="d-block fs-16 text-black font-w600">{convertStatusToReadable(lastUpdatedDetails?.status)}</span>
      <div className="progress mb-2">
        <div
          className="progress-bar progress-animated bg-success"
          style={{ width: `${statusIndex / 4 * 100}%` }}
        ></div>
      </div>
      <span className="d-block fs-12 font-w500">{lastUpdatedDetails?.message}</span>
      <span className="d-block"> {currentLocation?.zip} -{currentLocation?.city}</span>
    </>
  );
};

export default TrackingCard;
