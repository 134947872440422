import React, { Fragment, useContext, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import Logo from "../../components/elements/Logo/Logo";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            <Logo />
          </Fragment>
        ) : (
          <Fragment>
            <div className="nav-header-logo">
              <Logo botOnly={toggle} />
            </div>
          </Fragment>
        )}
      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
