import React, { useState, useEffect, useCallback } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Modal, Badge, Button, Col, Container, Row } from 'react-bootstrap'
import { formatDate } from '../../utils/formatDate'
import { useGetPaymentDetails } from '../../../../data/payment-data'
import { useAddPayment, useRemovePayment } from '../../../../data/order-details'
import { dataMultiSortByRequest } from '../../../../connector/httpConnector/httpRequest'
import { onConfirmSwal } from '../../utils/onConfirmSwal'
import { useStateValue } from '../../../../store/selectors/useStateValue'
import { useGetAppSettings } from '../../../../data/account-data'
import { config } from '../../../../connector/config'
import UpdatePayment from '../OrderDetail/UpdatePayment/UpdatePayment'
import Paginator from '../../elements/Paginator/Paginator'
import swal from 'sweetalert'

import LoadingSpinner from '../../elements/LoadingSpinner/LoadingSpinner'
const PaymentRow = ({ setSumary, setAppSettings }) => {
  const location = useLocation()
  const history = useHistory()

  const { products, auth } = useStateValue()
  const { baseURL, businessOrders } = config
  const [currPage, setCarPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get('page')) || 1
  )
  const [perPage, setPerPage] = useState(10)
  const [order, setOrder] = useState(null)
  const [paymentModal, setPaymentModal] = useState(false)
  const [paymentAmount, setPaymentAmount] = useState('')
  const addPaymentData = useAddPayment()
  const removePaymentData = useRemovePayment()
  const [sort, setSort] = useState([])
  const [sortString, setSortString] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [userDateFormat, setUserDateFormat] = useState('mmm dd, yyyy')

  const GetPaymentDetails = useGetPaymentDetails(currPage, perPage, sortString)

  const fetchAppSettings = useGetAppSettings()
  const [currencyFormat, setCurrencyFormat] = useState(
    Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    })
  )

  useEffect(() => {
    if (!GetPaymentDetails.isLoading) {
      if (GetPaymentDetails.isSuccess) {
        setSumary(GetPaymentDetails.data.data)
      }
    }
  }, [
    GetPaymentDetails.data,
    GetPaymentDetails.isLoading,
    GetPaymentDetails.isSuccess,
    setSumary,
  ])

  useEffect(() => {
    if (!fetchAppSettings.isLoading) {
      if (fetchAppSettings.isSuccess) {
        setAppSettings(fetchAppSettings?.data?.data?.data?.[0])
        setCurrencyFormat(
          Intl.NumberFormat(undefined, {
            style: 'currency',
            currency:
              fetchAppSettings?.data?.data?.data?.[0]?.currency || 'USD',
          })
        )
        setUserDateFormat(fetchAppSettings?.data?.data?.data?.[0]?.dateformat)
      }
    }
  }, [
    fetchAppSettings.data,
    fetchAppSettings.isLoading,
    fetchAppSettings.isSuccess,
    setAppSettings,
  ])

  useEffect(() => {
    setLoading(true)
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('page') !== null) {
      setCarPage(Number(searchParams.get('page')))
    }
    if (searchParams.get('sort') !== null) {
      setSortString(searchParams.get('sort'))
    }
    setLoading(false)
  }, [location.search])

  const handleChangePage = (currPage) => {
    setLoading(true)
    const searchParams = new URLSearchParams()
    searchParams.set('page', currPage)
    history.push({ search: searchParams.toString() })
    setLoading(false)
  }

  const SortIcon = ({ onClick, order }) => {
    if (order === 'asc') {
      return (
        <i
          role="button"
          className="fa fa-sort-down"
          onClick={onClick}
          style={{ marginLeft: 7 }}
        ></i>
      )
    } else if (order === 'desc') {
      return (
        <i
          role="button"
          className="fa fa-sort-up"
          onClick={onClick}
          style={{ marginLeft: 7 }}
        ></i>
      )
    } else {
      return (
        <i
          role="button"
          className="fa fa-sort"
          onClick={onClick}
          style={{ marginLeft: 7 }}
        ></i>
      )
    }
  }

  const updateSortOrder = (colName, order = 'asc') => {
    let copySort = [...sort]
    let index = copySort.findIndex((ele) => ele[0] === colName)
    if (index !== -1) {
      copySort.splice(index, 1)
    }
    copySort.unshift([colName, order])
    setSort(copySort)
  }

  const getSortOrder = (colName) => {
    let copySort = [...sort]
    let index = copySort.findIndex((ele) => ele[0] === colName)
    if (index === -1) {
      return null
    }
    let sortCol = copySort[index]
    return sortCol[1]
  }

  const swicthSortOrder = (colName) => {
    const order = getSortOrder(colName)
    if (order === 'asc') {
      updateSortOrder(colName, 'desc')
    }
    if (order === 'desc') {
      updateSortOrder(colName, 'asc')
    }
    if (order === null) {
      updateSortOrder(colName, 'asc')
    }
  }

  const sortProducts = useCallback(() => {
    setLoading(true)
    let parsedSort = sort
      .map((s, i) => `sort[${i}]=${s[0]}&sort[${i}]=${s[1]}`)
      .join('&')
    const searchParams = new URLSearchParams()
    searchParams.set('sort', parsedSort)
    history.push({ search: searchParams.toString() })
    setLoading(false)
  }, [sort, history])

  useEffect(() => {
    setLoading(true)
    sortProducts()
    setLoading(false)
  }, [sortProducts, sort, currPage])

  // Delete payment
  const handleDeletePayment = async (paymentId) => {
    // setLoading(true);

    try {
      setLoading(true)
      await removePaymentData.mutateAsync({ orderId: order.id, paymentId })
      setPaymentModal(false)
      swal('Good job!', 'Payment deleted', 'success')
      await GetPaymentDetails.refetch()

      setLoading(false)
    } catch (error) {
      swal('Oops', error.response.data.message, 'error')
      setLoading(false)
    }
  }

  const handleAddPayment = async () => {
    const newPayment = {
      paidAmount: paymentAmount,
      paymentDate: selectedDate,
    }

    try {
      await addPaymentData.mutateAsync({ orderId: order.id, newPayment })
      setPaymentModal(false)
      swal('Good job!', 'Payment added', 'success')
      setPaymentAmount('')
      setSelectedDate(new Date())
      setLoading(true)
      await GetPaymentDetails.refetch()
      setLoading(false)
    } catch (error) {
      swal('Oops', error.response.data.message, 'error')
      setLoading(false)
    }
  }

  const calculateTotalPaid = (order) => {
    const payments = order.Invoice?.Payments || []
    let totalPaid = 0
    payments.forEach((val) => (totalPaid += Number(val.paidAmount)))
    return totalPaid
  }
  return (
    <>
      {loading || GetPaymentDetails.isLoading ? (
        <LoadingSpinner size={150} />
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <Modal className="modal fade" show={paymentModal}>
                {}

                <UpdatePayment
                  closeModal={() => setPaymentModal()}
                  totalCost={order?.Invoice?.totalCost}
                  invoiceNumber={order?.Invoice?.invoiceNumber}
                  selectedDate={selectedDate}
                  onPaymentAmountChange={(e) =>
                    setPaymentAmount(e.target.value)
                  }
                  paymentValue={paymentAmount}
                  onDateChange={(date) => setSelectedDate(date)}
                  onAddPayment={handleAddPayment}
                  payments={order?.Invoice?.Payments}
                  remainingCost={
                    order?.Invoice?.totalCost -
                    order?.Invoice?.Payments?.reduce((accumulator, payment) => {
                      return accumulator + parseFloat(payment.paidAmount)
                    }, 0)
                  }
                  isDisabled={
                    paymentAmount === '' ||
                    addPaymentData.isLoading ||
                    removePaymentData.isLoading
                  }
                  onDeletePayment={(id) =>
                    onConfirmSwal('payment detail', () =>
                      handleDeletePayment(id)
                    )
                  }
                  currencyFormat={currencyFormat}
                />
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => setPaymentModal(false)}
                  >
                    Done
                  </button>
                </div>
              </Modal>
              <div className="card-body">
                <div className="table-responsive">
                  <div id="payments_data" className="dataTables_wrapper">
                    <table
                      className="display w-100 dataTable "
                      id="example5"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting"
                            sort="true"
                            style={{ width: '100px' }}
                          >
                            Order #
                            <SortIcon
                              order={getSortOrder('poNumber')}
                              onClick={() => swicthSortOrder('poNumber')}
                            />
                          </th>
                          <th className="sorting" style={{ width: '250px' }}>
                            Product
                            <SortIcon
                              order={getSortOrder('productName')}
                              onClick={() => swicthSortOrder('productName')}
                            />
                            <div className="mt-1 fs-12 font-w500">
                              <span className="font-w600 text-primary">
                                SKU
                                <SortIcon
                                  order={getSortOrder('productSKU')}
                                  onClick={() => swicthSortOrder('productSKU')}
                                />
                              </span>
                              <span className="">
                                / ASIN
                                <SortIcon
                                  order={getSortOrder('productASIN')}
                                  onClick={() => swicthSortOrder('productASIN')}
                                />
                              </span>
                            </div>
                          </th>
                          <th className="sorting" style={{ width: '100px' }}>
                            Total
                            <SortIcon
                              order={getSortOrder('totalCost')}
                              onClick={() => swicthSortOrder('totalCost')}
                            />
                            <span className="d-block fs-14 font-w500 text-primary">
                              Paid
                            </span>
                          </th>
                          <th className="sorting" style={{ width: '100px' }}>
                            Balance
                          </th>
                          <th className="sorting" style={{ width: '100px' }}>
                            Invoice
                            <SortIcon
                              order={getSortOrder('invoiceDate')}
                              onClick={() => swicthSortOrder('invoiceDate')}
                            />
                            <span className="d-block fs-14 font-w500 text-primary">
                              Inv No.
                              <SortIcon
                                order={getSortOrder('invoiceNumber')}
                                onClick={() => swicthSortOrder('invoiceNumber')}
                              />
                            </span>
                          </th>
                          <th className="sorting" style={{ width: '100px' }}>
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {GetPaymentDetails.data?.data.data.orders?.map(
                          (payment) => {
                            console.log('Payment', payment)
                            const MAX_CHAR_LENGTH = 60
                            const productName = payment.Product.productName
                            const truncatedName =
                              productName.length > MAX_CHAR_LENGTH
                                ? productName.substring(
                                    0,
                                    MAX_CHAR_LENGTH - 3
                                  ) + '...'
                                : productName
                            return (
                              <tr key={payment.id} className="odd" role="row">
                                <td>
                                  <Link
                                    to={`/order-detail/${payment.id}`}
                                    className=""
                                    data-name=""
                                  >
                                    <span className="fs-14 font-w600 text-primary">
                                      {payment.poNumber}
                                    </span>
                                  </Link>
                                </td>
                                <td className="text-black">
                                  <Link
                                    to={'/order-detail'}
                                    className=""
                                    data-name=""
                                  >
                                    {truncatedName}
                                  </Link>
                                  <div className="mt-1 fs-12 font-w500">
                                    <span className="font-w600 text-primary">
                                      {payment.Product.productSKU}
                                    </span>
                                    <span className="">
                                      {' '}
                                      / {payment.Product.productASIN}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <h2 className="fs-22 font-w700 mb-0">
                                    <span className="fs-16 font-w500 local_currency">
                                      {currencyFormat.format(
                                        payment.Invoice.totalCost
                                      )}
                                    </span>
                                  </h2>
                                  <div>
                                    <span className="fs-14 font-w500 text-primary local_currency">
                                      {currencyFormat.format(
                                        calculateTotalPaid(payment)
                                      )}
                                    </span>
                                    <span className="fs-14 font-w500 text-primary me-2"></span>
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary btn-xxs edit_paid"
                                      onClick={() => {
                                        setOrder(payment)
                                        setPaymentModal(true)
                                      }}
                                    >
                                      <i className="far fa-edit"></i>
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <h2 className="fs-22 font-w700 mb-0 text-danger">
                                    <span className="fs-16 font-w500 local_currency">
                                      {currencyFormat.format(
                                        payment.Invoice.totalCost -
                                          calculateTotalPaid(payment)
                                      )}
                                    </span>
                                  </h2>
                                </td>
                                <td>
                                  <div className="final-badge">
                                    <span className="badge badge-sm text-black border mt-0">
                                      <i className="far fa-file-alt me-3"></i>
                                      {formatDate(
                                        new Date(payment?.Invoice?.invoiceDate),
                                        userDateFormat
                                      )}
                                    </span>
                                  </div>
                                  <span className="fs-12 font-w400 text-dark ms-4">
                                    {payment?.Invoice?.invoiceNumber}
                                  </span>
                                </td>
                                <td>
                                  {Number(payment.Invoice.totalCost) ===
                                    calculateTotalPaid(payment) && (
                                    <Badge variant="badge badge-success badge-xl light">
                                      Complete
                                    </Badge>
                                  )}
                                  {Number(payment.Invoice.totalCost) !==
                                    calculateTotalPaid(payment) &&
                                    calculateTotalPaid(payment) !== 0 && (
                                      <Badge variant="badge badge-warning badge-xl light">
                                        Part Paid
                                      </Badge>
                                    )}
                                  {calculateTotalPaid(payment) === 0 && (
                                    <Badge variant="danger badge-xl light">
                                      Pending
                                    </Badge>
                                  )}
                                </td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            {GetPaymentDetails.data?.data.data.totalPages > 1 && (
              <Paginator
                total={GetPaymentDetails.data?.data.data.totalPages}
                current={currPage}
                onChangePage={handleChangePage}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default PaymentRow
