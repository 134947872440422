import React from 'react'
import { useStateValue } from '../../../../../store/selectors/useStateValue'

const UploadDocumentsCard = ({
  onClickUpload,
  documents,
  onDeleteDocument,
}) => {
  const { auth } = useStateValue()

  return (
    <div className="col-xl-6">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h5 className="text-black ">Documents</h5>
            </div>
            <div className="card-body pt-3">
              <div className="final-badge">
                {documents &&
                  documents.map((document) => (
                    <div className="file-container" key={document.orderDocId}>
                      {Number(auth.auth.id) ===
                        Number(document.Creator?.id) && (
                        <button
                          type="button"
                          className="btn"
                          style={{ color: '#FC2E53', padding: 4 }}
                          onClick={() => onDeleteDocument(document.orderDocId)}
                        >
                          <i
                            className="fa fa-minus-circle"
                            aria-hidden="true"
                          ></i>
                        </button>
                      )}
                      <a
                        download={document.orderDocFile}
                        href={document.fileSource}
                        className="badge text-black border max-w"
                      >
                        <i className="fas fa-file-pdf me-2"></i>
                        <div className="overflow-hidden">
                          {document.orderDocFile}
                        </div>
                      </a>
                    </div>
                  ))}
              </div>
              <div className="text-center mt-3">
                <button
                  type="button"
                  className="me-2 btn btn-primary btn-rounded"
                  onClick={onClickUpload}
                >
                  <span className="btn-icon-start text-primary">
                    <i className="fas fa-plus"></i>
                  </span>
                  Upload Documents
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadDocumentsCard
