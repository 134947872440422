import { useMutation, useQuery,useQueryClient } from "react-query";
import { useStateValue } from "../store/selectors/useStateValue";
import { config } from "../connector/config";
import http from "./http";

export const useHandleUploadProfileImage = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(async (file) => {
        const formData = new FormData();
        formData.append('userProfileImageFile', file);
        try {
            await http().request({
                url: `${config.baseURL}/users/upload-profile-image`,
                method: 'POST',
                headers: {
                    "x-access-token": auth.auth.accessToken,
                },
                data: formData,
            });
            queryClient.invalidateQueries(); 
        } catch (error) {
            throw new Error("Error uploading profile image");
        }
    });
}

export const useFetchProfile = () => {
    const { auth } = useStateValue();
    return useQuery('fetchProfile', async () => {
        const response = await http().request({
            url: `${config.baseURL}/user/profile`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        });
        return response.data; 
    });
}


export const useUpdateUserData = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({data}) => {
        return http().request({
            url: `${config.baseURL}/admin/users`,
            method: 'POST',
            data: data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        });
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('fetchUserProfile');
        }
    });
}

export const useGetUser = () => {
    const { auth } = useStateValue();
    return useQuery(['fetchUserProfile', auth.auth.accessToken],() => { 
        return  http().request({
            url: `${config.baseURL}/user/profile`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
        })
    })
};

export const useUpdateAppData = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({data}) => {
        return http().request({
            url: `${config.baseURL}/user/app-settings`,
            method: 'POST',
            data: data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        });
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('fetchUserAppSettings');
        }
    });
}

export const useGetAppSettings= () => {
    const { auth } = useStateValue();
    return useQuery(['fetchUserAppSettings', auth.auth.accessToken],() => { 
        return  http().request({
            url: `${config.baseURL}/user/app-settings`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
        })
    })
};

export const useUploadBillingAddress = () => {
    const { auth } = useStateValue();
    return useMutation(({data}) => {
        return http().request({
            url: `${config.baseURL}/business/billings/billing-address`,
            method: 'POST',
            data: data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        });
    });
}


export const useGetBillingAddress= () => {
    const { auth } = useStateValue();
    return useQuery(['fetchUserBillingAddress', auth.auth.accessToken],() => { 
        return  http().request({
            url: `${config.baseURL}/business/billings/billing-address`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
        })
    })
};

export const useConnectSellerAccount = () => {
    const { auth } = useStateValue();
    return useMutation(({params})=>{
        return http().request({
            url: `${config.baseURL}/get_token`,
            method: 'post',
            params: params,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
        })
    })
}


export const useDeleteSellerAccount = () => {
    const { auth } = useStateValue();

    return useMutation(({accountId})=>{
        return http().request({
            url: `${config.baseURL}/delete_seller_account/${accountId}`,
            method: 'delete',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
        })
    })
}