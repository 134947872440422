import React from "react";
import { Link } from "react-router-dom";

import logoBot from "../../../../images/app-logo-bot.png";
import logoText from "../../../../images/app-logo-text.png";

const Logo = ({
	botOnly,
}) => {
  return (
    <Link
      className="logo-link" 
      to="/dashboard"
    >
      <img src={logoBot} alt="Logo" />
			{!botOnly && (
				<img className="brand-title" src={logoText} alt="Logo" />
			)}
    </Link>
  );
}

export default Logo