import React from "react";

const InventorySumary = ({
  availableUnits,
  totalInbound,
  riskUnits,
  attentionUnits,
}) => {
  const inventorySummaryItems = [
    {
      id: "1",
      value: availableUnits,
      title: "Total",
      subTitle: "Available Units",
    },
    {
      id: "2",
      value: totalInbound,
      title: "Total",
      subTitle: "Inbound",
    },
    {
      id: "3",
      value: riskUnits,
      title: "Risk of going",
      subTitle: "Out of Stock",
    },
    {
      id: "4",
      value: attentionUnits,
      title: "Attention",
      subTitle: "Order Now",
    },
  ];

  return (
    <>
      {inventorySummaryItems.map((item) => {
        if (item.value > 0) {
          return (
            <div key={item.id} className="col-xl-3 col-sm-6">
              <div className="card">
                <div className="card-body d-flex px-4  justify-content-between">
                  <div>
                    <div className="">
                      <h2
                        className={`fs-32 font-w700 ${item.title === "Risk of going" && "text-danger"
                          } ${item.title === "Attention" && "text-warning"}`}
                      >
                        {item.value}
                      </h2>
                      <span className="fs-18 font-w500 d-block">
                        {item.title}
                      </span>
                      <span className="d-block fs-16 font-w400">
                        {item.subTitle}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default InventorySumary;
