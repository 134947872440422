import {
  SET_ORDERS_ACTION,
  SET_SELECTED_ORDER,
  SET_SELECTED_ORDER_ID,
} from "../actions/orderTypes";
import { ordersData } from "../../jsx/mocks/ordersMock";

const initialState = {
  ordersState: ordersData,
  selectedOrderId: localStorage.getItem(`selectedOrderId`),
  selectedOrder: null,
};

export default function OrdersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORDERS_ACTION:
      const updatedState = {
        ...state,
        ...state.ordersState,
        ordersState: action.orders,
      };
      return updatedState;

    case SET_SELECTED_ORDER_ID:
      localStorage.setItem(`selectedOrderId`,action.id)
      return {
        ...state,
        ...state.ordersState,
        selectedOrderId: action.id,
      };
    case SET_SELECTED_ORDER:
      return {
        ...state,
        ...state.ordersState,
        selectedOrder: action.selectedOrder,
      };
    default:
      return state;
  }
}
