import { useMutation, useQuery, useQueryClient } from "react-query";
import http from "./http";
import { config } from "../connector/config";
import { useStateValue } from "../store/selectors/useStateValue";




export const useUserCreate = () => {

  const { auth } = useStateValue();
  const queryClient = useQueryClient();
  return useMutation((data)=> {
    return http().request({
      url:`${config.baseURL}/user/create`,
      method: 'POST',
      data: data,
      headers: {
          "Content-Type": "application/json",
          "x-access-token": auth.auth.accessToken,
      }
    })
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries(`fetchTeamData`)
    }
  }
  );
}
export const GetUserTeamData = (currPage,perPage) => {
    const { auth } = useStateValue();
    return useQuery(['fetchTeamData', auth.auth.accessToken,currPage,perPage],() => { 
        return  http().request({
            url: `${config.baseURL}/user/list`,
            method: 'GET',
            params: {
                currPage,
                perPage,
              },
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
        })
    })
};
export const useDeleteUserTeam = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({userIdToDelete}) => {
        return  http().request({
            url:    `${config.baseURL}/user/${userIdToDelete}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
            
        })
    },
    {
         onSuccess: () => { 
             queryClient.invalidateQueries('fetchTeamData')
         } 
    }
    )
}


export const useUserModify = () => {
  const { auth } = useStateValue();
  const queryClient = useQueryClient();
  return useMutation(({userIdToModify,newRole })=> {
    return http().request({
      url:`${config.baseURL}/user/${userIdToModify}/role`,
      method: 'POST',
      data: {
        new_role: newRole // Include the new_role parameter
      },
      headers: {
          "Content-Type": "application/json",
          "x-access-token": auth.auth.accessToken,
      }
    })
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries(`fetchTeamData`)
    }
  }
  );
}