import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
import { QueryClientProvider, QueryClient } from 'react-query'

const  queryClient = new QueryClient()

const WrappedApp = () => {
    return(
       <QueryClientProvider client={queryClient}>
            <App/>
       </QueryClientProvider>
    )
} 

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <SimpleReactLightbox>
          <BrowserRouter basename="">
            <ThemeContext>
                <WrappedApp />
            </ThemeContext>
          </BrowserRouter>
        </SimpleReactLightbox>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
