import { useEffect, useState } from 'react';
import { useStateValue } from "../../../store/selectors/useStateValue";
import axios from 'axios';

function useFetch(url) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
  const { auth } = useStateValue();
	
	const accessToken = auth.auth.accessToken;

	useEffect(() => {
		setLoading(true);
		axios
			.get(url, {
				headers: {
					Accept: "application/json",
					"x-access-token": accessToken,
				},
			})
			.then((response) =>{
				setData(response.data);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [url]);
	
	return {data, loading, error};
}

export default useFetch