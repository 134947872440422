import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import AddWarehouseForm, {
  initialWarehouseFormState,
} from "../customForms/AddWarehouseForm";
import EditWarehouseForm from "../customForms/EditWarehouseForm";
import LoadingSpinner from "../elements/LoadingSpinner/LoadingSpinner";
import Paginator from "../elements/Paginator/Paginator";
import swal from "sweetalert";
import { setWarehousesAction } from "../../../store/actions/WarehouseActions";
import { useDispatch } from "react-redux";
import { useStateValue } from "../../../store/selectors/useStateValue";
import {
  dataRequest,
  addWarehouseRequest,
  deleteDataRequest,
  editDataRequest,
} from "../../../connector/httpConnector/httpRequest";
import { config } from "../../../connector/config";
import LinkWithIcon from "../elements/LinkWithIcon/LinkWithIcon";
import { constants } from "buffer";

const MyWarehouse = () => {
  const { baseURL, businessWarehousesList, busniessWarehouse } = config;
  const dispatch = useDispatch();
  const { warehouses, auth } = useStateValue();
  const [editModel, setEditModel] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [addFormData, setAddFormData] = useState(initialWarehouseFormState);
  const [editFormData, setEditFormData] = useState({});
  const [warehouseData, setWarehouseData] = useState(
    warehouses.warehousesState
  );
  const [integratedWarehousesData, setIntegratedWarehousesData] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get("page")) || 1
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState();
 
  const token = auth.auth.accessToken;

  const fetchWarehouses = useCallback(() => {
    
    dataRequest(baseURL, token, businessWarehousesList, "get", page)
      .then((data) => {
        setIntegratedWarehousesData(data.data);
        setWarehouseData(data.data.warehouses);
        dispatch(setWarehousesAction(data.warehouses));
        setLoading(false);
        const queryParams = new URLSearchParams({
          page: page,
        });
        window.history.pushState(null, null, `?${queryParams.toString()}`);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [baseURL, token, businessWarehousesList, page, dispatch]);

  useEffect(() => {
    setLoading(true);
    fetchWarehouses(page);
  }, [fetchWarehouses, page]);

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  // delete modal
  const handleDeleteModal = (id) => {
    const selectedWarehouse = warehouseData.find(
      (warehouse) => warehouse.id === id
    );
    setSelectedWarehouse(selectedWarehouse);
    setDeleteModal(true);
  };

  // delete data submit
  const handleDeleteSubmit = async () => {
    try {
      await deleteDataRequest(
        baseURL,
        token,
        busniessWarehouse,
        selectedWarehouse.id
      );
      swal("Good job!", "Successfully deleted", "success");
      setDeleteModal(false);
      fetchWarehouses();
    } catch (error) {
      swal("Oops", "Something went wrong", "error");
    }
  };

  //Add Submit data
  const handleAddFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    let error = false;
    let errorMsg = "";
    if (addFormData.warehouseName === "") {
      error = true;
      errorMsg = "Please fill Warehouse Name";
    } else if (addFormData.firstLine === "") {
      error = true;
      errorMsg = "Please fill first line.";
    } else if (addFormData.city === "") {
      error = true;
      errorMsg = "Please fill city";
    } else if (addFormData.county === "") {
      error = true;
      errorMsg = "Please fill county";
    } else if (addFormData.zipCode === "") {
      error = true;
      errorMsg = "Please fill zip code";
    } else if (addFormData.country === "") {
      error = true;
      errorMsg = "Please fill country";
    }
    if (!error) {
      const newWarehouse = {
        warehouseName: addFormData.warehouseName,
        addressLine1: addFormData.firstLine,
        cityName: addFormData.city,
        stateOrProvinceName: addFormData.county,
        zipCode: addFormData.zipCode,
        countryName: addFormData.country,
      };

      try {
        await addWarehouseRequest(
          baseURL,
          token,
          busniessWarehouse,
          "post",
          newWarehouse
        );
        swal("Good job!", "Successfully Added", "success");
        fetchWarehouses(page);
        setAddCard(false);
        const newWarehouses = [...warehouseData, newWarehouse];
        dispatch(setWarehousesAction(newWarehouses));
        setLoading(true);

        addFormData.name =
          addFormData.productSKU =
          addFormData.productThumb =
            "";
      } catch (error) {
        swal("Oops", "Something went wrong", "error");
      }
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  const handleChangePage = useCallback((page) => {
    setPage(page);
  }, []);

//edit
  const handleEditModal = (warehouse) => {
    setSelectedWarehouse(warehouse);
    setEditModel(true);
    setEditFormData(warehouse);
  };
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  
    setSelectedWarehouse((prevWarehouse) => ({
      ...prevWarehouse,
      [fieldName]: fieldValue,
    }));
  };
  useEffect(() => {
  }, [selectedWarehouse]);


    //Add edit data
    const handleEditFormSubmit = async (event) => {
      setLoading(true);
      event.preventDefault();
      let error = false;
      let errorMsg = "";
      if (editFormData.warehouseName === "") {
        error = true;
        errorMsg = "Please fill Warehouse Name";
      } else if (editFormData.addressLine1 === "") {
        error = true;
        errorMsg = "Please fill first line.";
      } else if (editFormData.cityName === "") {
        error = true;
        errorMsg = "Please fill city";
      } else if (editFormData.stateOrProvinceName === "") {
        error = true;
        errorMsg = "Please fill county";
      } else if (editFormData.zipCode === "") {
        error = true;
        errorMsg = "Please fill zip code";
      } else if (editFormData.countryName === "") {
        error = true;
        errorMsg = "Please fill country";
      }else if (editFormData.stateOrProvinceName === "") {
        error = true;
        errorMsg = "Please fill country";
      }
      if (!error) {
        const editWarehouse = {
          warehouseName: editFormData.warehouseName,
          addressLine1: editFormData.addressLine1,
          cityName: editFormData.cityName,
          stateOrProvinceName: editFormData.stateOrProvinceName,
          zipCode: editFormData.zipCode,
          countryName: editFormData.countryName,
        };
  
        try {
          await editDataRequest(
            baseURL,
            token,
            busniessWarehouse,
            "post",
            selectedWarehouse.id,
            editWarehouse,
          );
          swal("Good job!", "Successfully Added", "success");
          fetchWarehouses(page);
          setAddCard(false);
          const editWarehouses = [...warehouseData, editWarehouse];
          dispatch(setWarehousesAction(editWarehouses));
          setLoading(true);
  
          editFormData.name =
            editFormData.productSKU =
            editFormData.productThumb =
              "";
        } catch (error) {
          swal("Oops", "Something went wrong", "error");
        }
      } else {
        swal("Oops", errorMsg, "error");
      }
    };
  
 
  
  return (
    <>
    
<Modal
        className="modal fade"
        show={editModel}
        onHide={setEditModel}
        size="lg"
      >
        <div className="modal-header">
          <h4 className="modal-title fs-20">Edit a Warehouse</h4>
          <button
            type="button"
            className="btn-close"
            onClick={() => setEditModel(false)}
          ></button>
        </div>
        <form>
        <EditWarehouseForm
  warehouseName={selectedWarehouse?.warehouseName || ''}
  addressLine1={selectedWarehouse?.addressLine1 || ''}
  cityName={selectedWarehouse?.cityName || ''}
  stateOrProvinceName={selectedWarehouse?.stateOrProvinceName || ''}
  zipCode={selectedWarehouse?.zipCode || ''}
  countryName={selectedWarehouse?.countryName || ''}
  onWarehouseNameChange={(e) => handleEditFormChange(e, 'warehouseName')}
  onFirstLineChange={(e) => handleEditFormChange(e, 'addressLine1')}
  onCityChange={(e) => handleEditFormChange(e, 'cityName')}
  onCountyChange={(e) => handleEditFormChange(e, 'stateOrProvinceName')}
  onZipCodeChange={(e) => handleEditFormChange(e, 'zipCode')}
  onCountryChange={(e) => handleEditFormChange(e, 'countryName')}
  onClickSubmit={handleEditFormSubmit}
  onClickCancel={() => setEditModel(false)}
/>
        </form>
      </Modal>
    
      <Modal
        className="modal fade"
        show={addCard}
        onHide={setAddCard}
        size="lg"
      >
        <div className="modal-header">
          <h4 className="modal-title fs-20">Add a Warehouse</h4>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAddCard(false)}
          ></button>
        </div>
        <form>
          <AddWarehouseForm
            onWarehouseNameChange={handleAddFormChange}
            onFirstLineChange={handleAddFormChange}
            onCityChange={handleAddFormChange}
            onCountyChange={handleAddFormChange}
            onZipCodeChange={handleAddFormChange}
            onCountryChange={handleAddFormChange}
            onClickSubmit={handleAddFormSubmit}
            onClickCancel={() => setAddCard(false)}
          />
        </form>
      </Modal>
      <Modal className="modal fade" show={deleteModal} onHide={setDeleteModal}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">
            Are you sure you want to delete{" "}
            <b>{selectedWarehouse?.warehouseName}</b>?
          </h4>
          <button
            type="button"
            className="btn-close"
            onClick={() => setDeleteModal(false)}
            data-dismiss="modal"
          ></button>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={handleDeleteSubmit}
            className="btn btn-danger"
          >
            <i className="flaticon-delete-1"></i> Delete
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <div className="product-content">
        {loading ? (
          <LoadingSpinner size={150} />
        ) : (
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-3 col-xxl-4 col-lg-4 col-md-6 col-sm-6 items">
                  <div
                    onClick={() => setAddCard(true)}
                    className="card contact-bx item-content"
                  >
                    <div className="card-body user-profile add-item">
                      <div className="media-body user-meta-info add-item-card">
                        <i className="fas fa-solid fa-plus"></i>
                        <h4 className="fs-18 font-w600 my-1">
                          Add a Warehouse
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                {warehouseData?.map((warehouse) => (
                  <div
                    key={warehouse.id}
                    className="col-xl-3 col-xxl-4 col-lg-4 col-md-6 col-sm-6 items"
                  >
                    <div className="card contact-bx item-content">
                      <div className="card-body user-profile">
                        <div className="media-body user-meta-info item-card">
                          <h4 className="fs-18 text-primary font-w600 my-1">
                            {warehouse.warehouseName}
                          </h4>
                          <h6>{warehouse.addressLine1}</h6>
                          <h6>{warehouse.cityName}</h6>
                          <h6>{warehouse.stateOrProvinceName}</h6>
                          <h6>{warehouse.zipCode}</h6>
                          <h6>{warehouse.countryName}</h6>
                        </div>
                        <div className="card-footer text-center border-0 mt-0">
                          <div className="links-container">
                            <LinkWithIcon
                              path="#"
                              onClick={() => handleEditModal(warehouse)}
                              iconName="far fa-edit"
                            />
                            <LinkWithIcon
                              path="#"
                              onClick={() => handleDeleteModal(warehouse.id)}
                              iconName="fa-trash"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {integratedWarehousesData?.totaPages > 1 && (
          <Paginator
            total={integratedWarehousesData?.totaPages}
            current={page}
            onChangePage={handleChangePage}
          />
        )}
      </div>
    </>
  );
};

export default MyWarehouse;
