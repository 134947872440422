import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  loadingToggleAction,
  loginAction,
} from '../../store/actions/AuthActions'
import SignInForm from '../components/customForms/SignInForm'
import SignupOptions from '../components/molecules/SignupOptions/SignupOptions'
import { AUTH_ERROR_MEESAGE } from '../../connector/httpErrorResponse/httpErrorResponse'

// image
import logo from '../../images/app-logo.png'

function Login(props) {
  const [email, setEmail] = useState('')
  let errorsObj = { email: '', password: '' }
  const [errors, setErrors] = useState(errorsObj)
  const [password, setPassword] = useState('')
  const [isSignUp, setIsSignUp] = useState(false)
  const [rememberPreference, setRememberPreference] = useState(false)
  function onCheckboxChange(e) {
    setRememberPreference(e.target.checked)
  }

  const dispatch = useDispatch()
  function onLogin(e) {
    e.preventDefault()
    let error = false
    const errorObj = { ...errorsObj }

    setErrors(errorsObj)

    if (email === '') {
      errorObj.email = 'Email is Required'
      error = true
    }
    if (password === '') {
      errorObj.password = 'Password is Required'
      error = true
    }
    if (error) {
      setErrors(errorObj)
      return
    }

    dispatch(loadingToggleAction(true))
    dispatch(loginAction(email, password, props.history, rememberPreference))
  }

  useEffect(() => {
    // Reset email error message whenever props.errorMessage changes
    if (props.errorMessage === AUTH_ERROR_MEESAGE.NOT_VERIFIED) {
      setErrors((prevState) => ({
        ...prevState,
        email: 'Please verify email first!',
      }))
    } else if (props.errorMessage === AUTH_ERROR_MEESAGE.INVALID_EMAIL) {
      setErrors((prevState) => ({
        ...prevState,
        email: 'Invalid email address!',
      }))
    } else if (errors.email === 'Invalid email address!') {
      // Clear email error message if it was previously set
      setErrors((prevState) => ({
        ...prevState,
        email: '',
      }))
    }
  }, [errors.email, props.errorMessage])

  function onClickSignupLink(e) {
    e.preventDefault()
    setIsSignUp(true)
  }

  function showErrorMessage(message) {
    switch (message) {
      case AUTH_ERROR_MEESAGE.USER_NOT_FOUND: {
        return 'This user does not exist!'
      }
      case AUTH_ERROR_MEESAGE.INVALID_PASSWORD: {
        return 'Login failed! Please check your credentials and try again.'
      }
      default: {
        return ''
      }
    }
  }

  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="authincation_header">
        <div className="authincation_header_logo text-center mb-4 pt-1">
          <a href="https://www.bluudo.com">
            <img src={logo} alt="Logo" />
          </a>
        </div>
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <div id="sign-in" className="auth-form   form-validation">
                  {props.errorMessage && !isSignUp && (
                    <div className="text-danger fs-18 mb-3">
                      {showErrorMessage(props.errorMessage)}
                    </div>
                  )}
                  {/* {props.successMessage && (
                    <div className="text-success fs-18 mb-3">
                      {props.successMessage}
                    </div>
                  )} */}
                  {!isSignUp ? (
                    <>
                      <h3 className="text-center mb-4 text-black">
                        Welcome back!
                      </h3>
                      <form onSubmit={onLogin} className="form-validate">
                        <SignInForm
                          email={email}
                          password={password}
                          onChangeEmail={(e) => setEmail(e.target.value)}
                          onChangePassword={(e) => setPassword(e.target.value)}
                          errors={errors}
                          rememberPreference={rememberPreference} // Pass down rememberPreference
                          onCheckboxChange={onCheckboxChange} // Pass down onCheckboxChange
                        />
                      </form>
                      <div className="new-account mt-3">
                        <p>
                          Don't have an account?{' '}
                          <Link
                            className="text-primary"
                            to={'#'}
                            onClick={onClickSignupLink}
                          >
                            Sign up
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="text-primary"
                            to="/page-forgot-password"
                          >
                            Forgot password?
                          </Link>
                        </p>
                      </div>
                    </>
                  ) : (
                    <SignupOptions onBackToLogin={() => setIsSignUp(false)} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
    userType: state.auth.userType,
  }
}
export default connect(mapStateToProps)(Login)
