import React, { useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Typeahead } from 'react-bootstrap-typeahead'

import { useStateValue } from '../../../store/selectors/useStateValue'
import { config } from '../../../connector/config'
import {
  searchProductRequest,
  productDetailsRequest,
  dataRequest,
} from '../../../connector/httpConnector/httpRequest'
import LoadingSpinner from '../elements/LoadingSpinner/LoadingSpinner'
import { useDispatch } from 'react-redux'
import { setWarehousesAction } from '../../../store/actions/WarehouseActions'

const AddOrderForm = ({
  onClickNewProduct,
  onSelectProduct,
  onChangeOrderUnits,
  onChangeShippingMode,
  onChangeDestination,
  onChangeAMZFBA,
  onChangeMyWarehouse,
  onChangeSupplier,
  onSubmitForm,
  onCloaseModal,
  addNewProductFeature = true,
}) => {
  const dispatch = useDispatch()
  const { auth, warehouses } = useStateValue()
  const { baseURL, businessGetProducts, businessWarehousesList } = config
  const [myWarehouseOption, setMyWarehouseOption] = useState('Amazon FBA')

  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [assignedSuppliers, setAssignedSuppliers] = useState([])
  const [defaultSupplierId, setDefaultSupplierId] = useState('')
  const [warehouseData, setWarehouseData] = useState(warehouses.warehousesState)
  const [loading, setLoading] = useState(false)

  const token = auth.auth.accessToken

  const handleSearch = (query) => {
    setSearchTerm(query)
    if (query) {
      searchProducts(query)
    } else {
      setSearchResults([])
    }
  }

  const fetchWarehouses = useCallback(() => {
    dataRequest(baseURL, token, businessWarehousesList)
      .then((data) => {
        setWarehouseData(data.data.warehouses)
        dispatch(setWarehousesAction(data.data.warehouses))
      })
      .catch((error) => {
        setLoading(false)
      })
  }, [baseURL, token, businessWarehousesList, dispatch])

  useEffect(() => {
    fetchWarehouses()
  }, [fetchWarehouses])

  const searchProducts = useCallback(
    (query) => {
      searchProductRequest(
        baseURL,
        token,
        businessGetProducts,
        'get',
        1,
        10,
        query
      )
        .then((data) => {
          setSearchResults(data.products)
        })
        .catch((error) => {})
    },
    [baseURL, token, businessGetProducts]
  )

  const renderMenuItem = (option, props, index) => (
    <Typeahead.Menu.Item
      option={option}
      position={index}
      onClick={props.onClick}
    >
      <div>{option.productName}</div>
    </Typeahead.Menu.Item>
  )

  const customFilterBy = (option, text) => {
    const lowerCaseText = typeof text === 'string' ? text.toLowerCase() : ''
    return (
      option.productName.toLowerCase().includes(lowerCaseText) ||
      option.productSKU.toLowerCase().includes(lowerCaseText) ||
      option.productASIN.toLowerCase().includes(lowerCaseText)
    )
  }

  const handleSelect = async (selected) => {
    const selectedProductId = selected[0]?.id

    if (selected && selected.length > 0) {
      setLoading(true)
      onSelectProduct(selectedProductId)
      try {
        const response = await productDetailsRequest(
          baseURL,
          token,
          businessGetProducts,
          selectedProductId,
          'get'
        )
        setAssignedSuppliers(response.Suppliers)
        if (response.Suppliers.length > 0) {
          setDefaultSupplierId(response.Suppliers[0].id)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
  }

  const emptyLabel = searchTerm
    ? 'No matches found'
    : 'Start typing to search product'

  return (
    <form>
      <div className="modal-body">
        <i className="flaticon-cancel-12 close"></i>
        <div className="add-contact-box">
          <div className="add-contact-content">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group mb-3">
                  <div className="product-label-holder">
                    <label className="text-black font-w500">
                      Search product
                    </label>
                    {addNewProductFeature && (
                      <Link to={'#'} onClick={onClickNewProduct}>
                        Add New Product
                      </Link>
                    )}
                  </div>
                  <Typeahead
                    id="search-typahead"
                    labelKey="productName"
                    onInputChange={handleSearch}
                    options={searchResults}
                    placeholder="Search product..."
                    emptyLabel={emptyLabel}
                    filterBy={customFilterBy}
                    renderMenuItem={renderMenuItem}
                    onChange={handleSelect}
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">
                    Number of Units
                  </label>
                  <div className="contact-name">
                    <input
                      type="number"
                      className="form-control"
                      autoComplete="off"
                      name="orderUnits"
                      required="required"
                      onChange={onChangeOrderUnits}
                      placeholder="units"
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="product-label-holder">
                    <label className="text-black font-w500">
                      Shipping Mode
                    </label>
                  </div>
                  <select
                    defaultValue={'option'}
                    className="form-control form-control-md"
                    name="shippingMode"
                    onChange={onChangeShippingMode}
                  >
                    <option value="select">Select Shipping Mode</option>
                    <option>air</option>
                    <option>sea</option>
                    <option>land</option>
                  </select>
                </div>
                <div className="form-group mb-3">
                  <div className="product-label-holder">
                    <label className="text-black font-w500">
                      Destination option
                    </label>
                  </div>
                  <div className="col-sm-9 radio-holder">
                    <div className="form-check radio_select_box">
                      <input
                        id="amzfba"
                        onClick={() => setMyWarehouseOption('Amazon FBA')}
                        className="form-check-input"
                        type="radio"
                        name="destinationType"
                        value="Amazon FBA"
                        onChange={onChangeAMZFBA}
                        defaultChecked
                      />
                      <label className="form-check-label" htmlFor="amzfba">
                        Amazon FBA
                      </label>
                    </div>
                    <div className="form-check radio_select_box">
                      <input
                        id="mywarehouse"
                        onClick={() => setMyWarehouseOption('My Warehouse')}
                        className="form-check-input"
                        type="radio"
                        name="destinationType"
                        value="My Warehouse"
                        onChange={onChangeMyWarehouse}
                      />
                      <label className="form-check-label" htmlFor="mywarehouse">
                        My Warehouse
                      </label>
                    </div>
                  </div>
                </div>
                {myWarehouseOption === 'My Warehouse' && (
                  <div className="form-group mb-3">
                    <div className="product-label-holder">
                      <label className="text-black font-w500">
                        Destination
                      </label>
                    </div>
                    <select
                      defaultValue={'option'}
                      className="form-control form-control-md"
                      name="destination"
                      onChange={onChangeDestination}
                    >
                      <option value="select">Select a destination</option>
                      {warehouseData?.map((warehouse) => (
                        <option key={warehouse.id} data-id={warehouse.id}>
                          {warehouse.warehouseName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              {loading ? (
                <div className="col-md-4">
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  {assignedSuppliers.length > 0 && (
                    <div className="col-md-4">
                      <div className="form-group mb-3">
                        <div className="product-label-holder">
                          <label className="text-black font-w500">
                            Choose Supplier
                          </label>
                        </div>
                        <div className="col-sm-9">
                          {assignedSuppliers.map((supplier) => {
                            return (
                              <div className="form-check radio_select_box" key={supplier.id}>
                                <input
                                  id={supplier.id}
                                  className="form-check-input"
                                  type="radio"
                                  name="supplierId"
                                  value={supplier.id}
                                  onChange={onChangeSupplier}
                                  defaultChecked={
                                    supplier.id === defaultSupplierId
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={supplier.id}
                                >
                                  {supplier.name}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={(event) => onSubmitForm(event, defaultSupplierId)}
        >
          Add
        </button>
        <button
          type="button"
          onClick={onCloaseModal}
          className="btn btn-danger"
        >
          {' '}
          <i className="flaticon-delete-1"></i> Discard
        </button>
      </div>
    </form>
  )
}

export default AddOrderForm
