import { useMutation, useQuery,useQueryClient } from "react-query";
import { useStateValue } from "../store/selectors/useStateValue";
import { config } from "../connector/config";
import http from "./http";


export const useNotificationData = () => {
    const { auth } = useStateValue();
    return useMutation(({data}) => {
        return http().request({
            url: `${config.baseURL}/business/notification-settings`,
            method: 'POST',
            data: data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        });
    });
}

export const useFetchNotificationData  = () => {
    const { auth } = useStateValue();
    return useQuery('notification-settings', ()=> {
       return http().request({
           url: `${config.baseURL}/business/notification-settings`,
           method: 'GET',
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    })
}


export const useFetchHeaderNotificationData  = (currPage,perPage) => {
    const { auth } = useStateValue();
    return useQuery(['fetchHeaderNotificationData', auth.auth.accessToken,perPage,currPage], ()=> {
       return http().request({
           url: `${config.baseURL}/notifications`,
           method: 'GET',
           params: {
            currPage,
            perPage,
          },
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    })
}

export const useHandleNotificationSeen = () => {
    const { auth }    = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(()=>{
         return http().request({
            url:   `${config.baseURL}/notifications/seen`,
            method: 'POST',
            headers: {
                "Content-Type":  "application/json",
                "x-access-token": auth.auth.accessToken,
            }
         })
    },{
          onSuccess: () =>{
              queryClient.invalidateQueries(['fetchHeaderNotificationData'])
          }
    })
}
