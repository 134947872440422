import React from "react";
import { useState ,useEffect} from "react";
import { Form } from "react-bootstrap";
import Select from 'react-select'

const AssignSupplierForm = ({ 
      supplierOptions,
      setNewSupplierEmail,
      newSupplierEmail,
      selectSupplierEmail,
      setSelectSupplierEmail,
      supplierType,
      setSupplierType,
      searchSupplier,
      setSearchSupplier

}) => {

    const [options,setOptions] = useState([])
    useEffect(()=>{
          let newOptions = [...supplierOptions];
          newOptions = newOptions.map((op)=> ({ value: op.id, label: op.name || op.email}))
          setOptions(newOptions);
    },[supplierOptions]);

    return(
       <Form>
          <div className="modal-body">
              <div className="form-group mb-3">
                <div className="text-black font-w500 fs-4">
                  <input
                      type="radio"
                      value="new"
                      name="supplierType" 
                      checked={supplierType === "new"}
                      onChange={() => setSupplierType("new")}
                  />{" "}New Supplier
                  <input
                      type="radio"
                      value="existing"
                      name="supplierType"
                      checked={supplierType === "existing"}
                      onChange={() => setSupplierType("existing")}
                      style={{ marginLeft: '20px', marginBottom:'20px' }}
                    />{" "}Existing Supplier
                </div>
            </div>
            {supplierType === "new" &&
            <div className="form-group mb-3">
                <div className="">
                  <label className="text-black font-w500">Supplier Email</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      name="SupplierEmail"
                      placeholder="Supplier Email"
                      value={newSupplierEmail}
                      onChange={(e) => setNewSupplierEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
             </div>}
            {supplierType === "existing" &&
            <div className="form-group mb-3">
                <div className="">
                  <label className="text-black font-w500">Supplier Email</label>
                  <div className="contact-name">
                      <Select 
                         options={options}
                         value={selectSupplierEmail}
                         onChange={(newValue)=> setSelectSupplierEmail(newValue)}
                         inputValue={searchSupplier}
                         onInputChange={(newValue)=> setSearchSupplier(newValue)}
                     />
                  </div>
                </div>
             </div>}
          </div>
       </Form>
    )
};

export default AssignSupplierForm;
