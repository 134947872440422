import React from "react";
import { indexOf } from 'lodash';
import amazonLogo from "./../../../../images/freight/amazon.png";

const AMZReceivingCard = ({ destinationLogo, shipmentDetails }) => {
  return (
    <>
      <div className="d-flex align-items-center project-image mb-3">
        <img src={amazonLogo} alt="" />
        <div>
          <small className="d-block fs-16 font-w700">Shipping ID</small>
          <span className="d-block fs-12 font-w500">
            {shipmentDetails?.refId}
          </span>
        </div>
      </div>
      <span className="d-block fs-16 text-black font-w600">{shipmentDetails?.shipmentStatus}</span>
      <div className="progress mb-2">
        <div
          className="progress-bar progress-animated"
          style={{ width: `${(100 * (1 + indexOf(['WORKING', 'SHIPPED', 'CHECKED_IN', 'RECEIVING', 'CLOSED'], shipmentDetails?.shipmentStatus)) / 5)}%` }}
        ></div>
      </div>
      <span className="d-block fs-12 font-w500">
        {shipmentDetails?.fulfillmentCenterId}
        {shipmentDetails?.destinationAddressLineOne ? ', ' + shipmentDetails?.destinationAddressLineOne : ''}
        {shipmentDetails?.destinationCity ? ', ' + shipmentDetails?.destinationCity : ''}
        {shipmentDetails?.destinationStateOrProvinceCode ? ', ' + shipmentDetails?.destinationStateOrProvinceCode : ''}
        {shipmentDetails?.destinationPostalCode ? ', ' + shipmentDetails?.destinationPostalCode : ''}
        {shipmentDetails?.destinationCountryCode ? ', ' + shipmentDetails?.destinationCountryCode : ''}
      </span>
    </>
  );
};

export default AMZReceivingCard;
