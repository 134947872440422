import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingSpinner = ({ size }) => {
  return (
    <div className="loading-spinner">
      <Spinner
        style={{ width: `${size}px`, height: `${size}px` }}
        animation="border"
        variant="primary"
      />
    </div>
  );
};

export default LoadingSpinner;