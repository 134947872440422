import React, { useState, useCallback, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Nav, Tab, Modal, Dropdown } from 'react-bootstrap'
import swal from 'sweetalert'
import { nanoid } from 'nanoid'
import { sumBy } from 'lodash'
import { useStateValue } from '../../../store/selectors/useStateValue'
///Import
import OrderCard from '../molecules/OrderCard/OrderCard'
import AddOrderForm from '../customForms/AddOrderForm'
import Paginator from '../elements/Paginator/Paginator'
import LoadingSpinner from '../elements/LoadingSpinner/LoadingSpinner'
import { useGetAppSettings } from '../../../data/account-data'
import AddProductForm, { initialFormState } from '../customForms/AddProductForm'
import { setProductsAction } from '../../../store/actions/ProductActions'
import {
  setOrdersAction,
  setSelectedOrderIdAction,
} from '../../../store/actions/orderActions'
import { useUploadInvoice } from '../../../data/order-details'
import { useDispatch } from 'react-redux'
import UploadInvoiceForm from './OrderDetail/UploadInvoiceForm/UploadInvoiceForm'
import { initialInvoiceFormState } from '../../components/Dashboard/OrderDetail/initialState'
import { useFetchOrders, useSaveNewOrder } from '../../../data/orders-data'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import supplierDefult from '../../../images/avatar/supplierDefault.png'
const Orders = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { auth, products, orders } = useStateValue()
  const [addCard, setAddCard] = useState(false)
  const [productsData, setProducts] = useState(products.productsState)
  const [ordersDetails, setOrderDetails] = useState([])
  const [selectedOrderProductID, setSelectedOrderProductID] = useState('')
  const [addNewProductForm, setAddNewProductForm] = useState(false)
  const [invoiceModal, setInvoiceModal] = useState(false)
  const [addInvoiceFormData, setAddInvoiceFormData] = useState(
    initialInvoiceFormState
  )
  const [integratedOrdersData, setIntegratedOrdersData] = useState()
  const [selectedDestinationID, setSelectedDestinationID] = useState()

  const [page, setPage] = useState(
    new URLSearchParams(window.location.search).get('page') || 1
  )
  const [tabStatus, setTabStatus] = useState(
    new URLSearchParams(window.location.search).get('tabStatus') || `ALL`
  )
  const [orderBy, setOrderBy] = useState(
    new URLSearchParams(window.location.search).get('orderBy') || `latest`
  )
  const [nextUpStatus, setNextUpStatus] = useState(
    new URLSearchParams(window.location.search).get('nextUpStatus') || `ALL`
  )

  const MAX_CHAR_LENGTH = 30

  const [invoiceDetails, setInvoiceDetails] = useState([])

  const isBusiness = auth.auth.role[0] === 'business'
  const [searchQuery, setSearchQuery] = useState()

  const fetchAppSettings = useGetAppSettings()
  const uploadInvoiceData = useUploadInvoice()
  const fetchOrdersData = useFetchOrders({
    currPage: searchQuery ? 1 : page,
    perPage: 12,
    orderBy: orderBy?.toString(),
    orderStatus:
      tabStatus?.toString()?.toUpperCase() !== 'ALL'
        ? tabStatus?.toString()?.toUpperCase()
        : undefined,
    orderNextUpStatus: nextUpStatus !== 'ALL' ? nextUpStatus : undefined,
    ...(searchQuery ? { search: searchQuery } : {}),
  })
  const handleSaveNewOrder = useSaveNewOrder()
  const [currencyFormat, setCurrencyFormat] = useState(
    Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    })
  )
  const [userDateFormat, setUserDateFormat] = useState('mmm dd, yyyy')

  useEffect(() => {
    if (!fetchAppSettings.isLoading) {
      if (fetchAppSettings.isSuccess) {
        setCurrencyFormat(
          Intl.NumberFormat(undefined, {
            style: 'currency',
            currency:
              fetchAppSettings?.data?.data?.data?.[0]?.currency || 'USD',
            currencyDisplay: 'narrowSymbol',
          })
        )
        setUserDateFormat(fetchAppSettings?.data?.data?.data?.[0]?.dateformat)
      }
    }
  }, [
    fetchAppSettings.data,
    fetchAppSettings.isLoading,
    fetchAppSettings.isSuccess,
  ])

  useEffect(() => {
    if (fetchOrdersData.isSuccess) {
      const allOrders = isBusiness
        ? fetchOrdersData.data.data.data.orders
        : fetchOrdersData.data.data.orders
      const intigOrders = isBusiness
        ? fetchOrdersData.data.data.data
        : fetchOrdersData.data.data
      setIntegratedOrdersData(intigOrders)
      setOrderDetails(allOrders)
      dispatch(setOrdersAction(allOrders))
    } else {
      setIntegratedOrdersData({})
      setOrderDetails([])
      dispatch(setOrdersAction([]))
    }
  }, [fetchOrdersData.data])

  const handleTabChange = (tabStatusValue) => {
    setTabStatus(tabStatusValue)
    setNextUpStatus('ALL')
    setPage(1)
    const params = new URLSearchParams(window.location.search)
    params.set('tabStatus', tabStatusValue)
    params.set('nextUpStatus', 'ALL')
    params.set('page', 1)
    window.history.pushState(
      {},
      '',
      `${location.pathname}?${params.toString()}`
    )
  }
  const handleOrderBy = (sortOrder) => {
    setOrderBy(sortOrder)
    setPage(1)
    const params = new URLSearchParams(window.location.search)
    params.set('orderBy', sortOrder)
    params.set('page', 1)
    window.history.pushState(
      {},
      '',
      `${location.pathname}?${params.toString()}`
    )
  }

  const handleNextUpStatus = (nextUpStatus) => {
    setNextUpStatus(nextUpStatus)
    setPage(1)
    const params = new URLSearchParams(window.location.search)
    params.set('nextUpStatus', nextUpStatus)
    params.set('page', 1)
    window.history.pushState(
      {},
      '',
      `${location.pathname}?${params.toString()}`
    )
  }
  //For Image upload in ListBlog
  const [file, setFile] = useState(null)
  const fileHandler = (e) => {
    setFile(e.target.files[0])
    setTimeout(function () {
      let src = document.getElementById('saveImageFile').getAttribute('src')
      addProductFormData.productThumb = src
    }, 200)
  }

  //Add data
  const [addProductFormData, setAddProductFormData] = useState(initialFormState)

  // Add Product function
  const handleAddProductFormChange = (event) => {
    event.preventDefault()
    const fieldName = event.target.getAttribute('name')
    const fieldValue = event.target.value
    const newFormData = { ...addProductFormData }
    newFormData[fieldName] = fieldValue
    setAddProductFormData(newFormData)
  }

  //Add Product Submit data
  const handleAddProductFormSubmit = (event) => {
    event.preventDefault()
    let error = false
    let errorMsg = ''
    if (addProductFormData.productName === '') {
      error = true
      errorMsg = 'Please fill Name'
    } else if (addProductFormData.productSKU === '') {
      error = true
      errorMsg = 'Please fill profile.'
    } else if (addProductFormData.productThumb === '') {
      error = true
      errorMsg = 'Please attached image'
    }
    if (!error) {
      const newProduct = {
        id: nanoid(),
        productName: addProductFormData.productName,
        productSKU: addProductFormData.productSKU,
        productThumb: addProductFormData.productThumb,
        productASIN: addProductFormData.productASIN,
        productWidth: addProductFormData.productWidth,
        productHeight: addProductFormData.productHeight,
        productLength: addProductFormData.productLength,
        productWeight: addProductFormData.productWeight,
        productSize: addProductFormData.productSize,
        productManufacturingCost: addProductFormData.productManufacturingCost,
        shippingModeAirCost: addProductFormData.shippingModeAirCost,
        shippingModeSeaCost: addProductFormData.shippingModeSeaCost,
        shippingModeLandCost: addProductFormData.shippingModeLandCost,
        productManufacturingDays: addProductFormData.productManufacturingDays,
        fastestShippingDays: addProductFormData.fastestShippingDays,
        shippingHandlingDays: addProductFormData.shippingHandlingDays,
        myWarehouseStock: addProductFormData.myWarehouseStock,
        amazonIntegrated: false,
      }
      const newProducts = [...productsData, newProduct]
      setProducts(newProducts)
      dispatch(setProductsAction(newProducts))
      setAddNewProductForm(false)
      swal('Good job!', 'Successfully Added', 'success')
      addProductFormData.name =
        addProductFormData.productSKU =
        addProductFormData.productThumb =
          ''
    } else {
      swal('Oops', errorMsg, 'error')
    }
  }

  //Add Order
  const [addFormData, setAddFormData] = useState({
    id: '',
    productInfo: {},
    orderUnits: '',
    orderDate: '',
    shippingMode: '',
    destinationType: 'Amazon FBA',
    destination: '',
    destinationAddress: {},
    supplierId: '',
  })

  // Add Order change
  const handleAddFormChange = (event) => {
    event.stopPropagation()
    const fieldName = event.target.getAttribute('name')
    const fieldValue = event.target.value
    const newFormData = { ...addFormData }
    newFormData[fieldName] = fieldValue
    if (fieldName === 'destination') {
      const warehouseID =
        event.target.options[event.target.selectedIndex].getAttribute('data-id')
      setSelectedDestinationID(warehouseID)
    }
    setAddFormData(newFormData)
  }
  const handleSearchSelect = (productId) => {
    setSelectedOrderProductID(productId)
  }

  //Add Submit data
  const handleAddFormSubmit = async (event, defaultSupplierId) => {
    const selectedSupplierId =
      addFormData.supplierId === '' ? defaultSupplierId : addFormData.supplierId
    event.preventDefault()
    let error = false
    let errorMsg = ''
    if (selectedOrderProductID === '') {
      error = true
      errorMsg = 'Please select a Product'
    } else if (addFormData.orderUnits === '') {
      error = true
      errorMsg = 'Please fill number of units.'
    } else if (addFormData.shippingMode === '') {
      error = true
      errorMsg = 'Please select shipping mode'
    } else if (
      addFormData.destinationType === 'My Warehouse' &&
      addFormData.destination === ''
    ) {
      error = true
      errorMsg = 'Please select destination'
    }
    if (!error) {
      const newOrder = {
        productId: selectedOrderProductID,
        orderUnits: addFormData.orderUnits,
        shippingMode: addFormData.shippingMode,
        destinationType: addFormData.destinationType,
        supplierId: selectedSupplierId,
      }

      if (newOrder.supplierId === '') {
        delete newOrder.supplierId
      }
      if (addFormData.destinationType === 'My Warehouse') {
        newOrder.warehouseId = selectedDestinationID
      }

      handleSaveNewOrder.mutate({ ...newOrder })

      setNextUpStatus('ALL')
      setTabStatus('ALL')
      setOrderBy('latest')
      setPage(1)
      const params = new URLSearchParams(window.location.search)
      params.set('nextUpStatus', 'ALL')
      params.set('tabStatus', 'ALL')
      params.set('page', 1)
      params.set('orderBy', 'latest')
      window.history.pushState(
        {},
        '',
        `${location.pathname}?${params.toString()}`
      )

      setAddCard(false)

      addFormData.productName =
        addFormData.productSKU =
        addFormData.productThumb =
          ''
    } else {
      swal('Oops', errorMsg, 'error')
    }
    addFormData.destinationType = 'Amazon FBA'
  }

  useEffect(() => {
    if (handleSaveNewOrder.isSuccess) {
      swal('Good job!', 'Successfully Added', 'success')
    }
    if (handleSaveNewOrder.isError) {
      swal('Oops', 'Something went wrong', 'error')
    }
  }, [handleSaveNewOrder.data])

  const navigateToOrderDetails = (event, id) => {
    event.preventDefault()
    history.push({
      pathname: `/order-detail/${id}`,
      state: { pageNumber: page },
    })
    dispatch(setSelectedOrderIdAction(id))
  }

  const handleUploadInvoiceClick = (id) => {
    setInvoiceModal(true)
    dispatch(setSelectedOrderIdAction(id))
  }

  // Invoice file change
  const handleInvoiceFileChange = (e) => {
    setFile(e.target.files[0])
    setTimeout(() => {
      let src = URL.createObjectURL(e.target.files[0])
      addInvoiceFormData.invoiceSRC = src
    }, 200)
  }

  const newOrders = [...ordersDetails]
  const selectedOrder = newOrders.find(
    (order) => order.id === orders.selectedOrderId
  )

  // Add invoice form change
  const handleInvoiceFormChange = (event) => {
    event.preventDefault()
    const fieldName = event.target.getAttribute('name')
    const fieldValue = event.target.value
    const newFormData = { ...addInvoiceFormData }
    newFormData[fieldName] = fieldValue
    setAddInvoiceFormData(newFormData)
  }

  //Add Invoice Submit data
  const handleAddInvoiceFormSubmit = async (event) => {
    event.preventDefault()
    let error = false
    let errorMsg = ''
    if (addInvoiceFormData.totalCost === '') {
      error = true
      errorMsg = 'Please fill total cost'
    } else if (addInvoiceFormData.productSKU === '') {
      error = true
      errorMsg = 'Please fill product sku.'
    } else if (addInvoiceFormData.invoiceSRC === '') {
      error = true
      errorMsg = 'Please upload invoice'
    }
    try {
      if (!error) {
        const formData = new FormData()
        formData.append('invoiceNumber', addInvoiceFormData.invoiceNumber)
        formData.append('totalCost', addInvoiceFormData.totalCost)
        formData.append('shippingCost', addInvoiceFormData.shippingCost)
        formData.append('invoiceFile', file)
        formData.append('invoiceDate', new Date())
        await uploadInvoiceData.mutateAsync({
          orderId: selectedOrder.id,
          newInvoice: formData,
        })

        setInvoiceModal(false)
        swal('Good job!', 'Successfully Added', 'success')
        const newInvoice = {
          id: nanoid(),
          totalCost: addInvoiceFormData.totalCost,
          invoiceNumber: addInvoiceFormData.invoiceNumber,
          invoice: file,
          invoiceSRC: addInvoiceFormData.invoiceSRC,
          createdDate: new Date(),
        }

        addInvoiceFormData.totalCost =
          addInvoiceFormData.invoiceNumber =
          addInvoiceFormData.invoiceSRC =
            ''
        const newInvoiceData = invoiceDetails.length
          ? (invoiceDetails[0] = newInvoice)
          : [newInvoice]
        setInvoiceDetails(newInvoiceData)
        selectedOrder.invoiceInfo = newInvoice
        const newOrders = [...ordersDetails]
        let itemIndex = newOrders.findIndex(
          (item) => item.id === selectedOrder.id
        )
        newOrders[itemIndex] = selectedOrder
        dispatch(setOrdersAction(newOrders))
      } else {
        swal('Oops', errorMsg, 'error')
      }
    } catch (error) {
      swal('Oops', error?.response?.data?.[0]?.message, 'error')
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case 'PENDING': {
        return 'btn-danger'
      }
      case 'IN_PRODUCTION': {
        return 'btn-warning'
      }
      case 'SHIPPED': {
        return 'btn-success'
      }
      case 'CLOSED': {
        return 'btn-success'
      }
      default: {
        return
      }
    }
  }

  const handleChangePage = useCallback(
    (page) => {
      setPage(page)
      const params = new URLSearchParams(window.location.search)
      params.set('page', page)
      window.history.pushState(
        {},
        '',
        `${location.pathname}?${params.toString()}`
      )
    },
    [location.pathname]
  )

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const pageParam = params.get('page')
    setPage(pageParam ? parseInt(pageParam) : 1)
  }, [])

  useEffect(() => {
    const handlePopState = () => {
      const params = new URLSearchParams(window.location.search)
      const pageParam = params.get('page')
      setPage(pageParam ? parseInt(pageParam) : 1)
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  return (
    <>
      <Tab.Container defaultActiveKey="ALL" activeKey={tabStatus}>
        <div className="project-page d-flex justify-content-between align-items-center flex-wrap">
          <div className="input-group search po-search contacts-search mb-1">
            <input
              type="text"
              className="form-control"
              placeholder="Search here..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <span className="input-group-text">
              <Link to={'#'}>
                <i className="flaticon-381-search-2"></i>
              </Link>
            </span>
          </div>
          <div className="project mb-1">
            <Nav as="ul" className="nav nav-tabs" role="tablist">
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  className="nav-link"
                  eventKey={`ALL`}
                  onClick={() => handleTabChange(`ALL`)}
                >
                  All Status
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  className="nav-link"
                  eventKey="PENDING"
                  onClick={() => handleTabChange(`PENDING`)}
                >
                  Pending
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  className="nav-link"
                  eventKey="IN_PRODUCTION"
                  onClick={() => handleTabChange(`IN_PRODUCTION`)}
                >
                  Production
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  className="nav-link"
                  eventKey="SHIPPED"
                  onClick={() => handleTabChange(`SHIPPED`)}
                >
                  Shipped
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  className="nav-link"
                  eventKey="CLOSED"
                  onClick={() => handleTabChange(`CLOSED`)}
                >
                  Closed
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="mb-1">
            {auth.userType === 'business' && (
              <>
                <Link
                  to={'#'}
                  className="btn btn-primary btn-rounded fs-18"
                  onClick={() => setAddCard(true)}
                >
                  + New Order
                </Link>
                <Link
                  to={'#'}
                  className="d-block fs-14 user-work text-primary text-center"
                >
                  How it works? <i className="fas fa-play-circle"></i>
                </Link>
                <Modal
                  className="modal fade"
                  show={addCard}
                  onHide={setAddCard}
                  size="lg"
                >
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Create New Order</h4>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setAddCard(false)}
                    ></button>
                  </div>
                  {addNewProductForm ? (
                    <AddProductForm
                      onChangeFile={fileHandler}
                      onClickFile={(event) => setFile(event.target.value)}
                      file={file}
                      onProductNameChange={handleAddProductFormChange}
                      onProductASINChange={handleAddProductFormChange}
                      onClickSubmit={handleAddProductFormSubmit}
                      onClickCancel={() => setAddNewProductForm(false)}
                    />
                  ) : (
                    <AddOrderForm
                      productOptions={productsData}
                      onClickNewProduct={() => setAddNewProductForm(true)}
                      onChangeProduct={handleAddFormChange}
                      onSelectProduct={handleSearchSelect}
                      onChangeOrderUnits={handleAddFormChange}
                      onChangeShippingMode={handleAddFormChange}
                      onChangeDestination={handleAddFormChange}
                      onChangeAMZFBA={handleAddFormChange}
                      onChangeMyWarehouse={handleAddFormChange}
                      onChangeSupplier={handleAddFormChange}
                      onSubmitForm={handleAddFormSubmit}
                      onCloaseModal={() => setAddCard(false)}
                    />
                  )}
                </Modal>
              </>
            )}
          </div>
          <Modal className="modal fade" show={invoiceModal}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Upload Invoice</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setInvoiceModal(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <UploadInvoiceForm
              onClickCancel={() => setInvoiceModal(false)}
              onChangeFile={handleInvoiceFileChange}
              onTotalCostChange={handleInvoiceFormChange}
              onShippingCostChange={handleInvoiceFormChange}
              onInvoiceNumberChange={handleInvoiceFormChange}
              onProductSKUChange={handleInvoiceFormChange}
              onClickSubmit={handleAddInvoiceFormSubmit}
            />
          </Modal>
        </div>
        <div className="d-flex justify-content-end align-items-center flex-wrap">
          <div>
            <Dropdown>
              <Dropdown.Toggle variant="" className="ps-0 mt-1 mb-2 pt-1 pb-1">
                <i className="fas fa-sort-amount-up me-2"></i>Sort by
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  active={orderBy === 'oldest'}
                  onClick={() => handleOrderBy(`oldest`)}
                >
                  Oldest
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  active={orderBy === 'latest'}
                  onClick={() => handleOrderBy(`latest`)}
                >
                  Latest
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            <Dropdown>
              <Dropdown.Toggle variant="" className="ps-0 mt-1 mb-2 pt-1 pb-1">
                <i className="fas fa-filter me-2"></i>Filter
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  active={nextUpStatus === 'ALL'}
                  onClick={() => handleNextUpStatus('ALL')}
                >
                  {' '}
                  All NextUp Status
                </Dropdown.Item>
                {(tabStatus === 'PENDING' || tabStatus === 'ALL') && (
                  <Dropdown.Item
                    href="#"
                    active={nextUpStatus === 'Upload Invoice'}
                    onClick={() => handleNextUpStatus('Upload Invoice')}
                  >
                    {' '}
                    Upload Invoice
                  </Dropdown.Item>
                )}
                {(tabStatus === 'PENDING' || tabStatus === 'ALL') && (
                  <Dropdown.Item
                    href="#"
                    active={nextUpStatus === 'Review Invoice'}
                    onClick={() => handleNextUpStatus('Review Invoice')}
                  >
                    {' '}
                    Review Invoice
                  </Dropdown.Item>
                )}
                {(tabStatus === 'PENDING' || tabStatus === 'ALL') && (
                  <Dropdown.Item
                    href="#"
                    active={nextUpStatus === 'Update Payment'}
                    onClick={() => handleNextUpStatus('Update Payment')}
                  >
                    {' '}
                    Update Payment
                  </Dropdown.Item>
                )}
                {(tabStatus === 'IN_PRODUCTION' || tabStatus === 'ALL') && (
                  <Dropdown.Item
                    href="#"
                    active={nextUpStatus === 'Add Shipment Details'}
                    onClick={() => handleNextUpStatus('Add Shipment Details')}
                  >
                    Add Shipment Details
                  </Dropdown.Item>
                )}
                {(tabStatus === 'IN_PRODUCTION' || tabStatus === 'ALL') && (
                  <Dropdown.Item
                    href="#"
                    active={nextUpStatus === 'Create Shipment + Label'}
                    onClick={() =>
                      handleNextUpStatus('Create Shipment + Label')
                    }
                  >
                    Create Shipment + Label
                  </Dropdown.Item>
                )}
                {(tabStatus === 'IN_PRODUCTION' || tabStatus === 'ALL') && (
                  <Dropdown.Item
                    href="#"
                    active={nextUpStatus === 'Download Label'}
                    onClick={() => handleNextUpStatus('Download Label')}
                  >
                    {' '}
                    Download Label
                  </Dropdown.Item>
                )}
                {(tabStatus === 'SHIPPED' || tabStatus === 'ALL') && (
                  <Dropdown.Item
                    href="#"
                    active={nextUpStatus === 'Add Tracking details'}
                    onClick={() => handleNextUpStatus('Add Tracking details')}
                  >
                    {' '}
                    Add Tracking Details
                  </Dropdown.Item>
                )}
                {(tabStatus === 'SHIPPED' || tabStatus === 'ALL') && (
                  <Dropdown.Item
                    href="#"
                    active={nextUpStatus === 'Waiting for delivery'}
                    onClick={() => handleNextUpStatus('Waiting for delivery')}
                  >
                    {' '}
                    Waiting for delivery
                  </Dropdown.Item>
                )}
                {(tabStatus === 'SHIPPED' || tabStatus === 'ALL') && (
                  <Dropdown.Item
                    href="#"
                    active={nextUpStatus === 'Order Delivered'}
                    onClick={() => handleNextUpStatus('Order Delivered')}
                  >
                    {' '}
                    Order Delivered
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <Tab.Content>
              {fetchOrdersData.isLoading || uploadInvoiceData.isLoading ? (
                <LoadingSpinner size={150} />
              ) : (
                <Tab.Pane eventKey={tabStatus} active={true}>
                  {[...ordersDetails].map((order) => {
                    const truncatedProductName =
                      order.Product.productName.length > MAX_CHAR_LENGTH
                        ? `${order.Product.productName.substring(
                            0,
                            MAX_CHAR_LENGTH
                          )}...`
                        : order.Product.productName

                    const totalPayments = sumBy(
                      order?.Invoice?.Payments,
                      (payment) => {
                        return parseFloat(payment.paidAmount)
                      }
                    )
                    return (
                      <OrderCard
                        Boxes={order.Boxes}
                        key={order.poNumber}
                        poNumber={order.poNumber}
                        orderDate={new Date(order.orderDate)}
                        productThumb={order.Product.productThumb}
                        productName={truncatedProductName}
                        productSKU={order.Product.productSKU}
                        productASIN={order.Product.productASIN}
                        supplierLogo={
                          isBusiness
                            ? order.supplierLogo || supplierDefult
                            : order.Business.profileImage
                        }
                        supplierName={
                          isBusiness
                            ? order.supplierName || 'No Supplier'
                            : order.Business.name
                        }
                        orderUnits={order.orderUnits}
                        receivedUnits={order.receivedUnits}
                        orderCost={order.Invoice?.totalCost}
                        receivedPayment={totalPayments}
                        onUploadInvoiceClick={() =>
                          handleUploadInvoiceClick(order.id)
                        }
                        shipmentAgentLogo={order.shipmentAgentLogo}
                        shipmentMasterTrackingID={
                          order.shipmentMasterTrackingID
                        }
                        destinationLogo={order.destinationLogo}
                        destinationShippingID={order.destinationShippingID}
                        destinationType={order.destinationType}
                        orderStatus={order.orderStatus}
                        orderNextUpStatus={order.orderNextUpStatus}
                        orderStatusColor={getStatus(order.orderStatus)}
                        Shipment={order.Shipment}
                        shippingMode={order.shippingMode}
                        warehouseName={order.Warehouse?.warehouseName}
                        firstLine={order.Warehouse?.addressLine1}
                        city={order.Warehouse?.cityName}
                        county={order.Warehouse?.stateOrProvinceName}
                        zipCode={order.Warehouse?.zipCode}
                        country={order.Warehouse?.countryName}
                        invoiceLink={order.invoiceInfo?.invoiceSRC}
                        invoiceName={order.invoiceInfo?.invoice?.name}
                        onClickOrder={(e) =>
                          navigateToOrderDetails(e, order.id)
                        }
                        currencyFormat={currencyFormat}
                        userDateFormat={userDateFormat}
                      />
                    )
                  })}
                </Tab.Pane>
              )}
            </Tab.Content>
          </div>
          <div className="d-flex justify-content-end align-items-center flex-wrap">
            {integratedOrdersData?.totalPages > 1 && (
              <Paginator
                total={integratedOrdersData.totalPages}
                current={page}
                onChangePage={handleChangePage}
              />
            )}
          </div>
        </div>
      </Tab.Container>
    </>
  )
}
export default Orders
