import http from "./http"
import { config } from "../connector/config"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { useStateValue } from "../store/selectors/useStateValue"

export const useGetOrderDetails = ( orderId ) => {

    const { auth } = useStateValue();

    const { businessOrders, supplierOrders } = config;

    const endpoint = auth.auth.role[0] === `business`? businessOrders: supplierOrders;

    return useQuery(['get-order-details',orderId, auth.auth.accessToken], () => {
         return  http().request({
            url:    `${endpoint}/${orderId}`,
            method: 'GET',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            }
         })
    })
}


export const useSubmiBoxDetails =  ({onSuccess}) => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({orderId, boxes})=> {
        return  http().request({
            url:    `${config.businessOrders}/${orderId}/boxes`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
            data: { boxes }
         })
    },{
        onSuccess: () => {
              onSuccess();
              queryClient.invalidateQueries(`get-order-details`)
        }
    })
}

export const useUploadInvoice = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({orderId, newInvoice}) => { 
        return  http().request({
            url:    `${config.businessOrders}/${orderId}/upload-invoice`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
            data: newInvoice
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`get-order-details`)
        }
    })
}

export const useApproveInvoice = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({orderId }) => { 
        return  http().request({
            url:    `${config.businessOrders}/${orderId}/approve-invoice`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`get-order-details`)
        }
    })
}

export const useDeclineInvoice = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({orderId }) => { 
        return  http().request({
            url:    `${config.businessOrders}/${orderId}/decline-invoice`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`get-order-details`)
        }
    })
}

export const useAddPayment = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({orderId, newPayment}) => { 
        return  http().request({
            url:    `${config.businessOrders}/${orderId}/payments`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
            data: newPayment
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`get-order-details`)
        }
    })
}

export const useRemovePayment = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({orderId, paymentId}) => { 
        return  http().request({
            url:    `${config.businessOrders}/${orderId}/payments/${paymentId}`,
            method: 'DELETE',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`get-order-details`)
        }
    })
}
export const useUploadBatch = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({orderId,data}) => { 
        return  http().request({
            url:    `${config.businessOrders}/${orderId}/batches`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
            data:data
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`get-order-details`)
        }
    })
}

export const useAddNote = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({orderId,data}) => { 
        return  http().request({
            url:    `${config.businessOrders}/${orderId}/notes`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
            data:data
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`fetchNote`)
        }
    })
}

export const useAttachShipmentId = () => {

    const { auth } = useStateValue();

    return useMutation(({ orderId, data }) => {
        return http().request({
            url: `${config.businessOrders}/${orderId}/attach-shipment-id`,
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
            data: data
        });
    })
};

export const useGetNote = ({orderId}) => {
    const { auth } = useStateValue();
    return useQuery(['fetchNote', orderId, auth.auth.accessToken],() => { 
        return  http().request({
            url: `${config.businessOrders}/${orderId}/notes`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
        });
    })
};

export const useUploadImage = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({orderId,data}) => { 
        return  http().request({
            url:    `${config.businessOrders}/${orderId}/upload-image`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
            data:data
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`get-order-details`)
        }
    })
}

export const useDeleteOrderImage = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({imageId,data })=> {
       return http().request({
           url: `${config.businessOrders}/images/${imageId}`,
           method: 'DELETE',
           data: data,
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`get-order-details`)
        }
    })
 }

 
export const useUploadDocument = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({orderId,data}) => { 
        return  http().request({
            url:    `${config.businessOrders}/${orderId}/upload-document`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
            data:data
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`get-order-details`)
        }
    })
}
 
export const useDeleteDocument = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({documentId,data}) => { 
        return  http().request({
            url:    `${config.businessOrders}/documents/${documentId}`,
            method: 'DELETE',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
            data:data
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries(`get-order-details`)
        }
    })
}