import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { useStateValue } from '../../../store/selectors/useStateValue'
import HeaderBanner from '../molecules/HeaderBanner/HeaderBanner'
import profile1 from '../../../images/profile/1.jpg'
import { config } from '../../../connector/config'
import { setWarehousesAction } from '../../../store/actions/WarehouseActions'
import { useDispatch } from 'react-redux'
import swal from 'sweetalert'
import EditWarehouseForm from '../customForms/EditWarehouseForm'
import Paginator from '../elements/Paginator/Paginator'
import LoadingSpinner from '../elements/LoadingSpinner/LoadingSpinner'
import {
  useFetchSuppliersProducts,
  useUnlinkProduct,
} from '../../../data/suppliers-data'
import {
  dataRequest,
  addWarehouseRequest,
  editDataRequest,
  deleteDataRequest,
} from '../../../connector/httpConnector/httpRequest'
//** Import Image */
import AddLocationForm, {
  initialLocationFormState,
} from '../customForms/AddLocationForm'
import EditLocationForm from '../customForms/EditLocationForm'
import UnLinkPaginator from '../elements/Paginator/UnLinkPaginator'
const SupplierProfile = () => {
  const location = useLocation()
  const selectedSupplier = location.state?.selectedSupplier
  // const [activeToggle, setActiveToggle] = useState("aboutMe");
  // const [sendMessage, setSendMessage] = useState(false);
  const { locations, auth } = useStateValue()
  const [editModel, setEditModel] = useState(false)
  const [integratedLocationData, setIntegratedLocationData] = useState()
  const [locationPage, setLocationPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get('locationPage')) ||
      1
  )
  const token = auth.auth.accessToken
  const [selectedLocation, setSelectedLocation] = useState()
  const { baseURL, businessSupplierLocations } = config
  const [addCard, setAddCard] = useState(false)
  const [addFormData, setAddFormData] = useState(initialLocationFormState)
  const [editFormData, setEditFormData] = useState({})
  const [locationsData, setLocationsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const [linkedData, setLinkedData] = useState([])
  const [unLinkedData, setUnLinkedData] = useState([])
  const [unLinkedPage, setUnlinkedPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get('linkedPage')) || 1
  )
  const [linkedPage, setLinkedPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get('unlinkedPage')) ||
      1
  )
  const [integratedLinkedData, setIntegratedLinkdData] = useState()
  const [integratedUnLinkedData, setIntegratedUnLinkdData] = useState()
  const [unlinkProductModal, setUnlinkProductModal] = useState()
  const [selectUnlinkProduct, setSelectUnlinkProduct] = useState()
  const unlinkProductData = useUnlinkProduct({
    onSuccess: () => setUnlinkProductModal(false),
  })

  const fetchLocations = useCallback(() => {
    dataRequest(
      baseURL,
      token,
      businessSupplierLocations.replace('{supplierId}', selectedSupplier.id),
      'get',
      locationPage,
      12
    )
      .then((data) => {
        setLocationsData(data.data.locations)
        setIntegratedLocationData(data.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [locationPage])

  useEffect(() => {
    fetchLocations(locationPage)
  }, [locationPage])

  const handleLocationChangePage = useCallback((locationPage) => {
    setLocationPage(locationPage) // Update the state with the new page number
  }, [])

  //linkedproducts

  const supplierFeachProduct = useFetchSuppliersProducts({
    currPage: linkedPage,
    perPage: 12,
  })
  //unlinkedproducts
  const supplierUnlinkedProduct = useFetchSuppliersProducts({
    currPage: unLinkedPage,
    perPage: 12,
    linked: false,
  })
  //linkedproducts
  useEffect(() => {
    if (supplierFeachProduct.isLoading === false) {
      if (supplierFeachProduct.status === 'success') {
        const fetchedSuppliersLinked =
          supplierFeachProduct.data?.data?.data?.products || []
        setLinkedData(fetchedSuppliersLinked)
        setIntegratedLinkdData(supplierFeachProduct.data.data.data)
      }
      if (supplierFeachProduct.status === 'error') {
        setLinkedData([])
      }
    }
  }, [supplierFeachProduct.data, supplierFeachProduct.isLoading])
  //unlinkedproducts
  useEffect(() => {
    if (supplierUnlinkedProduct.isLoading === false) {
      if (supplierUnlinkedProduct.status === 'success') {
        const fetchedSuppliersLinked =
          supplierUnlinkedProduct.data.data.data.products
        setUnLinkedData(fetchedSuppliersLinked)
        setIntegratedUnLinkdData(supplierUnlinkedProduct.data.data.data)
      }
      if (supplierUnlinkedProduct.status === 'error') {
        setUnLinkedData([])
        setIntegratedUnLinkdData({})
      }
    }
  }, [supplierUnlinkedProduct.data, supplierUnlinkedProduct.isLoading])
  const handleChangeUnlinkedPage = useCallback((unLinkedPage) => {
    setUnlinkedPage(unLinkedPage)
  }, [])
  const handleChangePage = useCallback((linkedPage) => {
    setLinkedPage(linkedPage)
  }, [])
  //addform
  const handleAddFormChange = (event) => {
    event.preventDefault()
    const fieldName = event.target.getAttribute('name')
    const fieldValue = event.target.value
    const newFormData = { ...addFormData }
    newFormData[fieldName] = fieldValue
    setAddFormData(newFormData)
  }
  //addform
  const handleAddFormSubmit = async (event) => {
    console.log('Handling add form submission...')
    setLoading(true)
    event.preventDefault()
    let error = false
    let errorMsg = ''
    if (addFormData.locationName === '') {
      error = true
      errorMsg = 'Please fill Location Name'
    } else if (addFormData.firstLine === '') {
      error = true
      errorMsg = 'Please fill first line.'
    } else if (addFormData.city === '') {
      error = true
      errorMsg = 'Please fill city'
    } else if (addFormData.county === '') {
      error = true
      errorMsg = 'Please fill county'
    } else if (addFormData.zipCode === '') {
      error = true
      errorMsg = 'Please fill zip code'
    } else if (addFormData.country === '') {
      error = true
      errorMsg = 'Please fill country'
    } else if (addFormData.phoneNumber === '') {
      error = true
      errorMsg = 'Please fill phone number'
    }
    if (!error) {
      const newLocation = {
        locationName: addFormData.locationName,
        addressLine1: addFormData.firstLine,
        cityName: addFormData.city,
        stateOrProvinceName: addFormData.county,
        zipCode: addFormData.zipCode,
        countryName: addFormData.country,
        phoneNumber: addFormData.phoneNumber,
      }

      try {
        await addWarehouseRequest(
          baseURL,
          token,
          businessSupplierLocations.replace(
            '{supplierId}',
            selectedSupplier.id
          ),
          'post',
          newLocation
        )
        setAddCard(false)
        swal('Good job!', 'Successfully Added', 'success')
        fetchLocations(locationPage)
        setAddCard(false)
        const newLocations = [...locationsData, newLocation]
        dispatch(setWarehousesAction(newLocations))
        setLoading(true)

        addFormData.name =
          addFormData.productSKU =
          addFormData.productThumb =
            ''
      } catch (error) {
        console.log(error)
        console.log('Error message', error.message)
        swal('Oops', error.response?.data.message[0]?.message, 'error')
      }
    } else {
      swal('Oops', errorMsg, 'error')
    }
  }

  const handleEditModal = (location) => {
    setSelectedLocation(location)
    setEditModel(true)
    setEditFormData(location)
  }

  const handleEditFormChange = (event, fieldName) => {
    event.preventDefault()
    const fieldValue = event.target.value
    const newFormData = { ...editFormData }
    newFormData[fieldName] = fieldValue
    setEditFormData(newFormData)
    setSelectedLocation((prevLocation) => ({
      ...prevLocation,
      [fieldName]: fieldValue,
    }))
  }

  //Add edit data
  const handleEditFormSubmit = async (event) => {
    console.log('Handling edit form submission...')
    console.log('Edit form data:', editFormData)
    setLoading(true)
    event.preventDefault()
    let error = false
    let errorMsg = ''
    if (editFormData.locationName === '') {
      error = true
      errorMsg = 'Please fill Location Name'
    } else if (editFormData.addressLine1 === '') {
      error = true
      errorMsg = 'Please fill first line.'
    } else if (editFormData.cityName === '') {
      error = true
      errorMsg = 'Please fill city'
    } else if (editFormData.stateOrProvinceName === '') {
      error = true
      errorMsg = 'Please fill county'
    } else if (editFormData.zipCode === '') {
      error = true
      errorMsg = 'Please fill zip code'
    } else if (editFormData.countryName === '') {
      error = true
      errorMsg = 'Please fill country'
    } else if (editFormData.phoneNumber === '') {
      error = true
      errorMsg = 'Please fill phone number'
    }
    if (!error) {
      const editLocation = {
        locationName: editFormData.locationName,
        addressLine1: editFormData.addressLine1,
        cityName: editFormData.cityName,
        stateOrProvinceName: editFormData.stateOrProvinceName,
        zipCode: editFormData.zipCode,
        countryName: editFormData.countryName,
        phoneNumber: editFormData.phoneNumber,
      }

      try {
        await editDataRequest(
          baseURL,
          token,
          businessSupplierLocations.replace(
            '{supplierId}',
            selectedSupplier.id
          ),
          'post',
          selectedLocation.id,
          editLocation
        )
        setEditModel(false)
        swal('Good job!', 'Successfully Updated', 'success')
        fetchLocations(locationPage)
        setAddCard(false)
        //   const editLocations = [...locationsData, editLocation];
        //   dispatch(setWarehousesAction(editLocations));
        setLoading(true)
        editFormData.name =
          editFormData.productSKU =
          editFormData.productThumb =
            ''
      } catch (error) {
        console.log(error)
        console.log('Error message', error.message)
        swal('Oops', error.response?.data.message[0].message, 'error')
      }
    } else {
      swal('Oops', errorMsg, 'error')
    }
  }

  // delete modal
  const handleDeleteModal = (id) => {
    const selectedLocation = locationsData.find(
      (location) => location.id === id
    )
    setSelectedLocation(selectedLocation)
    setDeleteModal(true)
  }

  // delete data submit
  const handleDeleteSubmit = async () => {
    try {
      await deleteDataRequest(
        baseURL,
        token,
        businessSupplierLocations.replace('{supplierId}', selectedSupplier.id),
        selectedLocation.id
      )
      swal('Good job!', 'Successfully deleted', 'success')
      setDeleteModal(false)
      fetchLocations()
    } catch (error) {
      console.log(error)
      swal('Oops', 'Something went wrong', 'error')
    }
  }

  const handleUnlinkProduct = (e, linkedProduct) => {
    setSelectUnlinkProduct(linkedProduct)
    setUnlinkProductModal(true)
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="profile-info">
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <div className="profile-name px-3 pt-2">
                      <h4 className="text-primary mb-0">
                        <i
                          class="fas fa-user"
                          style={{ marginRight: '5px' }}
                        ></i>
                        {selectedSupplier && selectedSupplier.name
                          ? selectedSupplier.name
                          : 'Unknown Name'}
                      </h4>
                      <p>
                        <i
                          class="fas fa-envelope"
                          style={{ marginRight: '5px' }}
                        ></i>
                        Email:{' '}
                        {selectedSupplier && selectedSupplier.email
                          ? selectedSupplier.email
                          : 'N/A'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <span className="d-block fs-14 font-w600 text-primary mb-3">
            Linked Products
          </span>
					<div className="row">
						{linkedData &&
							linkedData.map((linked) => (
                <div className="col-sm-6" key={linked.id}>
                  <div className="widget-stat card supplier_products position-relative">
                    <span
                      className="position-absolute end-0 p-2"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleUnlinkProduct(e, linked)}
                    >
                      &#x2715;
                    </span>
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-primary text-primary">
                          <img
                            src={linked.productThumb}
                            className="product-image"
                          />
                        </span>
                        <div className="media-body">
                          <p className="mb-1 fs-14 supplier_products__sku">
                            {linked.productSKU}
                          </p>
                          <span className="d-block fs-12 mb-3 ms-0">
                            {linked.productASIN}
                          </span>
                          <div className="badge badge-success">LINKED</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
						))}
					</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingBottom: '60px',
            }}
          >
            {integratedLinkedData?.totalPages > 1 && (
              <UnLinkPaginator
                total={integratedLinkedData?.totalPages}
                current={linkedPage}
                onChangePage={handleChangePage}
              />
            )}
          </div>

          <span className="d-block fs-14 font-w600 text-danger mb-3">
            Pending Connection
          </span>
          {unLinkedData &&
            unLinkedData.map((linked) => (
              <div className="row" key={linked.id}>
                <div className="col-sm-6">
                  <div className="widget-stat card supplier_products">
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-primary text-primary">
                          <img
                            src={linked.productThumb}
                            className="product-image"
                          />
                        </span>
                        <div className="media-body">
                          <p className="mb-1 fs-14 supplier_products__sku">
                            {linked.productSKU}
                          </p>
                          <span className="d-block fs-12 mb-3 ms-0">
                            {linked.productASIN}
                          </span>
                          <div className="badge badge-warning">PENDING</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingBottom: '30px',
            }}
          >
            {integratedUnLinkedData?.totalPages > 1 && (
              <UnLinkPaginator
                total={integratedUnLinkedData?.totalPages}
                current={unLinkedPage}
                onChangePage={handleChangeUnlinkedPage}
              />
            )}
          </div>
        </div>

        <div className="col-xl-6">
          <span className="d-block fs-14 font-w600 text-primary mb-3">
            Factory/location Locations
          </span>
          {locationsData?.map((location) => (
            <div className="row" key={location.id}>
              <div className="col-sm-12">
                <div className="widget-stat card supplier_locations">
                  <div className="card-body p-4">
                    <div className="media-body">
                      <h4 className="fs-18 font-w700 mb-2">
                        {location.locationName}
                      </h4>
                      <p class="fs-14 font-w400 mb-0">
                        {location.addressLine1}
                      </p>
                      <p class="fs-14 font-w400 mb-0">{location.cityName}</p>
                      <p class="fs-14 font-w400 mb-0">
                        {location.stateOrProvinceName}
                      </p>
                      <p class="fs-14 font-w400 mb-0">{location.zipCode}</p>
                      <p class="fs-14 font-w400 mb-0">{location.countryName}</p>
                      <p class="fs-14 font-w400 mb-0">{location.phoneNumber}</p>
                    </div>
                    <ul className="mt-3">
                      <li>
                        <button
                          onClick={() => handleDeleteModal(location.id)}
                          style={{
                            margin: '0 10px',
                            height: '48px',
                            width: '48px',
                            borderRadius: '50px',
                            background: 'rgb(106 101 255 / 10%)',
                            color: '#6a65ff',
                            fontSize: '20px',
                            display: 'block',
                            lineHeight: '48px',
                            transition: 'all 0.5s',
                            textAlign: 'center',
                            border: 'none',
                          }}
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleEditModal(location)}
                          style={{
                            margin: '0 10px',
                            height: '48px',
                            width: '48px',
                            borderRadius: '50px',
                            background: 'rgb(106 101 255 / 10%)',
                            color: '#6a65ff',
                            fontSize: '20px',
                            display: 'block',
                            lineHeight: '48px',
                            transition: 'all 0.5s',
                            textAlign: 'center',
                            border: 'none',
                          }}
                        >
                          <i class="far fa-edit"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {integratedLocationData?.totalPages > 1 && (
            <Paginator
              total={integratedLocationData?.totalPages}
              current={locationPage} // Use locationPage here
              onChangePage={handleLocationChangePage}
            />
          )}
          <div
            className="btn btn-primary light btn-rounded btn-md me-2 mb-2"
            onClick={() => setAddCard(true)}
          >
            <i className="fas fa-plus me-3 scale5"></i>New Location
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <button type="button" className="btn btn-danger btn-rounded me-2">
            <span className="btn-icon-start text-danger">
              <i className="fa fa-trash color-danger"></i>
            </span>
            Delete Supplier
          </button>
        </div>
      </div>
      <Modal
        className="modal fade"
        show={addCard}
        onHide={setAddCard}
        size="lg"
      >
        <div className="modal-header">
          <h4 className="modal-title fs-20">Add a location</h4>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAddCard(false)}
          ></button>
        </div>
        <form>
          <AddLocationForm
            onLocationNameChange={handleAddFormChange}
            onFirstLineChange={handleAddFormChange}
            onCityChange={handleAddFormChange}
            onCountyChange={handleAddFormChange}
            onZipCodeChange={handleAddFormChange}
            onCountryChange={handleAddFormChange}
            onPhoneNumberChange={handleAddFormChange}
            onClickSubmit={handleAddFormSubmit}
            onClickCancel={() => setAddCard(false)}
          />
        </form>
      </Modal>

      <Modal
        className="modal fade"
        show={editModel}
        onHide={setEditModel}
        size="lg"
      >
        <div className="modal-header">
          <h4 className="modal-title fs-20">Edit a Location</h4>
          <button
            type="button"
            className="btn-close"
            onClick={() => setEditModel(false)}
          ></button>
        </div>
        <form>
          <EditLocationForm
            locationName={selectedLocation?.locationName || ''}
            addressLine1={selectedLocation?.addressLine1 || ''}
            cityName={selectedLocation?.cityName || ''}
            stateOrProvinceName={selectedLocation?.stateOrProvinceName || ''}
            zipCode={selectedLocation?.zipCode || ''}
            countryName={selectedLocation?.countryName || ''}
            phoneNumber={selectedLocation?.phoneNumber || ''}
            onLocationNameChange={(e) =>
              handleEditFormChange(e, 'locationName')
            }
            onFirstLineChange={(e) => handleEditFormChange(e, 'addressLine1')}
            onCityChange={(e) => handleEditFormChange(e, 'cityName')}
            onCountyChange={(e) =>
              handleEditFormChange(e, 'stateOrProvinceName')
            }
            onZipCodeChange={(e) => handleEditFormChange(e, 'zipCode')}
            onCountryChange={(e) => handleEditFormChange(e, 'countryName')}
            onPhoneNumberChange={(e) => handleEditFormChange(e, 'phoneNumber')}
            onClickSubmit={handleEditFormSubmit}
            onClickCancel={() => setEditModel(false)}
            countryCode={selectedLocation?.countryCode}
            countyCode={selectedLocation?.stateOrProvinceCode}
          />
        </form>
      </Modal>
      <Modal className="modal fade" show={deleteModal} onHide={setDeleteModal}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">
            Are you sure you want to delete{' '}
            <b>{selectedLocation?.locationName}</b>?
          </h4>
          <button
            type="button"
            className="btn-close"
            onClick={() => setDeleteModal(false)}
            data-dismiss="modal"
          ></button>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={handleDeleteSubmit}
            className="btn btn-danger"
          >
            <i className="flaticon-delete-1"></i> Delete
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <Modal
        className="modal fade"
        show={unlinkProductModal}
        onHide={setUnlinkProductModal}
      >
        <div className="modal-header">
          <h4 className="modal-title fs-20">
            Are you sure you want to disconnect this product?
          </h4>
          <button
            type="button"
            className="btn-close"
            onClick={() => setUnlinkProductModal(false)}
            data-dismiss="modal"
          ></button>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={() =>
              unlinkProductData.mutate({ productId: selectUnlinkProduct.id })
            }
            className="btn btn-danger"
          >
            <i className="flaticon-delete-1"></i> Delete
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => setUnlinkProductModal(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default SupplierProfile
