import { useEffect, useState } from "react";
import { Field, FieldArray, Formik, Form } from "formik";
import { Alert, Button, ButtonGroup, Card, Modal } from "react-bootstrap";
import { useStateValue } from "../../../../../store/selectors/useStateValue";
import { useSubmiBoxDetails } from "../../../../../data/order-details";
import swal from "sweetalert";

export const AddBoxInfoModalForm = ({
    handleClose
}) => {
    const submitBoxDetails = useSubmiBoxDetails({
        onSuccess: () => {
            handleClose();
            swal('Success', 'Boxes are added', 'success')
        }
    });

    const { orders: { selectedOrder } } = useStateValue();
    const [boxes, setBoxes] = useState([{
        height: ``,
        length: ``,
        width: ``,
        weight: ``,
        quantity: ``
    }]);
    const [order, setOrder] = useState();
    const [disable, setDisable] = useState(false);


    useEffect(() => {
        let boxes = selectedOrder.Boxes?.map((box) => ({
            length: box.length,
            height: box.height,
            width: box.width,
            weight: box.weight,
            quantity: box.quantity
        }))
        if (boxes && boxes.length !== 0) {
            setBoxes(boxes);
            if (selectedOrder.Shipment) {
                setDisable(true)
            }
        }
        setOrder(selectedOrder)
    }, [selectedOrder])

    return (
        <>
            <Formik
                onSubmit={(values) => {
                    submitBoxDetails.mutate({
                        orderId: order.id,
                        boxes: values.boxes.map((box) => ({
                            length: parseInt(box.length),
                            height: parseInt(box.height),
                            width: parseInt(box.width),
                            weight: parseInt(box.weight),
                            quantity: parseInt(box.quantity)
                        }))
                    })
                }}
                initialValues={{ boxes }}
                enableReinitialize={true}
            >
                {({ values }) => (
                    <Form>
                        <Modal.Header>
                            <Modal.Title>Box Informations</Modal.Title>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => handleClose()}
                                data-dismiss="modal"
                            ></button>
                        </Modal.Header>
                        <Modal.Body >
                            <div className="add-contact-box">
                                <div className="add-contact-content">
                                    <FieldArray
                                        name="boxes"
                                    >{(arrayHelper) => (
                                        values.boxes.map((box, index) => (
                                            <div className="row p box_size_units" key={index} >
                                                <div className="form-group col-2 col-sm-2 mb-3">
                                                    <label className="text-black font-w500">Height(in)</label>
                                                    <div className="contact-name">
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="0"
                                                            autoComplete="off"
                                                            required="required"
                                                            name={`boxes.${index}.height`}
                                                            id={`boxes.${index}.height`}
                                                            disabled={disable}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-2 col-sm-2 mb-3">
                                                    <label className="text-black font-w500">Width(in)</label>
                                                    <div className="contact-name">
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="0"
                                                            autoComplete="off"
                                                            required="required"
                                                            name={`boxes.${index}.width`}
                                                            id={`boxes.${index}.width`}
                                                            disabled={disable}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-2 col-sm-2 mb-3">
                                                    <label className="text-black font-w500">Length(in)</label>
                                                    <div className="contact-name">
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="0"
                                                            autoComplete="off"
                                                            required="required"
                                                            name={`boxes.${index}.length`}
                                                            id={`boxes.${index}.length`}
                                                            disabled={disable}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-2 col-sm-2 mb-4">
                                                    <label className="text-black font-w500">Weight(lb)</label>
                                                    <div className="contact-name">
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="0"
                                                            autoComplete="off"
                                                            required="required"
                                                            name={`boxes.${index}.weight`}
                                                            id={`boxes.${index}.weight`}
                                                            disabled={disable}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-2 col-sm-2 mb-4">
                                                    <label className="text-black font-w500">Units</label>
                                                    <div className="contact-name">
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="0"
                                                            autoComplete="off"
                                                            required="required"
                                                            name={`boxes.${index}.quantity`}
                                                            id={`boxes.${index}.quantity`}
                                                            disabled={disable}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-2 col-sm-2 d-block mt-3 copy_add_remove">
                                                    <ButtonGroup variant="" size="sm">
                                                        {
                                                            !disable &&
                                                            index > 0 &&
                                                            <Button
                                                                type="button"
                                                                onClick={() => arrayHelper.remove(index)}
                                                                variant="link"
                                                            >
                                                                <span className="font-w500">&#10060;</span>
                                                            </Button>
                                                        }
                                                        {
                                                            !disable &&
                                                            (index == values.boxes.length - 1) &&
                                                            <Button
                                                                type="button"
                                                                onClick={() => arrayHelper.push({
                                                                    height: ``,
                                                                    length: ``,
                                                                    width: ``,
                                                                    weight: ``,
                                                                    quantity: ``
                                                                })}
                                                                disabled={false}
                                                                variant="link"
                                                            >
                                                                <span className="font-w500 text-success h3">&#x2b;</span>
                                                            </Button>}
                                                        {!disable &&
                                                            <Button
                                                                type="button"
                                                                onClick={() => arrayHelper.insert(index + 1, {
                                                                    ...values.boxes[index]
                                                                })}
                                                                disabled={false}
                                                                variant="link"
                                                            >
                                                                <span className="text-primary h3 fa fa-copy fa-sm" >
                                                                </span>
                                                            </Button>}
                                                    </ButtonGroup>
                                                </div>

                                            </div>
                                        ))
                                    )}
                                    </FieldArray>
                                </div>
                            </div>
                            {submitBoxDetails.isError &&
                                <Alert variant='danger'>
                                    {(
                                        submitBoxDetails.error.response?.data?.message?.[0]?.message ||
                                        submitBoxDetails.error.response?.data?.message
                                    )}
                                </Alert>}
                        </Modal.Body>
                        <Modal.Footer>
                            {!disable && <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={submitBoxDetails.isLoading}
                            >
                                Submit
                            </button>}
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleClose()}
                            >
                                Discard
                            </button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </>
    )
}