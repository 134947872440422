import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../images/app-logo.png'
import { AUTH_ERROR_MEESAGE } from '../../../connector/httpErrorResponse/httpErrorResponse'

const RegistrationForm = ({
  errorMessage,
  successMessage,
  errors,
  onSubmit,
  onChangeFullName,
  onChangeEmail,
  onChangePassword,
  onChangeTC,
  userType,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="authincation h-100 p-meddle">
      <div className="authincation_header">
        <div className="authincation_header_logo text-center mb-4 pt-1">
          <a href="https://www.bluudo.com">
            <img src={logo} alt="Logo" />
          </a>
        </div>
      </div>
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content style-1">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h3 className="text-center mb-4 ">{userType} sign up</h3>
                    {userType === 'Supplier' && (
                      <h4 className="text-primary text-center">Free forever</h4>
                    )}
                    {errorMessage === AUTH_ERROR_MEESAGE.EMAIL_EXIST && (
                      <div className="text-danger fs-18 mb-3">
                        {errorMessage}
                      </div>
                    )}
                    <form onSubmit={onSubmit}>
                      <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="fullName">
                          <strong>Name</strong>
                        </label>
                        <input
                          id="fullName"
                          name="fullName"
                          onChange={onChangeFullName}
                          type="text"
                          className="form-control"
                          placeholder="First & Last Name"
                        />
                        {errors.name && (
                          <div className="field_error">{errors.name}</div>
                        )}
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="email">
                          <strong>Email</strong>
                        </label>
                        <input
                          id="email"
                          name="email"
                          onChange={onChangeEmail}
                          className="form-control"
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="field_error">{errors.email}</div>
                        )}
                        {errorMessage[0]?.message ===
                          AUTH_ERROR_MEESAGE.INVALID_EMAIL && (
                          <div className="field_error">
                            Invalid email Address!
                          </div>
                        )}
                      </div>
                      <div className="form-group mb-3">
                        <div className="password-input">
                          <label className="mb-1">
                            <strong>Password</strong>
                          </label>
                          <input
                            type={showPassword ? 'text' : 'password'}
                            onChange={onChangePassword}
                            className="form-control"
                            placeholder="Choose a strong password"
                          />
                          <div
                            className="password-toggle"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <i className="fas fa-eye-slash"></i>
                            ) : (
                              <i className="fas fa-eye"></i>
                            )}
                          </div>
                        </div>
                        {errors.password && (
                          <div className="field_error">{errors.password}</div>
                        )}
                      </div>
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group mb-3">
                          <div className="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="agree_terms"
                              onChange={onChangeTC}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="agree_terms"
                            >
                              By checking this box, you agree to our{' '}
                              <a
                                href="https://www.bluudo.com/terms"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Terms of Service
                              </a>{' '}
                              and{' '}
                              <a
                                href="https://www.bluudo.com/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Privacy Policy
                              </a>
                              .
                            </label>
                          </div>
                          {errors.tc && (
                            <div className="field_error">{errors.tc}</div>
                          )}
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Sign up for Free
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{' '}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistrationForm
