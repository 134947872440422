import product1 from "../../images/product/4.jpg";
import supplier1 from "../../images/customers/11.jpg";
import freight1 from "../../images/freight/dhl.png";
import amazonLogo from "../../images/freight/amazon.png";

export const ordersData = [
  {
    id: "PO-000441429",
    orderDate: "Sep 7th, 2020",
    productInfo: {
      productName: "Sea Moss Gummies",
      productThumb: product1,
      productSKU: "SKU 346722",
      productASIN: "ASIN 462831",
      amazonIntegrated: true,
    },
    supplierLogo: supplier1,
    supplierName: "Vigo World",
    orderUnits: 5000,
    receivedUnits: 3500,
    receivedPayment: 4500,
    shipmentAgentLogo: freight1,
    shipmentMasterTrackingID: "770532218138",
    shippingMode: "Air",
    destinationLogo: amazonLogo,
    destinationShippingID: "FBA16YR0BH62",
    destinationType: "Amazon FBA",
    orderStatus: "PENDING",
    invoiceInfo: {
      id: "dsjsn_lFpMUcsOwWxeEVv",
      totalCost: "8000",
      invoiceNumber: "JHJGPUG",
      invoiceFile: {
        lastModified: 1644413968296,
        lastModifiedDate: new Date(),
        name: "6002VB0001766001.pdf",
        size: 77774,
        type: "application/pdf",
        webkitRelativePath: "",
      },
      invoiceSRC:
        "blob:http://localhost:3000/19cd8ce0-40cb-4deb-8b1b-9b951a6fbef7",
      createdDate: new Date(),
    },
    paymentInfo: [
      { id: "1", amount: 1000, date: new Date() },
      { id: "2", amount: 2500, date: new Date() },
      { id: "3", amount: 1000, date: new Date() },
    ],
    imageInfo: [],
    documentInfo: [],
  },
  {
    id: "PO-000441430",
    orderDate: "Dec 20th, 2020",
    productInfo: {
      productName: "Blue Pea Powder",
      productThumb: product1,
      productSKU: "BL003 DRESS01",
      productASIN: "B09ABC2327",
      amazonIntegrated: true,
    },
    supplierLogo: supplier1,
    supplierName: "Augo Biotech",
    orderUnits: 6000,
    receivedUnits: 4500,
    receivedPayment: 2500,
    shipmentAgentLogo: freight1,
    shipmentMasterTrackingID: "770532218138",
    shippingMode: "Sea",
    destinationLogo: amazonLogo,
    destinationShippingID: "FBA16YR0BH62",
    destinationType: "Amazon FBA",
    orderStatus: "ON_PROGRESS",
    invoiceInfo: {
      id: "dsjsn_lFpMUcsOwW7654",
      totalCost: "9000",
      invoiceNumber: "JHJGPUG",
      invoiceFile: {
        lastModified: 1644413968296,
        lastModifiedDate: new Date(),
        name: "6002VB0001766001.pdf",
        size: 77774,
        type: "application/pdf",
        webkitRelativePath: "",
      },
      invoiceSRC:
        "blob:http://localhost:3000/19cd8ce0-40cb-4deb-8b1b-9b951a6fbef7",
      createdDate: new Date(),
    },
    paymentInfo: [
      { id: "1", amount: 1250, date: new Date() },
      { id: "2", amount: 1250, date: new Date() },
    ],
    imageInfo: [],
    documentInfo: [],
  },
  {
    id: "PO-000441431",
    orderDate: "Jan 9th, 2020",
    productInfo: {
      productName: "Mullein capsules",
      productThumb: product1,
      productSKU: "BL003 DRESS04",
      productASIN: "B09ABC2367",
      amazonIntegrated: true,
    },
    supplierLogo: supplier1,
    supplierName: "Augo Biotech",
    orderUnits: 5000,
    receivedUnits: 5000,
    receivedPayment: 9000,
    shipmentAgentLogo: freight1,
    shipmentMasterTrackingID: "770532218138",
    shippingMode: "Land",
    destinationLogo: amazonLogo,
    destinationShippingID: "FBA16YR0BH62",
    destinationType: "Amazon FBA",
    orderStatus: "CLOSED",
    invoiceInfo: {
      id: "dsjsn_lFpMUcsOwW43121",
      totalCost: "12000",
      invoiceNumber: "JHJGPUG",
      invoiceFile: {
        lastModified: 1644413968296,
        lastModifiedDate: new Date(),
        name: "6002VB0001766001.pdf",
        size: 77774,
        type: "application/pdf",
        webkitRelativePath: "",
      },
      invoiceSRC:
        "blob:http://localhost:3000/19cd8ce0-40cb-4deb-8b1b-9b951a6fbef7",
      createdDate: new Date(),
    },
    paymentInfo: [
      { id: "1", amount: 6000, date: new Date() },
      { id: "2", amount: 1250, date: new Date() },
      { id: "3", amount: 1750, date: new Date() },
    ],
    imageInfo: [],
    documentInfo: [],
  },
  {
    id: "PO-000441490",
    orderDate: "Sep 11th, 2020",
    productInfo: {
      productName: "Sea Moss Gummies",
      productThumb: product1,
      productSKU: "SKU 346722",
      productASIN: "ASIN 462831",
      amazonIntegrated: true,
    },
    supplierLogo: supplier1,
    supplierName: "Augo Biotech",
    orderUnits: 5000,
    receivedUnits: 3500,
    receivedPayment: 4500,
    shipmentAgentLogo: freight1,
    shipmentMasterTrackingID: "770532218138",
    shippingMode: "Air",
    destinationLogo: amazonLogo,
    destinationShippingID: "FBA16YR0BH62",
    destinationType: "Amazon FBA",
    orderStatus: "PENDING",
    invoiceInfo: {
      id: "dsjsn_lFpMUcsOwW1234",
      totalCost: "8000",
      invoiceNumber: "JHJGPUG",
      invoiceFile: {
        lastModified: 1644413968296,
        lastModifiedDate: new Date(),
        name: "6002VB0001766001.pdf",
        size: 77774,
        type: "application/pdf",
        webkitRelativePath: "",
      },
      invoiceSRC:
        "blob:http://localhost:3000/19cd8ce0-40cb-4deb-8b1b-9b951a6fbef7",
      createdDate: new Date(),
    },
    paymentInfo: [{ id: "1", amount: 4500, date: new Date() }],
    imageInfo: [],
    documentInfo: [],
  },
];
