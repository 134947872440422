import React, { useState, useCallback, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Badge } from "react-bootstrap";

import { config } from "../../../../connector/config";
import { orderRequest } from "../../../../connector/httpConnector/httpRequest";
import { useStateValue } from "../../../../store/selectors/useStateValue";

import PaymentSumary from "./PaymentsSummary";
import PaymentRow from "./PaymentRow";


const Payments = () => {
  const { baseURL, businessOrders } = config;
  const { auth } = useStateValue();
  const [paymentsData, setPaymentsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get("page")) || 1
  );
  const token = auth.auth.accessToken;
  const [sumary,setSumary] = useState()
  const [appSettings, setAppSettings] = useState()

  return (
    <>
      <div className="row">
        <PaymentSumary sumary={sumary} appSettings={appSettings} />
      </div>
      <div className="col-12">
        <PaymentRow setSumary={setSumary} setAppSettings={setAppSettings} />
      </div>
    </>
  );
};

export default Payments;
