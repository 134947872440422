import React from "react";
import { Pagination } from "react-bootstrap";

const Paginator = ({ total, current, onChangePage }) => {
  return (
    <div className="paginator">
      <Pagination>
        <Pagination.Prev
          className="prev"
          key="prev"
          onClick={() => onChangePage(current - 1)}
          disabled={current === 1}
        >
          Prev
        </Pagination.Prev>

        <Pagination.Next
          className="next"
          key="next"
          onClick={() => onChangePage(current + 1)}
          disabled={(total === current)}
        >
          Next
        </Pagination.Next>
      </Pagination>
    </div>
  );
};

export default Paginator;