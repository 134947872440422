import React from 'react'
import { getShippingIcon } from './TrackingCard'

const TrackingUnavailable = ({ shippingMode }) => {
  return (
    <div className="d-flex align-items-center project-image mb-4">
      <div className="circle-icon">
        <span className="ms-0 me-3">
          <i className={`fas ${getShippingIcon(shippingMode)}`}></i>
        </span>
      </div>
      <div>
        <h3 className="fs-16 text-black font-w600 mb-0">Shipping Mode</h3>
        <span className="d-block fs-12 font-w500">{shippingMode}</span>
      </div>
    </div>
  )
}

export default TrackingUnavailable
