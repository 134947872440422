import React, { useEffect, useCallback, useState } from "react";
import ProfileCard from "../molecules/ProfileCard/ProfileCard";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedClientIdAction } from "../../../store/actions/clientActions";
import { useFetchClients } from "../../../data/suppliers-data";
import LoadingSpinner from "../elements/LoadingSpinner/LoadingSpinner";
import Paginator from "../elements/Paginator/Paginator";

const Clients = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(parseInt(new URLSearchParams(window.location.search).get("page")) || 1);
  const [totalPages, setTotalPages] = useState(0);
  const [clientsData, setClientsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState();

  const clientsFetchData = useFetchClients(
    {
      currPage: page,
      perPage: 12,
      search: searchQuery,
    }
  );

  const handleSelectClient = (id) => {
    const newClientData = [...clientsData];
    const selectedClient = newClientData.find(
      (client) => client.id === id
    );
    dispatch(setSelectedClientIdAction(selectedClient.id));
  };

  useEffect(
    () => {
      if (clientsFetchData.isLoading === false) {
        if (clientsFetchData.status === 'success') {
          const fetchedClients = clientsFetchData.data.data.clients;
          setClientsData(fetchedClients);
          setTotalPages(clientsFetchData?.data?.data?.totalPages)
        }
        if (clientsFetchData.isError) {
          setClientsData([]);
        }
      }
    }, [clientsFetchData.data, clientsFetchData.isLoading, clientsFetchData.status, clientsFetchData.isError]
  )

  const handleChangePage = useCallback((page) => {
    setPage(page);
  }, []);

  return (
    <>
      {clientsFetchData.isLoading ?
        (
          <LoadingSpinner size={150} />
        ) : (
          <div>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="input-group contacts-search mb-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <span className="input-group-text">
                  <Link to={"#"}>
                    <i className="flaticon-381-search-2"></i>
                  </Link>
                </span>
              </div>
            </div>
            <div className="row">
              {clientsData.map((client) => {
                return (
                  <ProfileCard
                    key={client.id}
                    profileName={client.name}
                    profileType="Client"
                    profileImage={client.profileImage}
                    profileLocation={client.location}
                    profileEmail={client.email}
                    profilePhone={client.phoneNumber}
                    profileProducts={client.products}
                    link2={`/client-profile/${client.id}`}
                    onClick2={() => handleSelectClient(client.id)}
                  />
                );
              })}
            </div>
            <div className="d-flex justify-content-end align-items-center flex-wrap">
              {totalPages > -1 && (
                <Paginator
                  total={totalPages}
                  current={page}
                  onChangePage={handleChangePage}
                />
              )}
            </div>
          </div>)
      }
    </>
  );
};
export default Clients;
