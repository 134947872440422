import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  SET_USER_TYPE_ACTION,
} from '../actions/AuthActions'

const initialState = {
  auth: {
    id: null,
    name: '',
    email: '',
    role: [''],
    accessToken: '',
    expireIn: {},
  },
  errorMessage: '',
  successMessage: '',
  showLoading: false,
  userType: '',
}

export function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_CONFIRMED_ACTION: {
      return {
        ...state,
        auth: action.payload,
        errorMessage: '',
        successMessage: 'Signup Successfully Completed',
        showLoading: false,
      }
    }

    case LOGIN_CONFIRMED_ACTION: {
      return {
        ...state,
        auth: action.payload,
        errorMessage: '',
        successMessage: 'Login Successfully Completed',
        showLoading: false,
        userType: action.payload.role[0],
      }
    }

    case LOGOUT_ACTION: {
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        auth: {
          id: null,
          name: '',
          email: '',
          role: [''],
          accessToken: '',
          expireIn: {},
        },
      }
    }

    case LOADING_TOGGLE_ACTION: {
      return {
        ...state,
        showLoading: action.payload,
      }
    }

    case SET_USER_TYPE_ACTION: {
      return {
        ...state,
        userType: action.userType,
      }
    }

    case SIGNUP_FAILED_ACTION: {
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: '',
        showLoading: false,
      }
    }

    case LOGIN_FAILED_ACTION: {
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: '',
        showLoading: false,
      }
    }

    default:
      return state
  }
}
