import React from "react";
import swal from "sweetalert";
import { Badge, Button } from "react-bootstrap";
import { useDeleteSellerAccount } from "../../../../../data/account-data";
import { onConfirmSwal } from "../../../utils/onConfirmSwal";

const SellerAccountCard = ({ sellerAccountsData, fetchSellerAccount }) => {

  const deleteOrderDocument = useDeleteSellerAccount();

  // Delete image
  const handleDeleteAccount = async (accountId) => {

    try {
      const request = await deleteOrderDocument.mutateAsync({ accountId: accountId })
      if (request) {
        fetchSellerAccount()
        swal("Good job!", "Integration successfully deleted", "success");
      }
      else
        swal("Oops", "Something went wrong!", "error");
    } catch (error) {
      swal("Oops", JSON.parse(error.request.response)?.message, "error");
    }
  };

  const getRegion = (regionCode) => {
    switch (regionCode) {
      case "na": {
        return "North America";
      }
      case "eu": {
        return "Europe";
      }
      case "fe": {
        return "Far East";
      }
      case "in": {
        return "India";
      }
      default:
        break;
    }
  };

  return (
    <>
      {sellerAccountsData?.map((sellerAccount) => (
        <div key={sellerAccount.id} className="col-xl-6 col-sm-6">
          <div className="card">
            <div className="card-body d-flex px-4  justify-content-between">
              <div>
                <div className="">
                  <h2 className="fs-20 font-w700">{sellerAccount.sellerId}</h2>
                  <span className="fs-18 font-w500 d-block">
                    {getRegion(sellerAccount.regionCode)}
                  </span>
                  <span className="d-block fs-12 font-w400">
                    <i className="fab fa-amazon me-2 scale3"></i>Amazon Seller
                    Account
                  </span>
                </div>
              </div>
            </div>
            <div className="card-footer d-flex justify-content-between align-items-center flex-wrap">
              <Button
                variant="danger"
                className="btn btn-xs btn-danger btn-rounded"
                // className="mb-2 me-2"

                onClick={() => onConfirmSwal("Amazon Integration", () => handleDeleteAccount(sellerAccount.id))}
              >
                <i className="fa fa-trash"></i>
              </Button>
              <Badge
                variant={`${sellerAccount.active ? "success" : "danger"
                  } badge-md light`}>
                {sellerAccount.active ? "Active" : "Disabled"}
              </Badge>
            </div>
          </div>
        </div >
      ))}
    </>
  );
};

export default SellerAccountCard;
