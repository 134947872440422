import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { nonPaginatedDataRequest } from '../../../connector/httpConnector/httpRequest'
import { config } from '../../../connector/config'
import { useStateValue } from '../../../store/selectors/useStateValue'
import { Modal, Dropdown, Badge, Spinner } from 'react-bootstrap'
import swal from 'sweetalert'
import { setProductsAction } from '../../../store/actions/ProductActions'
import { useDispatch } from 'react-redux'
import AddProductForm, { initialFormState } from '../customForms/AddProductForm'
import AddOrderFormTwo from '../customForms/AddOrderFormTwo'
import { onDeleteSwal } from '../utils/onDeleteSwal'
import LoadingSpinner from '../elements/LoadingSpinner/LoadingSpinner'
import Paginator from '../elements/Paginator/Paginator'
import { round } from 'lodash'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import {
  useAddProduct,
  useFetchProducts,
  useHandleGetProduct,
  useHandleUploadProductDesign,
  useHandleUploadProductDoc,
  useHandleUploadProductImage,
  useUpdateProduct,
  useDeleteProductEditDesign,
  useDeleteProductEditImage,
  useDeleteProductEditDocuments,
} from '../../../data/product-data'
import { useFormik } from 'formik'
import { useSaveNewOrder } from '../../../data/orders-data'
import moment from 'moment'
import { useFetchProfile } from '../../../data/account-data'

const __int = (val) => {
  if (val === undefined || val === null) {
    return null
  }
  if (typeof val === 'string' && val.trim() === '') {
    return ''
  }
  return Number(val)
}

const __isUrl = (str) => {
  try {
    let url = new URL(str)
  } catch (_) {
    return false
  }
}
const Products = () => {
  const dispatch = useDispatch()
  const { baseURL, adminGetUsers, getSellerAccountsList } = config
  const history = useHistory()
  const location = useLocation()
  const { auth } = useStateValue()
  const [showGuide, setShowGuide] = useState(false)
  const [orderAddCard, setOrderAddCard] = useState(false)
  const handleSaveNewOrder = useSaveNewOrder()
  const fetchProfile = useFetchProfile()
  const [addCard, setAddCard] = useState(false)
  const [productsData, setProducts] = useState([])
  const [integratedProductsData, setIntegratedProductsData] = useState()
  const [productSearchQuery, setProductSearchQuery] = useState(
    new URLSearchParams(window.location.search).get('search') || undefined
  )
  const token = auth.auth.accessToken
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get('page')) || 1
  )
  let { id } = useParams()
  const [sort, setSort] = useState([
    [
      new URLSearchParams(window.location.search).get('sort[0][0]') ||
        'createdAt',
      new URLSearchParams(window.location.search).get('sort[0][1]') || 'desc',
    ],
  ])
  // edit  data
  const [editFormData, setEditFormData] = useState(initialFormState)
  //Edit Modal
  const [editModal, setEditModal] = useState(false)

  // Edit function editable page loop
  const [editProductId, setEditProductId] = useState(null)
  const [docFile, setDocFile] = useState()
  const [imageFile, setImageFile] = useState()
  const [designFile, setDesignFile] = useState()

  const MAX_CHAR_LENGTH = 75
  const addProductData = useAddProduct()
  const getProductData = useHandleGetProduct()
  const uploadDocFileData = useHandleUploadProductDoc()
  const uploadImageFileData = useHandleUploadProductImage()
  const uploadDesignFileData = useHandleUploadProductDesign()
  const updateProductData = useUpdateProduct()
  const DeleteProductEditDocumentsData = useDeleteProductEditDocuments({
    onSuccess: () =>
      getProductData.mutate({ productId: id, documentsId: document.id }),
  })
  const DeleteProductEditDesignData = useDeleteProductEditDesign({
    onSuccess: () =>
      getProductData.mutate({ productId: id, designsId: document.id }),
  })
  const DeleteProductEditImagesData = useDeleteProductEditImage({
    onSuccess: () =>
      getProductData.mutate({ productId: id, imagesId: document.id }),
  })
  const productsQueryData = useFetchProducts({
    currPage: page,
    perPage: 12,
    sort,
    search: productSearchQuery,
  })
  const [sellerAccountsData, setSellerAccountsData] = useState()

  const fetchSellerAccount = useCallback(() => {
    nonPaginatedDataRequest(baseURL, token, getSellerAccountsList)
      .then((data) => {
        setSellerAccountsData(data.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [baseURL, getSellerAccountsList, token])

  const redirectToAmazonSellerCentral = () => {
    const redirectToAmazonSellerCentralUrl = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.25ab9c5d-6391-442d-a7d1-618f6764749c&state=na&version=beta`
    window.location.href = redirectToAmazonSellerCentralUrl
  }

  const editProductFormik = useFormik({
    initialValues: editFormData,
    onSubmit: (values) => {
      const editedProductData = {
        productName: values.productName,
        productThumb: values.productThumb,
        productASIN: values.productASIN,
        productWidth: __int(values.productWidth),
        productHeight: __int(values.productHeight),
        productLength: __int(values.productLength),
        productWeight: __int(values.productWeight),
        productSize: values.productSize,
        productManufacturingCost: __int(values.productManufacturingCost),
        shippingModeAirCost: __int(values.shippingModeAirCost),
        shippingModeSeaCost: __int(values.shippingModeSeaCost),
        shippingModeLandCost: __int(values.shippingModeLandCost),
        productManufacturingDays: __int(values.productManufacturingDays),
        fastestShippingDays: __int(values.fastestShippingDays),
        shippingHandlingDays: __int(values.shippingHandlingDays),
        myWarehouseStock: __int(values.myWarehouseStock),
        dailySales: __int(values.dailySales),
        amazonIntegrated: values.amazonIntegrated,
      }
      let keys = Object.keys(editedProductData)
      keys.forEach((key, i) => {
        const val = editedProductData[key]
        if (val === undefined || val === null) {
          delete editedProductData[key]
        }
        if (__isUrl(val)) {
          delete editedProductData[key]
        }
        if (val === '') {
          delete editedProductData[key]
        }
      })
      if (editedProductData['productHeight'] !== undefined) {
        editedProductData['productHeightUnit'] = 'cm'
      }
      if (editedProductData['productLength'] !== undefined) {
        editedProductData['productLengthUnit'] = 'cm'
      }
      if (editedProductData['productWidth'] !== undefined) {
        editedProductData['productWidthUnit'] = 'cm'
      }
      if (editedProductData['productWeight'] !== undefined) {
        editedProductData['productWeightUnit'] = 'cm'
      }

      if (editedProductData.amazonIntegrated === true) {
        delete editedProductData.productASIN
        delete editedProductData.productSKU
        delete editedProductData.productName
        delete editedProductData.productThumb
        delete editedProductData.dailySales
      }
      delete editedProductData.amazonIntegrated
      const newFormData = new FormData()
      keys = Object.keys(editedProductData)
      keys.forEach((key, i) => {
        const val = editedProductData[key]
        newFormData.append(key, val)
      })
      updateProductData.mutate({ productId: editProductId, data: newFormData })
    },
    enableReinitialize: true,
  })

  useEffect(() => {
    fetchSellerAccount()
    if (!updateProductData.isLoading) {
      if (updateProductData.isSuccess) {
        swal('Good job!', 'Successfully Updated', 'success')
      }
      if (updateProductData.isError) {
        swal('Oops', updateProductData.error['response'].data.message, 'error')
      }
      updateProductData.reset()
    }
  }, [
    updateProductData.data,
    updateProductData.isLoading,
    updateProductData.isSuccess,
    updateProductData.isError,
  ])

  useEffect(() => {
    if (productsQueryData.isSuccess) {
      let data = productsQueryData.data.data.data
      setIntegratedProductsData(data)
      setProducts(data.products)
      dispatch(setProductsAction(data.products))
    } else {
      setIntegratedProductsData({})
      setProducts([])
      dispatch(setProductsAction([]))
    }
  }, [
    productsQueryData.data,
    productsQueryData.isLoading,
    productsQueryData.isError,
    productsQueryData.isSuccess,
  ])

  const handleSearchQuery = (value) => {
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.set('search', value)
    window.history.pushState(null, null, `?${queryParams.toString()}`)
    setProductSearchQuery(value)
    setPage(1)
  }

  const addNewOrderFormik = useFormik({
    initialValues: {
      orderDate: moment().format('YYYY-MM-DD'),
      orderUnits: '',
      shippingMode: '',
      supplierId: '',
      warehouseId: '',
      destinationType: 'Amazon FBA',
    },
    onSubmit: (values) => {
      let error = false
      let errorMsg = ''
      if (values.orderUnits === '') {
        error = true
        errorMsg = 'Please fill number of units.'
      } else if (values.shippingMode === '') {
        error = true
        errorMsg = 'Please select shipping mode'
      } else if (
        values.destinationType === 'My Warehouse' &&
        values.warehouseId === ''
      ) {
        error = true
        errorMsg = 'Please select destination'
      } else if (
        values.destinationType === 'Amazon FBA' &&
        values.supplierId === ''
      ) {
        error = true
        errorMsg = 'Please select supplier'
      }
      if (!error) {
        let newOrder = {
          productId: newOrderProduct.id,
          orderUnits: values.orderUnits,
          shippingMode: values.shippingMode,
          destinationType: values.destinationType,
        }

        if (values.destinationType === 'My Warehouse') {
          newOrder.warehouseId = values.warehouseId
        }
        if (values.destinationType === 'Amazon FBA') {
          newOrder.supplierId = values.supplierId
        }
        addNewOrderFormik.resetForm()
        handleSaveNewOrder.mutate({ ...newOrder })
      } else {
        swal('Oops', errorMsg, 'error')
      }
    },
  })

  const [newOrderProduct, setNewOrderProduct] = useState()

  const navigateToPage = (event, id) => {
    event.preventDefault()
    history.push({
      pathname: `/product-detail/${id}`,
      state: { pageNumber: page },
    })
  }

  //For Image upload in ListBlog
  const [file, setFile] = useState(null)
  const [objectURL, setObjectURL] = useState(null)

  const fileHandler = (e) => {
    const selectedFile = e.target.files[0]
    setFile(selectedFile)

    if (objectURL) {
      URL.revokeObjectURL(objectURL) // Release the previous object URL
    }

    setTimeout(() => {
      let src = URL.createObjectURL(selectedFile)
      setObjectURL(src)
      addFormData.productThumb = src
    }, 0)
  }

  //Add data
  const [addFormData, setAddFormData] = useState(initialFormState)

  // Add Product function
  const handleAddFormChange = (event) => {
    event.preventDefault()
    const fieldName = event.target.getAttribute('name')
    const fieldValue = event.target.value
    const newFormData = { ...addFormData }
    newFormData[fieldName] = fieldValue
    setAddFormData(newFormData)
  }

  //Add Submit data
  const handleAddFormSubmit = async (event) => {
    event.preventDefault()
    let error = false
    let errorMsg = ''
    if (addFormData.productName === '') {
      error = true
      errorMsg = 'Please fill Name'
    } else if (addFormData.productSKU === '') {
      error = true
      errorMsg = 'Please fill profile.'
    } else if (addFormData.productThumb === '') {
      error = true
      errorMsg = 'Please attached image'
    }
    if (!error) {
      const productData = new FormData()
      productData.append('productName', addFormData.productName)
      productData.append('productSKU', addFormData.productSKU)
      productData.append('productThumb', file)
      addProductData.mutate({ newProduct: productData })
    } else {
      swal('Oops', errorMsg, 'error')
    }
  }

  useEffect(() => {
    if (!addProductData.isLoading) {
      if (addProductData.isSuccess === true) {
        addProductData.reset()
        setAddCard(false)
        swal('Good job!', 'Successfully Added', 'success')
      }
      if (addProductData.isError === true) {
        addProductData.reset()
        setAddCard(false)
        swal('Oops', 'Something went wrong', 'error')
      }
      addFormData.name = addFormData.productSKU = addFormData.productThumb = ''
    }
  }, [
    addProductData.data,
    addProductData.isLoading,
    addProductData.isSuccess,
    addProductData.isError,
  ])

  // Edit function button click to edit
  const handleEditClick = (event, product) => {
    event.preventDefault()
    // setEditProductId(product.id);
    // forgetGetProduct();
    getProductData.mutate({ productId: product.id })
    setEditFormData({})
    setEditProductId(null)
  }

  useEffect(() => {
    if (!getProductData.isLoading) {
      if (getProductData.isSuccess) {
        let product = getProductData.data.data.data
        const formValues = {
          productName: product.productName,
          productSKU: product.productSKU,
          productThumb: product.productThumb,
          productASIN: product.productASIN,
          productWidth: product.productWidth,
          productHeight: product.productHeight,
          productLength: product.productLength,
          productWeight: product.productWeight,
          productSize: product.productSize,
          productManufacturingCost: product.productManufacturingCost,
          shippingModeAirCost: product.shippingModeAirCost,
          shippingModeSeaCost: product.shippingModeSeaCost,
          shippingModeLandCost: product.shippingModeLandCost,
          productManufacturingDays: product.productManufacturingDays,
          fastestShippingDays: product.fastestShippingDays,
          shippingHandlingDays: product.shippingHandlingDays,
          myWarehouseStock: product.myWarehouseStock,
          dailySales: product.dailySales,
          Documents: product.Documents,
          Designs: product.Designs,
          Images: product.Images,
          amazonIntegrated: product.amazonIntegrated,
        }
        setEditFormData(formValues)
        setEditModal(true)
        setEditProductId(product.id)
      }
    }
  }, [
    getProductData.data,
    getProductData.isLoading,
    getProductData.isSuccess,
    getProductData.isError,
  ])

  const handleSortBy = async (sortBy) => {
    let newsort
    switch (sortBy) {
      case 'latest':
        newsort = [['createdAt', 'desc']]
        break
      case 'oldest':
        newsort = [['createdAt', 'asc']]
        break
      case 'a-z':
        newsort = [['productSKU', 'asc']]
        break
      case 'z-a':
        newsort = [['productSKU', 'desc']]
        break
      default:
        break
    }
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.set('sort[0][0]', newsort[0][0])
    queryParams.set('sort[0][1]', newsort[0][1])
    window.history.pushState(null, null, `?${queryParams.toString()}`)
    setSort(newsort)
  }

  const getSortAttr = () => {
    if (sort[0][0] === 'createdAt' && sort[0][1] === 'desc') {
      return 'latest'
    }
    if (sort[0][0] === 'createdAt' && sort[0][1] === 'asc') {
      return 'oldest'
    }
    if (sort[0][0] === 'productSKU' && sort[0][1] === 'asc') {
      return 'a-z'
    }
    if (sort[0][0] === 'productSKU' && sort[0][1] === 'desc') {
      return 'z-a'
    }
  }

  const handleChangePage = useCallback(
    (page) => {
      setPage(page)
      const params = new URLSearchParams(window.location.search)
      params.set('page', page)
      window.history.pushState(
        {},
        '',
        `${location.pathname}?${params.toString()}`
      )
    },
    [location.pathname]
  )

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const pageParam = params.get('page')
    setPage(pageParam ? parseInt(pageParam) : 1)
  }, [])

  useEffect(() => {
    const handlePopState = () => {
      const params = new URLSearchParams(window.location.search)
      const pageParam = params.get('page')
      setPage(pageParam ? parseInt(pageParam) : 1)
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  const handleUploadDocFile = () => {
    const newDocData = new FormData()
    newDocData.append('productDocFile', docFile)
    uploadDocFileData.mutate({ productId: editProductId, data: newDocData })
  }

  const handleUploadImageFile = () => {
    const newImageData = new FormData()
    newImageData.append('productImageFile', imageFile)
    uploadImageFileData.mutate({ productId: editProductId, data: newImageData })
  }

  const handleUploadDesignFile = () => {
    const newDesignData = new FormData()
    newDesignData.append('productDesignFile', designFile)
    uploadDesignFileData.mutate({
      productId: editProductId,
      data: newDesignData,
    })
  }

  useEffect(() => {
    if (uploadDesignFileData.isSuccess) {
      getProductData.mutate({ productId: editProductId })
      uploadDesignFileData.reset()
      setDesignFile(null)
    }
  }, [
    uploadDesignFileData.data,
    uploadDesignFileData.isLoading,
    uploadDesignFileData.isSuccess,
    uploadDesignFileData.isError,
  ])

  useEffect(() => {
    if (uploadImageFileData.isSuccess) {
      getProductData.mutate({ productId: editProductId })
      uploadImageFileData.reset()
      setImageFile(null)
    }
  }, [
    uploadImageFileData.data,
    uploadImageFileData.isLoading,
    uploadImageFileData.isSuccess,
    uploadImageFileData.isError,
  ])

  useEffect(() => {
    if (uploadDocFileData.isSuccess) {
      getProductData.mutate({ productId: editProductId })
      uploadDocFileData.reset()
      setDocFile(null)
    }
  }, [
    uploadDocFileData.data,
    uploadDocFileData.isLoading,
    uploadDocFileData.isSuccess,
    uploadDocFileData.isError,
  ])

  useEffect(() => {
    if (!handleSaveNewOrder.isLoading) {
      setOrderAddCard(false)
      if (handleSaveNewOrder.isSuccess) {
        swal('Good job!', 'Successfully Added', 'success')
      }
      if (handleSaveNewOrder.isError) {
        swal('Oops', 'Something went wrong', 'error')
      }
    }
  }, [
    handleSaveNewOrder.data,
    handleSaveNewOrder.isLoading,
    handleSaveNewOrder.isSuccess,
    handleSaveNewOrder.isError,
  ])
  const handelOrderCardOpen = (event, product) => {
    setOrderAddCard(true)
    setNewOrderProduct(product)
  }

  const handleDeleteDesignEdit = async (productId, designId) => {
    try {
      await DeleteProductEditDesignData.mutateAsync({
        productId: productId,
        designsId: designId,
        data: {},
      })
    } catch (error) {}
  }

  //images delete
  const handleDeleteImageEdit = async (productId, imagesId) => {
    try {
      await DeleteProductEditImagesData.mutateAsync({
        productId: productId,
        imagesId: imagesId,
        data: {},
      })
    } catch (error) {}
  }

  //Documents delete
  const handleDeleteDocumentsEdit = async (productId, documentsId) => {
    try {
      await DeleteProductEditDocumentsData.mutateAsync({
        productId: productId,
        documentsId: documentsId,
        data: {},
      })
    } catch (error) {}
  }
  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="input-group contacts-search mb-1">
          <input
            type="text"
            value={productSearchQuery}
            onChange={(e) => handleSearchQuery(e.target.value)}
            className="form-control"
            placeholder="Search here..."
          />
          <span className="input-group-text">
            <Link to={'#'}>
              <i className="flaticon-381-search-2"></i>
            </Link>
          </span>
        </div>
        <div className="mb-1">
          <Link
            to={'#'}
            className="btn btn-primary btn-rounded fs-18"
            onClick={() => setAddCard(true)}
          >
            + New Product
          </Link>
          <Link
            to={'#'}
            onClick={() => setShowGuide(true)}
            class="d-block fs-14 user-work text-primary text-center"
          >
            How it works? <i class="fas fa-play-circle"></i>
          </Link>
          <Modal
            className="modal fade"
            show={showGuide}
            onHide={setShowGuide}
            size="lg"
          >
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowGuide(false)}
              ></button>
            </div>
            <div className="card-body">
              <iframe
                width="100%"
                height="420"
                src="https://www.youtube.com/embed/Ao7vfNRn0vI?si=3hszUFts0K3nGpPf&amp;controls=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </Modal>
          <Modal className="modal fade" show={addCard} onHide={setAddCard}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add Product</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setAddCard(false)}
              ></button>
            </div>
            <form>
              <AddProductForm
                onChangeFile={fileHandler}
                file={file}
                onProductNameChange={handleAddFormChange}
                onProductASINChange={handleAddFormChange}
                onClickSubmit={handleAddFormSubmit}
                onClickCancel={() => setAddCard(false)}
                loading={addProductData.isLoading}
              />
            </form>
          </Modal>
          <Modal
            className="modal fade"
            show={editModal}
            onHide={setEditModal}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Edit Product</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setEditModal(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={editProductFormik.handleSubmit}>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box edit_product_form">
                  <div className="add-contact-content">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="image-placeholder mb-4">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              // onChange={fileHandler}
                              id="imageUpload"
                              name="productThumb"
                              // onClick={(event) => setFile(event.target.value)}
                              onChange={(event) =>
                                editProductFormik.setFieldValue(
                                  'productThumb',
                                  event.currentTarget.files[0]
                                )
                              }
                            />
                            <label htmlFor="imageUpload" name=""></label>
                          </div>
                          <div className="avatar-preview">
                            <div id="imagePreview">
                              {editProductFormik.values.productThumb && (
                                <img
                                  id="saveImageFile"
                                  src={
                                    typeof editProductFormik.values
                                      .productThumb === 'object'
                                      ? URL.createObjectURL(
                                          editProductFormik.values.productThumb
                                        )
                                      : editProductFormik.values.productThumb
                                  }
                                  alt={
                                    editProductFormik.values.productThumb
                                      ? editProductFormik.values.productThumb
                                          .name
                                      : null
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 input-primary">
                          <label className="text-black font-w500">
                            Name{typeof editProductFormik.values.productThumb}
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              name="productName"
                              required="required"
                              value={editProductFormik.values.productName}
                              onChange={editProductFormik.handleChange}
                              disabled={
                                editProductFormik.isLoading ||
                                editFormData.amazonIntegrated === true
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group mb-3 input-primary">
                          <label className="text-black font-w500">SKU</label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              name="productSKU"
                              required="required"
                              value={editProductFormik.values.productSKU}
                              onChange={editProductFormik.handleChange}
                              disabled={
                                editProductFormik.isLoading ||
                                editFormData.amazonIntegrated === true
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group mb-3 input-primary">
                          <label className="text-black font-w500">ASIN</label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              name="productASIN"
                              value={editProductFormik.values.productASIN}
                              onChange={editProductFormik.handleChange}
                              disabled={
                                editProductFormik.isLoading ||
                                editFormData.amazonIntegrated === true
                              }
                            />
                          </div>
                        </div>
                        <div className="text-black bg-primary card card--form_element">
                          <div className="card-header">
                            <div className="text-black card-title h5">
                              Dimensions & Weight
                            </div>
                          </div>
                          <div className="mb-0 card-body">
                            <div className="row">
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Width
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      style={{ width: 0 }}
                                      name="productWidth"
                                      value={round(
                                        editProductFormik.values.productWidth,
                                        2
                                      )}
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">cm</span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Height
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      style={{ width: 0 }}
                                      name="productHeight"
                                      value={round(
                                        editProductFormik.values.productHeight,
                                        2
                                      )}
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">cm</span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Length
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      style={{ width: 0 }}
                                      name="productLength"
                                      value={round(
                                        editProductFormik.values.productLength,
                                        2
                                      )}
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">cm</span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Weight
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      style={{ width: 0 }}
                                      name="productWeight"
                                      value={round(
                                        editProductFormik.values.productWeight,
                                        2
                                      )}
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      lbs
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-black bg-primary card card--form_element">
                          <div className="card-header">
                            <div className="text-black card-title h5">
                              Manufacturing
                            </div>
                          </div>
                          <div className="mb-0 card-body">
                            <div className="row">
                              <div className="form-group col-md-6 mb-4">
                                <label className="text-black font-w500">
                                  Cost per unit
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <span className="input-group-text">$</span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      style={{ width: 0 }}
                                      name="productManufacturingCost"
                                      value={
                                        editProductFormik.values
                                          .productManufacturingCost
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={
                                        updateProductData.isLoading ||
                                        editFormData.amazonIntegrated
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-black bg-primary card card--form_element">
                          <div className="card-header">
                            <div className="text-black card-title h5"></div>
                          </div>
                          <div className="mb-0 card-body">
                            <div className="row">
                              <div className="form-group col-md-6 mb-4 p-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-black bg-primary card card--form_element">
                          <div className="card-header">
                            <div className="text-black card-title h5">
                              Shipping Cost
                            </div>
                          </div>
                          <div className="mb-0 card-body">
                            <div className="row">
                              <div className="form-group col-md-4 mb-4">
                                <label className="text-blawhiteck font-w500">
                                  Air
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <span className="input-group-text">$</span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      style={{ width: 0 }}
                                      name="shippingModeAirCost"
                                      value={
                                        editProductFormik.values
                                          .shippingModeAirCost
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Sea
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <span className="input-group-text">$</span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      style={{ width: 0 }}
                                      name="shippingModeSeaCost"
                                      value={
                                        editProductFormik.values
                                          .shippingModeSeaCost
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Land
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <span className="input-group-text">$</span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      style={{ width: 0 }}
                                      name="shippingModeLandCost"
                                      value={
                                        editProductFormik.values
                                          .shippingModeLandCost
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-black border_primary card card--form_element no_bg">
                          <div className="card-header">
                            <div className="text-black card-title h5">
                              Attachments
                            </div>
                          </div>
                          <div className="mb-0 card-body">
                            <div className="form-group mb-4">
                              <div>
                                {editProductFormik.values.Designs?.map(
                                  (doc) => (
                                    <div
                                      key={doc.id}
                                      className="d-flex align-items-center mb-2"
                                    >
                                      <button
                                        type="button"
                                        className="btn mr-2"
                                        style={{ color: '#FC2E53', padding: 4 }}
                                        onClick={() =>
                                          onDeleteSwal('file', () =>
                                            handleDeleteDesignEdit(
                                              doc.productId,
                                              doc.id
                                            )
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-minus-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <Badge
                                        pill
                                        variant="primary"
                                        className="mb-1 mx-1"
                                      >
                                        {(() => {
                                          let url_arr = doc.fileSource
                                            .split('?')[0]
                                            .split('/')
                                          let fname =
                                            url_arr[url_arr.length - 1]
                                          return fname.slice(-50)
                                        })()}
                                      </Badge>
                                    </div>
                                  )
                                )}
                              </div>
                              <label className="text-black font-w500">
                                Designs
                              </label>
                              <div className="contact-name">
                                <div className="input-group mb-3">
                                  <div className="form-file">
                                    <input
                                      type="file"
                                      className="custom-file-input form-control"
                                      onChange={(event) => {
                                        setDesignFile(
                                          event.currentTarget.files[0]
                                        )
                                      }}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    onClick={handleUploadDesignFile}
                                    disabled={
                                      uploadDesignFileData.isLoading ||
                                      !designFile
                                    }
                                  >
                                    {uploadDesignFileData.isLoading && (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                      ></Spinner>
                                    )}
                                    <span className="ms-1">Upload</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-4">
                              <div>
                                {editProductFormik.values.Images?.map((doc) => (
                                  <div
                                    key={doc.id}
                                    className="d-flex align-items-center mb-2"
                                  >
                                    <button
                                      type="button"
                                      className="btn mr-2"
                                      style={{ color: '#FC2E53', padding: 4 }}
                                      onClick={() =>
                                        onDeleteSwal('file', () =>
                                          handleDeleteImageEdit(
                                            doc.productId,
                                            doc.id
                                          )
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-minus-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <Badge
                                      pill
                                      variant="primary"
                                      className="mb-1 mx-1"
                                    >
                                      {(() => {
                                        let url_arr = doc.fileSource
                                          .split('?')[0]
                                          .split('/')
                                        let fname = url_arr[url_arr.length - 1]
                                        return fname.slice(-50)
                                      })()}
                                    </Badge>
                                  </div>
                                ))}
                              </div>
                              <label className="text-black font-w500">
                                Images
                              </label>
                              <div className="contact-name">
                                <div className="input-group mb-3">
                                  <div className="form-file">
                                    <input
                                      type="file"
                                      className="custom-file-input form-control"
                                      onChange={(event) => {
                                        setImageFile(
                                          event.currentTarget.files[0]
                                        )
                                      }}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    onClick={handleUploadImageFile}
                                    disabled={
                                      uploadImageFileData.isLoading ||
                                      !imageFile
                                    }
                                  >
                                    {uploadImageFileData.isLoading && (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                      ></Spinner>
                                    )}
                                    <span className="ms-1">Upload</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-4">
                              <div>
                                {editProductFormik.values.Documents?.map(
                                  (doc) => (
                                    <div
                                      key={doc.id}
                                      className="d-flex align-items-center mb-2"
                                    >
                                      <button
                                        type="button"
                                        className="btn mr-2" // Added mr-2 class
                                        style={{ color: '#FC2E53', padding: 4 }}
                                        onClick={() =>
                                          onDeleteSwal('file', () =>
                                            handleDeleteDocumentsEdit(
                                              doc.productId,
                                              doc.id
                                            )
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-minus-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <Badge
                                        pill
                                        variant="primary"
                                        className="mb-1 mx-1"
                                      >
                                        {(() => {
                                          let url_arr = doc.fileSource
                                            .split('?')[0]
                                            .split('/')
                                          let fname =
                                            url_arr[url_arr.length - 1]
                                          return fname.slice(-50)
                                        })()}
                                      </Badge>
                                    </div>
                                  )
                                )}
                              </div>
                              <label className="text-black font-w500">
                                Documents
                              </label>
                              <div className="contact-name">
                                <div className="input-group mb-3">
                                  <div className="form-file">
                                    <input
                                      type="file"
                                      className="custom-file-input form-control"
                                      onChange={(event) => {
                                        setDocFile(event.currentTarget.files[0])
                                      }}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    onClick={handleUploadDocFile}
                                    disabled={
                                      uploadDocFileData.isLoading || !docFile
                                    }
                                  >
                                    {uploadDocFileData.isLoading && (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                      ></Spinner>
                                    )}
                                    <span className="ms-1">Upload</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-black bg-primary card card--form_element">
                          <div className="card-header">
                            <div className="text-black card-title h5">
                              Inventory Settings
                            </div>
                          </div>
                          <div className="mb-0 card-body">
                            <p className="text-black">
                              This section helps to notify when the next batch
                              need to be ordered.
                            </p>
                            <div className="row">
                              <div className="form-group col-md-12 mb-4">
                                <label className="text-black font-w500">
                                  My Warehouse Stock
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="myWarehouseStock"
                                      style={{ width: 0 }}
                                      value={
                                        editProductFormik.values
                                          .myWarehouseStock
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      Units
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <label className="text-text-black font-w500">
                                  My Average Sales(Non-Amazon)
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="dailySales"
                                      style={{ width: 0 }}
                                      value={
                                        editProductFormik.values.dailySales
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      Units
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-6 mb-4">
                                <label className="text-text-black font-w500">
                                  Manufacturing Time
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      style={{ width: 0 }}
                                      name="productManufacturingDays"
                                      value={
                                        editProductFormik.values
                                          .productManufacturingDays || 7
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      Days
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-6 mb-3">
                                <label className="text-black font-w500">
                                  Fastest Shipping Time
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="fastestShippingDays"
                                      style={{ width: 0 }}
                                      value={
                                        editProductFormik.values
                                          .fastestShippingDays || 7
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      Days
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-6 mb-3">
                                <label className="text-black font-w500">
                                  Shipment Handling Time
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      style={{ width: 0 }}
                                      name="shippingHandlingDays"
                                      value={
                                        editProductFormik.values
                                          .shippingHandlingDays || 2
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      Days
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 col-6">
                                <div className="form-check custom-checkbox mb-3 checkbox-success">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckBox1"
                                    required=""
                                    checked
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customCheckBox1"
                                  >
                                    Notify when to order
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {/* { updateProductData.isError &&
                        <Alert variant="danger" 
                        >{updateProductData.error['response'].data.message}
                        </Alert>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={editProductFormik.handleSubmit}
                disabled={updateProductData.isLoading}
              >
                {updateProductData.isLoading && (
                  <Spinner size="sm" as="span" animation="border" />
                )}
                <span className="ms-1">Save</span>
              </button>
              <button
                type="button"
                onClick={() => setEditModal(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Discard
              </button>
            </div>
          </Modal>
          <Modal
            className="modal fade"
            show={orderAddCard}
            onHide={() => setOrderAddCard(false)}
            size="lg"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Create New Order</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOrderAddCard(false)}
              ></button>
            </div>
            <AddOrderFormTwo
              onCloseModal={() => setOrderAddCard(false)}
              onInputChange={addNewOrderFormik.handleChange}
              inputValues={addNewOrderFormik.values}
              onSubmit={addNewOrderFormik.handleSubmit}
              setFieldValue={addNewOrderFormik.setFieldValue}
              selectedProduct={newOrderProduct}
              loading={handleSaveNewOrder.isLoading}
            />
          </Modal>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center flex-wrap">
        <div>
          <Dropdown>
            <Dropdown.Toggle variant="" className="ps-0 mt-1 mb-2 pt-1 pb-1">
              <i className="fas fa-sort-amount-up me-2"></i>Sort by
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                active={getSortAttr() === 'oldest'}
                onClick={() => handleSortBy('oldest')}
              >
                Oldest
              </Dropdown.Item>
              <Dropdown.Item
                active={getSortAttr() === 'latest'}
                onClick={() => handleSortBy('latest')}
              >
                Latest
              </Dropdown.Item>
              <Dropdown.Item
                active={getSortAttr() === 'a-z'}
                onClick={() => handleSortBy('a-z')}
              >
                A - Z
              </Dropdown.Item>
              <Dropdown.Item
                active={getSortAttr() === 'z-a'}
                onClick={() => handleSortBy('z-a')}
              >
                Z - A
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="product-content">
        {!sellerAccountsData?.length && auth.userType === 'business' && (
          <div className="row">
            <div className="col-xl-3 col-xxl-4 col-lg-4 col-md-6 col-sm-6 items">
              <div className="card tryal-gradient">
                <>
                  <div className="card-body tryal row">
                    <span className="mt-0">
                      Link your Amazon Seller Central account with bluudo and
                      streamline your operations. Effortlessly create FBA
                      shipments and live-track your shipments. Additionally,
                      track your orders and inventory levels in real-time with
                      our cutting-edge automated systems.
                    </span>
                    <button
                      onClick={redirectToAmazonSellerCentral}
                      className="btn btn-rounded fs-18 font-w500 bg-white text-black position-relative font-weight-bold "
                      style={{ zIndex: '3' }}
                    >
                      Connect
                    </button>
                  </div>
                </>
              </div>
            </div>
          </div>
        )}
        {productsQueryData.isLoading ? (
          <LoadingSpinner size={150} />
        ) : (
          <div className="row">
            {productsData &&
              productsData.map((product) => {
                const truncatedProductName =
                  product.productName.length > MAX_CHAR_LENGTH
                    ? `${product.productName.substring(0, MAX_CHAR_LENGTH)}...`
                    : product.productName
                return (
                  <div
                    className="col-xl-3 col-xxl-4 col-lg-4 col-md-6 col-sm-6 items"
                    key={product.id}
                  >
                    <div className="card contact-bx item-content">
                      <div className="card-body user-profile">
                        <div className="image-bx">
                          <Link
                            to={'#'}
                            onClick={(e) => navigateToPage(e, product.id)}
                          >
                            <img
                              src={product.productThumb}
                              alt=""
                              className="rounded-circle"
                            />
                          </Link>
                        </div>
                        <div className="media-body user-meta-info">
                          <h6 className="fs-18 font-w600 my-1">
                            <Link
                              to={'#'}
                              onClick={(e) => navigateToPage(e, product.id)}
                              className="text-black user-name"
                              data-name=""
                            >
                              {truncatedProductName}
                            </Link>
                          </h6>
                          <span className="d-block fs-14 mb-3 user-work">
                            {product.productASIN}
                          </span>
                          <ul>
                            <li>
                              <Link
                                to={'#'}
                                onClick={(event) =>
                                  handelOrderCardOpen(event, product)
                                }
                              >
                                <i className="fas fa-plus"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={'#'}
                                onClick={(event) =>
                                  handleEditClick(event, product)
                                }
                              >
                                <i className="far fa-edit"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

            {fetchProfile?.data?.data?.integrated &&
              productsData.length === 0 && (
                <div>
                  <Spinner
                    className="me-2"
                    size={'sm'}
                    style={{ float: 'left' }}
                    animation="border"
                  ></Spinner>
                  <h6>Products syncing! Expect to see them shortly</h6>
                </div>
              )}
          </div>
        )}
        {integratedProductsData?.totalPages > 1 && (
          <Paginator
            total={integratedProductsData.totalPages}
            current={page}
            onChangePage={handleChangePage}
          />
        )}
      </div>
    </>
  )
}
export default Products
