export const config = {
  baseURL: process.env.REACT_APP_BASE_URL,
  userSignup: process.env.REACT_APP_USER_SIGNUP,
  userLogin: process.env.REACT_APP_USER_LOGIN,
  adminGetUsers: process.env.REACT_APP_USERS_API,
  businessGetProducts: process.env.REACT_APP_BUSINESS_PRODUCTS,
  businessOrders: process.env.REACT_APP_BUSINESS_ORDERS,
  supplierOrders: process.env.REACT_APP_SUPPLIER_ORDERS,
  businessSuppliersList: process.env.REACT_APP_BUSINESS_SUPPLIERS_LIST,
  businessWarehousesList: process.env.REACT_APP_BUSINESS_WAREHOUSES_LIST,
  busniessWarehouse: process.env.REACT_APP_BUSINESS_WAREHOUSE,
  getBillingPayments: process.env.REACT_APP_BILLING_PAYMENTS,
  getSellerAccountsList: process.env.REACT_APP_AMAZON_INTEGRATED_ACCOUNTS,
  businessSupplierLocations: process.env.REACT_APP_BUSINESS_SUPPLIERS_LOCATIONS,
  businessShipments: process.env.REACT_APP_BUSINESS_SHIPMENT,
  supplierLocations: process.env.REACT_APP_SUPPLIER_LOCATIONS,
  businessSuppliersProducts: process.env.REACT_APP_BUSINESS_SUPPLIERS_PRODUCTS_LIST,
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
};
