import { Link } from "react-router-dom";
import { Modal, Dropdown, Badge, Stack, Spinner, Alert, Nav, Tab } from "react-bootstrap";
import AddOrderFormTwo from "../../../customForms/AddOrderFormTwo";
import { useFetchOrders, useSaveNewOrder } from "../../../../../data/orders-data";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const IntroCard = ({
  productThumb,
  productName,
  productSKU,
  productASIN,
  onEditProduct,
  onAssignSupplier,
  onDeleteProduct,
  isDeleteDisabled,
  product,
  isBusinessUser,
}) => {
  const [addCard, setAddCard] = useState(false);
  const [orderAddCard, setOrderAddCard] = useState(false)
  //  const [selectedOrderProductID, setSelectedOrderProductID] = useState("");
  //  const [selectedProduct, setSelectedProduct] = useState();
  const handleSaveNewOrder = useSaveNewOrder();
  // const [selectedDestinationID, setSelectedDestinationID] = useState();
  const { id } = useParams()
  const [selectedDestinationID, setSelectedDestinationID] = useState('');
  //create order
  const addNewOrderFormik = useFormik({
    initialValues: {
      orderDate: moment().format("YYYY-MM-DD"),
      orderUnits: "",
      shippingMode: "",
      supplierId: "",
      warehouseId: "",
      destinationType: "Amazon FBA",
    },
    onSubmit: (values) => {
      let error = false;
      let errorMsg = "";

      if (values.destinationType === "Amazon FBA") {
        if (values.supplierId === "") {
          error = true;
          errorMsg = "Please select a supplier";
        }
      } else if (values.destinationType === "My Warehouse") {
        if (values.warehouseId === "") {
          error = true;
          errorMsg = "Please select a warehouse";
        }
      }

      if (values.orderUnits === "") {
        error = true;
        errorMsg = "Please fill in the number of units.";
      } else if (values.shippingMode === "") {
        error = true;
        errorMsg = "Please select a shipping mode";
      }

      if (!error) {
        const newOrder = {
          productId: id,
          orderUnits: values.orderUnits,
          shippingMode: values.shippingMode,
          destinationType: values.destinationType,
        };

        if (values.destinationType === "Amazon FBA") {
          newOrder['supplierId'] = values.supplierId
        }
        if (values.destinationType === "My Warehouse") {
          newOrder['warehouseId'] = values.warehouseId
        }
        handleSaveNewOrder.mutate({ ...newOrder });
        setOrderAddCard(false);

        values.orderUnits = "";
        values.shippingMode = "";

      } else {
        swal("Oops", errorMsg, "error");
      }
      values.destinationType = "Amazon FBA";
    },
  });

  // Add Submit data
  const handleOrderAddFormSubmit = async (event, defaultSupplierId) => {

  };
  useEffect(() => {
    if (!handleSaveNewOrder.isLoading) {
      if (handleSaveNewOrder.isSuccess) {
        swal("Good job!", "Successfully Added", "success");
      }
      if (handleSaveNewOrder.isError) {
        swal("Oops", "Something went wrong", "error");
      }
    }
  }, [
    handleSaveNewOrder.data,
    handleSaveNewOrder.isLoading,
    handleSaveNewOrder.isSuccess,
    handleSaveNewOrder.isError,
  ])
  const handelOrderCardOpen = (event) => {
    setOrderAddCard(true);
    // setSelectedOrderProductID(parseInt(product.id));
    // setNewOrderProduct(product)
  }
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex flex-wrap">
              <div className="mb-3 col-sm-2">
                <div className="me-3">
                  <img className="img-fluid" src={productThumb} alt="" />
                </div>
              </div>
              <div className="mb-3 col-sm-9">
                <h4 className="fs-24 font-w700">{productName}</h4>
                <span className="d-block fs-22 font-w500">
                  SKU: {productSKU}
                </span>
                <h3 className="d-block fs-18 font-w600">ASIN: {productASIN}</h3>
                <div className="d-flex align-items-center mt-4 flex-wrap">
                  <div className="invite mb-3">
                    {isBusinessUser && (<Link
                      to={"#"}
                      className="btn btn-primary light btn-rounded btn-md me-2 mb-2"
                      onClick={(event) => handelOrderCardOpen(event)}
                    >
                      <i className="fas fa-plus me-3 scale5"></i>Create Order
                    </Link>)}
                    {isBusinessUser && (<Link
                      to={"#"}
                      onClick={onEditProduct}
                      className="btn btn-outline-light btn-rounded btn-md me-2 mb-2"
                    >
                      <i className="far fa-edit me-2 scale2"></i>Edit
                    </Link>)}
                    {isBusinessUser && (<button
                      type="button"
                      className="btn btn-success btn-rounded me-2"
                      onClick={onAssignSupplier}
                    >
                      <span className="btn-icon-start text-success">
                        <i className="fa fa-link color-success"></i>
                      </span>
                      Assign Supplier
                    </button>)}
                    {isBusinessUser && (<button
                      type="button"
                      className="btn btn-danger btn-rounded me-2"
                      onClick={onDeleteProduct}
                      disabled={isDeleteDisabled}
                    >
                      <span className="btn-icon-start text-danger">
                        <i className="fa fa-trash color-danger"></i>
                      </span>
                      Delete Product
                    </button>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="modal fade" show={orderAddCard} onHide={() => setOrderAddCard(false)} size="lg">
        <div className="modal-header">
          <h4 className="modal-title fs-20">Create New Order</h4>
          <button type="button" className="btn-close" onClick={() => setOrderAddCard(false)}></button>
        </div>
        <AddOrderFormTwo
          onCloseModal={() => setOrderAddCard(false)}
          onInputChange={addNewOrderFormik.handleChange}
          inputValues={addNewOrderFormik.values}
          onSubmit={addNewOrderFormik.handleSubmit}
          setFieldValue={addNewOrderFormik.setFieldValue}
          selectedProduct={{ productName, id }}
          loading={handleSaveNewOrder.isLoading}

        />
      </Modal>
    </div>
  );
};

export default IntroCard;
