import axios from 'axios'
import swal from 'sweetalert'
import { loginConfirmedAction, logout } from '../store/actions/AuthActions'
import { config } from '../connector/config'

const { baseURL, userSignup, userLogin } = config

export function signUp(name, email, password, role) {
  const postData = {
    name,
    email,
    password,
    role,
  }
  return axios.post(`${baseURL}${userSignup}`, postData)
}

export function login(email, password, rememberPreference = false) {
  const postData = {
    email,
    password,
  }
  const EXPIRE_IN = rememberPreference ? 7 * 24 * 60 * 60 : 24 * 60 * 60
  return axios
    .post(`${baseURL}${userLogin}`, postData)
    .then((response) => {
      if (response && response.data) {
        saveTokenInLocalStorage(response.data, EXPIRE_IN)
      }
      return response
    })
    .catch((error) => {
      throw error
    })
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case 'EMAIL_EXISTS':
      swal('Oops', 'Email already exists', 'error')
      break
    case 'EMAIL_NOT_FOUND':
      swal('Oops', 'Email not found', 'error', { button: 'Try Again!' })
      break
    case 'INVALID_PASSWORD':
      swal('Oops', 'Invalid Password', 'error', { button: 'Try Again!' })
      break
    case 'USER_DISABLED':
      return 'User Disabled'
    default:
      return ''
  }
}

export function saveTokenInLocalStorage(tokenDetails, EXPIRE_IN) {
  const expirationTime = new Date().getTime() + (EXPIRE_IN + 1) * 1000
  const expireDate = new Date(expirationTime)
  tokenDetails.expireDate = expireDate
  localStorage.setItem('userDetails', JSON.stringify(tokenDetails))
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history))
  }, timer)
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem('userDetails')
  const currentPath = history.location.pathname

  if (
    !tokenDetailsString &&
    !currentPath.includes('/register-business') &&
    !currentPath.includes('/register-supplier')
  ) {
    dispatch(logout(history))
    return
  }

  if (!tokenDetailsString) {
    return
  }

  const tokenDetails = JSON.parse(tokenDetailsString)
  const expireDate = new Date(tokenDetails.expireDate)

  if (isNaN(expireDate)) {
    dispatch(logout(history))
    return
  }

  const todaysDate = new Date()

  if (todaysDate > expireDate) {
    dispatch(logout(history))
    return
  }

  dispatch(loginConfirmedAction(tokenDetails))

  const timer = expireDate.getTime() - todaysDate.getTime()
  runLogoutTimer(dispatch, timer, history)
}
