import React from 'react'
import Status from '../../elements/Status/Status'
import TrackingUnavailableCard from './TrackingUnavailableCard'
import TrackingCard from './TrackingCard'
import AMZReceivingCard from './AMZReceivingCard'
import AMZCreateLabelCard from './AMZCreateLabelCard'
import PrivateDestination from './PrivateDestination'
import { formatDate } from '../../utils/formatDate'
import { Link } from 'react-router-dom'

const OrderCard = ({
  Boxes,
  orderId,
  poNumber,
  orderDate,
  productThumb,
  productName,
  productSKU,
  productASIN,
  supplierLogo,
  supplierName,
  orderUnits,
  receivedUnits,
  orderCost,
  receivedPayment,
  onUploadInvoiceClick,
  shipmentAgentLogo,
  Shipment,
  shipmentMasterTrackingID,
  shippingMode,
  destinationLogo,
  destinationShippingID,
  destinationType,
  orderStatus,
  orderNextUpStatus,
  orderStatusColor,
  warehouseName,
  firstLine,
  city,
  county,
  zipCode,
  country,
  onClickOrder,
  invoiceLink,
  invoiceName,
  currencyFormat,
  userDateFormat,
}) => {
  function toggleCase(str) {
    return str
      ?.toLowerCase()
      .split(' ')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join(' ')
  }

  const getFormattedText = (str) => {
    return toggleCase(str?.replace('_', ' '))
  }
  const formattedStatusText = getFormattedText(orderStatus)

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-xl-3  col-lg-6 col-sm-12 align-items-center product-details">
            <div className="media-body">
              <Link
                to={'#'}
                onClick={onClickOrder}
                className="text-primary d-block fs-18 font-w600 mb-1"
              >
                #{poNumber}
              </Link>
              <span className="d-block mb-2 fs-14">
                <i className="fas fa-calendar me-2"></i>Ordered on{' '}
                {formatDate(orderDate, userDateFormat)}
              </span>
              <div className="d-flex align-items-center project-image mb-3">
                <img src={productThumb} alt="Product Thumbnail" />
                <div>
                  <h3 className="fs-18 text-black font-w600 mb-0">
                    {productName}
                  </h3>
                </div>
              </div>
              <span className="d-block fs-12 font-w500">SKU: {productSKU}</span>
              <span className="d-block fs-12 font-w500">
                ASIN: {productASIN}
              </span>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-4 col-6 mb-3 order-details">
            <div className="d-flex align-items-center project-image mb-3">
              <img src={supplierLogo} alt="" />
              <div>
                <h3 className="fs-16 text-black font-w600 mb-0">
                  {supplierName}
                </h3>
              </div>
            </div>
            <div className="d-flex align-items-end mt-2 justify-content-between">
              <span className="d-block fs-16 text-black font-w600 mb-0">
                {orderUnits} Units
              </span>
              <span>{orderUnits - receivedUnits} Remaining</span>
            </div>
            <div className="progress  mb-4">
              <div
                className="progress-bar progress-animated"
                style={{
                  width: (receivedUnits / orderUnits) * 100 + '%',
                }}
              ></div>
            </div>
            {orderNextUpStatus !== 'Upload Invoice' &&
              orderNextUpStatus !== 'Waiting Invoice' &&
              orderCost !== undefined && (
                <>
                  <div className="d-flex align-items-end mt-2 justify-content-between">
                    <span className="d-block fs-16 text-black font-w600 mb-0">
                      {currencyFormat.format(orderCost)}
                    </span>
                    <span>
                      {currencyFormat.format(orderCost - receivedPayment)}{' '}
                      Remaining
                    </span>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar progress-animated"
                      style={{
                        width: (receivedPayment / orderCost) * 100 + '%',
                      }}
                    ></div>
                  </div>
                </>
              )}
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-4 col-6 mb-3">
            {orderNextUpStatus === 'Waiting for delivery' ||
            orderNextUpStatus === 'Order Delivered' ? (
              <TrackingCard
                shipmentAgentLogo={shipmentAgentLogo}
                masterCarton={
                  Boxes?.filter((box) => box.trackingInfo !== null)?.[0]
                }
                shippingMode={shippingMode}
              />
            ) : (
              <TrackingUnavailableCard shippingMode={shippingMode} />
            )}
          </div>
          <div className="col-xl-3  col-lg-6 col-sm-6 mb-sm-4 mb-0">
            {Shipment?.SubShipments?.[0] &&
              destinationType === 'Amazon FBA' && (
                <AMZReceivingCard
                  destinationLogo={destinationLogo}
                  shipmentDetails={Shipment?.SubShipments?.[0]}
                />
              )}
            {!Shipment?.SubShipments?.[0] &&
              destinationType === 'Amazon FBA' && (
                <AMZCreateLabelCard destinationLogo={destinationLogo} />
              )}
            {!destinationShippingID && destinationType === 'My Warehouse' && (
              <PrivateDestination
                warehouseName={warehouseName}
                firstLine={firstLine}
                city={city}
                county={county}
                zipCode={zipCode}
                country={country}
              />
            )}
          </div>
          <div className="col-xl-12  col-lg-6 col-sm-4">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="fs-16 font-w600 me-2">
                <span className="me-2">Next up:</span>
                {orderNextUpStatus !== 'Order Delivered' && (
                  <>
                    <i className="fas fa-exclamation-triangle me-1 text-danger"></i>
                    <span className="text-primary">{orderNextUpStatus}</span>
                  </>
                )}
                {orderNextUpStatus === 'Order Delivered' && (
                  <span className="text-success">{orderNextUpStatus}</span>
                )}
              </div>
              <div className="d-flex justify-content-end align-items-center flex-wrap">
                {/* {orderCost && (
									<div className="circle-icon circle-icon--medium me-2">
										<a
											className="ms-0 badge badge-outline-primary badge-circle"
											target="_blank"
											href={invoiceLink}
											download={invoiceName} rel="noreferrer"
										>
											<i className="fs-22 fas fa-file-pdf"></i>
										</a>
									</div>
								)} */}
                <div className="d-flex justify-content-end project-btn">
                  <Status
                    text={formattedStatusText}
                    color={orderStatusColor}
                    onClick={onClickOrder}
                    path="#"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderCard
