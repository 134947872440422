import { SET_PRODUCTS_ACTION } from "../actions/ProductTypes";
import { productsData } from "../../jsx/mocks/productsMocks";

const initialState = {
  productsState: productsData,
  selectedProductId: 0,
};

export default function ProductsReducer(state = initialState, action) {
  if (action.type === SET_PRODUCTS_ACTION) {
    const updatedState = {
      ...state,
      ...state.productState,
      productsState: action.products,
    };
    return updatedState;
  }
  return state;
}
