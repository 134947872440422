import React, { useState, useEffect, useCallback } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useStateValue } from '../../../../../store/selectors/useStateValue'
import { config } from '../../../../../connector/config'
import { nonPaginatedDataRequest } from '../../../../../connector/httpConnector/httpRequest'
import BillingPaymentRow from './BillingPaymentRow'
import { Elements, CardNumberElement } from '@stripe/react-stripe-js';
import StripePaymentForm from './StripePaymentsForm';
import {loadStripe} from '@stripe/stripe-js';
import LoadingSpinner from '../../../elements/LoadingSpinner/LoadingSpinner'
import {
  useGetPlanDetails,
  useUploadPaymentdetails,
  useGetPaymentDetails,
  useSubscribePlan,
  useGetSubscriptionDetails,
} from '../../../../../data/billing-payments-data'
import swal from 'sweetalert'
import {
  useUploadBillingAddress,
  useGetBillingAddress
} from '../../../../../data/account-data'
import { Country, State } from 'country-state-city'
import PerfectScrollbar from "react-perfect-scrollbar";
import { Badge, Dropdown } from "react-bootstrap";
import { isEmpty } from 'lodash';

//Images
const BillingPayments = () => {
  const { auth } = useStateValue()
  const { baseURL, getBillingPayments, stripeKey } = config
  const stripePromise = loadStripe(String(stripeKey));
  const [billingPaymentsData, setBillingPaymentsData] = useState()
  const [loading, setLoading] = useState(false)
  const token = auth.auth.accessToken
  const fetchPlanDetails = useGetPlanDetails()
  const UploadPaymentdetails = useUploadPaymentdetails()
  const fetchPaymentDetails = useGetPaymentDetails()
  const fetchSubscriptionDetails = useGetSubscriptionDetails()
  const [selectedPlan, setSelectedPlan] = useState()
  const subscribePlan = useSubscribePlan()
  const [actionId, setActionId] = useState()
  const uploadBillingAddress = useUploadBillingAddress()
  const fetchBillingAddress = useGetBillingAddress()
  const [currentCountry, setCurrentCountry] = useState()

  const [stripe, setStripe] = useState();
  const [elements, setElements] = useState();

  const getStripeDate = (stripe, elements) => {
    setStripe(stripe);
    setElements(elements);
  }

  // const fetchBillingPayments = useCallback(() => {
  //   nonPaginatedDataRequest(baseURL, token, getBillingPayments)
  //     .then((data) => {
  //       setBillingPaymentsData(data.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setLoading(false);
  //     });
  // }, [baseURL, getBillingPayments, token]);

  // useEffect(() => {
  //   setLoading(true);
  //   fetchBillingPayments();
  // }, [fetchBillingPayments]);

  const [formData, setFormData] = useState({})
  useEffect(() => {
    if (fetchPaymentDetails.isSuccess) {
      setFormData({
        card: fetchPaymentDetails.data.data.data.number,
        cvc: fetchPaymentDetails.data.data.data.cvc,
        exp_month: fetchPaymentDetails.data.data.data.exp_month,
        exp_year: fetchPaymentDetails.data.data.data.exp_year,
      })
    }
  }, [fetchPaymentDetails.data])


  const getStripeToken = async (stripe, elements) => {
    if (isEmpty(stripe) || isEmpty(elements)) return null;
    const card = elements.getElement(CardNumberElement);
    const { token, error } = await stripe.createToken(card);
    if (!isEmpty(token)) {
      const { id } = token;
      return id;
    } else if (error) {
      swal('Add Card!', error?.message || 'Unable to add card.', 'error')
    }
    return null;
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    const token = await getStripeToken(stripe,elements) || {};
    if(!isEmpty(token)) UploadPaymentdetails.mutate({ data: { token } });
  }

  useEffect(() => {
    if (!UploadPaymentdetails.isLoading) {
      if (UploadPaymentdetails.isSuccess) {
        swal('Good job!', 'Card added successfully', 'success')
      }
      if (UploadPaymentdetails.isError) {
        swal('oops!', UploadPaymentdetails.error.response.data.message, 'error')
      }
      UploadPaymentdetails.reset()
    }
  }, [
    UploadPaymentdetails.isSuccess,
    UploadPaymentdetails.isError,
    UploadPaymentdetails.data,
    UploadPaymentdetails.isLoading,
  ])
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (!fetchPlanDetails.isLoading) {
      if (fetchPlanDetails.isSuccess) {
        const plans = fetchPlanDetails.data.data.data
        for (let i = 0; i < plans.length; i++) {
          const plan = plans[i]
          if (plan.selected === true) {
            setSelectedPlan(plan)
          }
        }
      }
    }
  }, [
    fetchPlanDetails.isSuccess,
    fetchPlanDetails.isError,
    fetchPlanDetails.data,
    fetchPlanDetails.isLoading,
  ])

  const handleSubscribePlan = (planId) => {
    setActionId(planId)
    subscribePlan.mutate({ planId })
  }

  useEffect(() => {
    if (!subscribePlan.isLoading) {
      if (subscribePlan.isSuccess) {
        swal('Good job!', 'Plan activated successfully', 'success')
      }
      if (subscribePlan.isError) {
        swal('oops!', subscribePlan.error.response.data.message, 'error')
      }
      subscribePlan.reset()
    }
  }, [
    subscribePlan.data,
    subscribePlan.isSuccess,
    subscribePlan.isError,
    subscribePlan.isLoading,
  ])

  //billing
  const [billingFormData, setBillingFormData] = useState({})
  useEffect(() => {
    if (!fetchBillingAddress.isLoading && fetchBillingAddress.isSuccess) {
      setCurrentCountry(fetchBillingAddress.data.data.data.countryName)
      setBillingFormData({
        addressLine1: fetchBillingAddress.data.data.data.addressLine1,
        addressLine2:
          fetchBillingAddress.data.data.data.addressLine2 ?? undefined,
        cityName: fetchBillingAddress.data.data.data.cityName,
        zipCode: fetchBillingAddress.data.data.data.zipCode,
        countryName: fetchBillingAddress.data.data.data.countryName,
        stateOrProvinceName:
          fetchBillingAddress.data.data.data.stateOrProvinceName ?? undefined,
      })
    }
  }, [
    fetchBillingAddress.data,
    fetchBillingAddress.isSuccess,
    fetchBillingAddress.isError,
    fetchBillingAddress.isLoading,
  ])

  const handleBillingSubmit = async (e) => {
    e.preventDefault()
    if (billingFormData.addressLine2 === '') {
      delete billingFormData.addressLine2
    }
    uploadBillingAddress.mutate({ data: billingFormData })
  }

  useEffect(() => {
    if (!uploadBillingAddress.isLoading) {
      if (uploadBillingAddress.isSuccess) {
        swal('Good job!', 'Billing Address added successfully', 'success')
      }
      if (uploadBillingAddress.isError) {
        swal('Oops', uploadBillingAddress.error.response.data.message, 'error')
      }
      uploadBillingAddress.reset()
    }
  }, [
    uploadBillingAddress.data,
    uploadBillingAddress.isError,
    uploadBillingAddress.isSuccess,
    uploadBillingAddress.isLoading,
  ])

  const handleBillingChange = (e) => {
    setBillingFormData({ ...billingFormData, [e.target.name]: e.target.value })
  }

  return (
    <>
      <div className="row">
        <div className="col-xl-4 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Subscription</h4>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
                <div className="clearfix">
                  <h3 className="card-title">Plan Details</h3>
                  <span className="text-primary">{selectedPlan?.planName}</span>
                </div>
                <div className="clearfix text-center">
                  <h3 className="text-primary mb-0">
                    {selectedPlan?.planPrice} {selectedPlan?.planCurrency}
                  </h3>
                  <span>
                    {selectedPlan ? selectedPlan.planInterval + 'ly' : ''}
                  </span>
                </div>
              </div>
              {/*
               */}
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Plans</h4>
            </div>
            <div className="card-body">
              <PerfectScrollbar
                style={{ height: "200px" }}
                id="DZ_W_Todo1"
                className="widget-media dlab-scroll ps ps--active-y"
              >
                {fetchPlanDetails.isSuccess && (
                  <ul className="timeline">
                    {fetchPlanDetails.data?.data.data.map((plan) => (
                      <li key={plan.id}>
                        <div className="timeline-panel">
                          <div className="media-body">
                            <h5 className="mb-1">{plan.planName}</h5>
                            <small className="d-block">${plan.planPrice} ({plan.planCurrency}) | {plan.maxMonthlyOrder} Orders per month | {plan.maxSupplierAssigned} per product </small>
                          </div>
                          <button
                            className={
                              `btn btn-sm btn-rounded ` +
                              (plan.id == selectedPlan?.id
                                ? 'btn-success'
                                : 'btn-primary')
                            }
                            onClick={() => handleSubscribePlan(plan.id)}
                            disabled={plan.id == selectedPlan?.id}
                          >
                            {subscribePlan.isLoading &&
                              plan.id === actionId && (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  className="me-2"
                                ></Spinner>
                              )}
                            <span>Active</span>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </PerfectScrollbar>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Billing Address</h4>
            </div>
            <div className="card-body">
              <div className="basic-form authincation-content style-2">
                <form onSubmit={handleBillingSubmit}>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="countryname"
                    >
                      Country
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        id="countryName"
                        name="countryName"
                        onChange={handleBillingChange}
                        onChangeCapture={(e) =>
                          setCurrentCountry(e.target.value)
                        }
                        value={billingFormData.countryName}
                      >
                        <option value selected disabled>
                          Choose...
                        </option>
                        {Country.getAllCountries().map((country) => (
                          <option value={country.isoCode}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="stateorprovincename"
                    >
                      State or Province
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        id="stateorprovincename"
                        name="stateOrProvinceName"
                        value={billingFormData.stateOrProvinceName}
                        onChange={handleBillingChange}
                      >
                        <option value selected disabled>
                          Choose ...
                        </option>
                        {State.getStatesOfCountry(currentCountry).map(
                          (state) => (
                            <option value={state.isoCode}>{state.name}</option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="first-name"
                    >
                      Address Line 1
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Address Line1"
                        id="Address-Line1"
                        name="addressLine1"
                        value={billingFormData.addressLine1}
                        onChange={handleBillingChange}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="Address-Line2"
                    >
                      Address Line 2
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Address Line2"
                        id="Address-Line2"
                        name="addressLine2"
                        value={billingFormData.addressLine2}
                        onChange={handleBillingChange}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="city-Name"
                    >
                      City
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="city Name"
                        id="cityName"
                        name="cityName"
                        value={billingFormData.cityName}
                        onChange={handleBillingChange}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="zipcode"
                    >
                      Postal Code
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="zip Code"
                        id="zipcode"
                        name="zipCode"
                        value={billingFormData.zipCode}
                        onChange={handleBillingChange}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-primary justify-content-end"
                      >
                        {uploadBillingAddress.isLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            className="me-2"
                          ></Spinner>
                        )}
                        <span>Save</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12">
          <div className="text-white bg-primary card">
            <div className="card-header">
              <h4 className="card-title text-white">Payment Details</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSubmit}>
                  <Elements stripe={stripePromise} >
                    <StripePaymentForm getStripeDate={getStripeDate} />
                  </Elements>
                  {/* <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="first-name"
                    >
                      Card Number
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Card Number"
                        id="card-number"
                        name="card"
                        value={formData.card}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="last-name"
                    >
                      CVC
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="CVC"
                        id="CVC"
                        name="cvc"
                        value={formData.cvc}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="last-name"
                    >
                      Expiry Month
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="MM"
                        id="expiryMonth"
                        name="exp_month"
                        value={formData.exp_month}
                        onChange={handleChange}
                        max={12}
                        min={1}
                      />
                    </div>
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="last-name"
                    >
                      Expiry Year
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="YYYY"
                        id="expiryYear"
                        name="exp_year"
                        value={formData.exp_year}
                        onChange={handleChange}
                        min={1000}
                        max={9999}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button
                        type="submit"
                        className="btn bg-white text-primary mt-3"
                      >
                        {UploadPaymentdetails.isLoading && (
                          <Spinner
                            animation="border"
                            size="sm"
                            as="span"
                            className="me-2"
                          ></Spinner>
                        )}
                        <span>Save</span>
                      </button>
                    </div>
                  </div> */}
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button
                        type="submit"
                        className="btn bg-white text-primary mt-3"
                      >
                        {UploadPaymentdetails.isLoading && (
                          <Spinner
                            animation="border"
                            size="sm"
                            as="span"
                            className="me-2"
                          ></Spinner>
                        )}
                        <span>Save</span>
                      </button>
                    </div>
                  </div>
                  
                    
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Invoices</h4>
            </div>

            {loading ? (
              <LoadingSpinner size={150} />
            ) : (
              <div className="card-body">
                <div className="table-responsive">
                  <div id="permission_data" className="dataTables_wrapper">
                    <table
                      className="display w-100 dataTable"
                      id="example5"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th className="sorting" style={{ width: '200px' }}>
                            Subscription
                          </th>
                          <th className="sorting" style={{ width: '220px' }}>
                            Amount
                          </th>
                          <th className="sorting" style={{ width: '80px' }}>
                            Date
                          </th>
                          <th className="sorting" style={{ width: '50px' }}>
                            Settings
                          </th>
                        </tr>
                      </thead>

                      {fetchSubscriptionDetails.isSuccess && (
                        <tbody>
                          {fetchSubscriptionDetails.data?.data.data.map(
                            (Subscription) => (
                              <tr key={Subscription.id}>
                                <td>
                                  <div>
                                    <span className="fs-16 font-w500 text-black me-2">
                                      {Subscription.plan}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <span className="fs-16 font-w500 text-black me-2">
                                      {Subscription.amount}(usd)
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <span className="fs-16 font-w500 text-black me-2">
                                      {Subscription.createdAt}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <a
                                      className="btn btn-success shadow btn-xs sharp me-1"
                                      href={Subscription.url}
                                      download
                                    >
                                      <i className="fa fa-download"></i>
                                    </a>
                                    <a
                                      className="btn btn-primary shadow btn-xs sharp"
                                      target="_blank"
                                      href={Subscription.previewUrl}
                                    >
                                      <i className="fa fa-eye"></i>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default BillingPayments
