import React, { useEffect, useState } from 'react'
import {
  useNotificationData,
  useFetchNotificationData,
} from '../../../../data/notification-data'
import { useStateValue } from '../../../../store/selectors/useStateValue'
import { Spinner } from 'react-bootstrap'
import swal from 'sweetalert'

const Notifications = () => {
  const { auth } = useStateValue()
  const isBusinessUser = auth.userType === 'business'
  const notificationDataMutation = useNotificationData()
  const FetchNotificationData = useFetchNotificationData()
  const [formData, setFormData] = useState({})

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }))
  }

  const handleSubmit = async () => {
    try {
      const response = await notificationDataMutation.mutateAsync({
        data: formData,
      })

      if (notificationDataMutation.isSuccess) {
        swal('Good job!', 'Successfully Updated', 'success')
      }
    } catch (error) {
      if (notificationDataMutation.isError) {
        swal('Oops', 'Something went wrong', 'error')
      }
    }
  }

  const { data } = FetchNotificationData
  useEffect(() => {
    if (data && data.data) {
      setFormData({
        onOrderCreate: data?.data?.data?.onOrderCreate,
        onInvoiceCreate: data?.data?.data?.onInvoiceCreate,
        onPaymentCreate: data?.data?.data?.onPaymentCreate,
        onLabelCreate: data?.data?.data?.onLabelCreate,
        onNoteCreate: data?.data?.data?.onNoteCreate,
        onFileUpload: data?.data?.data?.onFileUpload,
        onStockOutage: data?.data?.data?.onStockOutage,
        onAttentionNeed: data?.data?.data?.onAttentionNeed,
        onBatchCreate: data?.data?.data?.onBatchCreate,
      })
    }
  }, [data])

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Email notifications</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row mb-3">
                    <div className="col-sm-4">
                      <div>
                        <small className="d-block fs-16 font-w700">
                          Orders
                        </small>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      {!isBusinessUser && (
                        <div className="form-check custom-checkbox mb-1 checkbox-info">
                          <input
                            type="checkbox"
                            name="onOrderCreate"
                            className="form-check-input"
                            id="customCheckBox1"
                            checked={formData.onOrderCreate || false}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox1"
                          >
                            Email when new order received
                          </label>
                        </div>
                      )}
                      {isBusinessUser && (
                        <div className="form-check custom-checkbox mb-1 checkbox-info">
                          <input
                            type="checkbox"
                            name="onInvoiceCreate"
                            className="form-check-input"
                            id="customCheckBox2"
                            checked={formData.onInvoiceCreate || false}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          >
                            Email when Supplier upload Invoice
                          </label>
                        </div>
                      )}
                      {!isBusinessUser && (
                        <>
                          <div className="form-check custom-checkbox mb-1 checkbox-info">
                            <input
                              type="checkbox"
                              name="onPaymentCreate"
                              className="form-check-input"
                              id="customCheckBox3"
                              checked={formData.onPaymentCreate || false}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckBox3"
                            >
                              Email when marked 'payment made' & updated
                            </label>
                          </div>
                          <div className="form-check custom-checkbox mb-1 checkbox-info">
                            <input
                              type="checkbox"
                              name="onLabelCreate"
                              className="form-check-input"
                              id="customCheckBox4"
                              checked={formData.onLabelCreate || false}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckBox4"
                            >
                              Email when amazon label created
                            </label>
                          </div>
                        </>
                      )}
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          name="onNoteCreate"
                          className="form-check-input"
                          id="customCheckBox5"
                          checked={formData.onNoteCreate || false}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox5"
                        >
                          Email when a note added
                        </label>
                      </div>
                      {isBusinessUser && (
                        <div className="form-check custom-checkbox mb-1 checkbox-info">
                          <input
                            type="checkbox"
                            name="onBatchCreate"
                            id="customCheckBox6"
                            className="form-check-input"
                            checked={formData.onBatchCreate || false}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox6"
                          >
                            Email when batch information added
                          </label>
                        </div>
                      )}
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          name="onFileUpload"
                          id="customCheckBox7"
                          className="form-check-input"
                          checked={formData.onFileUpload || false}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox7"
                        >
                          Email when file uploaded
                        </label>
                      </div>
                      <div className="form-check custom-checkbox mb-1 checkbox-info d-none">
                        <input
                          type="checkbox"
                          name="orderReceived"
                          id="customCheckBox8"
                          className="form-check-input"
                          checked={formData.orderReceived || false}
                          onChange={handleCheckboxChange}
                          disabled
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox8"
                        >
                          Email when order status updated [both]
                        </label>
                      </div>
                    </div>
                  </div>
                  {isBusinessUser && (
                    <div className="row mb-3">
                      <div className="col-sm-4">
                        <div>
                          <small className="d-block fs-16 font-w700">
                            Inventory
                          </small>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="form-check custom-checkbox mb-1 checkbox-info">
                          <input
                            type="checkbox"
                            name="onStockOutage"
                            id="customCheckBox9"
                            className="form-check-input"
                            checked={formData.onStockOutage || false}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox9"
                          >
                            Email when Risk of going out of stock
                          </label>
                        </div>
                        <div className="form-check custom-checkbox mb-1 checkbox-info">
                          <input
                            type="checkbox"
                            name="onAttentionNeed"
                            id="customCheckBox10"
                            className="form-check-input"
                            checked={formData.onAttentionNeed || false}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox10"
                          >
                            Email when attention needed
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary btn-rounded fs-18"
                      onClick={handleSubmit}
                      disabled={notificationDataMutation.isLoading}
                    >
                      {notificationDataMutation.isLoading ? (
                        <Spinner size="md" as="span" animation="border" />
                      ) : (
                        <span>Save</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12" style={{ display: 'none' }}>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Push notifications</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row mb-3">
                    <div className="col-sm-4">
                      <div>
                        <small className="d-block fs-16 font-w700">
                          Orders
                        </small>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="form-check-input"
                          id="customCheckBox11"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox11"
                        >
                          Email when new order received [for supplier]
                        </label>
                      </div>
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="form-check-input"
                          id="customCheckBox12"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox12"
                        >
                          Email when Supplier upload Invoice
                        </label>
                      </div>
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="form-check-input"
                          id="customCheckBox13"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox13"
                        >
                          Email when marked 'payment made' [for supplier]
                        </label>
                      </div>
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheckBox14"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox14"
                        >
                          Email when marked payment updated [for supplier]
                        </label>
                      </div>
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="form-check-input"
                          id="customCheckBox15"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox15"
                        >
                          Email when amazon label created [for supplier]
                        </label>
                      </div>
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="form-check-input"
                          id="customCheckBox16"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox16"
                        >
                          Email when a note added [only when offline] [both]
                        </label>
                      </div>
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheckBox17"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox17"
                        >
                          Email when batch information added
                        </label>
                      </div>
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="form-check-input"
                          id="customCheckBox18"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox18"
                        >
                          Email when file uploaded [both]
                        </label>
                      </div>
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="form-check-input"
                          id="customCheckBox19"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox19"
                        >
                          Email when order status updated [both]
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-4">
                      <div>
                        <small className="d-block fs-16 font-w700">
                          Inventory
                        </small>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="form-check-input"
                          id="customCheckBox20"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox20"
                        >
                          Email when Risk of going out of stock
                        </label>
                      </div>
                      <div className="form-check custom-checkbox mb-1 checkbox-info">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="form-check-input"
                          id="customCheckBox21"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox21"
                        >
                          Email when attention needed
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Notifications
