import React from 'react'
import { Link } from 'react-router-dom'

const SignupOptions = ({ onBackToLogin }) => {
  return (
    <div className="signup-content-holder">
      <h3 className="text-center mb-4 text-black">Sign up as a</h3>
      <div className="signup_option form-group mb-3">
        <Link to="./register-business" className="fs-18">
          Business owner
          <span>14 Days Free Trial</span>
          <i className="fas fa-chevron-right ms-2"></i>
        </Link>
      </div>
      <div className="signup_option form-group mb-3">
        <Link to="./register-supplier" className="fs-18">
          Supplier / Manufacturer
          <span>Free Forever</span>
          <i className="fas fa-chevron-right ms-2"></i>
        </Link>
      </div>
      <div className="new-account mt-3">
        <p>
          Already have an account?{' '}
          <Link className="text-primary" to={'#'} onClick={onBackToLogin}>
            Login
          </Link>
        </p>
      </div>
    </div>
  )
}

export default SignupOptions
