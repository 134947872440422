import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Dropdown from 'react-bootstrap/Dropdown';
import { Field, Form, Formik } from "formik";
import { useSaveBoxTrackNo, useAddBoxTrackNo } from "../../../../../data/shipment-data";
import { Alert, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { isEmpty, pullAt } from "lodash";

const UpdateBatchInfoForm = ({onClickCancel, boxes, order, orderId, shipment, disableInput}) => {

  const addBoxTrackNoData = useSaveBoxTrackNo();
  const addBoxTrackNo = useAddBoxTrackNo();
  const [totalBoxes, setTotalBoxes] = useState(isEmpty(boxes) ? [{ trackingId: '' }] : boxes);
  const addMoreBox= () => {
    setTotalBoxes([...totalBoxes, { trackingId: '' }]);
  };
  const deleteBox = (index) => {
    setTotalBoxes(totalBoxes.filter((_, i) => i !== index));

  }

  return (
    <Formik
       initialValues={{
        carrierName: (boxes.length !== 0 ? boxes[0].carrierName : ''),
        boxes: !isEmpty(boxes) ? boxes.map((bx) => ({ trackingId: bx.trackingId, id: bx.id })) : totalBoxes.map((bx) => ({ trackingId: bx.trackingId })), 
       }}

      onSubmit={(values) => {
        if (order?.destinationType === "My Warehouse" || order?.Product?.amazonIntegrated === false) {
          let boxInfos = values.boxes.map((bx) => ({
            trackingId: bx.trackingId,
            boxId: bx.id,
            carrierName: values.carrierName
          }));
          addBoxTrackNo.mutate({ orderId, data: { boxes: boxInfos } })
         }
        else {
          swal({
            title: "Mark Shipment as Shipped?",
            text: `Once confirmed, This shipment will be marked as shipped and you can not change this later on.`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
            confirmButtonText: "Confirm and Add",
            cancelButtonText: "Skip and Add",
          }).then((willConfirm) => {
            let boxInfos = values.boxes.map((bx) => ({
              trackingId: bx.trackingId,
              boxId: bx.id,
              carrierName: values.carrierName
            }));
            if (willConfirm) {
              addBoxTrackNoData.mutate({ orderId, data: { boxes: boxInfos, shipped: true } })
            } else {
              addBoxTrackNoData.mutate({ orderId, data: { boxes: boxInfos, shipped: false } })
            }
          });
        }
      }}
      // enableReinitialize={true}
    >
       <Form>
    <div className="modal-body">
      <i className="flaticon-cancel-12 close"></i>
      <div className="add-contact-box">
        <div className="add-contact-content">
          <div className="form-group mb-3 col-md-7">
            <label className="text-black font-w500">Carrier Name</label>
            <Field
               as="select"
               name="carrierName"
               className="form-control form-control-md"
               disabled={disableInput}
            >
              <option value="" disabled selected >Choose</option>
              <option value="DHL_EXPRESS_USA_INC">DHL</option>
              <option value="FEDERAL_EXPRESS_CORP">FED-EX</option>
              <option value="UNITED_STATES_POSTAL_SERVICE">USPS</option>
              <option value="UNITED_PARCEL_SERVICE_INC">UPS</option>
              <option value="OTHER">Other</option>
            </Field>
          </div>
          <div className="form-group mb-3">
            <label className="text-black font-w500">Traking id</label>
            {
            totalBoxes.map((box, index)=>(
              <div className="form-group mb-1 col-md-7">
                <label className="text-black font-w300">Box {index + 1}</label>

                <button
                  type="button"
                  className="btn btn-danger"
                  style={{float: "right", marginTop: "15px"}}
                  onClick={() => deleteBox(index)}
                  hidden={!(order?.destinationType === "My Warehouse" || order?.Product?.amazonIntegrated === false)}
                >X</button>
                <Field
                  className="form-control"
                  autoComplete="off"
                  style={{ width: "76%" }}
                  name={`boxes.${index}.trackingId`}
                  placeholder="Tracking id"
                  disabled={disableInput}
                />


              </div>
            ))
          }
          </div>
        </div>
        { (addBoxTrackNoData?.isError || addBoxTrackNo?.isError) &&
        <Alert variant="danger">
             {
                addBoxTrackNoData?.error?.response?.data?.message[0]?.message ||
                addBoxTrackNoData?.error?.response?.data?.message ||
                addBoxTrackNo?.error?.response?.data?.message[0]?.message ||
                addBoxTrackNo?.error?.response?.data?.message
                
             }
        </Alert>}
        {
          (addBoxTrackNoData.isSuccess && onClickCancel()) || (addBoxTrackNo.isSuccess && onClickCancel())
        }
      </div>
      <div className="modal-footer">
            <button
              type="button"
              onClick={() => addMoreBox()}
              disabled={addBoxTrackNoData.isLoading || addBoxTrackNo.isLoading}
              className="btn btn-primary"
              hidden={!(order?.destinationType === "My Warehouse" || order?.Product?.amazonIntegrated === false)}
            >
              <span> Add Box</span>
            </button>
        <button
          type="submit"
          disabled={addBoxTrackNoData.isLoading || addBoxTrackNo.isLoading}
          className="btn btn-primary"
        >
          {
            (addBoxTrackNoData.isLoading || addBoxTrackNo.isLoading) &&
            <Spinner
              as="span"
              animation="border"
              className="me-2"
              size="sm"
            >
            </Spinner>
          }
          <span> Add </span>
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={onClickCancel}
        >
          {" "}
          <i className="flaticon-delete-1"></i> Discard
        </button>
      </div>
    </div>
    </Form>
    </Formik>
  );
};

export default UpdateBatchInfoForm;
