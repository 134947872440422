import { useMutation, useQuery, useQueryClient } from "react-query"
import http from "./http"
import { config } from "../connector/config"
import { useStateValue } from "../store/selectors/useStateValue"

export const useFetchProducts = (params = {}) => {
    const { auth } = useStateValue();
    if( params.search?.trim() === ''){
        delete params.search;
    }
    let sort = params.sort;
    delete params.sort;
    sort = sort.map((s,i) =>`sort[${i}][]=${s[0]}&sort[${i}][]=${s[1]}`).join('&');
    return useQuery(['get-products', JSON.stringify(params), sort],
     () => {
         return http().request({
             url:    `${config.businessGetProducts}?${sort}`,
             method: 'GET',
             params:  params,
             headers:{
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
             }
         })
     },{
        retry:0
     }
    )
}

export const useAddProduct = () => {
     const { auth } = useStateValue();
     const queryClient = useQueryClient();
     return useMutation(({newProduct})=> {
        return http().request({
            url:    `${config.businessGetProducts}`,
            method: 'POST',
            data: newProduct,
            headers:{
               "Content-Type": "application/json",
               "x-access-token": auth.auth.accessToken,
            }
        })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('get-products');
            queryClient.invalidateQueries('get-product-details')
        }
    })
}

export const useHandleGetProduct = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId})=> {
       return http().request({
           url:    `${config.businessGetProducts}/${productId}/permissions`,
           method: 'GET',
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('get-products');
            queryClient.invalidateQueries('get-product-details')
        }
    })
}

export const useForgetGetProductData = () =>{
   const queryClient = useQueryClient();
   return ()=> {
        queryClient.invalidateQueries('get-product-details');
   }
}

export const useHandleUploadProductDoc = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId, data })=> {
       return http().request({
           url:    `${config.businessGetProducts}/${productId}/upload-document`,
           method: 'POST',
           data: data,
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('get-products');
            queryClient.invalidateQueries('get-product-details')
        }
    })
}

export const useHandleUploadProductImage = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId, data })=> {
       return http().request({
           url:    `${config.businessGetProducts}/${productId}/upload-image`,
           method: 'POST',
           data: data,
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('get-products');
            queryClient.invalidateQueries('get-product-details')
        }
    })
}

export const useHandleUploadProductDesign = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId, data })=> {
       return http().request({
           url: `${config.businessGetProducts}/${productId}/upload-design`,
           method: 'POST',
           data: data,
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('get-products');
            queryClient.invalidateQueries('get-product-details')
        }
    })
}

export const useUpdateProduct = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({productId, data })=> {
       return http().request({
           url:    `${config.businessGetProducts}/${productId}`,
           method: 'POST',
           data: data,
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('get-products');
            queryClient.invalidateQueries('get-product-details')
        }
    })
}

export const useFetchProductDetails = ({productId}) => {
    const { auth } = useStateValue();
    return useQuery('get-product-details',()=> {
       return http().request({
           url: `${config.businessGetProducts}/${productId}/permissions`,
           method: 'GET',
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    })
}
export const useDeleteProductDesign = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId,designsId,data })=> {
       return http().request({
           url: `${config.businessGetProducts}/${productId}/designs/${designsId}`,
           method: 'DELETE',
           data: data,
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('get-products');
            queryClient.invalidateQueries('get-product-details')
        }
    })
}

export const useDeleteProductImage = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId,imagesId,data })=> {
       return http().request({
           url: `${config.businessGetProducts}/${productId}/images/${imagesId}`,
           method: 'DELETE',
           data: data,
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('get-products');
            queryClient.invalidateQueries('get-product-details')
        }
    })
}
export const useDeleteProductDocuments = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId,documentsId,data })=> {
       return http().request({
           url: `${config.businessGetProducts}/${productId}/documents/${documentsId}`,
           method: 'DELETE',
           data: data,
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('get-products');
            queryClient.invalidateQueries('get-product-details')
        }
    })
}

export const useDeleteProductDetailEditDocuments = ({onSuccess}) => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId,documentsId,data })=> {
       return http().request({
           url: `${config.businessGetProducts}/${productId}/documents/${documentsId}`,
           method: 'DELETE',
           data: data,
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            onSuccess()
            queryClient.invalidateQueries('get-product-details')
        }
    })
}

export const useDeleteProductDetailEditImage = ({onSuccess}) => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId,imagesId,data })=> {
       return http().request({
           url: `${config.businessGetProducts}/${productId}/images/${imagesId}`,
           method: 'DELETE',
           data: data,
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            onSuccess()
            queryClient.invalidateQueries('get-product-details')
        }
    })
}

export const useDeleteProductDetailEditDesign = ({onSuccess}) => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId,designsId,data })=> {
       return http().request({
           url: `${config.businessGetProducts}/${productId}/designs/${designsId}`,
           method: 'DELETE',
           data: data,
           headers:{
              "Content-Type": "application/json",
              "x-access-token": auth.auth.accessToken,
           }
       })
    },{
        onSuccess: () => {
            onSuccess()
            queryClient.invalidateQueries('get-product-details')
        }
    })
}


export const useDeleteProductEditDocuments = ({onSuccess}) => {
   const { auth } = useStateValue();
   return useMutation(({productId,documentsId,data })=> {
      return http().request({
          url: `${config.businessGetProducts}/${productId}/documents/${documentsId}`,
          method: 'DELETE',
          data: data,
          headers:{
             "Content-Type": "application/json",
             "x-access-token": auth.auth.accessToken,
          }
      })
   },{
       onSuccess: () => {
           onSuccess()
       }
   })
}

export const useDeleteProductEditImage = ({onSuccess}) => {
   const { auth } = useStateValue();
   return useMutation(({productId,imagesId,data })=> {
      return http().request({
          url: `${config.businessGetProducts}/${productId}/images/${imagesId}`,
          method: 'DELETE',
          data: data,
          headers:{
             "Content-Type": "application/json",
             "x-access-token": auth.auth.accessToken,
          }
      })
   },{
       onSuccess: () => {
           onSuccess()
       }
   })
}

export const useDeleteProductEditDesign = ({onSuccess}) => {
   const { auth } = useStateValue();
   return useMutation(({productId,designsId,data })=> {
      return http().request({
          url: `${config.businessGetProducts}/${productId}/designs/${designsId}`,
          method: 'DELETE',
          data: data,
          headers:{
             "Content-Type": "application/json",
             "x-access-token": auth.auth.accessToken,
          }
      })
   },{
       onSuccess: () => {
           onSuccess()
       }
   })
}

export const useUploadProductImage = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({productId,data}) => { 
        return  http().request({
            url:    `${config.businessGetProducts}/${productId}/upload-image`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
            data:data
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries()
        }
    })
}

export const useUploadProductDesign = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({productId,data}) => { 
        return  http().request({
            url:    `${config.businessGetProducts}/${productId}/upload-design`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
            data:data
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries()
        }
    })
}

export const useUploaddocumentDesign = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();

    return useMutation(({productId,data}) => { 
        return  http().request({
            url:    `${config.businessGetProducts}/${productId}/upload-document`,
            method: 'POST',
            headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
            },
            data:data
         })
    },{
        onSuccess: () => {
            queryClient.invalidateQueries()
        }
    })
}

export const useDeleteProduct = () => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId})=>{
        return http().request({
            url: `${config.businessGetProducts}/${productId}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    },{
        onSuccess: () => {
             queryClient.invalidateQueries('get-products');
        }
    })
}