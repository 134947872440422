import axios from "axios";

export const createAxiosInstance = (baseURL, token) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

export const usersRequest = async (
  baseURL,
  token,
  endpoint,
  method = "get",
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: endpoint,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data.data);
};

export const dataRequest = async (
  baseURL,
  token,
  endpoint,
  method = "get",
  currPage = 1,
  perPage = 12,
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}?currPage=${currPage}&perPage=${perPage}`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const dataSortByRequest = async (
  baseURL,
  token,
  endpoint,
  method = "get",
  currPage = 1,
  perPage = 12,
  sortBy,
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}?currPage=${currPage}&perPage=${perPage}&orderBy=${sortBy}`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const dataMultiSortByRequest = async (
  baseURL,
  token,
  endpoint,
  method = "get",
  currPage = 1,
  perPage = 12,
  sort,
  requestData = null
) => {

  const axiosInstance = createAxiosInstance(baseURL, token);
  sort = sort.map((s,i)=> `sort[${i}]=${s[0]}&sort[${i}]=${s[1]}`).join('&')

  const config = {
    method: method,
    url: `${endpoint}?currPage=${currPage}&perPage=${perPage}&${sort}`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const searchProductRequest = async (
  baseURL,
  token,
  endpoint,
  method = "get",
  currPage,
  perPage,
  searchValue,
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}?currPage=${currPage}&perPage=${perPage}` + (searchValue? `&search=${searchValue}`:``),
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data.data);
};

export const productDetailsRequest = async (
  baseURL,
  token,
  endpoint,
  productId,
  method,
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${productId}/permissions`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data.data);
};

export const connectAmazonRequest = async (
  baseURL,
  token,
  oAuthToken,
  partnerId,
  method = "post"
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `/get_token?spapi_oauth_code=${oAuthToken}&selling_partner_id=${partnerId}&regionCode=na`,
  };
  return axiosInstance
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addDataRequest = async (
  baseURL,
  token,
  endpoint,
  method = "post",
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const deleteDataRequest = async (
  baseURL,
  token,
  endpoint,
  productId,
  method = "delete"
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${productId}`,
  };
  return axiosInstance.request(config).then((response) => response.data.data);
};
export const editDataRequest = async (
  baseURL,
  token,
  endpoint,
  method = "post",
  id,
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${id}`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data.data);
};

export const orderRequest = async (
  baseURL,
  token,
  endpoint,
  method = "get",
  currPage = 1,
  perPage = 10,
  requestData = null,
  params = {}
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}?currPage=${currPage}&perPage=${perPage}`,
    data: requestData,
    params
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const createOrderRequest = async (
  baseURL,
  token,
  endpoint,
  method = "post",
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const deleteOrderRequest = async (
  baseURL,
  token,
  endpoint,
  id,
  method = "delete"
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${id}`,
  };
  return axiosInstance.request(config).then((response) => response.data.data);
};

export const orderDetailsRequest = async (
  baseURL,
  token,
  endpoint,
  orderId,
  method = "get",
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${orderId}`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => {
    return response.data;
  });
};

export const businessSuppliersListRequest = async (
  baseURL,
  token,
  endpoint,
  method = "get",
  currPage = 1,
  perPage = 10,
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}?currPage=${currPage}&perPage=${perPage}`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const uploadInvoiceRequest = async (
  baseURL,
  token,
  endpoint,
  orderId,
  method = "post",
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${orderId}/upload-invoice`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data.data);
};

export const approveInvoiceRequest = async (
  baseURL,
  token,
  endpoint,
  orderId,
  method = "post"
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${orderId}/approve-invoice`,
  };
  return axiosInstance.request(config).then((response) => response.data.data);
};

export const declineInvoiceRequest = async (
  baseURL,
  token,
  endpoint,
  orderId,
  method = "post"
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${orderId}/decline-invoice`,
  };
  return axiosInstance.request(config).then((response) => {
    return response.data;
  });
};

export const addPaymentRequest = async (
  baseURL,
  token,
  endpoint,
  orderId,
  method = "post",
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${orderId}/payments`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data.data);
};

export const deletePaymentRequest = async (
  baseURL,
  token,
  endpoint,
  paymentId,
  method = "delete"
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/payments/${paymentId}`,
  };
  return axiosInstance.request(config).then((response) => response.data.data);
};

export const uploadOrderImageRequest = async (
  baseURL,
  token,
  endpoint,
  orderId,
  method = "post",
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${orderId}/upload-image`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const deleteOrderImageRequest = async (
  baseURL,
  token,
  endpoint,
  imageId,
  method = "delete"
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/images/${imageId}`,
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const uploadOrderDocumentRequest = async (
  baseURL,
  token,
  endpoint,
  orderId,
  method = "post",
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${orderId}/upload-document`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const deleteOrderDocumentRequest = async (
  baseURL,
  token,
  endpoint,
  documentId,
  method = "delete"
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/documents/${documentId}`,
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const updateBatchInfoRequest = async (
  baseURL,
  token,
  endpoint,
  orderId,
  method = "post",
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/${orderId}/batches`,
    data: requestData,
  };
  console.log("Batch api url", `${baseURL}${config.url}`);
  return axiosInstance.request(config).then((response) => {
    return response.data.data;
  });
};

export const addWarehouseRequest = async (
  baseURL,
  token,
  endpoint,
  method = "post",
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}/create`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data);
};

export const nonPaginatedDataRequest = async (
  baseURL,
  token,
  endpoint,
  method = "get",
  requestData = null
) => {
  const axiosInstance = createAxiosInstance(baseURL, token);
  const config = {
    method: method,
    url: `${endpoint}`,
    data: requestData,
  };
  return axiosInstance.request(config).then((response) => response.data);
};