import React, { useEffect,useCallback, useState } from "react";
import ProfileCard from "../molecules/ProfileCard/ProfileCard";
import { Link } from "react-router-dom";
import { useStateValue } from "../../../store/selectors/useStateValue";
import { useDispatch } from "react-redux";
import { setSelectedSupplierIdAction } from "../../../store/actions/SupplierActions";
import { useFetchSuppliers } from "../../../data/suppliers-data";
import Paginator from "../elements/Paginator/Paginator";

const Suppliers = () => {
  const { suppliers } = useStateValue();
  const dispatch = useDispatch();
  const [page, setPage] = useState(parseInt(new URLSearchParams(window.location.search).get("page")) || 1);
  const [suppliersData, setSuppliersData] = useState ([]);
  const [searchQuery, setSearchQuery] = useState ();
  const [supplierDataIntegration, setSupplierDataIntegration] = useState ();
  // const [suppliersData] = useState(suppliers.suppliersState);

  // const handleSelectSupplier = (id) => {
  //   const newSupplierData = [...suppliersData];
  //   const selectedSupplier = newSupplierData.find(
  //     (supplier) => supplier.id === id
  //   );
  //   dispatch(setSelectedSupplierIdAction(selectedSupplier.id));
  // };
  const suppliersFeachData = useFetchSuppliers (
    {
      currPage:page,
      perPage:12,
      search:searchQuery, 
    }
  );
  useEffect (
    () => {
      if(suppliersFeachData.isLoading === false)
      {
        if(suppliersFeachData.status === 'success'){
            const fetchedSuppliers = suppliersFeachData.data.data.suppliers;
            setSuppliersData(fetchedSuppliers);
            setSupplierDataIntegration(suppliersFeachData.data.data);

        }
        if( suppliersFeachData.status === 'error'){
            setSuppliersData([]);
            setSupplierDataIntegration({});
        }
      }
     

    },[suppliersFeachData.data,suppliersFeachData.isLoading]
  )

  const handleChangePage = useCallback((page) => {
    setPage(page);
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="input-group contacts-search mb-4">
          <input
            type="text"
            className="form-control"
            placeholder="Search here..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <span className="input-group-text">
            <Link to={"#"}>
              <i className="flaticon-381-search-2"></i>
            </Link>
          </span>
        </div>
      </div>
      <div className="row">
      {suppliersData && suppliersData.map((supplier) => {
          return (
            <ProfileCard
              key={supplier.id}
              profileName={supplier.name || 'Unknown Name'}
              profileType="Supplier"
              profileImage={supplier.image}
              profileLocation={supplier.location}
              profileEmail={supplier.email}
              profilePhone={supplier.phoneNumber}
              profileProducts={supplier.products}
              link1="#"
              link2={{
                pathname: `/supplier-profile/${supplier.id}`,
                state: { selectedSupplier: supplier },
              }}
              // onClick2={() => handleSelectSupplier(supplier.id)}
            />
          );
        })}
      </div>
      <div className="d-flex justify-content-end align-items-center flex-wrap">
            {supplierDataIntegration?.totalPages > 1 && (
              <Paginator
                total={supplierDataIntegration.totalPages}
                current={page}
                onChangePage={handleChangePage}
              />
            )}
          </div>
    </>
  );
};
export default Suppliers;
