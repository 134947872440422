import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Modal, Dropdown, Badge, Stack, Spinner, Alert } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useStateValue } from '../../../../store/selectors/useStateValue'
import { setProductsAction } from '../../../../store/actions/ProductActions'
import { initialFormState } from '../../customForms/AddProductForm'
import { config } from '../../../../connector/config'
import { onDeleteSwal } from '../../utils/onDeleteSwal'
import { useGetAppSettings } from '../../../../data/account-data'
import { round } from 'lodash'
import {
  productDetailsRequest,
  deleteDataRequest,
  businessSuppliersListRequest,
} from '../../../../connector/httpConnector/httpRequest'
import UploadbuttonDocumentsProductDetails from '../ProductDetail/UploadbuttonDocumentsProductDetails'
import UploadbuttonDesignProductDetails from '../ProductDetail/UploadbuttonDesignProductDetails'
import UploadbuttonProductDetails from '../ProductDetail/UploadbuttonProductDetails'
import UploadDesignFormProductDetails from '../ProductDetail/UploadDesignFormProductDetails'
import UploadImagesFormProductDetails from '../ProductDetail/UploadImagesFormProductDetails'
import UploadDocumentsFormProductDetails from '../ProductDetail/UploadDocumentsFormProductDetails'
import IntroCard from './IntroCard/IntroCard'
import EditProductForm from '../../customForms/EditProductForm'
import AssignSupplierForm from '../../customForms/AssignSupplierForm'
import LoadingSpinner from '../../elements/LoadingSpinner/LoadingSpinner'
import swal from 'sweetalert'
import {
  useAssignSupplier,
  useFetchSuppliers,
  useFetchSuppliersMutaion,
} from '../../../../data/suppliers-data'
import {
  useDeleteProduct,
  useFetchProductDetails,
} from '../../../../data/product-data'
import { useMutation, useQueryClient } from 'react-query'
import bg1 from './../../../../images/big/img1.jpg'
import { useDebounce } from 'react-use'
import {
  useFetchSuppliersProducts,
  useUnlinkProductTwo,
} from '../../../../data/suppliers-data'
import {
  useAddProduct,
  useFetchProducts,
  useForgetGetProductData,
  useHandleGetProduct,
  useHandleUploadProductDesign,
  useHandleUploadProductDoc,
  useHandleUploadProductImage,
  useUpdateProduct,
  useDeleteProductDesign,
  useDeleteProductImage,
  useDeleteProductDocuments,
  useDeleteProductDetailEditDesign,
  useDeleteProductDetailEditImage,
  useDeleteProductDetailEditDocuments,
  useUploadProductImage,
  useUploadProductDesign,
  useUploaddocumentDesign,
} from '../../../../data/product-data'
import { useFormik } from 'formik'
import { Loader } from 'react-bootstrap-typeahead'
const __int = (val) => {
  if (val === undefined || val === null) {
    return null
  }
  if (typeof val === 'string' && val.trim() === '') {
    return ''
  }
  return Number(val)
}

const __isUrl = (str) => {
  try {
    let url = new URL(str)
  } catch (_) {
    return false
  }
}

const ProductDetail = () => {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  // const { pageNumber } = location.state;
  const dispatch = useDispatch()
  const { products, auth } = useStateValue()
  const isBusinessUser = auth.userType === 'business'
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [productsData, setProductsData] = useState(products.productsState)
  const [connectedSuppliersData, setConnectedSuppliersData] = useState()
  const [newSupplierEmail, setNewSupplierEmail] = useState('')
  const [selectSupplierEmail, setSelectSupplierEmail] = useState('')
  const [supplierType, setSupplierType] = useState('new')
  const [product, setProduct] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchSupplier, setSearchSupplier] = useState()
  const fetchSupplierData = useFetchSuppliersMutaion()
  const fetchProductDetailsData = useFetchProductDetails({ productId: id })
  const DeleteProductDetailsDesignData = useDeleteProductDesign()
  const DeleteProductDetailsImagesData = useDeleteProductImage()
  const DeleteProductEditDocumentsData = useDeleteProductDetailEditDocuments({
    onSuccess: () =>
      getProductData.mutate({
        productId: product.id,
        documentsId: document.id,
      }),
  })
  const DeleteProductEditDesignData = useDeleteProductDetailEditDesign({
    onSuccess: () =>
      getProductData.mutate({ productId: product.id, designsId: document.id }),
  })
  const DeleteProductEditImagesData = useDeleteProductDetailEditImage({
    onSuccess: () =>
      getProductData.mutate({ productId: product.id, imagesId: document.id }),
  })
  const DeleteProductDetailsDocumentsData = useDeleteProductDocuments()
  const uploadProductImage = useUploadProductImage()
  const uploadProductDesign = useUploadProductDesign()
  const UploaddocumentDesign = useUploaddocumentDesign()
  // const [assignedSuppliersData, setAssignedSuppliersData] = useState(
  //   product?.suppliers
  // );
  const [selectUnlinkProduct, setSelectUnlinkProduct] = useState()
  const unlinkProductData = useUnlinkProductTwo({
    onSuccess: () => setUnlinkProductModal(false),
  })
  const [unlinkProductModal, setUnlinkProductModal] = useState()
  const { baseURL, businessGetProducts, businessSuppliersList } = config
  const [supplierToDelete, setSupplierToDelete] = useState(null)
  const handleUnlinkSupplier = (e, supplier) => {
    // Set the supplier to be deleted in state
    setSupplierToDelete(supplier)
    // Open the modal
    setUnlinkProductModal(true)
  }
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get('page')) || 1
  )
  const [integratedProductsData, setIntegratedProductsData] = useState()
  const [productDataa, setProducts] = useState(products.productsState)
  const [sort, setSort] = useState([
    [
      new URLSearchParams(window.location.search).get('sort[0][0]') ||
        'createdAt',
      new URLSearchParams(window.location.search).get('sort[0][1]') || 'desc',
    ],
  ])
  const [productSearchQuery, setProductSearchQuery] = useState(
    new URLSearchParams(window.location.search).get('search') || undefined
  )
  const [imagesModal, setImagesModal] = useState(false)
  const [designModal, setDesignModal] = useState(false)
  const [documentModal, setDocumentModal] = useState(false)
  // edit  data
  const [editFormData, setEditFormData] = useState(initialFormState)
  //Edit Modal
  const [editModal, setEditModal] = useState(false)

  // Edit function editable page loop
  const [editProductId, setEditProductId] = useState(null)

  const fetchAppSettings = useGetAppSettings()
  const [currencyFormat, setCurrencyFormat] = useState(
    Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    })
  )
  const [docFile, setDocFile] = useState()
  const [imageFile, setImageFile] = useState()
  const [designFile, setDesignFile] = useState()
  const MAX_CHAR_LENGTH = 75
  const token = auth.auth.accessToken
  const addProductData = useAddProduct()
  const getProductData = useHandleGetProduct()
  const forgetGetProduct = useForgetGetProductData()
  const uploadDocFileData = useHandleUploadProductDoc()
  const uploadImageFileData = useHandleUploadProductImage()
  const uploadDesignFileData = useHandleUploadProductDesign()
  const updateProductData = useUpdateProduct()
  const deleteProductData = useDeleteProduct()

  const productsQueryData = useFetchProducts({
    currPage: page,
    perPage: 12,
    sort,
    search: productSearchQuery,
  })

  const editProductFormik = useFormik({
    initialValues: editFormData,
    onSubmit: (values) => {
      const editedProductData = {
        productName: values.productName,
        productThumb: values.productThumb,
        productASIN: values.productASIN,
        productWidth: __int(values.productWidth),
        productHeight: __int(values.productHeight),
        productLength: __int(values.productLength),
        productWeight: __int(values.productWeight),
        productSize: values.productSize,
        productManufacturingCost: __int(values.productManufacturingCost),
        shippingModeAirCost: __int(values.shippingModeAirCost),
        shippingModeSeaCost: __int(values.shippingModeSeaCost),
        shippingModeLandCost: __int(values.shippingModeLandCost),
        productManufacturingDays: __int(values.productManufacturingDays),
        fastestShippingDays: __int(values.fastestShippingDays),
        shippingHandlingDays: __int(values.shippingHandlingDays),
        myWarehouseStock: __int(values.myWarehouseStock),
        dailySales: __int(values.dailySales),
        amazonIntegrated: values.amazonIntegrated,
      }
      let keys = Object.keys(editedProductData)
      keys.forEach((key, i) => {
        const val = editedProductData[key]
        if (val === undefined || val === null) {
          delete editedProductData[key]
        }
        if (__isUrl(val)) {
          delete editedProductData[key]
        }
        if (val === '') {
          delete editedProductData[key]
        }
      })
      if (editedProductData['productHeight'] !== undefined) {
        editedProductData['productHeightUnit'] = 'cm'
      }
      if (editedProductData['productLength'] !== undefined) {
        editedProductData['productLengthUnit'] = 'cm'
      }
      if (editedProductData['productWidth'] !== undefined) {
        editedProductData['productWidthUnit'] = 'cm'
      }
      if (editedProductData['productWeight'] !== undefined) {
        editedProductData['productWeightUnit'] = 'cm'
      }

      if (editedProductData.amazonIntegrated === true) {
        delete editedProductData.productASIN
        delete editedProductData.productSKU
        delete editedProductData.productName
        delete editedProductData.productThumb
        delete editedProductData.dailySales
      }
      delete editedProductData.amazonIntegrated
      const newFormData = new FormData()
      keys = Object.keys(editedProductData)
      keys.forEach((key, i) => {
        const val = editedProductData[key]
        newFormData.append(key, val)
      })
      updateProductData.mutate({ productId: editProductId, data: newFormData })
    },
    enableReinitialize: true,
  })

  useEffect(() => {
    if (!fetchAppSettings.isLoading) {
      if (fetchAppSettings.isSuccess) {
        setCurrencyFormat(
          Intl.NumberFormat(undefined, {
            style: 'currency',
            currency:
              fetchAppSettings?.data?.data?.data?.[0]?.currency || 'USD',
            currencyDisplay: 'narrowSymbol',
          })
        )
      }
    }
  }, [
    fetchAppSettings.data,
    fetchAppSettings.isLoading,
    fetchAppSettings.isSuccess,
  ])

  useEffect(() => {
    if (!updateProductData.isLoading) {
      if (updateProductData.isSuccess) {
        swal('Good job!', 'Successfully Updated', 'success')
        setEditModal(false)
      }
      if (updateProductData.isError) {
        swal('Oops', updateProductData.error['response'].data.message, 'error')
      }
      updateProductData.reset()
    }
  }, [
    updateProductData.data,
    updateProductData.isLoading,
    updateProductData.isSuccess,
    updateProductData.isError,
  ])

  useEffect(() => {
    if (productsQueryData.isSuccess) {
      let data = productsQueryData.data.data.data
      setIntegratedProductsData(data)
      setProducts(data.products)
      dispatch(setProductsAction(data.products))
    } else {
      setIntegratedProductsData({})
      setProducts([])
      dispatch(setProductsAction([]))
    }
  }, [
    productsQueryData.data,
    productsQueryData.isLoading,
    productsQueryData.isError,
    productsQueryData.isSuccess,
  ])

  // Edit function button click to edit
  const handleEditClick = (event, product) => {
    event.preventDefault()
    // setEditProductId(product.id);
    // forgetGetProduct();
    getProductData.mutate({ productId: product.id })
    setEditFormData({})
    setEditProductId(null)
  }

  useEffect(() => {
    if (!getProductData.isLoading) {
      if (getProductData.isSuccess) {
        let product = getProductData.data.data.data
        const formValues = {
          productName: product.productName,
          productSKU: product.productSKU,
          productThumb: product.productThumb,
          productASIN: product.productASIN,
          productWidth: product.productWidth,
          productHeight: product.productHeight,
          productLength: product.productLength,
          productWeight: product.productWeight,
          productSize: product.productSize,
          productManufacturingCost: product.productManufacturingCost,
          shippingModeAirCost: product.shippingModeAirCost,
          shippingModeSeaCost: product.shippingModeSeaCost,
          shippingModeLandCost: product.shippingModeLandCost,
          productManufacturingDays: product.productManufacturingDays,
          fastestShippingDays: product.fastestShippingDays,
          shippingHandlingDays: product.shippingHandlingDays,
          myWarehouseStock: product.myWarehouseStock,
          dailySales: product.dailySales,
          Documents: product.Documents,
          Designs: product.Designs,
          Images: product.Images,
          amazonIntegrated: product.amazonIntegrated,
        }
        setEditFormData(formValues)
        setEditModal(true)
        setEditProductId(product.id)
      }
    }
  }, [
    getProductData.data,
    getProductData.isLoading,
    getProductData.isSuccess,
    getProductData.isError,
  ])

  const handleUploadDocFile = () => {
    const newDocData = new FormData()
    newDocData.append('productDocFile', docFile)
    uploadDocFileData.mutate({ productId: editProductId, data: newDocData })
  }

  const handleUploadImageFile = () => {
    const newImageData = new FormData()
    newImageData.append('productImageFile', imageFile)
    uploadImageFileData.mutate({ productId: editProductId, data: newImageData })
  }

  const handleUploadDesignFile = () => {
    const newDesignData = new FormData()
    newDesignData.append('productDesignFile', designFile)
    uploadDesignFileData.mutate({
      productId: editProductId,
      data: newDesignData,
    })
  }

  useEffect(() => {
    if (uploadDesignFileData.isSuccess) {
      getProductData.mutate({ productId: editProductId })
      uploadDesignFileData.reset()
      setDesignFile(null)
    }
  }, [
    uploadDesignFileData.data,
    uploadDesignFileData.isLoading,
    uploadDesignFileData.isSuccess,
    uploadDesignFileData.isError,
  ])

  useEffect(() => {
    if (uploadImageFileData.isSuccess) {
      getProductData.mutate({ productId: editProductId })
      uploadImageFileData.reset()
      setImageFile(null)
    }
  }, [
    uploadImageFileData.data,
    uploadImageFileData.isLoading,
    uploadImageFileData.isSuccess,
    uploadImageFileData.isError,
  ])

  useEffect(() => {
    if (uploadDocFileData.isSuccess) {
      getProductData.mutate({ productId: editProductId })
      uploadDocFileData.reset()
      setDocFile(null)
    }
  }, [
    uploadDocFileData.data,
    uploadDocFileData.isLoading,
    uploadDocFileData.isSuccess,
    uploadDocFileData.isError,
  ])

  // useEffect(() => {
  //   setLoading(true);
  //   productDetailsRequest(baseURL, token, businessGetProducts, id, "get")
  //     .then((data) => {
  //       console.log("Product detail data", data);
  //       setProduct(data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setLoading(false);
  //     });
  // }, [baseURL, businessGetProducts, id, token]);
  useEffect(() => {
    if (!fetchProductDetailsData.isLoading) {
      if (fetchProductDetailsData.isSuccess) {
        setProduct(fetchProductDetailsData.data.data.data)
      }
      if (fetchSupplierData.isError) {
        setProduct([])
      }
    }
  }, [
    fetchProductDetailsData.data,
    fetchProductDetailsData.isLoading,
    fetchProductDetailsData.isSuccess,
    fetchProductDetailsData.isError,
  ])

  useDebounce(
    () => {
      if (isBusinessUser) {
        fetchSupplierData.mutate({
          currPage: 1,
          perPage: 12,
          search: searchSupplier,
        })
      }
    },
    300,
    [searchSupplier]
  )

  useEffect(() => {
    if (isBusinessUser) {
      if (!fetchSupplierData.isLoading) {
        if (fetchSupplierData.isSuccess) {
          setConnectedSuppliersData(fetchSupplierData.data.data.suppliers)
        }
        if (fetchSupplierData.isError) {
          setConnectedSuppliersData([])
        }
      }
    }
  }, [
    fetchSupplierData.data,
    fetchSupplierData.isLoading,
    fetchSupplierData.isSuccess,
    fetchSupplierData.isError,
  ])

  const updatedproductDispatch = () => {
    const updatedProducts = [...productsData]
    let itemIndex = updatedProducts.findIndex((item) => item.id === product.id)
    updatedProducts[itemIndex] = product
    dispatch(setProductsAction(updatedProducts))
  }

  // delete data
  const [deleteModal, setDeleteModal] = useState(false)

  // delete data submit
  const handleDeleteSubmit = async () => {
    // try {
    //   await deleteDataRequest(baseURL, token, businessGetProducts, id);
    //   swal("Good job!", "Successfully deleted", "success");
    //   setDeleteModal(false);
    //   // history.push(`/products?page=${pageNumber}`);
    // } catch (error) {
    //   console.log(error);
    //   swal("Oops", "Something went wrong", "error");
    // }
    deleteProductData.mutate({ productId: id })
  }

  useEffect(() => {
    if (!deleteProductData.isLoading) {
      if (deleteProductData.isSuccess) {
        history.push(`/products`)
        swal('Good job!', 'Successfully deleted', 'success')
      }
      if (deleteProductData.isError) {
        swal(
          'oops!',
          deleteProductData?.error?.response?.data?.message,
          'error'
        )
      }
      // deleteProductData.reset();
    }
  }, [
    deleteProductData.isLoading,
    deleteProductData.data,
    deleteProductData.isError,
    deleteProductData.isSuccess,
    deleteProductData.error,
  ])
  //For Image upload in ListBlog
  const [file, setFile] = useState(null)
  const fileHandler = (e) => {
    setFile(e.target.files[0])
    setTimeout(function () {
      let src = document.getElementById('saveImageFile').getAttribute('src')
      editFormData.productThumb = src
    }, 200)
  }
  // assign supplier
  const [assignSupplierModal, setAssignSupplierModal] = useState(false)
  const [addSupplierData, setAddSupplierData] = useState({
    supplierInfo: {},
  })
  const [selectedSupplierId, setSelectedSupplierId] = useState('')
  const assignSupplierMutation = useAssignSupplier()
  // assign supplier change function
  const handleAssignSupplierChange = (event) => {
    event.stopPropagation()
    const supplierName = event.target.value
    const supplierId =
      event.target.options[event.target.selectedIndex].getAttribute('data-id')
    let newFormData = { ...addSupplierData }
    newFormData = {
      supplierName: supplierName,
      supplierId: supplierId,
    }
    setAddSupplierData(newFormData)
    setSelectedSupplierId(supplierId)
  }

  // assign supplier submit function
  const handleAssignSupplierSubmit = async (event) => {
    event.preventDefault()
    let error = false
    let errorMsg = ''
    const permissionData = {
      productId: product.id,
      email: '', // Initialize the email field
    }

    if (supplierType === 'existing') {
      if (selectSupplierEmail === 'select') {
        error = true
        errorMsg = 'Please select an Existing Supplier'
      } else {
        permissionData.email = connectedSuppliersData.find(
          (ele) => ele.id == selectSupplierEmail.value
        ).email // Use selected supplier email
      }
    } else {
      if (!newSupplierEmail) {
        error = true
        errorMsg = 'Please enter the Supplier Email'
      } else {
        permissionData.email = newSupplierEmail // Use new supplier email
      }
    }

    // if (addSupplierData.supplierName === "") {
    //   error = true;
    //   errorMsg = "Please select a Supplier";
    // }
    if (!error) {
      try {
        await assignSupplierMutation.mutateAsync(permissionData)
        setSelectSupplierEmail()
        setNewSupplierEmail()
        setSupplierType('new')
        swal('Good job!', 'Invitation successfully sent', 'success')
      } catch (error) {
        swal('Oops', JSON.parse(error?.request?.response)?.message, 'error')
      }
      setAssignSupplierModal(false)
    } else {
      swal('Oops', errorMsg, 'error')
    }
  }
  //designe delete
  const handleDeleteDesign = async (productId, designId) => {
    try {
      await DeleteProductDetailsDesignData.mutateAsync({
        productId: productId,
        designsId: designId,
        data: {},
      })
    } catch (error) {
      console.error('Error deleting image', error)
    }
  }

  //images delete
  const handleDeleteImage = async (productId, imagesId) => {
    try {
      await DeleteProductDetailsImagesData.mutateAsync({
        productId: productId,
        imagesId: imagesId,
        data: {},
      })
    } catch (error) {
      console.error('Error deleting image', error)
    }
  }

  //Documents delete
  const handleDeleteDocuments = async (productId, documentsId) => {
    try {
      await DeleteProductDetailsDocumentsData.mutateAsync({
        productId: productId,
        documentsId: documentsId,
        data: {},
      })
    } catch (error) {
      console.error('Error deleting image', error)
    }
  }

  const handleDeleteDesignEdit = async (productId, designId) => {
    try {
      await DeleteProductEditDesignData.mutateAsync({
        productId: productId,
        designsId: designId,
        data: {},
      })
    } catch (error) {
      console.error('Error deleting image', error)
    }
  }

  //images delete
  const handleDeleteImageEdit = async (productId, imagesId) => {
    try {
      await DeleteProductEditImagesData.mutateAsync({
        productId: productId,
        imagesId: imagesId,
        data: {},
      })
    } catch (error) {
      console.error('Error deleting image', error)
    }
  }

  //Documents delete
  const handleDeleteDocumentsEdit = async (productId, documentsId) => {
    try {
      await DeleteProductEditDocumentsData.mutateAsync({
        productId: productId,
        documentsId: documentsId,
        data: {},
      })
    } catch (error) {
      console.error('Error deleting image', error)
    }
  }

  // Handle add image change
  const handleImageFileChange = (e) => {
    console.log(e.target.files[0].size)
    if (e.target.files[0].size > 5242880)
      swal('Oops', 'Image size must be less than 5MB.', 'error')
    else setFile(e.target.files[0])
  }
  // Add image submit data
  const handleAddImageSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()
    let error = false
    let errorMsg = ''
    if (file === null) {
      error = true
      errorMsg = 'Please upload an image'
    }
    if (!error) {
      const imageData = new FormData()
      imageData.append('productImageFile', file)

      try {
        const productId = id
        await uploadProductImage.mutateAsync({ productId, data: imageData })
        setLoading(false)
        swal('Good job!', 'Image uploaded', 'success')
        setFile(null)
        setImagesModal(false)
      } catch (error) {
        console.error(error.message)
        swal('Oops', 'Something went wrong', 'error')
      }
    } else {
      swal('Oops', errorMsg, 'error')
    }
  }

  // Handle add design change
  const handleDesignFileChange = (e) => {
    setFile(e.target.files[0])
  }
  // Add image design data
  const handleAddDesignSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()
    let error = false
    let errorMsg = ''
    if (file === null) {
      error = true
      errorMsg = 'Please upload an image'
    }
    if (!error) {
      const imageData = new FormData()
      imageData.append('productDesignFile', file)

      try {
        const productId = id
        await uploadProductDesign.mutateAsync({ productId, data: imageData })
        setLoading(false)
        swal('Good job!', 'Design File uploaded', 'success')
        setFile(null)
        setImagesModal(false)
      } catch (error) {
        console.error(error.message)
        swal('Oops', 'Something went wrong', 'error')
      }
    } else {
      console.error(error)
      swal('Oops', errorMsg, 'error')
    }
  }

  // Handle add documents change
  const handleDocumentFileChange = (e) => {
    setFile(e.target.files[0])
  }
  // Add image documents data
  const handleAddDocumentSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()
    let error = false
    let errorMsg = ''
    if (file === null) {
      error = true
      errorMsg = 'Please upload an image'
    }
    if (!error) {
      const imageData = new FormData()
      imageData.append('productDocFile', file)

      try {
        const productId = id
        await UploaddocumentDesign.mutateAsync({ productId, data: imageData })
        setLoading(false)
        swal('Good job!', 'DocFile uploaded', 'success')
        setFile(null)
        setImagesModal(false)
      } catch (error) {
        console.error(error.message)
        swal('Oops', 'Something went wrong', 'error')
      }
    } else {
      console.error(error)
      swal('Oops', errorMsg, 'error')
    }
  }
  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="mb-4">
          <Modal
            className="modal fade"
            show={editModal}
            onHide={() => setEditModal(false)}
            size="xl"
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Edit Product</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setEditModal(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={editProductFormik.handleSubmit}>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box edit_product_form">
                  <div className="add-contact-content">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="image-placeholder mb-4">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              // onChange={fileHandler}
                              id="imageUpload"
                              name="productThumb"
                              // onClick={(event) => setFile(event.target.value)}
                              onChange={(event) =>
                                event.currentTarget.files[0].size > 5242880
                                  ? swal(
                                      'Oops',
                                      'Image size must be less than 5MB',
                                      'error'
                                    )
                                  : editProductFormik.setFieldValue(
                                      'productThumb',
                                      event.currentTarget.files[0]
                                    )
                              }
                            />
                            <label htmlFor="imageUpload" name=""></label>
                          </div>
                          <div className="avatar-preview">
                            <div id="imagePreview">
                              {editProductFormik.values.productThumb && (
                                <img
                                  id="saveImageFile"
                                  src={
                                    typeof editProductFormik.values
                                      .productThumb === 'object'
                                      ? URL.createObjectURL(
                                          editProductFormik.values.productThumb
                                        )
                                      : editProductFormik.values.productThumb
                                  }
                                  alt={
                                    editProductFormik.values.productThumb
                                      ? editProductFormik.values.productThumb
                                          .name
                                      : null
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 input-primary">
                          <label className="text-black font-w500">
                            Name{typeof editProductFormik.values.productThumb}
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              name="productName"
                              required="required"
                              value={editProductFormik.values.productName}
                              onChange={editProductFormik.handleChange}
                              disabled={
                                editProductFormik.isLoading ||
                                editFormData.amazonIntegrated === true
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group mb-3 input-primary">
                          <label className="text-black font-w500">SKU</label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              name="productSKU"
                              required="required"
                              value={editProductFormik.values.productSKU}
                              onChange={editProductFormik.handleChange}
                              disabled={
                                editProductFormik.isLoading ||
                                editFormData.amazonIntegrated === true
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group mb-3 input-primary">
                          <label className="text-black font-w500">ASIN</label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              name="productASIN"
                              value={editProductFormik.values.productASIN}
                              onChange={editProductFormik.handleChange}
                              disabled={
                                editProductFormik.isLoading ||
                                editFormData.amazonIntegrated === true
                              }
                            />
                          </div>
                        </div>
                        <div className="text-black bg-primary card card--form_element">
                          <div className="card-header">
                            <div className="text-black card-title h5">
                              Dimensions & Weight
                            </div>
                          </div>
                          <div className="mb-0 card-body">
                            <div className="row">
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Width
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="productWidth"
                                      style={{ width: 0 }}
                                      value={round(
                                        editProductFormik.values.productWidth,
                                        2
                                      )}
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">cm</span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Height
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="productHeight"
                                      style={{ width: 0 }}
                                      value={round(
                                        editProductFormik.values.productHeight,
                                        2
                                      )}
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">cm</span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Length
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="productLength"
                                      style={{ width: 0 }}
                                      value={round(
                                        editProductFormik.values.productLength,
                                        2
                                      )}
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">cm</span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Weight
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="productWeight"
                                      style={{ width: 0 }}
                                      value={round(
                                        editProductFormik.values.productWeight,
                                        2
                                      )}
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      lbs
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-black bg-primary card card--form_element">
                          <div className="card-header">
                            <div className="text-black card-title h5">
                              Manufacturing
                            </div>
                          </div>
                          <div className="mb-0 card-body">
                            <div className="row">
                              <div className="form-group col-md-6 mb-4">
                                <label className="text-black font-w500">
                                  Cost per unit
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <span className="input-group-text">
                                      {currencyFormat
                                        .format(0)
                                        .replace(/[\d,.]/g, '')
                                        .trim()}
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="productManufacturingCost"
                                      style={{ width: 0 }}
                                      value={
                                        editProductFormik.values
                                          .productManufacturingCost
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={
                                        updateProductData.isLoading ||
                                        editFormData.amazonIntegrated
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-primary card card--form_element">
                          <div className="card-header">
                            <div className="card-title h5">Order Settings</div>
                          </div>
                          <div className="mb-0 card-body">
                            <p className="">Turn on/off Required Fields</p>
                            <div className="row">
                              <div className="col-xl-4 col-xxl-6 col-6">
                                <div className="form-check custom-checkbox mb-3 checkbox-success">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckBox1"
                                    required=""
                                    checked
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customCheckBox1"
                                  >
                                    Expiry Date
                                  </label>
                                </div>
                              </div>
                              <div className="col-xl-4 col-xxl-6 col-6">
                                <div className="form-check custom-checkbox mb-3 checkbox-success">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckBox1"
                                    required=""
                                    checked
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customCheckBox1"
                                  >
                                    Expiry Date
                                  </label>
                                </div>
                              </div>
                              <div className="col-xl-4 col-xxl-6 col-6">
                                <div className="form-check custom-checkbox mb-3 checkbox-success">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckBox1"
                                    required=""
                                    checked
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customCheckBox1"
                                  >
                                    Expiry Date
                                  </label>
                                </div>
                              </div>
                              <div className="col-xl-4 col-xxl-6 col-6">
                                <div className="form-check custom-checkbox mb-3 checkbox-success">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckBox1"
                                    required=""
                                    checked
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customCheckBox1"
                                  >
                                    Expiry Date
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-black bg-primary card card--form_element">
                          <div className="card-header">
                            <div className="text-black card-title h5">
                              Shipping Cost
                            </div>
                          </div>
                          <div className="mb-0 card-body">
                            <div className="row">
                              <div className="form-group col-md-4 mb-4">
                                <label className="text-blawhiteck font-w500">
                                  Air
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <span className="input-group-text">
                                      {currencyFormat
                                        .format(0)
                                        .replace(/[\d,.]/g, '')
                                        .trim()}
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="shippingModeAirCost"
                                      style={{ width: 0 }}
                                      value={
                                        editProductFormik.values
                                          .shippingModeAirCost
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Sea
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <span className="input-group-text">
                                      {currencyFormat
                                        .format(0)
                                        .replace(/[\d,.]/g, '')
                                        .trim()}
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="shippingModeSeaCost"
                                      style={{ width: 0 }}
                                      value={
                                        editProductFormik.values
                                          .shippingModeSeaCost
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-4 mb-3">
                                <label className="text-black font-w500">
                                  Land
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <span className="input-group-text">
                                      {currencyFormat
                                        .format(0)
                                        .replace(/[\d,.]/g, '')
                                        .trim()}
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="shippingModeLandCost"
                                      style={{ width: 0 }}
                                      value={
                                        editProductFormik.values
                                          .shippingModeLandCost
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-black border_primary card card--form_element no_bg">
                          <div className="card-header">
                            <div className="text-black card-title h5">
                              Attachments
                            </div>
                          </div>
                          <div className="mb-0 card-body">
                            <div className="form-group mb-4">
                              <div>
                                {editProductFormik.values.Designs?.map(
                                  (doc) => (
                                    <div
                                      key={doc.id}
                                      className="d-flex align-items-center mb-2"
                                    >
                                      <button
                                        type="button"
                                        className="btn mr-2"
                                        style={{ color: '#FC2E53', padding: 4 }}
                                        onClick={() =>
                                          onDeleteSwal('file', () =>
                                            handleDeleteDesignEdit(
                                              doc.productId,
                                              doc.id
                                            )
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-minus-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <Badge
                                        pill
                                        variant="primary"
                                        className="mb-1 mx-1"
                                      >
                                        {(() => {
                                          let url_arr = doc.fileSource
                                            .split('?')[0]
                                            .split('/')
                                          let fname =
                                            url_arr[url_arr.length - 1]
                                          return fname.slice(-50)
                                        })()}
                                      </Badge>
                                    </div>
                                  )
                                )}
                              </div>
                              <label className="text-black font-w500">
                                Designs
                              </label>
                              <div className="contact-name">
                                <div className="input-group mb-3">
                                  <div className="form-file">
                                    <input
                                      type="file"
                                      className="custom-file-input form-control"
                                      onChange={(event) => {
                                        setDesignFile(
                                          event.currentTarget.files[0]
                                        )
                                      }}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    onClick={handleUploadDesignFile}
                                    disabled={
                                      uploadDesignFileData.isLoading ||
                                      !designFile
                                    }
                                  >
                                    {uploadDesignFileData.isLoading && (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                      ></Spinner>
                                    )}
                                    <span className="ms-1">Upload</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-4">
                              <div>
                                {editProductFormik.values.Images?.map((doc) => (
                                  <div
                                    key={doc.id}
                                    className="d-flex align-items-center mb-2"
                                  >
                                    <button
                                      type="button"
                                      className="btn mr-2"
                                      style={{ color: '#FC2E53', padding: 4 }}
                                      onClick={() =>
                                        onDeleteSwal('file', () =>
                                          handleDeleteImageEdit(
                                            doc.productId,
                                            doc.id
                                          )
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-minus-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <Badge
                                      pill
                                      variant="primary"
                                      className="mb-1 mx-1"
                                    >
                                      {(() => {
                                        let url_arr = doc.fileSource
                                          .split('?')[0]
                                          .split('/')
                                        let fname = url_arr[url_arr.length - 1]
                                        return fname.slice(-50)
                                      })()}
                                    </Badge>
                                  </div>
                                ))}
                              </div>
                              <label className="text-black font-w500">
                                Images
                              </label>
                              <div className="contact-name">
                                <div className="input-group mb-3">
                                  <div className="form-file">
                                    <input
                                      type="file"
                                      className="custom-file-input form-control"
                                      onChange={(event) => {
                                        setImageFile(
                                          event.currentTarget.files[0]
                                        )
                                      }}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    onClick={handleUploadImageFile}
                                    disabled={
                                      uploadImageFileData.isLoading ||
                                      !imageFile
                                    }
                                  >
                                    {uploadImageFileData.isLoading && (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                      ></Spinner>
                                    )}
                                    <span className="ms-1">Upload</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-4">
                              <div>
                                {editProductFormik.values.Documents?.map(
                                  (doc) => (
                                    <div
                                      key={doc.id}
                                      className="d-flex align-items-center mb-2"
                                    >
                                      <button
                                        type="button"
                                        className="btn mr-2" // Added mr-2 class
                                        style={{ color: '#FC2E53', padding: 4 }}
                                        onClick={() =>
                                          onDeleteSwal('file', () =>
                                            handleDeleteDocumentsEdit(
                                              doc.productId,
                                              doc.id
                                            )
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-minus-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <Badge
                                        pill
                                        variant="primary"
                                        className="mb-1 mx-1"
                                      >
                                        {(() => {
                                          let url_arr = doc.fileSource
                                            .split('?')[0]
                                            .split('/')
                                          let fname =
                                            url_arr[url_arr.length - 1]
                                          return fname.slice(-50)
                                        })()}
                                      </Badge>
                                    </div>
                                  )
                                )}
                              </div>
                              <label className="text-black font-w500">
                                Documents
                              </label>
                              <div className="contact-name">
                                <div className="input-group mb-3">
                                  <div className="form-file">
                                    <input
                                      type="file"
                                      className="custom-file-input form-control"
                                      onChange={(event) => {
                                        setDocFile(event.currentTarget.files[0])
                                      }}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    onClick={handleUploadDocFile}
                                    disabled={
                                      uploadDocFileData.isLoading || !docFile
                                    }
                                  >
                                    {uploadDocFileData.isLoading && (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                      ></Spinner>
                                    )}
                                    <span className="ms-1">Upload</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-black bg-primary card card--form_element">
                          <div className="card-header">
                            <div className="text-black card-title h5">
                              Inventory Settings
                            </div>
                          </div>
                          <div className="mb-0 card-body">
                            <p className="text-black">
                              This section helps to notify when the next batch
                              need to be ordered.
                            </p>
                            <div className="row">
                              <div className="form-group col-md-12 mb-4">
                                <label className="text-black font-w500">
                                  My Warehouse Stock
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="myWarehouseStock"
                                      style={{ width: 0 }}
                                      value={
                                        editProductFormik.values
                                          .myWarehouseStock
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      Units
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mb-4">
                                <label className="text-text-black font-w500">
                                  My Average Sales(Non-Amazon)
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      style={{ width: 0 }}
                                      autoComplete="off"
                                      name="dailySales"
                                      value={
                                        editProductFormik.values.dailySales
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      Units
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-6 mb-4">
                                <label className="text-text-black font-w500">
                                  Manufacturing Time
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="productManufacturingDays"
                                      style={{ width: 0 }}
                                      value={
                                        editProductFormik.values
                                          .productManufacturingDays || 7
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      Days
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-6 mb-3">
                                <label className="text-black font-w500">
                                  Fastest Shipping Time
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="fastestShippingDays"
                                      style={{ width: 0 }}
                                      value={
                                        editProductFormik.values
                                          .fastestShippingDays || 7
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      Days
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-md-6 mb-3">
                                <label className="text-black font-w500">
                                  Shipment Handling Time
                                </label>
                                <div className="contact-name">
                                  <div className="input-group input-white">
                                    <input
                                      type="text"
                                      className="form-control"
                                      autoComplete="off"
                                      name="shippingHandlingDays"
                                      style={{ width: 0 }}
                                      value={
                                        editProductFormik.values
                                          .shippingHandlingDays || 2
                                      }
                                      onChange={editProductFormik.handleChange}
                                      disabled={updateProductData.isLoading}
                                    />
                                    <span className="input-group-text">
                                      Days
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 col-6">
                                <div className="form-check custom-checkbox mb-3 checkbox-success">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckBox1"
                                    required=""
                                    checked
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customCheckBox1"
                                  >
                                    Notify when to order
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {/* { updateProductData.isError &&
                        <Alert variant="danger" 
                        >{updateProductData.error['response'].data.message}
                        </Alert>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={editProductFormik.handleSubmit}
                disabled={updateProductData.isLoading}
              >
                {updateProductData.isLoading && (
                  <Spinner size="sm" as="span" animation="border" />
                )}
                <span className="ms-1">Save</span>
              </button>
              <button
                type="button"
                onClick={() => setEditModal(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Discard
              </button>
            </div>
          </Modal>
          <Modal
            className="modal fade"
            show={deleteModal}
            onHide={setDeleteModal}
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">
                Are you sure you want to delete <b>{product?.productName}</b>?
              </h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setDeleteModal(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                onClick={handleDeleteSubmit}
                className="btn btn-danger"
                disabled={deleteProductData.isLoading}
              >
                {/* <i className="flaticon-delete-1"></i> */}
                {deleteProductData.isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    className="me-2"
                    size="sm"
                  />
                )}
                <span>Delete</span>
              </button>
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setDeleteModal(false)}
              >
                Cancel
              </button>
            </div>
          </Modal>
          <Modal
            className="modal fade"
            show={assignSupplierModal}
            onHide={() => setAssignSupplierModal(false)}
          >
            <div className="modal-header">
              <h4 className="modal-title fs-20">Assign Supplier</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setAssignSupplierModal(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <AssignSupplierForm
              supplierOptions={connectedSuppliersData}
              // onChangeSupplier={handleAssignSupplierChange}
              selectSupplierEmail={selectSupplierEmail}
              setSelectSupplierEmail={setSelectSupplierEmail}
              newSupplierEmail={newSupplierEmail}
              setNewSupplierEmail={setNewSupplierEmail}
              supplierType={supplierType}
              setSupplierType={setSupplierType}
              loading={assignSupplierMutation.isLoading}
              searchSupplier={searchSupplier}
              setSearchSupplier={setSearchSupplier}
            />
            <div className="modal-footer">
              <button
                type="submit"
                onClick={handleAssignSupplierSubmit}
                className="btn btn-success"
                disabled={assignSupplierMutation.isLoading}
              >
                {assignSupplierMutation.isLoading && (
                  <Spinner size="sm" animation="border" as="span" />
                )}
                <i className="fa fa-link color-success ms-1"></i> Assign
              </button>
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setAssignSupplierModal(false)}
              >
                Cancel
              </button>
            </div>
          </Modal>
        </div>
      </div>
      {fetchProductDetailsData.isLoading ? (
        <LoadingSpinner size={150} />
      ) : (
        <>
          <IntroCard
            productThumb={product?.productThumb}
            productName={product?.productName}
            productSKU={product?.productSKU}
            productASIN={product?.productASIN}
            onEditProduct={(event) => handleEditClick(event, product)}
            onAssignSupplier={() => setAssignSupplierModal(true)}
            onDeleteProduct={() => setDeleteModal(true)}
            isDeleteDisabled={product?.amazonIntegrated}
            isBusinessUser={isBusinessUser}
          />
          <div className="row">
            {isBusinessUser && (
              <div className="col-xl-4 col-lg-6 col-sm-12">
                <div className="card overflow-hidden">
                  <div
                    className="text-center p-3 overlay-box"
                    style={{ backgroundImage: `url(${bg1})` }}
                  >
                    <h3 className="mt-3 mb-1 text-black">Suppliers</h3>
                  </div>
                  <ul className="list-group list-group-flush">
                    {product.Suppliers &&
                      Array.isArray(product.Suppliers) &&
                      product.Suppliers.map((supplier) => (
                        <li
                          key={supplier.id}
                          className="list-group-item d-flex justify-content-between"
                        >
                          <h4 className="mb-0">{supplier.name}</h4>
                          <button
                            type="button"
                            className="me-2 btn btn-xs btn-danger btn-rounded"
                            onClick={(e) => handleUnlinkSupplier(e, supplier)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
            <Modal
              className="modal fade"
              show={unlinkProductModal}
              onHide={() => setUnlinkProductModal(false)}
            >
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  Are you sure you want to disconnect this product from{' '}
                  <b>{supplierToDelete?.name}</b>?
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setUnlinkProductModal(false)}
                  data-dismiss="modal"
                ></button>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    // Close the modal
                    // setUnlinkProductModal(false);
                    // Delete the supplier here
                    if (supplierToDelete) {
                      const permission = product.Suppliers.find(
                        (sup) => sup.id === supplierToDelete.id
                      ).Permission
                      unlinkProductData.mutate({
                        productId: product.id,
                        permissionId: permission.id,
                      })
                    }
                    // Refresh the page
                    // window.location.reload();
                  }}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"></i> Delete
                </button>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => setUnlinkProductModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Modal>
            <div className="col-xl-8 col-lg-6 col-sm-12">
              <div className="row">
                <div className="col-lg-5">
                  <div className="text-white bg-primary card">
                    <div className="card-header">
                      <div className="text-white card-title h5">
                        Manufacturing
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="profile-statistics">
                        <div className="text-center">
                          <div className="row">
                            <div className="col text-white">
                              <h2 className="m-b-0 text-white font-w700">
                                {currencyFormat.format(
                                  product?.productManufacturingCost
                                )}
                              </h2>
                              <span>per unit</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="text-white bg-primary card">
                    <div className="card-header">
                      <div className="text-white card-title h5">
                        Shipping per Unit
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="profile-statistics">
                        <div className="text-center">
                          <div className="row">
                            <div className="col text-white">
                              <h3 className="m-b-0 text-white">
                                {currencyFormat.format(
                                  product?.shippingModeAirCost
                                )}
                              </h3>
                              <span>Air</span>
                            </div>
                            <div className="col text-white">
                              <h3 className="m-b-0 text-white">
                                {currencyFormat.format(
                                  product?.shippingModeSeaCost
                                )}
                              </h3>{' '}
                              <span>Sea</span>
                            </div>
                            <div className="col text-white">
                              <h3 className="m-b-0 text-white">
                                {currencyFormat.format(
                                  product?.shippingModeLandCost
                                )}
                              </h3>{' '}
                              <span>Ground</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title h5">Dimensions</div>
                    </div>
                    <div className="card-body">
                      <div className="profile-statistics">
                        <div className="text-center">
                          <div className="row">
                            <div className="col ">
                              <h3 className="m-b-0">
                                {round(product?.productWidth, 2)} cm
                              </h3>
                              <span>Width</span>
                            </div>
                            <div className="col">
                              <h3 className="m-b-0">
                                {round(product?.productHeight, 2)} cm
                              </h3>{' '}
                              <span>Height</span>
                            </div>
                            <div className="col">
                              <h3 className="m-b-0">
                                {round(product?.productLength, 2)} cm
                              </h3>{' '}
                              <span>Length</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title h5">Weight</div>
                    </div>
                    <div className="card-body">
                      <div className="profile-statistics">
                        <div className="text-center">
                          <div className="row">
                            <div className="col">
                              <h3 className="m-b-0">
                                {round(product?.productWeight, 2)} lbs
                              </h3>{' '}
                              <span>Weight</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="row"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h5 className="text-black ">Design Files</h5>
                    </div>
                    <div className="card-body pt-3">
                      <span className="fw-bold">Design</span>
                      <div
                        className="final-badge"
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                      >
                        {product.Designs &&
                          product.Designs.map((design) => (
                            <div key={design.id}>
                              <button
                                type="button"
                                className="btn"
                                style={{ color: '#FC2E53', padding: 4 }}
                                onClick={() =>
                                  onDeleteSwal('file', () =>
                                    handleDeleteDesign(id, design.id)
                                  )
                                }
                              >
                                <i
                                  className="fa fa-minus-circle"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <span className="badge text-black border">
                                <i className="far fa-file-alt me-3"></i>
                                <a
                                  href={design.fileSource}
                                  target="_blank"
                                  rel="noreferrer"
                                  download={design.fileSource}
                                >
                                  {
                                    design.fileSource
                                      .split('/')
                                      .pop()
                                      .split('?')[0]
                                  }
                                </a>
                              </span>
                            </div>
                          ))}
                      </div>
                      <UploadbuttonDesignProductDetails
                        onClickUpload={() => setDesignModal(true)}
                      />
                    </div>
                    <Modal className="modal fade" show={designModal}>
                      <UploadDesignFormProductDetails
                        onClickCancel={() => setDesignModal(false)}
                        onChangeFile={handleDesignFileChange}
                        onClickSubmit={handleAddDesignSubmit}
                      />
                    </Modal>
                    <Modal className="modal fade" show={imagesModal}>
                      <UploadImagesFormProductDetails
                        onClickCancel={() => setImagesModal(false)}
                        onChangeFile={handleImageFileChange}
                        onClickSubmit={handleAddImageSubmit}
                      />
                    </Modal>

                    <div className="card-body" style={{ paddingTop: '0px' }}>
                      <span className="fw-bold">Image</span>
                      <div
                        className="final-badge"
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                      >
                        {product.Images &&
                          product.Images.map((image) => (
                            <div key={image.id}>
                              <button
                                type="button"
                                className="btn"
                                style={{ color: '#FC2E53', padding: 4 }}
                                onClick={() =>
                                  onDeleteSwal('file', () =>
                                    handleDeleteImage(id, image.id)
                                  )
                                }
                              >
                                <i
                                  className="fa fa-minus-circle"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <span className="badge text-black border">
                                <i className="fas fa-image me-3"></i>
                                <a
                                  href={image.fileSource}
                                  target="_blank"
                                  rel="noreferrer"
                                  download={image.fileSource}
                                >
                                  {
                                    image.fileSource
                                      .split('/')
                                      .pop()
                                      .split('?')[0]
                                  }
                                </a>
                              </span>
                            </div>
                          ))}
                      </div>
                      <UploadbuttonProductDetails
                        onClickUpload={() => setImagesModal(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal className="modal fade" show={documentModal}>
              <UploadDocumentsFormProductDetails
                onClickCancel={() => setDocumentModal(false)}
                onChangeFile={handleDocumentFileChange}
                onClickSubmit={handleAddDocumentSubmit}
              />
            </Modal>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h5 className="text-black ">Documents</h5>
                    </div>
                    <div className="card-body pt-3">
                      <div
                        className="final-badge"
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                      >
                        {product.Documents &&
                          product.Documents.map((Document) => (
                            <div key={Document.id}>
                              <button
                                type="button"
                                className="btn"
                                style={{ color: '#FC2E53', padding: 4 }}
                                onClick={() =>
                                  onDeleteSwal('file', () =>
                                    handleDeleteDocuments(id, Document.id)
                                  )
                                }
                              >
                                <i
                                  className="fa fa-minus-circle"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <span className="badge text-black border">
                                <i className="far fa-file-alt me-3"></i>
                                <a
                                  href={Document.fileSource}
                                  target="_blank"
                                  rel="noreferrer"
                                  download={Document.fileSource}
                                >
                                  {
                                    Document.fileSource
                                      .split('/')
                                      .pop()
                                      .split('?')[0]
                                  }
                                </a>
                              </span>
                            </div>
                          ))}
                      </div>
                      <UploadbuttonDocumentsProductDetails
                        onClickUpload={() => setDocumentModal(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ProductDetail
