import { useMutation, useQuery,useQueryClient } from "react-query";
import { useStateValue } from "../store/selectors/useStateValue";
import { config } from "../connector/config";
import http from "./http";

export const useGetPlanDetails= () => {
    const { auth } = useStateValue();
    return useQuery(['fetchUserPlanDetails', auth.auth.accessToken],() => { 
        return  http().request({
            url: `${config.baseURL}/business/plans`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
        })
    })
};

export const useGetPaymentDetails= () => {
    const { auth } = useStateValue();
    return useQuery(['fetchUserPaymentDetails', auth.auth.accessToken],() => { 
        return  http().request({
            url: `${config.baseURL}/business/payment-methods`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
        })
    })
};


export const useUploadPaymentdetails = () => {
    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({data}) => {
        return http().request({
            url: `${config.baseURL}/business/payment-methods`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        });
    },{
        onSuccess: () => {
            queryClient.invalidateQueries('fetchUserPaymentDetails')
        }
    });
}

export const useGetSubscriptionDetails= () => {
    const { auth } = useStateValue();
    return useQuery(['fetchUserSubscriptionDetails', auth.auth.accessToken],() => { 
        return  http().request({
            url: `${config.baseURL}/business/subscriptions/invoices`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            },
        })
    })
};
export const useSubscribePlan = () => {
     const { auth }    = useStateValue();
     const queryClient = useQueryClient();
     return useMutation(({planId}) => {
        return http().request({
            url: `${config.baseURL}/business/subscriptions/`,
            method: 'POST',
            data: {
                 planId
            },
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        });
     },{
         onSuccess: () =>{
             queryClient.invalidateQueries('fetchUserPlanDetails');
             queryClient.invalidateQueries('fetchUserSubscriptionDetails');
         }
     })
}
