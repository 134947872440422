import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { nonPaginatedDataRequest } from '../../../connector/httpConnector/httpRequest'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { Badge } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useStateValue } from '../../../store/selectors/useStateValue'
import { config } from '../../../connector/config'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useGetAppSettings } from '../../../data/account-data'
import { usersRequest } from '../../../connector/httpConnector/httpRequest'
import { useFetchRecentOrdersData } from '../../../data/recent-orders-data'
import { indexOf } from 'lodash'
import LoadingSpinner from '../elements/LoadingSpinner/LoadingSpinner'
import { setSelectedOrderIdAction } from '../../../store/actions/orderActions'

import dhl from './../../../images/freight/dhl.png'
import fedex from './../../../images/freight/fedex.png'
import ups from './../../../images/freight/ups.png'
import usps from './../../../images/freight/usps.png'
import defaultIcon from './../../../images/freight/default-icon.png'

//Images
import amzlogo from './../../../images/amazon-logo.png'

//Import Components
import { ThemeContext } from '../../../context/ThemeContext'
const CompletionApexChart = loadable(() =>
  pMinDelay(import('./Dashboard/CompletionApexChart'), 1000)
)

const Home = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { changeBackground, changePrimaryColor } = useContext(ThemeContext)
  const { auth } = useStateValue()
  const { baseURL, adminGetUsers, getSellerAccountsList } = config
  const [, setUsers] = useState()
  const [, setLoading] = useState(false)
  const [sellerAccountsData, setSellerAccountsData] = useState()

  const fetchAppSettings = useGetAppSettings()
  const fetchRecentOrdersData = useFetchRecentOrdersData()
  const [orders, setOrders] = useState(
    fetchRecentOrdersData?.data?.data?.data?.orders
  )
  const user = auth.auth
  const token = auth.auth.accessToken
  const statusArray = [
    'pre_transit',
    'in_transit',
    'available_for_pickup',
    'out_for_delivery',
    'delivered',
  ]
  const [currencyFormat, setCurrencyFormat] = useState(
    Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    })
  )

  const getCarrierIcon = (carrier) => {
    switch (carrier) {
      case 'DHL_EXPRESS_USA_INC': {
        return dhl
      }
      case 'FEDERAL_EXPRESS_CORP': {
        return fedex
      }
      case 'UNITED_STATES_POSTAL_SERVICE': {
        return usps
      }
      case 'UNITED_PARCEL_SERVICE_INC': {
        return ups
      }
      default: {
        return defaultIcon
      }
    }
  }

  const fetchSellerAccount = useCallback(() => {
    nonPaginatedDataRequest(baseURL, token, getSellerAccountsList)
      .then((data) => {
        setSellerAccountsData(data.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }, [baseURL, getSellerAccountsList, token])

  function convertStatusToReadable(status) {
    const statusMap = {
      unknown: 'Unknown',
      pre_transit: 'Pre Transit',
      in_transit: 'In Transit',
      out_for_delivery: 'Out For Delivery',
      delivered: 'Delivered',
      available_for_pickup: 'Available for Pickup',
      return_to_sender: 'Return to Sender',
      failure: 'Failure',
      cancelled: 'Cancelled',
      error: 'Error',
    }

    return statusMap[status] || status
  }

  const navigateToOrderDetails = (event, id) => {
    event.preventDefault()
    history.push({
      pathname: `/order-detail/${id}`,
      state: { pageNumber: 1 },
    })
    dispatch(setSelectedOrderIdAction(id))
  }

  useEffect(() => {
    fetchSellerAccount()
    if (!fetchRecentOrdersData?.isLoading) {
      if (fetchRecentOrdersData?.isSuccess) {
        setOrders(fetchRecentOrdersData?.data?.data?.data?.orders)
      }
    }
  }, [
    fetchSellerAccount.isLoading,
    fetchSellerAccount.success,
    fetchSellerAccount,
    fetchRecentOrdersData?.data,
    fetchRecentOrdersData?.isLoading,
    fetchRecentOrdersData?.isSuccess,
  ])

  useEffect(() => {
    if (!fetchAppSettings.isLoading) {
      if (fetchAppSettings.isSuccess) {
        setCurrencyFormat(
          Intl.NumberFormat(undefined, {
            style: 'currency',
            currency:
              fetchAppSettings?.data?.data?.data?.[0]?.currency || 'USD',
            currencyDisplay: 'narrowSymbol',
          })
        )
      }
    }
  }, [
    fetchAppSettings.data,
    fetchAppSettings.isLoading,
    fetchAppSettings.isSuccess,
  ])

  useEffect(() => {
    changeBackground({ value: 'light', label: 'Light' })
    auth.userType === 'supplier' && changePrimaryColor('color_6')
    if (auth.userType === 'admin') {
      setLoading(true)
      usersRequest(baseURL, token, adminGetUsers)
        .then((data) => {
          setUsers(data)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        })
    }
  }, [baseURL, token, adminGetUsers])
  const redirectToAmazonSellerCentral = () => {
    const redirectToAmazonSellerCentralUrl = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.25ab9c5d-6391-442d-a7d1-618f6764749c&state=na&version=beta`
    window.location.href = redirectToAmazonSellerCentralUrl
  }
  return fetchRecentOrdersData?.isLoading ? (
    <LoadingSpinner size={150} />
  ) : (
    <>
      {orders && (
        <div className="row">
          {orders.length !== 0 && (
            <span className="d-block fs-14 font-w600 text-success mb-3">
              Live Tracking
            </span>
          )}
          {orders?.slice(0, 4)?.map((order) => (
            <div className="col-xl-3 col-lg-3 col-sm-4">
              <div className="card">
                <div className="card-body d-flex px-4 justify-content-between">
                  <div className="">
                    <div
                      className="d-flex justify-content-between mb-3"
                      onClick={(e) => navigateToOrderDetails(e, order.id)}
                    >
                      <div className="d-flex align-items-center project-image">
                        <img src={order.Product.productThumb} alt="" />
                        <div>
                          <span className="d-block fs-16 text-black font-w600">
                            {order.Product.productSKU}
                          </span>
                          <span className="d-block fs-14 font-w600">
                            {order.Product.productName.substring(0, 50)}
                          </span>
                          <span className="d-block fs-12 font-w600">
                            {order.orderUnits} Units
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <div className="d-flex align-items-center project-image">
                        <img
                          src={getCarrierIcon(order.Boxes?.[0]?.carrierName)}
                          alt=""
                        />
                        <div>
                          <span className="d-block fs-16 text-black font-w600">
                            {order.Boxes?.filter(
                              (box) => box.trackingInfo !== null
                            )?.[0]?.trackingInfo
                              ? convertStatusToReadable(
                                  order.Boxes?.filter(
                                    (box) => box.trackingInfo !== null
                                  )?.[0]?.trackingInfo?.tracking_details[
                                    order.Boxes?.filter(
                                      (box) => box.trackingInfo !== null
                                    )?.[0]?.trackingInfo?.tracking_details
                                      ?.length - 1
                                  ]?.status
                                )
                              : ''}
                          </span>
                          <div
                            className="progress mb-2"
                            style={{ width: '175px' }}
                          >
                            <div
                              className="progress-bar progress-animated bg-success"
                              style={{
                                width: `${
                                  ((order.Boxes?.filter(
                                    (box) => box.trackingInfo !== null
                                  )?.[0]?.trackingInfo
                                    ? statusArray.indexOf(
                                        order.Boxes?.filter(
                                          (box) => box.trackingInfo !== null
                                        )?.[0]?.trackingInfo?.tracking_details[
                                          order.Boxes?.filter(
                                            (box) => box.trackingInfo !== null
                                          )?.[0]?.trackingInfo?.tracking_details
                                            ?.length - 1
                                        ]?.status
                                      )
                                    : 0) /
                                    4) *
                                  100
                                }%`,
                              }}
                            ></div>
                          </div>
                          <span className="d-block fs-12 font-w500">
                            {order.Boxes?.filter(
                              (box) => box.trackingInfo !== null
                            )?.[0]?.trackingInfo
                              ? order.Boxes?.filter(
                                  (box) => box.trackingInfo !== null
                                )?.[0]?.trackingInfo?.tracking_details[
                                  order.Boxes?.filter(
                                    (box) => box.trackingInfo !== null
                                  )?.[0]?.trackingInfo?.tracking_details
                                    ?.length - 1
                                ]?.message
                              : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    {order?.Shipment?.SubShipments?.[0] && (
                      <div className="d-flex align-items-center project-image mb-3">
                        <img
                          src="/static/media/amazon.57cfc15445053c2fdb9e.png"
                          alt=""
                        />
                        <div>
                          <span className="d-block fs-16 text-black font-w600">
                            {order?.Shipment?.SubShipments?.[0]?.shipmentStatus}
                          </span>
                          <div
                            className="progress mb-2"
                            style={{ width: '175px' }}
                          >
                            <div
                              className="progress-bar progress-animated"
                              style={{
                                width: `${
                                  (100 *
                                    (1 +
                                      indexOf(
                                        [
                                          'WORKING',
                                          'SHIPPED',
                                          'CHECKED_IN',
                                          'RECEIVING',
                                          'CLOSED',
                                        ],
                                        order?.Shipment?.SubShipments?.[0]
                                          ?.shipmentStatus
                                      ))) /
                                  5
                                }%`,
                              }}
                            ></div>
                          </div>
                          <span className="d-block fs-12 font-w500">
                            Delivered to{' '}
                            {
                              order?.Shipment?.SubShipments?.[0]
                                ?.fulfillmentCenterId
                            }
                            {order?.Shipment?.SubShipments?.[0]
                              ?.destinationAddressLineOne
                              ? ', ' +
                                order?.Shipment?.SubShipments?.[0]
                                  ?.destinationAddressLineOne
                              : ''}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="row">
        {fetchRecentOrdersData?.data?.data?.data?.ordersWithOutSort && (
          <div className="col-xl-6">
            <div className="card">
              {auth.userType === 'business' ? (
                <>
                  <div className="card-header  border-0 pb-0">
                    <h4 className="card-title">Recent Orders </h4>
                  </div>
                  <div className="card-body">
                    <PerfectScrollbar
                      style={{ height: '300px' }}
                      id="DZ_W_Todo1"
                      className="widget-media dlab-scroll ps ps--active-y"
                    >
                      <ul className="timeline">
                        {fetchRecentOrdersData?.data?.data.data
                          .ordersWithOutSort
                          ? fetchRecentOrdersData?.data.data.data.ordersWithOutSort.map(
                              (order) => (
                                <li
                                  key={order.id}
                                  onClick={(e) =>
                                    navigateToOrderDetails(e, order.id)
                                  }
                                >
                                  <div className="timeline-panel">
                                    <div className="media me-2">
                                      <img
                                        alt=""
                                        width="50"
                                        src={order.Product.productThumb}
                                      />
                                    </div>
                                    <div className="media-body">
                                      <h5 className="mb-1">
                                        {order.Product.productName.substring(
                                          0,
                                          100
                                        )}
                                      </h5>
                                      <small className="d-block">
                                        {`${order.orderUnits} - ${
                                          order.shippingMode
                                        } - ${currencyFormat.format(
                                          order.Product.productPrice
                                        )}`}
                                      </small>
                                    </div>
                                    <Badge variant="danger badge-md light">
                                      {order.orderStatus}
                                    </Badge>
                                  </div>
                                </li>
                              )
                            )
                          : null}
                      </ul>
                    </PerfectScrollbar>
                  </div>
                </>
              ) : (
                <>
                  <div className="card-header  border-0 pb-0">
                    <h4 className="card-title">New Orders</h4>
                  </div>
                  <div className="card-body">
                    <PerfectScrollbar
                      style={{ height: '300px' }}
                      id="DZ_W_Todo1"
                      className="widget-media dlab-scroll ps ps--active-y"
                    >
                      <ul className="timeline">
                        {fetchRecentOrdersData?.data?.data.data
                          .ordersWithOutSort
                          ? fetchRecentOrdersData?.data.data.data.ordersWithOutSort.map(
                              (order) => (
                                <li>
                                  <div className="timeline-panel">
                                    <div className="media me-2">
                                      <img
                                        alt=""
                                        width="50"
                                        src={order.Product.productThumb}
                                      />
                                    </div>
                                    <div className="media-body">
                                      <h5 className="mb-1">
                                        {order.Product.productName.substring(
                                          0,
                                          100
                                        )}
                                      </h5>
                                      <small className="d-block">
                                        {`${order.orderUnits} - ${
                                          order.shippingMode
                                        } - ${currencyFormat.format(
                                          order.Product.productPrice
                                        )}`}
                                      </small>
                                    </div>
                                    <Badge variant="danger badge-md light">
                                      {order.orderStatus}
                                    </Badge>
                                  </div>
                                </li>
                              )
                            )
                          : null}
                      </ul>
                    </PerfectScrollbar>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {!sellerAccountsData?.length && auth.userType === 'business' && (
          <>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card tryal-gradient">
                    <>
                      <div className="card-body tryal row">
                        <div className="col-xl-7 col-sm-6">
                          <h2 className="text-black">
                            Connect Your Seller Account
                          </h2>
                          <span>
                            Link your Amazon Seller Central account with bluudo
                            and streamline your operations. Effortlessly create
                            FBA shipments and live-track your shipments.
                            Additionally, track your orders and inventory levels
                            in real-time with our cutting-edge automated
                            systems.
                          </span>
                          <button
                            onClick={redirectToAmazonSellerCentral}
                            className="btn btn-rounded fs-18 font-w500 bg-white text-black position-relative font-weight-bold "
                            style={{ zIndex: '3' }}
                          >
                            Connect
                          </button>
                        </div>
                        <div className="col-xl-5 col-sm-6">
                          <img src={amzlogo} alt="" className="sd-shape" />
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="row">
                <div class="col-xl-12">
                  <div class="row">
                    <div class="col-xl-6 col-sm-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="text-center pt-4 pb-4">
                            <h4 className="fs-22 font-w600 mb-3">
                              or... add a product
                            </h4>
                            <Link
                              to="/products"
                              className="btn btn-rounded fs-18 font-w500 btn-primary text-white position-relative font-weight-bold"
                              style={{ zIndex: '3' }}
                            >
                              + Add Product
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-xl-6 col-sm-6">
                      {(fetchRecentOrdersData?.data?.data?.data
                        ?.totalOrderNowUnits > 0 ||
                        fetchRecentOrdersData?.data?.data?.data
                          ?.totalInWaitingInvoice > 0) && (
                        <div className="card">
                          <div className="card-body d-flex px-4 justify-content-between">
                            {auth.userType === 'business' ? (
                              <>
                                <div>
                                  <div className="">
                                    <span className="d-block fs-14 font-w600 text-primary">
                                      Inventory
                                    </span>
                                    <h2 className="fs-32 font-w700 text-danger">
                                      {
                                        fetchRecentOrdersData?.data?.data?.data
                                          ?.totalOrderNowUnits
                                      }
                                    </h2>
                                    <span className="fs-18 font-w500 d-block">
                                      Risk of going
                                    </span>
                                    <span className="d-block fs-16 font-w400">
                                      Out of Stock
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <div className="">
                                    <span className="d-block fs-14 font-w600 text-primary">
                                      Orders
                                    </span>
                                    <h2 className="fs-32 font-w700 text-danger">
                                      {
                                        fetchRecentOrdersData?.data?.data?.data
                                          ?.totalInWaitingInvoice
                                      }
                                    </h2>
                                    <span className="fs-18 font-w500 d-block">
                                      Waiting
                                    </span>
                                    <span className="d-block fs-16 font-w400">
                                      Invoice
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {(fetchRecentOrdersData?.data?.data?.data
                      ?.totalInAddShipmentDetails > 0 ||
                      fetchRecentOrdersData?.data?.data?.data
                        ?.totalAttentionNowUnits > 0) && (
                      <div className="col-xl-6 col-sm-6">
                        <div className="card">
                          <div className="card-body d-flex px-4 justify-content-between">
                            {auth.userType === 'business' ? (
                              <>
                                <div>
                                  <div className="">
                                    <span className="d-block fs-14 font-w600 text-primary">
                                      Inventory
                                    </span>
                                    <h2 className="fs-32 font-w700 text-warning">
                                      {
                                        fetchRecentOrdersData?.data?.data?.data
                                          ?.totalAttentionNowUnits
                                      }
                                    </h2>
                                    <span className="fs-18 font-w500 d-block">
                                      Attention
                                    </span>
                                    <span className="d-block fs-16 font-w400">
                                      Order Now
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <div className="">
                                    <span className="d-block fs-14 font-w600 text-primary">
                                      Orders
                                    </span>
                                    <h2 className="fs-32 font-w700 text-warning">
                                      {
                                        fetchRecentOrdersData?.data?.data?.data
                                          ?.totalInAddShipmentDetails
                                      }
                                    </h2>
                                    <span className="fs-18 font-w500 d-block">
                                      Waiting
                                    </span>
                                    <span className="d-block fs-16 font-w400">
                                      Shipment Details
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {(fetchRecentOrdersData?.data?.data?.data
                      ?.totalInAddTrackingDetails > 0 ||
                      fetchRecentOrdersData?.data?.data?.data?.balance12 >
                        0) && (
                      <div className="col-xl-6 col-sm-6">
                        <div className="card">
                          <div className="card-body d-flex px-4  justify-content-between">
                            {auth.userType === 'business' ? (
                              <>
                                <div>
                                  <div className="">
                                    <span className="d-block fs-14 font-w600 text-primary">
                                      Products
                                    </span>
                                    <h2 className="fs-32 font-w700 text-danger">
                                      {currencyFormat.format(
                                        fetchRecentOrdersData?.data?.data?.data
                                          ?.balance12
                                      )}
                                    </h2>
                                    <span className="fs-18 font-w500 d-block">
                                      Pending
                                    </span>
                                    <span className="d-block fs-16 font-w400">
                                      Payment
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <div className="">
                                    <span className="d-block fs-14 font-w600 text-primary">
                                      Orders
                                    </span>
                                    <h2 className="fs-32 font-w700 text-danger">
                                      {
                                        fetchRecentOrdersData?.data?.data?.data
                                          ?.totalInAddTrackingDetails
                                      }
                                    </h2>
                                    <span className="fs-18 font-w500 d-block">
                                      Waiting
                                    </span>
                                    <span className="d-block fs-16 font-w400">
                                      Tracking Details
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {(fetchRecentOrdersData?.data?.data?.data
                      ?.totalInAddTrackingDetails > 0 ||
                      fetchRecentOrdersData?.data?.data?.data?.balance12 > 0) &&
                      (fetchRecentOrdersData?.data?.data?.data
                        ?.totalInAddShipmentDetails > 0 ||
                        fetchRecentOrdersData?.data?.data?.data
                          ?.totalAttentionNowUnits > 0) &&
                      (fetchRecentOrdersData?.data?.data?.data
                        ?.totalOrderNowUnits ||
                        fetchRecentOrdersData?.data?.data?.data
                          ?.totalInWaitingInvoice > 0) && (
                        <div className="col-xl-6 col-sm-6">
                          <div className="card">
                            <div className="card-body d-flex px-4  justify-content-between">
                              {auth.userType === 'business' ? (
                                <>
                                  <div>
                                    <div className="">
                                      <span className="d-block fs-14 font-w600 text-primary">
                                        &nbsp;
                                      </span>
                                      <h2 className="fs-32 font-w700 text-danger">
                                        &nbsp;
                                      </h2>
                                      <span className="fs-18 font-w500 d-block">
                                        &nbsp;
                                      </span>
                                      <span className="d-block fs-16 font-w400">
                                        &nbsp;
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <div className="">
                                      <span className="d-block fs-14 font-w600 text-primary">
                                        &nbsp;
                                      </span>
                                      <h2 className="fs-32 font-w700 text-danger">
                                        &nbsp;
                                      </h2>
                                      <span className="fs-18 font-w500 d-block">
                                        &nbsp;
                                      </span>
                                      <span className="d-block fs-16 font-w400">
                                        &nbsp;
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {auth.userType === 'business' && (
          <>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row">
                    {fetchRecentOrdersData?.data?.data?.data
                      ?.totalInReviewCount > 0 && (
                      <div className="col-xl-6 col-sm-6">
                        <div className="card">
                          <div className="card-body d-flex px-4 justify-content-between">
                            <div>
                              <div className="">
                                <span className="d-block fs-14 font-w600 text-primary">
                                  Orders
                                </span>
                                <h2 className="fs-32 font-w700 text-danger">
                                  {
                                    fetchRecentOrdersData?.data?.data?.data
                                      ?.totalInReviewCount
                                  }
                                </h2>
                                <span className="fs-18 font-w500 d-block">
                                  Waiting
                                </span>
                                <span className="d-block fs-16 font-w400">
                                  Review Invoice
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {fetchRecentOrdersData?.data?.data?.data
                      ?.totalInPaymentCount > 0 && (
                      <div className="col-xl-6 col-sm-6">
                        <div className="card">
                          <div className="card-body d-flex px-4 justify-content-between">
                            <div>
                              <div className="">
                                <span className="d-block fs-14 font-w600 text-primary">
                                  Orders
                                </span>
                                <h2 className="fs-32 font-w700 text-warning">
                                  {
                                    fetchRecentOrdersData?.data?.data?.data
                                      ?.totalInPaymentCount
                                  }
                                </h2>
                                <span className="fs-18 font-w500 d-block">
                                  Waiting
                                </span>
                                <span className="d-block fs-16 font-w400">
                                  Update Payment
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {fetchRecentOrdersData?.data?.data?.data
                      ?.totalInShipmentCreationCount > 0 && (
                      <div className="col-xl-6 col-sm-6">
                        <div className="card">
                          <div className="card-body d-flex px-4 justify-content-between">
                            <div>
                              <div className="">
                                <span className="d-block fs-14 font-w600 text-primary">
                                  Orders
                                </span>
                                <h2 className="fs-32 font-w700 text-danger">
                                  {
                                    fetchRecentOrdersData?.data?.data?.data
                                      ?.totalInShipmentCreationCount
                                  }
                                </h2>
                                <span className="fs-18 font-w500 d-block">
                                  Waiting
                                </span>
                                <span className="d-block fs-16 font-w400">
                                  Create Shipment
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {fetchRecentOrdersData?.data?.data?.data
                      ?.totalInShipmentCreationCount > 0 &&
                      fetchRecentOrdersData?.data?.data?.data
                        ?.totalInReviewCount > 0 &&
                      fetchRecentOrdersData?.data?.data?.data
                        ?.totalInPaymentCount > 0 && (
                        <div className="col-xl-6 col-sm-6">
                          <div className="card">
                            <div className="card-body d-flex px-4 justify-content-between">
                              <div>
                                <div className="">
                                  <span className="d-block fs-14 font-w600 text-primary">
                                    &nbsp;
                                  </span>
                                  <h2 className="fs-32 font-w700 text-danger">
                                    &nbsp;
                                  </h2>
                                  <span className="fs-18 font-w500 d-block">
                                    &nbsp;
                                  </span>
                                  <span className="d-block fs-16 font-w400">
                                    &nbsp;
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {auth.userType === 'business' && (
          <>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-xl-6 col-sm-6">
                      {fetchRecentOrdersData?.data?.data?.data?.totalCost30 >
                        0 && (
                        <div className="card">
                          <div className="card-body d-flex px-4  justify-content-between">
                            <div>
                              <div className="">
                                <span className="d-block fs-14 font-w600 text-primary">
                                  Payments
                                </span>
                                <h2 className="fs-32 font-w700">
                                  {currencyFormat.format(
                                    fetchRecentOrdersData?.data?.data?.data
                                      ?.totalCost30
                                  )}
                                </h2>
                                <span className="fs-18 font-w500 d-block">
                                  Order Total
                                </span>
                                <span className="d-block fs-16 font-w400">
                                  Last 30 Days
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {fetchRecentOrdersData?.data?.data?.data
                      ?.totalOrderUnits30 > 0 && (
                      <div className="col-xl-6 col-sm-6">
                        <div className="card">
                          <div className="card-body d-flex px-4  justify-content-between">
                            <div>
                              <div className="">
                                <span className="d-block fs-14 font-w600 text-primary">
                                  Orders
                                </span>
                                <h2 className="fs-32 font-w700">
                                  {
                                    fetchRecentOrdersData?.data?.data?.data
                                      ?.totalOrderUnits30
                                  }
                                </h2>
                                <span className="fs-18 font-w500 d-block">
                                  Units Ordered
                                </span>
                                <span className="d-block fs-16 font-w400">
                                  {/* <small className="text-success">+2%</small>  */}
                                  Last 30 Days
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {fetchRecentOrdersData?.data?.data?.data
                      ?.totalAvailableUnits > 0 && (
                      <div className="col-xl-6 col-sm-6">
                        <div className="card">
                          <div className="card-body d-flex px-4  justify-content-between">
                            <div>
                              <div className="">
                                <span className="d-block fs-14 font-w600 text-primary">
                                  Inventory
                                </span>
                                <h2 className="fs-32 font-w700">
                                  {
                                    fetchRecentOrdersData?.data?.data?.data
                                      ?.totalAvailableUnits
                                  }
                                </h2>
                                <span className="fs-18 font-w500 d-block">
                                  Total
                                </span>
                                <span className="d-block fs-16 font-w400">
                                  Available Units
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {fetchRecentOrdersData?.data?.data?.data
                      ?.totalInboundUnits && (
                      <div className="col-xl-6 col-sm-6">
                        <div className="card">
                          <div className="card-body d-flex px-4  justify-content-between">
                            <div>
                              <div className="">
                                <span className="d-block fs-14 font-w600 text-primary">
                                  Inventory
                                </span>
                                <h2 className="fs-32 font-w700 text-primary">
                                  {
                                    fetchRecentOrdersData?.data?.data?.data
                                      ?.totalInboundUnits
                                  }
                                </h2>
                                <span className="fs-18 font-w500 d-block">
                                  Total
                                </span>
                                <span className="d-block fs-16 font-w400">
                                  Inbound
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}
export default Home
