import React, { Fragment, useState, useEffect } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useStateValue } from "../../../store/selectors/useStateValue";
import { useFetchClientProducts } from "../../../data/suppliers-data";
import { useParams } from 'react-router-dom';
import { filter } from "lodash";
import HeaderBanner from "../molecules/HeaderBanner/HeaderBanner";

const ClientProfile = () => {
	const { clients, client } = useStateValue();
	const [linkedClientProductData, setLinkedClientProductData] = useState([]);
	const [pendingClientProductData, setPendingClientProductData] = useState([]);
	const [clientName, setClientName] = useState("");

	let { id } = useParams();

	const clientsFetchProductData = useFetchClientProducts(id);
	console.log(clientsFetchProductData, "clientsFetchProductData")

	useEffect(
		() => {
			if (clientsFetchProductData.isLoading === false) {
				if (clientsFetchProductData.isSuccess) {
					setLinkedClientProductData(filter(clientsFetchProductData?.data?.data?.clientProducts, function (product) { return product?.accepted; }));
					setPendingClientProductData(filter(clientsFetchProductData?.data?.data?.clientProducts, function (product) { return !product?.accepted; }));
					setClientName(clientsFetchProductData?.data?.data?.clientName);
				}
				if (clientsFetchProductData.isError) {
					setLinkedClientProductData([]);
					setPendingClientProductData([]);
					setClientName("");
				}
			}
		}, [clientsFetchProductData.data, clientsFetchProductData.isLoading, clientsFetchProductData.isSuccess, clientsFetchProductData.isError]
	)

	return (
		<Fragment>
			<div className="row">
				<div className="col-lg-12">
					<div className="profile card card-body px-3 pt-3 pb-0">
						<div className="profile-head">
							<div className="photo-content ">
								<HeaderBanner heading={clientName} />
							</div>
							<div className="profile-info">
								<div className="profile-details">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xl-12">
					{linkedClientProductData && (
						<div>
							<span className="d-block fs-14 font-w600 text-primary mb-3">
								Linked Products
							</span>
							<div className="row">
								{linkedClientProductData.map((product) => (
									<div className="col-sm-4" key={product.id}>
										<div className="widget-stat card supplier_products">
											<div className="card-body p-4">
												<div className="media ai-icon">
													<span className="me-3 bgl-primary text-primary">
														<a href={`/product-detail/${product.Product.id}`}><img src={product.Product.pictures} alt="" class="rounded-circle" /></a>
													</span>
													<div className="media-body">
														<p className="mb-1 fs-14 supplier_products__sku">{product.Product.sku}</p>
														<span className="d-block fs-12 mb-3 ms-0">{product.Product.asin}</span>
														<div className="badge badge-success">LINKED</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>)}
					<span className="d-block fs-14 font-w600 text-danger mb-3">
						Pending Connection
					</span>
					<div className="row">
						{pendingClientProductData.map((product) => (
							<div className="col-sm-4" key={product.id}>
								<div className="widget-stat card supplier_products">
									<div className="card-body p-4">
										<div className="media ai-icon">
											<span className="me-3 bgl-primary text-primary">
												<a href={`/product-detail/${product.Product.id}`}><img src={product.Product.pictures} alt="" class="rounded-circle" /></a>
											</span>
											<div className="media-body">
												<p className="mb-1 fs-14 supplier_products__sku">{product.Product.sku}</p>
												<span className="d-block fs-12 mb-3 ms-0">{product.Product.asin}</span>
												<div className="badge badge-warning">PENDING</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ClientProfile;
