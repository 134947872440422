import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import {
  Modal,
  Button,
  Spinner,
  InputGroup,
  Accordion,
  Card,
} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useStateValue } from '../../../../store/selectors/useStateValue'
import { setSelectedOrder } from '../../../../store/actions/orderActions'
import ShipmentTracking from './ShipmentTracking/ShipmentTracking'
import ShipmentTrackingForm from './ShipmentTracking/ShipmentTrackingForm'
import TrackingUnavailableCard from '../../molecules/OrderCard/TrackingUnavailableCard'
import AmazonShippingCard from './AMZReceivingCard/AmazonShippingCard'
import AttachShipmentIdForm from './AddShipmentDetailsForm/AttachShipmentIdForm'
import UpdatePayment from './UpdatePayment/UpdatePayment'
import UploadInvoiceForm from './UploadInvoiceForm/UploadInvoiceForm'
import UploadInvoiceCard from './UploadInvoiceCard/UploadInvoiceCard'
import PaymentInfoCard from './PaymentInfoCard/PaymentInfoCard'
import UploadImagesCard from './UploadImagesCard/UploadImagesCard'
import UploadDocumentsCard from './UploadDocumentsCard/UploadDocumentsCard'
import UploadImagesForm from './UploadImagesForm/UploadImagesForm'
import UploadDocumentsForm from './UploadDocumentsForm/UploadDocumentsForm'
import BatchInformationCard from './BatchInfomationCard/BatchInfomationCard'
import UpdateBatchInfoForm from './UpdateBatchInfoForm/UpdateBatchInfoForm'
import LoadingSpinner from '../../elements/LoadingSpinner/LoadingSpinner'
import 'bootstrap-icons/font/bootstrap-icons.css'
import swal from 'sweetalert'
import { formatDate } from '../../utils/formatDate'
import supplierDefult from '../../../../images/avatar/supplierDefault.png'
import { initialInvoiceFormState, initialBatchInfoState } from './initialState'
import { onDeleteSwal } from '../../utils/onDeleteSwal'

import PerfectScrollbar from 'react-perfect-scrollbar'
import AddShipmentMultiStepForm from './AddShipmentDetailsForm/AddShipmentMultiStepForm'
import {
  useAddPayment,
  useApproveInvoice,
  useDeclineInvoice,
  useGetOrderDetails,
  useRemovePayment,
  useUploadInvoice,
  useUploadBatch,
  useAttachShipmentId,
  useAddNote,
  useGetNote,
  useUploadImage,
  useDeleteOrderImage,
  useUploadDocument,
  useDeleteDocument,
} from '../../../../data/order-details'
import { AddBoxInfoModalForm } from './AddShipmentDetailsForm/AddBoxInfoModalForm'
import { usePrintLabels } from '../../../../data/shipment-data'
import { PrintLabelModalForm } from './AddShipmentDetailsForm/PrintLabelModalForm'
import { useQueryClient } from 'react-query'
import moment from 'moment'
import { useDeleteOrder } from '../../../../data/orders-data'
import { useGetAppSettings } from '../../../../data/account-data'

const OrderDetail = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    orders,
    auth,
    orders: { selectedOrder },
  } = useStateValue()
  const { id } = useParams()
  // const { pageNumber } = location.state;

  const [ordersData] = useState(orders.ordersState)
  const [order, setOrder] = useState({})

  const [noteData, setNoteData] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [invoiceModal, setInvoiceModal] = useState(false)
  const [shipmentIdModal, setShipmentIdModal] = useState(false)
  const [paymentModal, setPaymentModal] = useState(false)
  const [imagesModal, setImagesModal] = useState(false)
  const [documentsModal, setDocumentsModal] = useState(false)
  const [batchInfoModal, setBatchInfoModal] = useState(false)
  const [shipmentInfoModal, setShipmentInfoModal] = useState(false)
  const [file, setFile] = useState(null)
  const [addInvoiceFormData, setAddInvoiceFormData] = useState(
    initialInvoiceFormState
  )
  const [addBatchInfoFormData, setAddBatchInfoFormData] = useState(
    initialBatchInfoState
  )
  const [selectedDate, setSelectedDate] = useState(
    order.Batch?.expiresOn ? new Date(order.Batch?.expiresOn) : new Date()
  )
  const [paymentAmount, setPaymentAmount] = useState('')
  const [shipmentId, setShipmentId] = useState('')
  const [createShipmentModal, setCreateShipmentModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const [note, setNote] = useState('')
  const orderDetailsData = useGetOrderDetails(id)
  const uploadInvoiceData = useUploadInvoice()
  const declineInvoiceData = useDeclineInvoice()
  const approveInvoiceData = useApproveInvoice()
  const addPaymentData = useAddPayment()
  const removePaymentData = useRemovePayment()
  const printLabelData = usePrintLabels()
  const [boxInfoModalOpen, setBoxInfoModalOpen] = useState(false)
  const [printLabelModal, setPrintLabelModal] = useState(false)
  const queryClient = useQueryClient()
  const batchInfoRequest = useUploadBatch()
  const useAddNoteRequest = useAddNote()
  const useAttachShipmentIdRequest = useAttachShipmentId()
  const featchNoteData = useGetNote({ orderId: id })
  const uploadOrderImage = useUploadImage()
  const deleteOrderImage = useDeleteOrderImage()
  const uploadOrderDocument = useUploadDocument()
  const deleteOrderDocument = useDeleteDocument()
  const deleteOrderData = useDeleteOrder()

  const fetchAppSettings = useGetAppSettings()
  const [currencyFormat, setCurrencyFormat] = useState(
    Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    })
  )
  const [userDateFormat, setUserDateFormat] = useState('mmm dd, yyyy')

  useEffect(() => {
    if (!fetchAppSettings.isLoading) {
      if (fetchAppSettings.isSuccess) {
        setCurrencyFormat(
          Intl.NumberFormat(undefined, {
            style: 'currency',
            currency:
              fetchAppSettings?.data?.data?.data?.[0]?.currency || 'USD',
            currencyDisplay: 'narrowSymbol',
          })
        )
        setUserDateFormat(fetchAppSettings?.data?.data?.data?.[0]?.dateformat)
      }
    }
  }, [
    fetchAppSettings.data,
    fetchAppSettings.isLoading,
    fetchAppSettings.isSuccess,
  ])

  useEffect(() => {
    setLoading(orderDetailsData.isLoading)
    if (orderDetailsData.isLoading === false) {
      const orderDetails = orderDetailsData.data.data.data
      setOrder(orderDetails)
      dispatch(setSelectedOrder(orderDetails))
      const shipment = orderDetails.Shipment
      if (shipment) {
        if (shipment.transportStatus === 'LABEL_REQUESTED') {
          const defaults = queryClient.getQueryDefaults(['get-order-details'])
          if (!defaults) {
            queryClient.setQueryDefaults(['get-order-details'], {
              refetchInterval: 1000 * 10,
              refetchIntervalInBackground: true,
            })
          }
        }
        if (shipment.transportStatus === 'READY') {
          const defaults = queryClient.getQueryDefaults(['get-order-details'])
          if (defaults) {
            queryClient.setQueryDefaults('get-order-details', undefined)
          }
        }
      }
    }
  }, [orderDetailsData.data])

  // delete data submit
  const handleDeleteOrderSubmit = async () => {
    deleteOrderData.mutate({ orderId: id })
  }

  useEffect(() => {
    if (!deleteOrderData.isLoading) {
      if (deleteOrderData.isSuccess) {
        history.push('/orders')
        swal('Good job!', 'Order deleted successfully', 'success')
      }
      if (deleteOrderData.isError) {
        swal('Oops!', deleteOrderData?.error?.response?.data?.message, 'error')
      }
      deleteOrderData.reset()
    }
  }, [
    deleteOrderData.isLoading,
    deleteOrderData.data,
    deleteOrderData.isSuccess,
    deleteOrderData.isError,
  ])

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
    setTimeout(() => {
      let src = URL.createObjectURL(e.target.files[0])
      addInvoiceFormData.invoiceSRC = src
    }, 200)
  }

  const handleInvoiceFormChange = (event) => {
    event.preventDefault()
    const fieldName = event.target.getAttribute('name')
    const fieldValue = event.target.value
    const newFormData = { ...addInvoiceFormData }
    newFormData[fieldName] = fieldValue
    setAddInvoiceFormData(newFormData)
  }

  //Add Submit data
  const handleInvoiceSubmit = async (event) => {
    event.preventDefault()
    // setLoading(true);
    let error = false
    let errorMsg = ''
    if (addInvoiceFormData.totalCost === '') {
      error = true
      errorMsg = 'Please fill total cost'
    } else if (
      addInvoiceFormData.shippingCost === '' ||
      addInvoiceFormData.shippingCost === undefined
    ) {
      error = true
      errorMsg = 'Please fill shipping cost.'
    } else if (addInvoiceFormData.invoiceNumber === '') {
      error = true
      errorMsg = 'Please fill invoice number.'
    } else if (addInvoiceFormData.invoiceSRC === '') {
      error = true
      errorMsg = 'Please upload invoice'
    }
    if (!error) {
      const formData = new FormData()
      formData.append('invoiceNumber', addInvoiceFormData.invoiceNumber)
      formData.append('totalCost', addInvoiceFormData.totalCost)
      formData.append('shippingCost', addInvoiceFormData.shippingCost)
      formData.append('invoiceFile', file)
      formData.append('invoiceDate', new Date())
      uploadInvoiceData.mutate({ orderId: id, newInvoice: formData })
      setInvoiceModal(false)
    } else {
      swal('Oops', errorMsg, 'error')
    }
  }

  useEffect(() => {
    if (!uploadInvoiceData.isLoading) {
      if (uploadInvoiceData.isSuccess) {
        //swal("Good job!", "Successfully Added", "success");
        setAddInvoiceFormData({
          ...initialInvoiceFormState,
        })
      }
      if (uploadInvoiceData.isError) {
        swal('Oops', 'Something went wrong', 'error')
      }
    }
  }, [uploadInvoiceData.data])

  useEffect(() => {
    if (!useAttachShipmentIdRequest.isLoading) {
      if (useAttachShipmentIdRequest.isSuccess) {
      }
    }
  }, [
    useAttachShipmentIdRequest.isLoading,
    useAttachShipmentIdRequest.isSuccess,
    useAttachShipmentIdRequest.data,
  ])
  // Add payment
  const handleAddPayment = async () => {
    // setLoading(true);
    const newPayment = {
      paidAmount: paymentAmount,
      paymentDate: selectedDate,
    }

    addPaymentData.mutate({ orderId: id, newPayment })
  }

  useEffect(() => {
    if (!addPaymentData.isLoading) {
      if (addPaymentData.isError) {
        swal('Oops', `Failed to add payment`, 'error')
      }
      if (addPaymentData.isSuccess) {
        setPaymentAmount(``)
      }
    }
  }, [addPaymentData.isLoading])

  // Delete payment
  const handleDeletePayment = async (paymentId) => {
    removePaymentData.mutate({ orderId: id, paymentId })
  }

  useEffect(() => {
    if (!removePaymentData.isLoading) {
      if (removePaymentData.isError) {
        swal('Oops', `Failed to delete payment`, 'error')
      }
      if (removePaymentData.isSuccess) {
        setPaymentAmount(``)
      }
    }
  }, [removePaymentData.isLoading])

  // Total payments made
  const totalPayments = order.Invoice?.Payments?.reduce(
    (accumulator, payment) => {
      return accumulator + parseFloat(payment.paidAmount)
    },
    0
  )

  // Handle add image change
  const handleImageFileChange = (e) => {
    setFile(e.target.files[0])
  }

  // Add image submit data
  const handleAddImageSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()
    let error = false
    let errorMsg = ''
    if (file === null) {
      error = true
      errorMsg = 'Please upload an image'
    }
    if (!error) {
      const imageData = new FormData()
      imageData.append('orderImageFile', file)

      try {
        const orderId = id
        await uploadOrderImage.mutateAsync({ orderId, data: imageData })
        setLoading(false)
        swal('Good job!', 'Image uploaded', 'success')
        setFile(null)
        setImagesModal(false)
      } catch (error) {
        swal('Oops', 'Something went wrong', 'error')
      }
    } else {
      swal('Oops', errorMsg, 'error')
    }
  }

  // Delete image
  const handleDeleteImage = async (imageId) => {
    setLoading(true)

    try {
      await deleteOrderImage.mutateAsync({ imageId, data: {} })
      setLoading(false)
      swal('Good job!', 'Image successfully deleted', 'success')
    } catch (error) {
      swal('Oops', 'Something went wrong', 'error')
    }
  }
  // Handle add document change
  const handleDocumentFileChange = (e) => {
    setFile(e.target.files[0])
  }

  // Add document submit data
  const handleAddDocumentSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()
    let error = false
    let errorMsg = ''
    if (file === null) {
      error = true
      errorMsg = 'Please upload a document'
    }
    if (!error) {
      const documentData = new FormData()
      documentData.append('orderDocFile', file)

      try {
        const orderId = id
        await uploadOrderDocument.mutateAsync({ orderId, data: documentData })
        setLoading(false)
        swal('Good job!', 'Document uploaded', 'success')
        setFile(null)
        setDocumentsModal(false)
      } catch (error) {
        swal('Oops', 'Something went wrong', 'error')
      }
    } else {
      swal('Oops', errorMsg, 'error')
    }
  }

  // Delete image
  const handleDeleteDocument = async (documentId) => {
    setLoading(true)

    try {
      await deleteOrderDocument.mutateAsync({ documentId, data: {} })
      setLoading(false)
      swal('Good job!', 'Document successfully deleted', 'success')
    } catch (error) {
      console.error(error)
      swal('Oops', 'Something went wrong', 'error')
    }
  }
  // Update Batch information button click to edit
  const handleUpdatebatchInfoClick = (event) => {
    event.preventDefault()
    const formValues = {
      id: order.Batch?.id,
      expiresOn: '',
    }
    if (order.Batch?.lotNumber) formValues.lotNumber = order.Batch?.lotNumber
    if (order.Batch?.batchNumber)
      formValues.batchNumber = order.Batch?.batchNumber
    if (order.Batch?.color) formValues.color = order.Batch?.color
    if (order.Batch?.modelNumber)
      formValues.modelNumber = order.Batch?.modelNumber
    if (order.Batch?.type) formValues.type = order.Batch?.type
    if (order.Batch?.size) formValues.size = order.Batch?.size
    setAddBatchInfoFormData(formValues)
    setBatchInfoModal(true)
    setSelectedDate(moment(order.Batch?.expiresOn ?? undefined).toDate())
    console.log('datee', order.Batch?.expiresOn)
  }

  const handleAddShipmentIdClick = (event) => {
    try {
      event.preventDefault()
      setShipmentIdModal(true)
    } catch (error) {
      console.error(error, 'SEE THIS 1')
      swal('Oops', 'Something went wrong', 'error')
    }
  }

  // Update batch info change
  const handleBatchInfoFormChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    setAddBatchInfoFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  // Update batch info submit
  const handleBatchInfoFormSubmit = async (event) => {
    event.preventDefault()

    const newBatch = {
      lotNumber: addBatchInfoFormData.lotNumber,
      expiresOn: formatDate(selectedDate, userDateFormat),
      batchNumber: addBatchInfoFormData.batchNumber,
      color: addBatchInfoFormData.color,
      modelNumber: addBatchInfoFormData.modelNumber,
      type: addBatchInfoFormData.type,
      size: addBatchInfoFormData.size,
    }

    // setLoading(true);

    // try {
    await batchInfoRequest.mutate({ orderId: id, data: newBatch })
  }

  useEffect(() => {
    if (batchInfoRequest.isSuccess) {
      setBatchInfoModal(false)
      swal('Good job!', 'Batch info successfully updated', 'success')
    }
  }, [batchInfoRequest.isSuccess])

  const handleApproveInvoice = async () => {
    approveInvoiceData.mutate({ orderId: id })
  }

  useEffect(() => {
    if (!approveInvoiceData.isLoading) {
      if (approveInvoiceData.isError) {
        swal('Oops', 'Something went wrong', 'error')
      }
      if (approveInvoiceData.isError) {
        swal('Oops', 'Something went wrong', 'error')
      }
    }
  }, [approveInvoiceData.data])

  const handleDeclineInvoice = async () => {
    declineInvoiceData.mutate({ orderId: id })
  }

  useEffect(() => {
    if (!declineInvoiceData.isLoading) {
      if (declineInvoiceData.isError) {
        swal('Oops', 'Something went wrong', 'error')
      }
      if (declineInvoiceData.isError) {
        swal('Oops', 'Something went wrong', 'error')
      }
    }
  }, [declineInvoiceData.data])

  const handlePrintLabel = () => {
    const shipment = orderDetailsData.data?.data.data.Shipment
    const order = orderDetailsData.data?.data.data
    if (!shipment) {
      swal(
        'Oops',
        'Create labels is not ready. Please create shipment first',
        'error'
      )
    } else if (shipment.amazonPartnered === true) {
      if (shipment.transportStatus === 'CONFIRMED') {
        handleCreateLabelSubmit(order.id)
      } else if (shipment.transportStatus === 'READY') {
        setPrintLabelModal(true)
      } else {
        swal(
          'Oops',
          'Create labels is not ready. Please confirm estimate first',
          'error'
        )
      }
    } else if (shipment.amazonPartnered === false) {
      if (shipment.transportStatus === 'WORKING') {
        handleCreateLabelSubmit(order.id)
      } else if (shipment.transportStatus === 'READY') {
        setPrintLabelModal(true)
      } else {
        swal(
          'Oops',
          'Create labels is not ready. Please save Transport Details first',
          'error'
        )
      }
    }
  }

  //add notes
  const handleNoteFormSubmit = async () => {
    await useAddNoteRequest.mutateAsync({
      orderId: id,
      data: {
        content: note,
      },
    })
    setNote('')
  }

  const handleCreateLabelSubmit = async (id) => {
    try {
      const response = await printLabelData.mutateAsync({
        orderId: id,
      })
      swal('Good Job', response?.data?.message, 'success')
    } catch (error) {
      swal('Error', error?.response?.data?.message, 'error')
    }
  }

  const handleAttachShipmentIdSubmit = async () => {
    try {
      const response = await useAttachShipmentIdRequest.mutateAsync({
        orderId: id,
        data: {
          shipmentId: shipmentId,
        },
      })
      setShipmentIdModal(false)
      swal('Good Job', response?.data?.message, 'success')
    } catch (error) {
      setShipmentIdModal(false)
      swal('Incorrect Shipment ID', error?.response?.data?.message, 'error')
    }
  }

  //shipment
  // Update Batch information button click to edit
  const handleshipmentInfoClick = (event) => {
    event.preventDefault()
    if (
      order.Product.amazonIntegrated === false ||
      order.orderNextUpStatus === 'Add Tracking details' ||
      order.orderNextUpStatus === 'Waiting Tracking Details' ||
      order.orderNextUpStatus === 'Create Shipment + Label' ||
      order.orderNextUpStatus === 'Waiting label' ||
      order.destinationType === 'My Warehouse'
    ) {
      setShipmentInfoModal(true)
    } else {
      swal('Oops', 'Not ready for tracking details', 'error')
    }
  }
  const defaultAccordion = [
    {
      title: 'Invoice upload',
      text: (
        <UploadInvoiceCard
          invoiceNotUploaded={!order.Invoice?.totalCost}
          onClickUpload={() => setInvoiceModal(true)}
          invoiceName={order.Invoice?.invoiceNumber}
          invoiceSRC={order.Invoice?.invoiceFile[0]?.fileSource}
          invoiceDate={formatDate(new Date(order.Invoice?.invoiceDate))}
          onApprove={handleApproveInvoice}
          isInvoiceApproved={order.invoiceApproved}
          onDecline={handleDeclineInvoice}
          isInvoiceDeclined={order.invoiceDeclined}
        />
      ),
      completed: order.invoiceApproved === null ? false : order.invoiceApproved,
    },
    {
      title: 'Payment status',
      text: (
        <PaymentInfoCard
          onClickUpdate={() => setPaymentModal(true)}
          totalCost={order.Invoice?.totalCost}
          totalPaid={totalPayments}
          isInvoiceApproved={order.invoiceApproved}
        />
      ),
      completed: order.Invoice?.Payments.length !== 0,
    },
    order.destinationType === 'Amazon FBA' && order.Product.amazonIntegrated
      ? {
          title: 'Add box details',
          text: (
            <Button
              className="btn btn-md btn-danger light btn-rounded me-2 mb-0"
              onClick={
                order.Invoice?.Payments.length !== 0
                  ? () => setBoxInfoModalOpen(true)
                  : () => swal('Oops', 'Update Payment First', 'error')
              }
            >
              <i className="fas fa-box me-3 scale3"></i>
              {order.Boxes?.length === 0 ? 'Add Boxes' : 'Boxes Info'}
            </Button>
          ),
          completed: order.Boxes?.length > 0,
        }
      : null,
    order.destinationType === 'Amazon FBA' &&
    order.Product.amazonIntegrated &&
    order.Invoice?.Payments.length !== 0 &&
    auth.userType === 'business' &&
    order?.Boxes?.length !== 0
      ? {
          title:
            orderDetailsData.data?.data.data.Shipment?.transportStatus !==
            'READY'
              ? 'Create shipment'
              : 'Download label',
          text: (
            <div className="invite mb-0">
              {order.destinationType === 'Amazon FBA' &&
                order.Product.amazonIntegrated &&
                order.Invoice?.Payments.length !== 0 &&
                auth.userType === 'business' &&
                order?.Boxes?.length !== 0 &&
                orderDetailsData.data?.data.data.Shipment?.transportStatus !==
                  'READY' && (
                  <Button
                    className="btn btn-md btn-danger light btn-rounded me-2 mb-0"
                    onClick={() => setCreateShipmentModal(true)}
                  >
                    <i className="fab fa-amazon me-3 scale3"></i>
                    Create Shipment
                  </Button>
                )}
              {auth.userType === 'business' &&
                ((orderDetailsData.data?.data.data.Shipment?.amazonPartnered ===
                  true &&
                  (orderDetailsData.data?.data.data.Shipment
                    ?.transportStatus !== 'READY' ||
                    orderDetailsData.data?.data.data.Shipment
                      ?.transportStatus !== 'CONFIRMED' ||
                    orderDetailsData.data?.data.data.Shipment
                      ?.transportStatus !== 'LABEL_REQUESTED')) ||
                  (orderDetailsData.data?.data.data.Shipment
                    ?.amazonPartnered === false &&
                    (orderDetailsData.data?.data.data.Shipment
                      ?.transportStatus !== 'READY' ||
                      orderDetailsData.data?.data.data.Shipment
                        ?.transportStatus !== 'WORKING' ||
                      orderDetailsData.data?.data.data.Shipment
                        ?.transportStatus !== 'LABEL_REQUESTED'))) && (
                  <Button
                    className="btn btn-md btn-success light btn-rounded me-2 mb-0"
                    onClick={() => handlePrintLabel()}
                    disabled={
                      orderDetailsData.data?.data.data.Shipment
                        ?.transportStatus === 'LABEL_REQUESTED' ||
                      printLabelData.isLoading
                    }
                  >
                    {printLabelData.isLoading ? (
                      <Spinner
                        className="me-1"
                        size={'sm'}
                        animation="border"
                        as="span"
                      ></Spinner>
                    ) : (
                      <i className="fab fa-amazon me-3 scale3"></i>
                    )}
                    {orderDetailsData.data?.data.data.Shipment
                      ?.transportStatus !== 'READY' ? (
                      <span className="">Create Label</span>
                    ) : (
                      <span className="">Download Label</span>
                    )}
                  </Button>
                )}
            </div>
          ),
          completed:
            orderDetailsData.data?.data.data.Shipment?.transportStatus ===
            'READY',
        }
      : null,
    {
      title: 'Add tracking details',
      text: (
        <button
          className="btn btn-md btn-primary light btn-rounded me-2 mb-0"
          onClick={(event) => handleshipmentInfoClick(event)}
          disabled={
            order.orderNextUpStatus === 'Waiting for delivery' ||
            order.orderNextUpStatus === 'Order Delivered'
          }
        >
          <i className="fa fa-plus me-3 scale3"></i>Tracking Details
        </button>
      ),
      isAmazonStep: true,
      completed:
        order.orderNextUpStatus === 'Waiting for delivery' ||
        order.orderNextUpStatus === 'Order Delivered',
    },
  ].filter((item) => item !== null)

  // Find the index of the first item with completed === false
  const defaultActiveIndex = defaultAccordion.findIndex(
    (item) => item.completed === false
  )

  const [activeItemIndex, setActiveItemIndex] = useState(
    defaultActiveIndex !== -1 ? defaultActiveIndex : 0
  )

  const [manuallyChanged, setManuallyChanged] = useState(false)

  useEffect(() => {
    if (!manuallyChanged) {
      // Check if all items are completed
      const allCompleted = defaultAccordion.every((item) => item.completed)

      // If all items are completed, collapse all accordion items
      if (allCompleted) {
        setActiveItemIndex(-1)
      } else {
        // Find the index of the first incomplete item
        const incompleteIndex = defaultAccordion.findIndex(
          (item) => !item.completed
        )
        setActiveItemIndex(incompleteIndex !== -1 ? incompleteIndex : 0)
      }
    }
  }, [defaultAccordion, manuallyChanged])

  const handleToggleAccordion = (index) => {
    setActiveItemIndex((prevIndex) => {
      setManuallyChanged(true) // Set the flag to indicate manual change
      return prevIndex === index ? -1 : index
    })
  }

  return (
    <>
      <div>
        <Modal
          className="modal fade"
          show={deleteModal}
          onHide={setDeleteModal}
        >
          <div className="modal-header">
            <h4 className="modal-title fs-20">
              Are you sure you want to delete the order: <b>{order.poNumber}</b>
              ?
            </h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => setDeleteModal(false)}
              data-dismiss="modal"
            ></button>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              onClick={handleDeleteOrderSubmit}
              className="btn btn-danger"
            >
              {/* <i className="flaticon-delete-1"></i>  */}
              {deleteOrderData.isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  className="me-2"
                  size="sm"
                />
              )}
              <span>Delete</span>
            </button>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
          </div>
        </Modal>
        <Modal className="modal fade" show={invoiceModal}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Upload Invoice</h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => setInvoiceModal(false)}
              data-dismiss="modal"
            ></button>
          </div>
          <UploadInvoiceForm
            onClickCancel={() => setInvoiceModal(false)}
            onChangeFile={handleFileChange}
            onTotalCostChange={handleInvoiceFormChange}
            onShippingCostChange={handleInvoiceFormChange}
            onInvoiceNumberChange={handleInvoiceFormChange}
            onClickSubmit={handleInvoiceSubmit}
          />
        </Modal>
        <Modal className="modal fade" show={paymentModal}>
          <UpdatePayment
            closeModal={() => setPaymentModal()}
            totalCost={order.Invoice?.totalCost}
            invoiceNumber={order.Invoice?.invoiceNumber}
            selectedDate={selectedDate}
            onPaymentAmountChange={(e) => setPaymentAmount(e.target.value)}
            paymentValue={paymentAmount}
            onDateChange={(date) => setSelectedDate(date)}
            onAddPayment={handleAddPayment}
            payments={order.Invoice?.Payments}
            remainingCost={order.Invoice?.totalCost - totalPayments}
            isDisabled={
              paymentAmount === '' ||
              addPaymentData.isLoading ||
              removePaymentData.isLoading
            }
            onDeletePayment={(id) =>
              onDeleteSwal('payment detail', () => handleDeletePayment(id))
            }
            currencyFormat={currencyFormat}
            userDateFormat={userDateFormat}
          />
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setPaymentModal(false)}
            >
              Done
            </button>
          </div>
        </Modal>
        <Modal show={createShipmentModal} className="modal fade" size="lg">
          <AddShipmentMultiStepForm
            handleClose={() => setCreateShipmentModal(false)}
          />
        </Modal>
        <Modal show={boxInfoModalOpen} className="modal fade" size="lg">
          <AddBoxInfoModalForm handleClose={() => setBoxInfoModalOpen(false)} />
        </Modal>
        <Modal show={printLabelModal} className="modal fade">
          <PrintLabelModalForm
            handleClose={() => setPrintLabelModal(false)}
            subShipments={
              orderDetailsData.data?.data.data.Shipment?.SubShipments || []
            }
          />
        </Modal>

        <Modal className="modal fade" show={imagesModal}>
          <UploadImagesForm
            onClickCancel={() => setImagesModal(false)}
            onChangeFile={handleImageFileChange}
            onClickSubmit={handleAddImageSubmit}
          />
        </Modal>
        <Modal className="modal fade" show={shipmentIdModal}>
          <AttachShipmentIdForm
            onClickCancel={() => setShipmentIdModal(false)}
            onClickSubmit={handleAttachShipmentIdSubmit}
            onShipmentIdChange={(e) => setShipmentId(e.target.value)}
            loading={useAttachShipmentIdRequest.isLoading}
          />
        </Modal>
        <Modal className="modal fade" show={documentsModal}>
          <UploadDocumentsForm
            onClickCancel={() => setDocumentsModal(false)}
            onChangeFile={handleDocumentFileChange}
            onClickSubmit={handleAddDocumentSubmit}
          />
        </Modal>
        <Modal className="modal fade" show={batchInfoModal}>
          <UpdateBatchInfoForm
            onClickCancel={() => setBatchInfoModal(false)}
            onChangeFile={handleBatchInfoFormChange}
            onClickSubmit={handleBatchInfoFormSubmit}
            selectedDate={selectedDate}
            onDateChange={(date) => setSelectedDate(date)}
            onLOTNumberChange={handleBatchInfoFormChange}
            onBatchNumberChange={handleBatchInfoFormChange}
            onColorChange={handleBatchInfoFormChange}
            onModelNumberChange={handleBatchInfoFormChange}
            onTypeChange={handleBatchInfoFormChange}
            onSizeChange={handleBatchInfoFormChange}
            lotNumberValue={addBatchInfoFormData.lotNumber}
            batchNumberValue={addBatchInfoFormData.batchNumber}
            expiryDateValue={addBatchInfoFormData.expiresOn}
            colorValue={addBatchInfoFormData.color}
            modelNumberValue={addBatchInfoFormData.modelNumber}
            typeValue={addBatchInfoFormData.type}
            sizeValue={addBatchInfoFormData.size}
            loading={batchInfoRequest.isLoading}
            error={batchInfoRequest.error}
            userDateFormat={userDateFormat}
          />
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setBatchInfoModal(false)}
            >
              Done
            </button>
          </div>
        </Modal>
        <Modal className="modal fade" show={shipmentInfoModal}>
          <ShipmentTrackingForm
            onClickCancel={() => setShipmentInfoModal(false)}
            boxes={order.Boxes}
            shipment={order.Shipment}
            order={order}
            orderId={id}
            disableInput={order?.Shipment?.amazonPartnered}
          />
        </Modal>
      </div>
      {loading ||
      uploadInvoiceData.isLoading ||
      approveInvoiceData.isLoading ||
      declineInvoiceData.isLoading ? (
        <LoadingSpinner size={150} />
      ) : (
        <>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="mb-3">
                      <span className="text-primary d-block fs-18 font-w600 mb-1">
                        #{order.poNumber}
                      </span>
                      <span className="d-block mb-2 fs-14">
                        <i className="fas fa-calendar me-2"></i>Ordered on{' '}
                        {formatDate(new Date(order.orderDate), userDateFormat)}
                      </span>
                      <h4 className="fs-22 font-w700 mb-0">
                        {order.Product?.productName}
                      </h4>
                      <div className="mt-1 fs-16 font-w500 mb-2">
                        <span className="font-w600 text-primary">
                          {order.Product?.productSKU}
                        </span>
                        <span className="">
                          {' '}
                          / {order.Product?.productASIN}
                        </span>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="d-flex align-items-center project-image mb-3">
                        <img
                          src={order.supplierLogo || supplierDefult}
                          alt=""
                        />
                        <div>
                          <h3 className="fs-16 text-black font-w600 mb-0">
                            {order.supplierName || 'No Supplier'}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="d-flex align-items-end mt-2 justify-content-between">
                        <h4 className="fs-20 font-w700 mb-0 text-success me-5">
                          {order.orderUnits} Units
                        </h4>
                        <span>
                          {order.orderUnits - order.receivedUnits} Remaining
                        </span>
                      </div>
                      <div className="progress  mb-4">
                        <div
                          className="progress-bar progress-animated"
                          style={{
                            width: `${
                              (order.receivedUnits / order.orderUnits) * 100
                            }%`,
                          }}
                        ></div>
                      </div>
                      {order?.Invoice?.totalCost && (
                        <>
                          <div className="d-flex align-items-end mt-2 justify-content-between">
                            <h4 className="fs-20 font-w700 mb-0 text-danger me-5">
                              {currencyFormat.format(order?.Invoice?.totalCost)}
                            </h4>
                            <span>
                              {currencyFormat.format(
                                order.Invoice?.totalCost - totalPayments
                              )}{' '}
                              Remaining
                            </span>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar progress-animated"
                              style={{
                                width: `${
                                  (totalPayments / order.Invoice?.totalCost) *
                                  100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-0 flex-wrap">
                    <div className="fs-16 font-w600 me-2">
                      <span className="me-2">Next up:</span>
                      {order.orderNextUpStatus !== 'Order Delivered' && (
                        <>
                          <i className="fas fa-exclamation-triangle me-1 text-danger"></i>
                          <span className="text-primary">
                            {order.orderNextUpStatus}
                          </span>
                        </>
                      )}
                      {order.orderNextUpStatus == 'Order Delivered' && (
                        <span className="text-success">
                          {order.orderNextUpStatus}
                        </span>
                      )}
                    </div>
                    <div className="invite mb-0">
                      {order.destinationType === 'Amazon FBA' &&
                        order?.Product?.amazonIntegrated === true && (
                          <Button
                            className="btn btn-xs btn-danger light btn-rounded me-2 mb-0"
                            onClick={
                              order.Invoice?.Payments.length !== 0
                                ? () => setBoxInfoModalOpen(true)
                                : () =>
                                    swal(
                                      'Oops',
                                      'Update Payment First',
                                      'error'
                                    )
                            }
                          >
                            <i className="fas fa-box me-3 scale3"></i>
                            {order.Boxes.length === 0
                              ? 'Add Boxes'
                              : 'Boxes Info'}
                          </Button>
                        )}
                      {order.destinationType === 'Amazon FBA' &&
                        order.Product.amazonIntegrated &&
                        order.Invoice?.Payments.length !== 0 &&
                        auth.userType === 'business' &&
                        order?.Boxes.length !== 0 && (
                          <Button
                            className="btn btn-xs btn-danger light btn-rounded me-2 mb-0"
                            onClick={() => setCreateShipmentModal(true)}
                          >
                            <i className="fab fa-amazon me-3 scale3"></i>
                            {/* {!order.shipmentInfo
											 		? "Create Shipment"
											 		: "Shipment created"} */}
                            Create Shipment
                          </Button>
                        )}

                      {((orderDetailsData.data?.data.data.Shipment
                        ?.amazonPartnered === true &&
                        (orderDetailsData.data?.data.data.Shipment
                          ?.transportStatus !== 'READY' ||
                          orderDetailsData.data?.data.data.Shipment
                            ?.transportStatus !== 'CONFIRMED' ||
                          orderDetailsData.data?.data.data.Shipment
                            ?.transportStatus !== 'LABEL_REQUESTED')) ||
                        (orderDetailsData.data?.data.data.Shipment
                          ?.amazonPartnered === false &&
                          (orderDetailsData.data?.data.data.Shipment
                            ?.transportStatus !== 'READY' ||
                            orderDetailsData.data?.data.data.Shipment
                              ?.transportStatus !== 'WORKING' ||
                            orderDetailsData.data?.data.data.Shipment
                              ?.transportStatus !== 'LABEL_REQUESTED'))) && (
                        <Button
                          className="btn btn-xs btn-success light btn-rounded me-2 mb-0"
                          onClick={() => handlePrintLabel()}
                          disabled={
                            orderDetailsData.data?.data.data.Shipment
                              ?.transportStatus === 'LABEL_REQUESTED' ||
                            printLabelData.isLoading ||
                            orderDetailsData.data?.data.data.Shipment
                              ?.transportStatus !== 'READY'
                          }
                        >
                          {printLabelData.isLoading ||
                          orderDetailsData.data?.data.data.Shipment
                            ?.transportStatus !== 'READY' ? (
                            <Spinner
                              className="me-1"
                              size={'sm'}
                              animation="border"
                              as="span"
                            ></Spinner>
                          ) : (
                            <i className="fab fa-amazon me-3 scale3"></i>
                          )}
                          {orderDetailsData.data?.data.data.Shipment
                            ?.transportStatus !== 'READY' ? (
                            <span className="">Labels are being generated</span>
                          ) : (
                            <span className="">Download Label</span>
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row order_detail__content-area">
            <div className="col-xl-4 col-lg-6 col-sm-12">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card overflow-hidden">
                    <div className="text-center p-3 overlay-box">
                      <h3 className="mt-3 mb-1 text-black">Steps to Take</h3>
                    </div>
                    <div className="card-body">
                      <div className="row accordion-progress">
                        <Accordion
                          className="accordion accordion-bordered accordion-primary-solid accordion-with-icon"
                          activeKey={
                            activeItemIndex !== -1
                              ? activeItemIndex.toString()
                              : null
                          }
                        >
                          {defaultAccordion.map((d, i) => {
                            const isOpen = activeItemIndex === i
                            const previousItemCompleted =
                              i === 0 ? true : defaultAccordion[i - 1].completed

                            // Render the Accordion item only if the previous item is completed
                            if (previousItemCompleted) {
                              return (
                                <div
                                  className={`accordion-item ${
                                    d.completed ? 'completed' : ''
                                  }`}
                                  key={i}
                                >
                                  <Accordion.Toggle
                                    as={Card.Text}
                                    eventKey={i.toString()}
                                    className={`accordion-header ${
                                      isOpen ? '' : 'collapsed'
                                    }`}
                                    onClick={() => handleToggleAccordion(i)}
                                  >
                                    <span
                                      className={`accordion-header-icon ${
                                        d.completed && 'completed'
                                      }`}
                                    ></span>
                                    <span className="accordion-header-text">
                                      {d.title}
                                    </span>
                                    <span className="accordion-header-indicator indicator-bordered"></span>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse
                                    eventKey={`${i}`}
                                    className="accordion__body"
                                  >
                                    <div className="accordion-body-text text-center">
                                      {d.text}
                                    </div>
                                  </Accordion.Collapse>
                                </div>
                              )
                            }

                            return null
                          })}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BatchInformationCard
                onClickUpdate={(event) => handleUpdatebatchInfoClick(event)}
                info={order.Batch}
                userDateFormat={userDateFormat}
              />
            </div>
            <div className="col-xl-8 col-lg-6 col-sm-12">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-12 col-xl-6">
                  <div className="card overflow-hidden">
                    <div className="text-center p-3 overlay-box">
                      <h3 className="mt-3 mb-1 text-black">
                        Shipment Tracking
                      </h3>
                    </div>
                    <div className="card-body">
                      {order.orderNextUpStatus === 'Waiting for delivery' ||
                      order.orderNextUpStatus === 'Order Delivered' ? (
                        <ShipmentTracking
                          shippingMode={order.shippingMode}
                          boxes={order.Boxes}
                          shipment={order?.Shipment}
                        />
                      ) : (
                        <TrackingUnavailableCard
                          shippingMode={order.shippingMode}
                          onClickAdd={(event) => handleshipmentInfoClick(event)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {order.destinationType === 'Amazon FBA' && (
                  <div className="col-sm-12 col-md-6 col-lg-12 col-xl-6">
                    <div className="card overflow-hidden">
                      <div className="text-center p-3 overlay-box">
                        <div className="profile-photo">
                          <img
                            src={order?.productThumb}
                            width="100"
                            className="m-auto img-fluid rounded-circle d-block"
                            alt=""
                          />
                        </div>
                        <h3 className="mt-3 mb-1 text-black">
                          Amazon Tracking
                        </h3>
                      </div>
                      <div className="card-body">
                        <AmazonShippingCard
                          shipmentDetails={order?.Shipment?.SubShipments?.[0]}
                          onClickUpdate={(event) =>
                            handleAddShipmentIdClick(event)
                          }
                          orderUnits={order.orderUnits}
                          receivedUnits={order.receivedUnits}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="text-black card-title h5">
                        Order Notes
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <PerfectScrollbar
                          id="DZ_W_Todo1"
                          className="widget-media dlab-scroll ps ps--active-y"
                        >
                          <ul
                            className="timeline"
                            style={{ maxHeight: '250px', overflowY: 'auto' }}
                          >
                            {featchNoteData.data &&
                              featchNoteData.data.data.data.notes.map(
                                (note, index) => (
                                  <li key={note.id}>
                                    <div className="timeline-panel">
                                      <div className="media-body">
                                        <div className="row w-100">
                                          <div className="col-md-10">
                                            <h5 className="mb-1">
                                              {note.content}
                                            </h5>
                                            <small className="d-block">
                                              {moment(note.createdAt).fromNow()}{' '}
                                              <b>
                                                Posted by{' '}
                                                {note.User.name ||
                                                  note.User.email}
                                              </b>
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                          </ul>
                        </PerfectScrollbar>
                        <div className="card-footer border-0 type-massage">
                          <div className="input-group">
                            <InputGroup className="mb-3">
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                placeholder="Add small notes"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                style={{
                                  height: 'calc(3.45rem + 2px)',
                                  borderRadius: '10px 0px 0 10px',
                                  resize: 'none',
                                }}
                              />
                              <InputGroup.Append>
                                <Button
                                  variant="primary"
                                  onClick={handleNoteFormSubmit}
                                  style={{
                                    margin: '0px',
                                    borderRadius: '0px 10px 10px 0px',
                                    height: 'calc(3.45rem + 2px)',
                                    fontSize: '20px',
                                  }}
                                  disabled={useAddNoteRequest.isLoading}
                                >
                                  {' '}
                                  <i className="bi bi-send"></i>
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <UploadImagesCard
                  onClickUpload={() => setImagesModal(true)}
                  images={order.Images}
                  onDeleteImage={(id) =>
                    onDeleteSwal('file', () => handleDeleteImage(id))
                  }
                />

                <UploadDocumentsCard
                  onClickUpload={() => setDocumentsModal(true)}
                  documents={order.Documents}
                  onDeleteDocument={(id) =>
                    onDeleteSwal('file', () => handleDeleteDocument(id))
                  }
                />
              </div>
            </div>
            {order?.Boxes?.length === 0 &&
              Object.keys(order.Batch).filter(
                (key) => order.Batch[key] !== null
              ).length === 0 && (
                <div className="delete_order-detail">
                  <button
                    type="button"
                    className="btn btn-danger btn-rounded me-2"
                    onClick={() => setDeleteModal(true)}
                  >
                    <span className="btn-icon-start text-danger">
                      <i className="fa fa-trash color-danger"></i>
                    </span>
                    Delete Order
                  </button>
                </div>
              )}
          </div>
        </>
      )}
    </>
  )
}

export default OrderDetail
