import React from "react";
import user from "../../../images/pic1.jpg";
import { Spinner } from "react-bootstrap";
export const initialFormState = {
  productName: "",
  productSKU: "",
  productThumb: "",
  productASIN: "",
  productWidth: "",
  productHeight: "",
  productLength: "",
  productWeight: "",
  productSize: "",
  productManufacturingCost: "",
  shippingModeAirCost: "",
  shippingModeSeaCost: "",
  shippingModeLandCost: "",
  productManufacturingDays: "",
  fastestShippingDays: "",
  shippingHandlingDays: "",
  myWarehouseStock: "",
  amazonIntegrated: false,
};

const AddProductForm = ({
  onChangeFile,
  file,
  onProductNameChange,
  onProductASINChange,
  onClickSubmit,
  onClickCancel,
  loading
}) => {
  return (
    <div className="modal-body">
      <i className="flaticon-cancel-12 close"></i>
      <div className="add-contact-box">
        <div className="add-contact-content">
          <div className="image-placeholder">
            <div className="avatar-edit">
              <input
                type="file"
                onChange={onChangeFile}
                id="imageUpload"
              />
              <label htmlFor="imageUpload" name=""></label>
            </div>
            <div className="avatar-preview">
              <div id="imagePreview">
                {file ? (
                  <img
                    id="saveImageFile"
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                  />
                ) : (
                  <img id="saveImageFile" src={user} alt="Default Product Avatar" />
                )}
              </div>
            </div>
          </div>
          <div className="form-group mb-3">
            <label className="text-black font-w500">Name</label>
            <div className="contact-name">
              <input
                type="text"
                className="form-control"
                autoComplete="off"
                name="productName"
                required="required"
                onChange={onProductNameChange}
                placeholder="name"
              />
            </div>
          </div>
          <div className="form-group mb-3">
            <label className="text-black font-w500">SKU</label>
            <div className="contact-name">
              <input
                type="text"
                className="form-control"
                autoComplete="off"
                name="productSKU"
                required="required"
                onChange={onProductASINChange}
                placeholder="SKU"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={onClickSubmit}
          disabled={loading}
        > {loading?
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className=""
          />:
          <></>}
         <span className="ms-2">Add</span> 
        </button>
        <button
          type="button"
          onClick={onClickCancel}
          className="btn btn-danger"
        >
          {" "}
          <i className="flaticon-delete-1"></i> Discard
        </button>
      </div>
    </div>
  );
};

export default AddProductForm;
