import React from 'react'
import { Button } from 'react-bootstrap'
import { useStateValue } from '../../../../../store/selectors/useStateValue'

const UploadInvoiceCard = ({
  onClickUpload,
  invoiceName,
  invoiceDate,
  invoiceSRC,
  onApprove,
  onDecline,
  isInvoiceApproved,
  isInvoiceDeclined,
  invoiceNotUploaded,
}) => {
  const { auth } = useStateValue()

  const handleUploadInvoiceClick = () => {
    onClickUpload()
  }

  return (
    <>
      {invoiceNotUploaded ? (
        <div className="col-sm-12">
          <div className="">
            <div className="card-header">
              <div className="card-title h5">Invoice</div>
            </div>
            <div className="card-body">
              <div className="profile-statistics">
                <div className="text-center">
                  <button
                    onClick={onClickUpload}
                    type="button"
                    className="me-2 btn btn-primary btn-rounded"
                  >
                    <span className="btn-icon-start text-primary">
                      <i className="fas fa-plus"></i>
                    </span>{' '}
                    Upload Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-lg-12">
          <div>
            <div className="card-header">
              <div className="card-title h5">Invoice</div>
            </div>
            <div className="card-body">
              <div className="final-badge text-center">
                <a
                  download={invoiceName}
                  href={`${invoiceSRC}`}
                  className="badge text-black border mt-0"
                >
                  <i className="far fa-file-alt me-3"></i>
                  {invoiceName}
                </a>
                <span className="d-block fs-12 font-w400">{invoiceDate}</span>
              </div>
              {auth.userType === 'business' && (
                <div className="invoice-approval text-center mt-4">
                  <div className="row">
                    {isInvoiceDeclined !== true ? (
                      <div className="col">
                        <Button
                          onClick={onApprove}
                          variant="success btn-xs btn-rounded"
                          disabled={isInvoiceApproved}
                        >
                          <span className="">
                            <i className="fa fa-check color-success me-2" />
                          </span>
                          {isInvoiceApproved === true ? 'Approved' : 'Approve'}
                        </Button>
                      </div>
                    ) : (
                      <div className="col">
                        <button
                          onClick={handleUploadInvoiceClick}
                          type="button"
                          className=" btn btn-primary btn-xs btn-rounded "
                        >
                          <span className="">
                            <i className="fa fa-plus color-danger me-2" />
                          </span>
                          <span>Upload</span>
                        </button>
                      </div>
                    )}
                    {isInvoiceApproved !== true && (
                      <div className="col">
                        <Button
                          onClick={onDecline}
                          variant="danger btn-xs btn-rounded"
                          disabled={isInvoiceDeclined === true}
                        >
                          <span className="">
                            <i className="fa fa-window-close color-danger me-2" />
                          </span>
                          {isInvoiceDeclined === true ? 'Declined' : 'Decline'}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UploadInvoiceCard
