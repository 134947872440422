import { lazy, Suspense, useEffect } from "react";

/// Components
import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter,useLocation   } from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const SignUpSupplier = lazy(() => import("./jsx/pages/SignUpSupplier"));
const SignUpBusiness = lazy(() => import("./jsx/pages/SignUpBusiness"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const AmazonCallback = lazy(() => import("./jsx/pages/AmazonCallback"));
const ResetPassword = lazy(() =>  import("./jsx/pages/ResetPassword"));
const VerifyEmail = lazy(() =>  import("./jsx/pages/VerifyEmail"));
const AttachSupplier = lazy(() =>  import("./jsx/pages/AttachSupplier"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function App(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const isResetPasswordRoute = currentPath.includes("/user/reset-password");

    if (!isResetPasswordRoute && !currentPath.includes("/user/verify-email") && !currentPath.includes("/supplier-invitations/accept")) {
      checkAutoLogin(dispatch, props.history);
    }
  }, [dispatch, props.history]);
  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/register-supplier" component={SignUpSupplier} />
      <Route path="/register-business" component={SignUpBusiness} />
      <Route path="/page-forgot-password" component={ForgotPassword}/>
      <Route path="/page-amazon_callback" component={AmazonCallback}/>
      <Route path="/user/reset-password/:token" component={ResetPassword}/>
      <Route path="/user/verify-email/:token" component={VerifyEmail}/>
      <Route path="/supplier-invitations/accept/:token" component={AttachSupplier}/>
    </Switch>
  );
  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));