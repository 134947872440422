import React from "react";
import { getShippingIcon } from "../../../molecules/OrderCard/TrackingCard";
import dhl from "./../../../../../images/freight/dhl.png";
import fedex from "./../../../../../images/freight/fedex.png";
import ups from "./../../../../../images/freight/ups.png";
import usps from "./../../../../../images/freight/usps.png";
import defaultIcon from "./../../../../../images/freight/default-icon.png";

const ShipmentTracking = ({ boxes, shippingMode, shipment }) => {

  function getCarrierLink(trackingId, carrierName) {
    // Assuming you have a mapping of carrier names to their respective tracking links
    const carrierLinks = {
      'UNITED_PARCEL_SERVICE_INC': `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${trackingId}`,
      'DHL_EXPRESS_USA_INC': `https://www.dhl.com/pk-en/home/tracking.html?tracking-id=${trackingId}&submit=1`,
      'FEDERAL_EXPRESS_CORP': `https://www.fedex.com/fedextrack/system-error?trknbr=${trackingId}`,
      'UNITED_STATES_POSTAL_SERVICE': `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingId}`,
      // Add more carrier links here as needed
    };

    if (carrierName in carrierLinks) {
      return carrierLinks[carrierName];
    } else {
      return `#`;
    }
  }


  function getCarrierIcon(carrier) {
    switch (carrier) {
      case "DHL_EXPRESS_USA_INC": {
        return dhl;
      }
      case "FEDERAL_EXPRESS_CORP": {
        return fedex;
      }
      case "UNITED_STATES_POSTAL_SERVICE": {
        return usps;
      }
      case "UNITED_PARCEL_SERVICE_INC": {
        return ups;
      }
      default: {
        return defaultIcon;
      }
    }
  };

  function convertStatusToReadable(status) {
    const statusMap = {
      unknown: "Unknown",
      pre_transit: "Pre Transit",
      in_transit: "In Transit",
      out_for_delivery: "Out For Delivery",
      delivered: "Delivered",
      available_for_pickup: "Available for Pickup",
      return_to_sender: "Return to Sender",
      failure: "Failure",
      cancelled: "Cancelled",
      error: "Error"
    };

    return statusMap[status] || status;
  }

  const masterCarton = boxes?.filter(box => box.trackingInfo !== null)[0];
  const arr = masterCarton?.trackingInfo?.tracking_details
  const lastUpdatedDetails = arr?.[arr ? arr?.length - 1 : 0];
  const currentLocation = lastUpdatedDetails?.tracking_location
  const statusArray = ["pre_transit", "in_transit", "available_for_pickup", "out_for_delivery", "delivered"];
  const statusIndex = lastUpdatedDetails?.status ? statusArray.indexOf(lastUpdatedDetails.status) : -1;


  return (
    <div>
      <div className="freight_tracking">
        <div className="mb-3">
          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
            <div className="d-flex align-items-center project-image">
              <img src={getCarrierIcon(masterCarton?.carrierName)} alt="" />
              <div>
                <small className="d-block fs-16 font-w700">Tracking ID</small>
                <span className="d-block fs-12 font-w500">{masterCarton?.trackingId}</span>
              </div>
            </div>
            <div className="circle-icon">
              <span className="ms-2 me-0">
                <i className={`fas ${getShippingIcon(shippingMode)}`}></i>
              </span>
            </div>
          </div>
          {JSON.stringify()}

          <div>
            <span className="d-block fs-16 text-black font-w600">{shipment?.amazonPartnered && shipment?.shipmentType === 'LTL'  ? shipment?.transportStatus : convertStatusToReadable(lastUpdatedDetails?.status)}</span>
            <div className="progress mb-2">
              <div
                className="progress-bar progress-animated bg-success"
                style={{ width: `${statusIndex / 4 * 100}%` }}
              ></div>
            </div>
            <span className="d-block fs-12 font-w500">
              {lastUpdatedDetails?.message}
            </span>
          </div>

        </div>
        {currentLocation ? (<div className="row">
          <div className="col-xl-6">
            <h6 className="mb-0">Current Location:</h6>
            <div className="fs-12 font-w400">
              <span className="d-block"> {currentLocation?.zip ? `${currentLocation?.zip} - ` : ''}{currentLocation?.city ? `${currentLocation?.city}, ` : 'UNKNOWN'}{currentLocation?.state}</span>
              <span className="d-block">{currentLocation?.country}</span>
            </div>
          </div>
          <div className="col-xl-6">
            <h6 className="mb-0">Ship To:</h6>
            <div className="fs-12 font-w400">
            <span className="d-block"> {masterCarton?.trackingInfo?.carrier_detail?.destination_location}</span>
            </div>
          </div>
        </div>) :
        (<div></div> )
        }
        {
          <ul className="box_tracking_numbers">
          {boxes.map((box, index) => (
            <React.Fragment key={index}>
              <li><a className="fs-12 font-w500" rel="noreferrer" target="_blank" href={getCarrierLink(box?.trackingId, box?.carrierName)}>
                {box?.trackingId}
              </a></li>
              {/* {index !== boxes.length - 1 && <span className="d-block fs-12 font-w500">,  </span>} */}
            </React.Fragment>
          ))}
        </ul>                
        }
      </div>
      {/* <div className="card-footer text-center border-0 mt-0">
        <button type="button" className="me-2 btn btn-primary btn-rounded">
          <span className="btn-icon-start text-primary">
            <i className="fas fa-plus"></i>
          </span>
          Tracking details
        </button>
      </div> */}
    </div>
  );
};

export default ShipmentTracking;
