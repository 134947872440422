import React from 'react'
import { useStateValue } from './../../../../../store/selectors/useStateValue'

const PaymentInfoCard = ({
  onClickUpdate,
  totalCost,
  totalPaid,
  isInvoiceApproved,
}) => {
  const { auth } = useStateValue()

  return (
    <>
      {isInvoiceApproved && (
        <div className="col-lg-12">
          <div className="text-black bg-bluudo-gradient card">
            <div className="card-header justify-content-between">
              <div className="text-black card-title h5">Payment Status</div>
              {auth.userType === 'business' && (
                <button
                  onClick={onClickUpdate}
                  type="button"
                  className="btn btn-dark btn-sm"
                >
                  Update
                </button>
              )}
            </div>
            <div className="card-body">
              <div className="profile-statistics">
                <div className="text-center">
                  <div className="row">
                    <div className="col text-black">
                      <h3 className="m-b-0 text-black font-w600">
                        ${totalCost}
                      </h3>
                      <span>Total</span>
                    </div>
                    <div className="col text-black">
                      <h3 className="m-b-0 text-black font-w600">
                        ${totalPaid ? totalPaid : 0}
                      </h3>{' '}
                      <span>Paid</span>
                    </div>
                    <div className="col text-black">
                      <h3 className="m-b-0 text-black">
                        ${totalCost - (totalPaid ? totalPaid : 0)}
                      </h3>{' '}
                      <span>Remaining</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PaymentInfoCard
