import {useQuery,useMutation, useQueryClient} from "react-query";
import http from "./http";
import { config } from "../connector/config";
import { useStateValue } from "../store/selectors/useStateValue";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
export const useFetchSuppliers = (params = { }) => {

    const { auth } = useStateValue();

    return useQuery(['get-suppliers',JSON.stringify(params),auth.auth.accessToken],( ) => {
        if(params.search?.trim() === ""){
            delete params.search;
        }
        return http().request({
            url:    `${config.businessSuppliersList}`,
            method: 'GET',
            params: params,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
          })
    })
}

export const useFetchClients = (params = {}) => {

    const { auth } = useStateValue();

    return useQuery(['get-clients', JSON.stringify(params), auth.auth.accessToken], () => {
        if (params.search?.trim() === "") {
            delete params.search;
        }
        return http().request({
            url: `/supplier/clients`,
            method: 'GET',
            params: params,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    })
}

export const useFetchClientProducts = (id) => {

    const { auth } = useStateValue();

    return useQuery(['get-clients', auth.auth.accessToken], () => {
        return http().request({
            url: `/clients/${id}/products`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    })
}

export const useAssignSupplier = () => {

    const { auth } = useStateValue();

    return useMutation((newInvitation) => {
        return http().request({
            url:`${config.businessSuppliersList}/invitations`,
            method: 'POST',
            data: newInvitation,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
          })


    })
}

export const useFetchSuppliersProducts = (params = { }) => {
    const { auth } = useStateValue();
    const location = useLocation();
    const selectedSupplier = location.state?.selectedSupplier;
    return useQuery(['get-supplier-products',JSON.stringify(params),auth.auth.accessToken],( ) => {
        return http().request({
            url: `${config.businessSuppliersProducts.replace('{supplierId}', selectedSupplier.id)}`,
            method: 'GET',
            params: params,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
          })
    })
}

export const useFetchSuppliersMutaion = () => {

    const { auth } = useStateValue();

    return useMutation((params)=>{
        if(params.search?.trim() === ''){
            delete params.search
        }
        return http().request({
            url:    `${config.businessSuppliersList}`,
            method: 'GET',
            params: params,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    })
}

export const useUnlinkProduct = ({onSuccess}) => {

    const { auth } = useStateValue();
    const location = useLocation();
    const selectedSupplier = location.state?.selectedSupplier;
    const queryClient = useQueryClient();

    return useMutation(({productId})=>{
        return new Promise((resolve, reject) => {
            http().request({
                url:    `${config.businessGetProducts}/${productId}/permissions/`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.auth.accessToken,
                }
            }).then(({ data: { data: product }}) =>{
                const permission =  product.Suppliers
                                   .filter((supplier)=>{
                                      return Number(supplier.id) == Number(selectedSupplier.id)
                                   })[0].Permission
                http().request({
                    url:    `${config.businessGetProducts}/${productId}/permissions/${permission.id}`,
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": auth.auth.accessToken,
                    }
                }).then(({data})=>{
                     resolve(data)
                }).catch((e)=>{
                     reject(e);
                })
            }).catch((e) =>{
                 reject(e);
            })
        })
    },{
         onSuccess: () => { 
             onSuccess();
             queryClient.invalidateQueries('get-supplier-products')
         } 
    })
}
export const useUnlinkProductTwo = ({onSuccess}) => {

    const { auth } = useStateValue();
    const queryClient = useQueryClient();
    return useMutation(({productId,permissionId})=>{
        return  http().request({
            url:    `${config.businessGetProducts}/${productId}/permissions/${permissionId}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        })
    },{
         onSuccess: () => { 
             onSuccess();
             queryClient.invalidateQueries('get-product-details')
         } 
    })
}