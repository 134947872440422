import product1 from "../../images/product/4.jpg";
import pic2 from "../../images/pic2.jpg";
import pic3 from "../../images/pic3.jpg";
import pic4 from "../../images/pic4.jpg";
import pic5 from "../../images/pic5.jpg";
import pic6 from "../../images/pic6.jpg";
import pic8 from "../../images/contacts/2.jpg";
import pic9 from "../../images/contacts/3.jpg";
import pic10 from "../../images/contacts/4.jpg";

export const productsData = [
  {
    id: 1,
    productThumb: product1,
    productName: "Womens Vintage Retro Bodycon Ladies",
    productSKU: "BL003 DRESS01",
    productASIN: "B09ABC2327",
    productManufacturingCost: "2.1",
    productWidth: "123",
    productHeight: "321",
    productLength: "231",
    productWeight: "0.8",
    productSize: "S",
    shippingModeAirCost: "4",
    shippingModeSeaCost: "2.1",
    shippingModeLandCost: "1.4",
    productManufacturingDays: "7",
    fastestShippingDays: "7",
    shippingHandlingDays: "2",
    myWarehouseStock: "2000",
    amazonIntegrated: true,
    suppliers: [],
  },
  {
    id: 2,
    productThumb: pic10,
    productName: "Yellow Retro Bodycon Ladies",
    productSKU: "BL003 DRESS02",
    productASIN: "B09ABC2328",
    productManufacturingCost: "2.1",
    productWidth: "123",
    productHeight: "321",
    productLength: "231",
    productWeight: "0.8",
    productSize: "S",
    shippingModeAirCost: "4",
    shippingModeSeaCost: "2.1",
    shippingModeLandCost: "1.4",
    productManufacturingDays: "7",
    fastestShippingDays: "7",
    shippingHandlingDays: "2",
    myWarehouseStock: "2000",
    amazonIntegrated: true,
    suppliers: [],
  },
  {
    id: 3,
    productThumb: pic3,
    productName: "White Retro Bodycon Ladies",
    productSKU: "BL003 DRESS03",
    productASIN: "B09ABC2329",
    productManufacturingCost: "2.1",
    productWidth: "123",
    productHeight: "321",
    productLength: "231",
    productWeight: "0.8",
    productSize: "S",
    shippingModeAirCost: "4",
    shippingModeSeaCost: "2.1",
    shippingModeLandCost: "1.4",
    productManufacturingDays: "7",
    fastestShippingDays: "7",
    shippingHandlingDays: "2",
    myWarehouseStock: "2000",
    amazonIntegrated: true,
    suppliers: [],
  },
  {
    id: 4,
    productThumb: pic4,
    productName: "Mens Vintage Retro Bodycon Ladies",
    productSKU: "BL003 SHIRT04",
    productASIN: "B09ABC2330",
    productManufacturingCost: "2.1",
    productWidth: "123",
    productHeight: "321",
    productLength: "231",
    productWeight: "0.8",
    productSize: "S",
    shippingModeAirCost: "4",
    shippingModeSeaCost: "2.1",
    shippingModeLandCost: "1.4",
    productManufacturingDays: "7",
    fastestShippingDays: "7",
    shippingHandlingDays: "2",
    myWarehouseStock: "2000",
    amazonIntegrated: true,
    suppliers: [],
  },
  {
    id: 5,
    productThumb: pic2,
    productName: "Gretchen Aminoffs",
    productSKU: "BL003 SHIRT44",
    productASIN: "B09ABC2388",
    productManufacturingCost: "2.1",
    productWidth: "123",
    productHeight: "321",
    productLength: "231",
    productWeight: "0.8",
    productSize: "S",
    shippingModeAirCost: "4",
    shippingModeSeaCost: "2.1",
    shippingModeLandCost: "1.4",
    productManufacturingDays: "7",
    fastestShippingDays: "7",
    shippingHandlingDays: "2",
    myWarehouseStock: "2000",
    amazonIntegrated: true,
    suppliers: [],
  },
  {
    id: 6,
    productThumb: pic5,
    productName: "Davis Siphron",
    productSKU: "BL003 SHIRT99",
    productASIN: "B09ABC2397",
    productManufacturingCost: "2.1",
    productWidth: "123",
    productHeight: "321",
    productLength: "231",
    productWeight: "0.8",
    productSize: "S",
    shippingModeAirCost: "4",
    shippingModeSeaCost: "2.1",
    shippingModeLandCost: "1.4",
    productManufacturingDays: "7",
    fastestShippingDays: "7",
    shippingHandlingDays: "2",
    myWarehouseStock: "2000",
    amazonIntegrated: true,
    suppliers: [],
  },
  {
    id: 7,
    productThumb: pic6,
    productName: "Zaire Lubin",
    productSKU: "BL003 SHIRT29",
    productASIN: "B09ABC2384",
    productManufacturingCost: "2.1",
    productWidth: "123",
    productHeight: "321",
    productLength: "231",
    productWeight: "0.8",
    productSize: "S",
    shippingModeAirCost: "4",
    shippingModeSeaCost: "2.1",
    shippingModeLandCost: "1.4",
    productManufacturingDays: "7",
    fastestShippingDays: "7",
    shippingHandlingDays: "2",
    myWarehouseStock: "2000",
    amazonIntegrated: true,
    suppliers: [],
  },
  {
    id: 8,
    productThumb: pic9,
    productName: "Jakob Gouse",
    productSKU: "BL003 SHIRT77",
    productASIN: "B09ABC2356",
    productManufacturingCost: "2.1",
    productWidth: "123",
    productHeight: "321",
    productLength: "231",
    productWeight: "0.8",
    productSize: "S",
    shippingModeAirCost: "4",
    shippingModeSeaCost: "2.1",
    shippingModeLandCost: "1.4",
    productManufacturingDays: "7",
    fastestShippingDays: "7",
    shippingHandlingDays: "2",
    myWarehouseStock: "2000",
    amazonIntegrated: true,
    suppliers: [],
  }
];
