
import { useMutation, useQuery,useQueryClient } from "react-query";
import { useStateValue } from "../store/selectors/useStateValue";
import { config } from "../connector/config";
import http from "./http";

export const useUploadResetPassword = () => {
    const { auth } = useStateValue();
    return useMutation(({data}) => {
        return http().request({
            url: `${config.baseURL}/user/password/reset`,
            method: 'POST',
            data: data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        });
    });
}

export const useUploadNewPassword = () => {
    const { auth } = useStateValue();
    return useMutation(({token, data}) => {
        return http().request({
            url: `${config.baseURL}/user/password/reset/accept?token=${token}`,
            method: 'POST',
            data: data,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": auth.auth.accessToken,
            }
        });
    });
}
