import React from 'react'
import amazonLogo from '../../../../images/freight/amazon.png'

const AMZCreateLabelCard = () => {
  return (
    <div className="d-flex align-items-center project-image mb-4">
      <img src={amazonLogo} alt="Amazon logo" />
      <div>
        <small className="d-block fs-16 font-w700">Send to Amazon</small>
      </div>
    </div>
  )
}

export default AMZCreateLabelCard
